import axios, {AxiosResponse, ResponseType} from "axios";
import { navigate } from "../../history/navigate";
import { notifyError } from "../../components/notification/Notification";

export class HTTPClient {

    static _notifierFunc;

    public _params: {};
    public _timeout: number;
    public _contentType: string;
    public _responseType: ResponseType; 
    public _acceptType: string;
    public _formData: {} | null;
    public _userNotification: {"title" : string, "type" : string} ;
    public _reqPath: string ;
    public _reqType: string ;
    public _successCB? : ({}) => void;
    public _failCB? : ({}) => void ;
    public _promise : Promise<any> | null;

    
    public REQUEST_TYPE = {
        GET: "GET",
        POST: "POST",
        PUT: "PUT",
        DELETE: "DELETE"
    };

    public CONTENT_TYPE = {
        JSON: "application/json",
        MULTIPART: "multipart/form-data"
    };


    public ACCEPT = {
        DEFAULT: "application/json, text/plain, */*",
        JSON: "application/json,*/*",
    };

    
    constructor() {
        this._params = {};
        this._timeout = 10000;
        this._contentType = this.CONTENT_TYPE.JSON;
        this._responseType = "json";
        this._acceptType = this.ACCEPT.DEFAULT;
        this._formData = null;
        this._userNotification = { title: "TITLE_NOT_CONFIGURED", type: "TYPE_NOT_CONFIGURED" };
        this._reqPath = "";
        this._reqType = "";
        this._promise = null;
    }

    set requestPath(path) {
        this._reqPath = path;
    }

    set requestType(type) {
        if (Object.keys(this.REQUEST_TYPE).includes(type)) {
            this._reqType = type;
        } else {
            console.error("Request type is not defined on HTTPClient")
        }
    }

    set successCallback(cb) {
        this._successCB = cb;
    }

    set failCallback(cb) {
        this._failCB = cb;
    }

    set timeout(duration) {
        this._timeout = duration;
    }

    set contentType(type) {
        this._contentType = type;
    }

    set responseType(type) {
        this._responseType = type;
    }

    set acceptType(type) {
        this._acceptType = type;
    }

    set formData(formData) {
        this._formData = formData;
    }

    set userNotification(notification) {
        this._userNotification = notification;
    }

    addParameters = (params) => {
        this._params = { ...this._params, ...params };
    };

    static setAuthToken = (token) => {
        //axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    };

    static unsetAuthToken = () => {
        //axios.defaults.headers.common['Authorization'] = null;
    };

    _build = () => {
        const headers = { 'Content-Type': this._contentType, 'Accept': this._acceptType};
        const params = this._formData ? this._formData : this._params;
        this._promise = null;
        switch (this._reqType) {
            case this.REQUEST_TYPE.GET:
                this._promise = axios.get(this._reqPath, { headers: headers, timeout: this._timeout, responseType: this._responseType, params:params});
                break;
            case this.REQUEST_TYPE.POST:
                this._promise = axios.post(this._reqPath, params, { headers: headers, timeout: this._timeout, responseType: this._responseType, withCredentials:true  });
                break;
           /* case this.REQUEST_TYPE.PUT:
                this._promise = axios.put(this._reqPath, params, { headers: headers, timeout: this._timeout, responseType: this._responseType, withCredentials:true  });
                break;*/
            case this.REQUEST_TYPE.DELETE:
                this._promise = axios.delete(this._reqPath, { headers: headers, timeout: this._timeout, responseType: this._responseType, withCredentials:true  });
                break;
            default:
                console.log(`This request type (${this._reqType}) not handled by HTTPClient`);
                break;
        }
    };

    send = () => {
        this._build();
        this._promise && this._promise.then((res) => {
            this._successCB && this._successCB(res.data);
            if (HTTPClient._notifierFunc && this._userNotification) {
                HTTPClient._notifierFunc(this._userNotification.title, {
                    variant: this._userNotification.type
                });
            }
        }).catch((err) => {
            try {
                if(err.response.status == 403 )
                {
                    if(err.response.data.error=="TenantNotFound"){
                    navigate("/tenantNotFound");
                    }
                    else if(err.response.data.error=="TenantNotActivated"){
                        navigate("/tenantNotActivated");
                    }
           }
                
            } catch (error) {
                navigate('/tenantNotFound')
            }
            if(err.response.data)        
                this._failCB && this._failCB(err.response.data);
            else
                this._failCB && this._failCB(err.response);
            if (HTTPClient._notifierFunc) {
                HTTPClient._notifierFunc("Operation failed", {
                    variant: 'error'
                });
            }
            //HTTPClient.showErrorFunction(err.toString());
        })
    };

}
