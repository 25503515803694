import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { Checkbox, Divider, FormControl, FormHelperText, Input, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { notifyError } from '../notification/Notification';
import { useTranslation } from 'react-i18next';
import GenericButton from '../button/GenericButton';
import { Floor } from '../../interfaces/Floor';
import { Client } from '../../interfaces/Client';
import { Delete, FlipToFrontSharp } from '@material-ui/icons';
import { GeneralDialog } from '../modals/GeneralDialog';




interface Props {
  onSubmit: (floorObj) => void,
  onDelete: () => void,
  handleOpenModal: () => void,
  handleCloseModal: () => void,
  openConfirmModal:boolean,
  floor: Floor,
  isNew: boolean,
  floorPlan: File | null,
  floorImageFile: File | null,
  exclusiveUsers: Array<Client>,
  isLoading: boolean
}
interface Image {
  description: string,
  uri: string,
  id: string
}

export const UploadPlanForm: React.FC<Props> = (props) => {

  const { onSubmit, floor, floorPlan, isNew, exclusiveUsers, floorImageFile,isLoading } = props;
  const { t, i18n } = useTranslation();
  const [planHeader, setPlanHeader] = useState(floor.name);
  const [nameErr, setNameErr] = useState(false);
  const [nameErrText, setNameErrText] = useState("");
  // const [planImage, setPlanImage]   = useState(Array<File>());
  const [planImageId, setPlanImageId] = useState(""); // TODO (Kaan) : Get Floor Plan id from controller component  
  // const [planUri, setPlanUri] = useState("");
  const [isPlanUploaded, setIsPlanUploaded] = useState(false);
  const [floorImage, setFloorImage] = useState(floor.images);
  const [actualPlan, setActualPlan] = useState(floorPlan);
  const [actualImage, setActualImage] = useState(floorImageFile);
  const [planChanged, setPlanChanged] = useState(false)
  const [exclusiveUsersIds, setExclusiveUsersIds] = useState(floor.reservedForClients.map(client=>client.id))

  useEffect(() => {
    console.log("STATE MOUNTED", floorImage)
  },[])



  const planHeaderChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameErr(false);
    setNameErrText("");
    setPlanHeader(event.target.value);
  }
  const planImageChanged = (plan) => {
    if (plan) {

      HTTP_REQUESTS.TEMPIMAGE_API.POST_IMAGE(plan[0], (res) => {
        setPlanImageId(res.id);
        setIsPlanUploaded(true);
      }, (err) => {
        notifyError(err.message)
        setIsPlanUploaded(false);
      })
    }

  }

  const addtoState = (x) => {
    return (prevState) => [...prevState, x];
  }

  const deleteFloorImage = (index) => {
    let temp = floorImage.filter((_, i) => i !== index);
    setFloorImage(temp);
  }

  const floorImageChanged = (plan) => {
    if (plan) {
      HTTP_REQUESTS.TEMPIMAGE_API.POST_IMAGE(plan[0], (res) => {
        let uri= isNew ? res.uri:"string";
        let newObj: Image = { description: res.description, id: res.id, uri: uri };
        console.log("newObj")
        console.log(newObj)
        // let newVersion = addtoState(newObj);
        setFloorImage(()=>[newObj]);
      }, (err) => {
        notifyError(err.message)
      })
    }
  }

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
  };


  const beforeOnSubmit = () => {

    let formValid = true;

    if (!planHeader || planHeader === "") {
      setNameErr(true);
      setNameErrText(t('UPLOAD.1'));
      formValid = false;

    }

    if (isNew) {
      if (!planImageId || planImageId === "") {
        formValid = false;
        notifyError(t('UPLOAD.2'))
      }
    }

    if (formValid) {
      

      let floorObj  = {
        description:"string",
        name:planHeader,
        newFloorplanImageId: isPlanUploaded ? planImageId:null,
        newFloorplanScale: null,
        reservedForClients:exclusiveUsersIds,
        images:floorImage.length >0 ? floorImage : []
      }

      onSubmit(floorObj);

      
    }
    else {
      scrollToTop()
    }

  }

  const onDeleteFloor = () =>{
    props.handleOpenModal()
  }

  const onCloseConfirmModal = () => {
    props.handleCloseModal()
  }




  const handleExclusiveUsersChange = (event) => {
    //const {​​​​​​​​ options }​​​​​​​​ = event.target as HTMLSelectElement;
    //console.log(options);
    
    let values = event.target.value;
  
    let newArray:Array<string>=[];
    for (let i = 0; i < values.length; i++) {
        let element = values[i];
        if(element != t("CANVAS.28")){
            newArray.push(element);
        }
        
    }
    setExclusiveUsersIds(newArray);
  };

  const renderExclusiveSelect = (selected) => {
    console.log("selected");
    console.log(selected);
    let formatted:Array<string> = [];

    selected.forEach(selectedClient => {
        let found = exclusiveUsers.find((client)=>client.id==selectedClient);
        if( found!= undefined){
            formatted.push(found.name);
        }
    });

    if(formatted.length>0){
      return formatted.join(', ');
  }else{
      return t("CANVAS.28");
  }
}




  return (

    <div className="upload-plan-form">
      <Grid container  >

        <TextField
          color="secondary"
          required
          fullWidth
          id="plan-header"
          label={t('UPLOAD.3')}
          error={nameErr}
          helperText={nameErrText}
          name="planHeader"
          onChange={planHeaderChanged}
          value={planHeader}
          autoComplete="off"
          inputProps={{
            style: {
              height: "10vh",
              fontSize: "3rem",
              color: "rgba(0, 0, 0, 0.55)"
            },
          }}
          className="header-input"
        />
        <Divider orientation="horizontal" className="divider" />
        <div className="exclusive-select">
          
          <FormControl style={{ "minWidth": "100px" }}>
            <InputLabel id="demo-mutiple-checkbox-label">{t('UPLOAD.6')}</InputLabel>
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={exclusiveUsersIds.length == 0 ? [t("CANVAS.28")]:exclusiveUsersIds}
              onChange={handleExclusiveUsersChange}
              input={<Input />}
              renderValue={renderExclusiveSelect}
            //MenuProps={MenuProps}
            >
              {exclusiveUsers.map((existingClient) => (
                <MenuItem key={existingClient.id} value={existingClient.id}>
                  <Checkbox checked={exclusiveUsersIds.filter( userId => userId === existingClient.id ).length > 0}/>
                  <ListItemText primary={existingClient.name} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText >{t('UPLOAD.7')}</FormHelperText>
          </FormControl>

        </div>
        <div className="floor-image">
          <Divider orientation="horizontal" className="divider" />
          <Typography className="dropzone-header" color="secondary" variant="h4">{t('UPLOAD.8')}</Typography>
          <DropzoneArea
            acceptedFiles={['image/*']}
            onDrop={(files) => floorImageChanged(files)}
            onDelete={()=>deleteFloorImage(0)}
            filesLimit={1}
            getFileLimitExceedMessage = {(filesLimit:number)=>{return t("DROPZONE.1")}}
            getFileAddedMessage = {(fileName:string)=>{return t("DROPZONE.2")}}
            getFileRemovedMessage = {(fileName:string)=>{return t("DROPZONE.3")}}
            getDropRejectMessage = {(rejectedFile:File)=> {return t("DROPZONE.4")}}
            initialFiles={actualImage !== null ? [actualImage] : []}
            maxFileSize={104857600}
            dropzoneText={t('UPLOAD.9')}
            showFileNames={true}
            clearOnUnmount
            inputProps={{
              style: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "55vh"
              }
            }}
          />
        </div>
        <Divider orientation="horizontal" className="divider" />
        <Typography className="dropzone-header" color="secondary" variant="h4">{t('UPLOAD.4')}</Typography>
        <DropzoneArea
          acceptedFiles={['image/*']}
          onDrop={(files) => planImageChanged(files)}
          initialFiles={actualPlan !== null ? [actualPlan] : []}
          getFileLimitExceedMessage = {(filesLimit:number)=>{return t("DROPZONE.1")}}
          getFileAddedMessage = {(fileName:string)=>{return t("DROPZONE.2")}}
          getFileRemovedMessage = {(fileName:string)=>{return t("DROPZONE.3")}}
          getDropRejectMessage = {(rejectedFile:File)=> {return t("DROPZONE.4")}}
          filesLimit={1}
          maxFileSize={104857600}
          dropzoneText={t('UPLOAD.9')}
          showFileNames={true}
          clearOnUnmount
          inputProps={{
            style: {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "55vh"
            }
          }}
        />
        
        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", margin: "14px" }}>
        {
          !props.isNew ? 
              <Button  variant="contained" color="primary" className="delete-btn" disabled={isLoading} onClick={onDeleteFloor} > <Delete fontSize="small" className="delete-icon" /> {t('UPLOAD.17')}</Button>
          :null
        }
          <GenericButton label={ isNew ? 'UPLOAD.5' : 'UPLOAD.10'} disabled={isLoading} onClick={beforeOnSubmit} />
          {/* <Button variant="contained" type="button" onClick={beforeOnSubmit} color="primary">{t('UPLOAD.5')}</Button> */}
        </div>
        
      </Grid>

      {props.openConfirmModal ? <GeneralDialog cancel={t('UPLOAD.18')} open={props.openConfirmModal} confirm={t("UPLOAD.19")} confirmCB={props.onDelete} cancelCB={onCloseConfirmModal} header={t('UPLOAD.20')} text={t('UPLOAD.21')} ></GeneralDialog> :null}



    </div>


  )
}
