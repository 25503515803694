import React, { useState } from 'react';
import { Button, Typography, Grid, FormControl, InputLabel, Select, MenuItem, TextField, FormControlLabel, FormLabel, Checkbox, FormHelperText } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { navigate } from '../../history/navigate';
import { useTranslation } from 'react-i18next';
import { Clear, EditOutlined } from '@material-ui/icons';
import { Client } from '../../interfaces/Client';
import { AppContext } from "../../middlewares/appContext/AppContext";
import AvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone'
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { notifyError } from '../notification/Notification';

import defaultProfilePicture from '../images/blank-profile-picture-973460_960_720.png';

interface userObj {
    email: string,
    enabled?: boolean,
    firstname: string,
    lastname: string,
    role: string,
    salutation: string,
    password: string,
    newPassword: string,
    newPassword2: string,
    client:{
        id:string,
        name:string
    }
}

interface AuthUpdateObj {
    newImageId:string,
    password:string,
    firstname: string,
    lastname: string,
    salutation: string,
    newPassword: string,
    newPassword2: string,
}

interface Props {
    user?: userObj,
    userImage: string,
    isClientAdmin: boolean,
    onSubmit: (updateObj:AuthUpdateObj) => void
}

export const ProfileForm: React.FC<Props> = (props) => {
    const { t, i18n } = useTranslation();
    const { user, userImage } = props

    const context = React.useContext(AppContext);


    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    document.title = t('TITLE.8');

    const checkObjNull = (obj) => {

        return Object.keys(obj).length === 0 && obj.constructor === Object
    }

    const checkPass = () => {
        if (!checkObjNull(user)) {
            return ""
        }
        else {
            return ""
        }
    }

    const checkRole = () => {
        if(props.isClientAdmin)
        {
            if(context)
            {
                return context.user.client?.id
            }
            else {
                return '';
            }
            
        }
        else {
           return props.user?.client.id
        }
    }

    const setAvatarImage = () => {
        if(userImage!="" || userImage !== null)
        {
            let avatar = defaultProfilePicture;
            return avatar
        }
        else {
            let avatar = userImage;
            return avatar
        }
    }

    const [email, setEmail] = useState(user?.email);
    const [password, setPassword] = useState(checkPass);
    const [newPassword, setNewPassword] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [salutation, setSalutation] = useState(user?.salutation);
    const [firstName, setFirstName] = useState(user?.firstname);
    const [lastName, setLastName] = useState(user?.lastname);
    const [role, setRole] = useState(user?.role || 'USER');
    const [enabled, setEnabled] = useState(true);
    const [avatar, setAvatar] = useState(userImage);
    const [imageId, setImageId] = useState("");
    const [disabled, setDisabled] = useState(false); // Disabled all non-required fields

    const [emailErr, setEmailErr] = useState(false);
    const [passwordErr, setPasswordErr] = useState(false);
    const [newPasswordErr, setNewPasswordErr] = useState(false);
    const [salutationErr, setSalutationErr] = useState(false);
    const [firstNameErr, setFirstNameErr] = useState(false);
    const [lastNameErr, setLastNameErr] = useState(false);
    const [enabledErr, setEnabledErr] = useState(false);

    const [emailErrText, setEmailErrText] = useState("");
    const [salutationErrText, setSalutationErrText] = useState("");
    const [firstNameErrText, setFirstNameErrText] = useState("");
    const [lastNameErrText, setLastNameErrText] = useState("");
    const [passwordErrText, setPasswordErrText] = useState("");
    const [newPasswordErrText, setNewPasswordErrText] = useState("");
    const [enabledErrText, setEnabledErrText] = useState("");

    const roleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRole((event.target as HTMLInputElement).value);

    };

    const enabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnabled(!enabled);
        setEnabledErr(false);
        setEnabledErrText("");
    }

    const emailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setEmailErr(false);
        setEmailErrText("");
    }

    const passwordChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name) {
            case 'password':
                setPassword(e.target.value);
                setPasswordErr(false);
                setPasswordErrText('');
                break;
            case 'newPassword':
                setNewPassword(e.target.value);
                setNewPasswordErr(false);
                setNewPasswordErrText('');
                break;
            case 'newPassword2':
                setNewPassword2(e.target.value);
                setNewPasswordErr(false);
                setNewPasswordErrText('');
                break;
            default:
                break;
        }
    }

    const salutationChanged = (e: any) => { //TODO: Found suitable solution for onChange Event
        e.preventDefault();
        setSalutation(e.target.value);
        setSalutationErr(false);
        setSalutationErrText("");
    }

    const firstNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
        setFirstNameErr(false);
        setFirstNameErrText("");
    }

    const lastNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value);
        setLastNameErr(false);
        setLastNameErrText("");
    }


    const backButtonClick = () => {
        navigate('/users');
    }

    const handleDrop = dropped => {

        dropped.forEach(file => {
             HTTP_REQUESTS.TEMPIMAGE_API.POST_IMAGE(file,
                (res) => 
                {console.log(res); setImageId(res.id);setAvatar(dropped[0])}, 
             (err) => {notifyError(err.message);
            })

            }) 
    }




    const onSubmitClick = () => {

        let formValid = true;

        if (salutation === ' ' || salutation == null) {
            setSalutationErr(true)
            formValid = false;
            setSalutationErrText(t('USERCRUD.26'))
        }
        if (email === '' || email == null) {
            setEmailErr(true)
            formValid = false;
            setEmailErrText(t('USERCRUD.27'))
        }
        else if (email) {
            if (!re.test(email)) {
                setEmailErr(true)
                formValid = false;
                setEmailErrText(t('USERCRUD.27'))
            }
        }
        if (firstName === '' || firstName == null) {
            setFirstNameErr(true)
            formValid = false;
            setFirstNameErrText(t('USERCRUD.28'))
        }
        if (lastName === '' || lastName == null) {
            setLastNameErr(true)
            formValid = false;
            setLastNameErrText(t('USERCRUD.29'))
        }
        if( (password === '' || password == null) && (newPassword || newPassword2)) {
            formValid = false;
            setPasswordErr(true);
            setPasswordErrText(t('USERCRUD.30'));
        }
        if (password && (newPassword !== newPassword2)) {
            formValid = false;
            setNewPasswordErr(true);
            setNewPasswordErrText(t('USERCRUD.31'));
        }
        if (formValid) {
            let updateObj:AuthUpdateObj = {
              newImageId:imageId,
              password: password || '',
              firstname: firstName || '',
              lastname: lastName || '',
              newPassword: newPassword || '',
              newPassword2: newPassword2 || '',
              salutation: salutation || ''
            }

            props.onSubmit(updateObj)

        }
    }

    return (
        <div className="user-crud-container">
            <Grid container spacing={2} direction="column" className="wrapper">
                <Grid container className="header">
                    <Typography variant="h5" className="user-header">{!checkObjNull(user) ? `${user?.firstname}  ${user?.lastname}` : t('USERCRUD.15')}</Typography>
                </Grid>
                <Grid container className="button-group" style={{justifyContent:"space-between"}}>
                    <Button className="back-btn" variant="contained" onClick={!checkObjNull(user) ? backButtonClick : onSubmitClick}><ArrowBackIosIcon fontSize="small" className="back-icon" />{!checkObjNull(user) ? t('USERCRUD.9') : t('USERCRUD.18')}</Button>
                    {!checkObjNull(user) ?
                        <Button className="edit-btn" variant="contained" onClick={onSubmitClick} > <EditOutlined fontSize="small" className="edit-icon" color="primary" /> {t('USERCRUD.10')}</Button>
                        : <Button className="cancel-btn" variant="outlined" onClick={backButtonClick} > <Clear fontSize="small" className="cancel-icon" color="primary" /> {t('USERCRUD.19')}</Button>}
                </Grid>
                <form className="user-info-form" noValidate>
                    <Grid container spacing={3} direction="row" className="first-row">

                        <Grid className="select-grid" item xs={12} >
                            <FormControl className="select"
                                color="secondary"
                                error={salutationErr}
                                disabled={disabled}
                            >
                                <InputLabel>{t('USERCRUD.1')}</InputLabel>
                                <Select
                                    onChange={salutationChanged}
                                    defaultValue={salutation}
                                    inputProps={{ 'aria-label': '1' }}
                                >
                                    <MenuItem value={' '}><em>{t('USERCRUD.14')}</em></MenuItem>
                                    <MenuItem value={"Mr"}>{t('USERCRUD.11')}</MenuItem>
                                    <MenuItem value={"Mrs"}>{t('USERCRUD.12')}</MenuItem>
                                </Select>
                                <FormHelperText color="primary" >{salutationErr ? salutationErrText : null}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className="unset-left-padding">
                            <TextField
                                autoComplete="off"
                                name="firstName"
                                fullWidth
                                id="firstName"
                                label={t('USERCRUD.2')}
                                color="secondary"
                                value={firstName}
                                onChange={firstNameChanged}
                                error={firstNameErr}
                                helperText={firstNameErrText}
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                fullWidth
                                id="lastName"
                                label={t('USERCRUD.3')}
                                name="lastName"
                                autoComplete="off"
                                color="secondary"
                                value={lastName}
                                onChange={lastNameChanged}
                                error={lastNameErr}
                                helperText={lastNameErrText}
                                disabled={disabled}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="second-row">
                        
                        <Grid item xs={4} style={{textAlign:"center"}}>
                        <Dropzone
                            onDrop={handleDrop}
                            noKeyboard 
                        >
                            {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <AvatarEditor width={150} borderRadius={200} height={150} image={avatar} />
                                <input {...getInputProps()} />
                            </div>
                            )}
                        </Dropzone>
                            <FormHelperText style={{textAlign:"center"}} color="secondary" ><em>{t("PROFILE.3")}</em></FormHelperText>

                        </Grid>
                        

                        <Grid item xs={8} className="unset-left-padding">
                            <TextField
                                color="secondary"
                                fullWidth
                                id="email"
                                label={t('USERCRUD.4')}
                                name="email"
                                autoComplete="off"
                                value={email}
                                className="mail-input"
                                onChange={emailChanged}
                                error={emailErr}
                                helperText={emailErrText}
                                disabled={true}
                            />
                            <TextField
                                color="secondary"
                                fullWidth
                                id="password"
                                type="password"
                                label={t('USERCRUD.5')}
                                name="password"
                                autoComplete="off"
                                value={password}
                                className="password-input"
                                error={passwordErr}
                                helperText={passwordErrText}
                                onChange={passwordChanged}
                                disabled={disabled}
                            />
                            <TextField
                              color="secondary"
                              fullWidth
                              id="newPassword"
                              type="password"
                              label={t('USERCRUD.24')}
                              name="newPassword"
                              autoComplete="off"
                              value={newPassword}
                              className="password-input"
                              error={newPasswordErr}
                              helperText={newPasswordErrText}
                              onChange={passwordChanged}
                              disabled={disabled}
                            />
                            <TextField
                              color="secondary"
                              fullWidth
                              id="newPassword2"
                              type="password"
                              label={t('USERCRUD.21')}
                              name="newPassword2"
                              autoComplete="off"
                              value={newPassword2}
                              className="password-input"
                              onChange={passwordChanged}
                              error={newPasswordErr}
                              helperText={newPasswordErrText}
                              disabled={disabled}
                            />
                        </Grid>

                    </Grid>
                    {/* <Grid container direction="row" justify="flex-end" className="radio-group">
                        <Grid container xs={8} justify="space-between">
                            <Grid item xs={8}>
                                <FormControl component="fieldset" >
                                    <FormLabel component="legend">{t('USERCRUD.6')}</FormLabel>
                                    <RadioGroup row aria-label="Rolle" name="role" value={role} onChange={roleChanged} style={{ justifyContent: "space-evenly" }} >
                                        {
                                            props.isClientAdmin ? 
                                                <div>
                                                    <FormControlLabel value="CLIENT_ADMIN" control={<Radio color="primary" />} label={"Client Admin"} />
                                                    <FormControlLabel value="CLIENT_USER" control={<Radio color="primary" />} label={"Client User"} /> 
                                                </div> :
                                                <div>
                                                    <FormControlLabel value="USER" control={<Radio color="primary" />} label={t('USERCRUD.7')} />
                                                    <FormControlLabel value="ADMIN" control={<Radio color="primary" />} label={t('USERCRUD.8')} />
                                                    <FormControlLabel value="CLIENT_ADMIN" control={<Radio color="primary" />} label={"Client Admin"} />
                                                    <FormControlLabel value="CLIENT_USER" control={<Radio color="primary" />} label={"Client User"} />
                                                </div>
                                        }


                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={4} className="user-status-grid">
                                <FormControlLabel control={<Checkbox checked={enabled} value={enabled} color="primary" onChange={enabledChange} />} label={t('USERCRUD.13')} />
                            </Grid>
                        </Grid>
                    </Grid> */}
                </form>
            </Grid>
        </div >
    );

}
