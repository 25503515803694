import React from 'react';
import Konva from 'konva';
import { Stage, Layer, Star, Text, Image, KonvaNodeComponent, Circle, Line } from 'react-konva';
import Button from '@material-ui/core/Button';
import CropTwoToneIcon from '@material-ui/icons/CropTwoTone';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { Grid, ButtonGroup, TextField, Typography, Checkbox, FormGroup, InputLabel, Select, Input, MenuItem, ListItemText } from '@material-ui/core';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import DoneIcon from '@material-ui/icons/Done';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { withTranslation, WithTranslation } from 'react-i18next';
import MeasureIcon from '../icons/MeasureIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { RoomSharp } from '@material-ui/icons';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { HelperModal } from './HelperModal';

interface State {
    image: HTMLImageElement,
    canvasWidth: number,
    canvasHeight: number,
    drawMode: boolean,
    newRoomPoints: Array<number>
    rooms: Array<{ id: string, isExclusive: boolean, isNew: boolean, name: string, description: string, features: Array<string>, points: Array<number>,reservationForClientsEditable:boolean,reservedForClients:Array<{id:string,name:string}>, chairs: Array<{ id: string, x: number, y: number, collision: boolean, roomId: string, isExclusive: boolean, features: Array<string>, ignoreCollision: boolean, name: string,reservationForClientsEditable:boolean,reservedForClients:Array<{id:string,name:string}> }>, layer: any }>,
    editPoints: Array<{ roomId: string, x: number, y: number }>,
    editMode: boolean,
    selectedRoom: { id: string, isExclusive: boolean, features: Array<string>, name: string, description: string, points: Array<number>, reservationForClientsEditable:boolean,reservedForClients:Array<{id:string,name:string}>, chairs: Array<{ id: string, x: number, y: number, collision: boolean, features: Array<string>, roomId: string,reservationForClientsEditable:boolean,reservedForClients:Array<{id:string,name:string}> }>, layer: any } | null,
    oldRoom: { id: string, isExclusive: boolean, isNew: boolean, name: string, description: string, features: Array<string>, points: Array<number>, reservationForClientsEditable:boolean,reservedForClients:Array<{id:string,name:string}>, chairs: Array<{ id: string, x: number, y: number, collision: boolean, roomId: string, isExclusive: boolean, features: Array<string>, ignoreCollision: boolean, name: string,reservationForClientsEditable:boolean,reservedForClients:Array<{id:string,name:string}> }>, layer: any } | null,
    selectedRoomIndex: number | null,
    safeDistanceInPixels: number,
    roomName: string,
    roomDescription: string,
    roomNameErr: boolean,
    roomNameErrText: string,
    roomType: string,
    roomProperties: {},
    seatProperties: {},
    seatName: string,
    seatIsExclusive: boolean,
    seatIgnoreCollision: boolean,
    selectedSeat: { id: string, x: number, y: number, collision: boolean, roomId: string, isExclusive: boolean, features: Array<string>, ignoreCollision: boolean, name: string,reservationForClientsEditable:boolean,reservedForClients:Array<{id:string,name:string}> } | null,
    changedSeatFeatures: Array<string>,
    changedRoomFeatures: Array<string>,
    featureRoomArray: Array<string>,
    featureSeatArray: Array<string>,
    exclusiveUsersIds:Array<string>,
    modalOpen:boolean
}

interface Props {
    rooms: Array<{ id: string, isExclusive: boolean, isNew: boolean, name: string, description: string, features: Array<string>, points: Array<number>,reservationForClientsEditable:boolean,reservedForClients:Array<{id:string,name:string}>, chairs: Array<{ id: string, x: number, y: number, collision: boolean, roomId: string, isExclusive: boolean, features: Array<string>, ignoreCollision: boolean, name: string,reservationForClientsEditable:boolean,reservedForClients:Array<{id:string,name:string}> }>, layer: any }>,    
    saveCurrentRoomAction: (room: { id: string, isExclusive: boolean, name: string, description: string, points: Array<number>, chairs: Array<{ id: string, x: number, y: number, features: Array<string>, collision: boolean, roomId: string }>, layer: any }, name: string, description: string, roomType: string, features: Array<string>,userIds:Array<string>, callback:()=> void) => void,
    saveCurrentSeatAction: (seat: { id: string, x: number, y: number, collision: boolean, roomId: string, isExclusive: boolean, ignoreCollision: boolean, name: string }, seatName: string, seatIgnoreCollision: boolean, features: Array<string>,userIds:Array<string>, callback:()=> void)=> void,
    imageUrl: string | null,
    safeDistanceInPixels: number,
    roomFeatures: Array<string>,
    seatFeatures: Array<string>,
    saveNewRoom: (room: any, callback:()=> void) => any | null,
    deleteSeat: (seatId: string, callback:()=> void) => void,
    deleteRoom: (roomId: string, callback:()=> void) => void,
    exclusiveUsers: Array<{ id: string, name: string }>,
    measureClicked: () => void
}

interface RoomProperties {
    fullHDMonitor: boolean, monitorFourK: boolean, keyboard: boolean, mouse: boolean, standartSeat: boolean, ergonomicSeat: boolean
}


let newRoomCounter = 0;
let seatCounter = 0;
let lastpos = { x: 0, y: 0 };

class CanvasKonvaDraw extends React.Component<Props & WithTranslation, State> {

    constructor(props) {
        super(props);

        this.state = {
            image: new window.Image(),
            canvasWidth: 1000,
            canvasHeight: 1000,
            drawMode: false,
            newRoomPoints: [],
            editPoints: [],
            editMode: false,
            selectedRoom: null,
            oldRoom: null,
            selectedSeat: null,
            safeDistanceInPixels: this.props.safeDistanceInPixels,
            rooms: [],
            roomName: "",
            roomDescription: "",
            selectedRoomIndex: null,
            roomNameErr: false,
            roomNameErrText: "",
            roomType: "",
            seatIgnoreCollision: false,
            seatIsExclusive: false,
            seatName: "",
            roomProperties: {},
            seatProperties: {},
            changedRoomFeatures: [],
            changedSeatFeatures: [],
            featureRoomArray: [],
            featureSeatArray: [],
            exclusiveUsersIds:[],
            modalOpen:false

        };

    }
    /*componentDidUpdate(prevProps) {


        this.setState({
            rooms:this.props.rooms
        })

     }*/

    componentDidMount = () => {
        document.title = this.props.t('TITLE.11');
        if (this.props.imageUrl) {

            const roomSizeInMeter = 60;
            const image = new window.Image();
            image.src = this.props.imageUrl;
            image.onload = () => {

                this.setState({
                    image: image,
                    rooms: this.props.rooms,
                    canvasWidth: image.naturalWidth,
                    canvasHeight: image.naturalHeight,
                    changedRoomFeatures: this.props.roomFeatures,
                    changedSeatFeatures: this.props.seatFeatures
                });
                this.checkForCollision();
            }
        }
    }


    checkForCollision = () => {

        let rooms = this.state.rooms.slice();

        for (var i = 0; i < rooms.length; i++) {
            for (var j = 0; j < rooms[i].chairs.length; j++) {
                rooms[i].chairs[j].collision = false;
            }

        }

        for (var i = 0; i < rooms.length; i++) {
            let chairs = rooms[i].chairs;


            for (var j = 0; j < chairs.length; j++) {
                for (var k = 0; k < chairs.length; k++) {
                    if (chairs[j] != chairs[k] && this.haveIntersection(chairs[j], chairs[k], this.state.safeDistanceInPixels)) {
                        chairs[j].collision = true;
                        chairs[k].collision = true;
                    }
                }
            }


        }

        this.setState({
            rooms: rooms
        });
    }

    haveIntersection = (element1, element2, safeDistance) => {
        var a = element1.x - element2.x;
        var b = element1.y - element2.y;
        var c = Math.sqrt(a * a + b * b);

        if (c < safeDistance) {
            return true;
        } else {
            return false;
        }
    }

    handleDragStart = e => {

    };

    handleDragEnd = e => {
        const pointer = { x: e.target._lastPos.x, y: e.target._lastPos.y };

        let rooms = this.state.rooms;

        let newPoint = {
            id: e.target.attrs.id,
            x: pointer.x,
            y: pointer.y,
            collision: false,
            roomId: "",
            isExclusive: false,
            features: [],
            ignoreCollision: false,
            name: "",
            reservationForClientsEditable:true,
            reservedForClients:[]
        }


        for (var i = 0; i < rooms.length; i++) {
            for (var j = 0; j < rooms[i].chairs.length; j++) {

                if (rooms[i].chairs[j].id == e.target.attrs.id) {
                    newPoint.roomId = rooms[i].id;
                    rooms[i].chairs.splice(j, 1);
                    rooms[i].chairs.push(newPoint);

                    this.setState({
                        rooms: rooms
                    }, () => {
                        this.checkForCollision();
                    });
                    break;
                }
            }
        }

    };


    handleMouseDown = e => {
        const mousePos = e.target.getStage().getPointerPosition();

        if (this.state.drawMode) {
            this.handleCreateNewRoom(mousePos);
        }

    }

    handleCreateNewRoom = (mousePos) => {

        this.setState({
            newRoomPoints: this.state.newRoomPoints.concat([mousePos.x, mousePos.y])
        })

    }

    createOfficeClicked = e => {

        this.setState({
            drawMode: !this.state.drawMode,
            editPoints: []
        })
    }

    createOfficeDoneClicked = e => {
        let newRoom = {
            "id": "newRoom" + newRoomCounter,
            "isExclusive": true,
            "points": this.state.newRoomPoints,
            "features": [],
            "chairs": [],
            "layer": null,
            "name": "New Room",
            "description": "Description",
            "isNew": true,
            "reservationForClientsEditable":true,
            "reservedForClients":[]
        }

        //let existingRooms = this.state.rooms.slice();
        //existingRooms.push(newRoom);
        this.props.saveNewRoom(newRoom,this.resetRooms);
        
        this.setState({
            //rooms: this.props.rooms,
            newRoomPoints: [],
            drawMode: false,
            editPoints: [],
            editMode: false,
            selectedRoom: null,
            selectedRoomIndex: null
        });
        newRoomCounter++;
    }

    resetRooms = () => {
        //alert("sa");
        this.setState({
            rooms:this.props.rooms
        },()=>{
            this.checkForCollision();
        })
    }

    onRoomTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (event.target.value === "exclusive") {
            this.setState({ roomType: "exclusive" });
        }
        else {
            this.setState({ roomType: "shared" });
        }
    }

    onRoomPropertyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target

        const newFeatures = this.state.roomProperties;
        newFeatures[name] = checked;
        this.setState({ roomProperties: newFeatures })
        var keys = Object.entries(this.state.roomProperties).filter(([, v]) => v === true).map(([k]) => k);
        this.setState({ featureRoomArray: keys })

    }

    onSeatPropertyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target

        const newFeatures = this.state.seatProperties;
        newFeatures[name] = checked;
        this.setState({ seatProperties: newFeatures })
        var keys = Object.entries(this.state.seatProperties).filter(([, v]) => v === true).map(([k]) => k);
        this.setState({ featureSeatArray: keys })
    }


    saveButtonClicked = e => {

        /*let newRoom = {
            "id": "newRoom" + newRoomCounter,
            "isExclusive": true,
            "points": this.state.newRoomPoints,
            "chairs": [],
            "layer": null
        }

        let existingRooms = this.state.rooms.slice();
        existingRooms.push(newRoom);
*/

        if (this.state.selectedRoom != null && this.state.selectedRoomIndex != null) {


            let isExclusive= this.state.roomType == "exclusive" ? true :false;


            this.props.saveCurrentRoomAction(this.state.rooms[this.state.selectedRoomIndex], this.state.roomName, this.state.roomDescription, this.state.roomType, this.state.featureRoomArray, this.state.exclusiveUsersIds, this.resetRooms);

            /*this.setState({
                drawMode: false,
                editPoints: [],
                editMode: false,
                selectedRoom: null,
                selectedRoomIndex: null
            });
            newRoomCounter++;*/
        } else if (this.state.selectedSeat != null) {
            let isExclusive= this.state.roomType == "exclusive" ? true :false;

            this.props.saveCurrentSeatAction(this.state.selectedSeat, this.state.seatName, this.state.seatIgnoreCollision, this.state.featureSeatArray,this.state.exclusiveUsersIds, this.resetRooms);
        }


        this.setState({
            selectedSeat:null,
            selectedRoom:null,
            selectedRoomIndex:null,
            editPoints:[],
            editMode:false
        })

    }

    editModeClikced = e => {

        if (this.state.selectedSeat == null) {
            if (this.state.editMode) {
                let rooms = this.state.rooms.slice();
                if (this.state.selectedRoomIndex != null && this.state.oldRoom != null) {


                    rooms.splice(this.state.selectedRoomIndex, 1);
                    rooms.push(this.state.oldRoom);

                    this.setState({
                        rooms: rooms,
                        oldRoom: null,
                        selectedRoom: null,
                        selectedRoomIndex: null,
                        editPoints: []
                    })
                }


            } else {
                if (this.state.selectedRoomIndex != null) {
                    this.setState({
                        oldRoom: JSON.parse(JSON.stringify(this.state.rooms[this.state.selectedRoomIndex]))
                    });

                }

            }
        }





        this.setState({
            editMode: !this.state.editMode
        });

    }



    roomSelected = e => {



        if (!this.state.editMode) {

            let points = e.target.attrs.points;
            let id = e.target.attrs.name;
            let targetRoom = e.target;
            let selectedIndex = 0;
            let room = this.state.rooms.find((element, index) => {
                return element.id == id
            });

            if (room) {
                selectedIndex = this.state.rooms.indexOf(room);
                console.log(room);

                if (room.features) {
                    const featureObj = Object.fromEntries(this.props.roomFeatures.map(key => [key, false]));
                    // console.log("FeatureOBJ Before");
                    // console.log(myObj)
                    this.props.roomFeatures.forEach(feature => {
                        if (room ?.features.includes(feature))
                            featureObj[feature] = true

                    });
                    //console.log("FeatureOBJ After");
                    const filteredFeatures = this.filterFeaturesSelection(featureObj);
                    this.setState({ roomProperties: featureObj, featureRoomArray: Object.keys(filteredFeatures) })
                }

                let exclusiveUsers = room.reservedForClients.map(client=>client.id);
                this.setState({
                    roomDescription: room.description,
                    roomName: room.name,
                    roomType: room.isExclusive ? "exclusive" : "shared",
                    exclusiveUsersIds:exclusiveUsers
                })
            }

            let newEditPoints: Array<{ roomId: string, x: number, y: number }> = [];

            for (let i = 0; i < points.length; i++) {
                if (i % 2 == 0) {
                    newEditPoints.push({ roomId: id, x: points[i], y: points[i + 1] });
                }
            }

            if (room) {
                room.layer = targetRoom;
                this.setState({
                    selectedRoom: room,
                    selectedRoomIndex: selectedIndex,
                    editPoints: newEditPoints,
                    editMode: false,
                    selectedSeat: null,
                })
            }

        }
    }


    onCircleMove = e => {

        if (this.state.editMode) {

            let x = e.target._lastPos.x;
            let y = e.target._lastPos.y;

            let roomId = e.target.attrs.id.split("_")[0];
            let pointIndex = e.target.attrs.id.split("_")[1] * 2;

            let rooms = this.state.rooms.slice();

            for (let i = 0; i < rooms.length; i++) {
                const room = rooms[i];
                if (room.id === roomId) {
                    room.points[pointIndex] = x;
                    room.points[pointIndex + 1] = y;
                }
            }

            this.setState({
                rooms: rooms
            })
        }
    };

    addSeatClicked = e => {
        if (this.state.selectedRoom) {
            let id = this.state.selectedRoom.id;
            let rooms = this.state.rooms.slice();

            for (let i = 0; i < rooms.length; i++) {

                let room = rooms[i];
                if (room.id == id) {
                    let midPoint = this.getMidPoint(room);
                    let newSeat = {
                        id: "newSeat_" + seatCounter,
                        x: midPoint.x,
                        y: midPoint.y,
                        collision: false,
                        roomId: room.id,
                        features: [],
                        isExclusive: false,
                        ignoreCollision: false,
                        name: "",
                        reservationForClientsEditable:true,
                        reservedForClients:[]
                    }
                    room.chairs.push(newSeat);
                }
            }
            this.setState({
                rooms: rooms
            }, () => {
                seatCounter += 1;
                this.checkForCollision();
            });
        }
    }

    getMidPoint = (room) => {
        let totalX = 0;
        let totalY = 0;
        let count = room.points.length / 2;

        for (let i = 0; i < room.points.length; i++) {
            if (i % 2 == 0) {
                totalX += room.points[i];
                totalY += room.points[i + 1];
            }
        }

        return { x: totalX / count, y: totalY / count }
    }

    getCursor = () => {
        if (this.state.drawMode) {
            return "crosshair";
        } else if (this.state.editMode) {
            return "cell";
        } else {
            return "auto";
        }
    }

    dragBoundFunc = (pos) => {

        let dragPoint = pos;
        let room = this.state.selectedRoom;
        if (room) {
            //console.log(room.layer);
            let intersection = room.layer.intersects({ x: dragPoint.x, y: dragPoint.y });

            if (!intersection) {
                return lastpos;
            } else {
                lastpos = pos;
                return pos;
            }
        }

        return pos;

    }

    getDraggable = (chair) => {

        if (this.state.selectedRoom) {
            if (this.state.editMode && this.state.selectedRoom.id == chair.roomId) {
                return true;
            } else {
                return false;
            }
        }

        return false;

    }

    onChangeRoomName = e => {
        this.setState({
            roomName: e.target.value, roomNameErr: false, roomNameErrText: ""
        });
    }

    onChangeRoomDescription = e => {
        this.setState({
            roomDescription: e.target.value
        });
    }

    filterFeaturesSelection (featureObj) {
        return Object.fromEntries(Object.entries(featureObj).
        filter(([key, val]) => val === true));
    }


    seatSelected = e => {

        if (!this.state.editMode) {
            let id = e.target.attrs.id;
            let rooms = this.state.rooms.slice();

            for (let i = 0; i < rooms.length; i++) {
                let room = rooms[i];

                for (let j = 0; j < room.chairs.length; j++) {
                    let chair = room.chairs[j];
                    if (chair.id == id) {
                        console.log("----------------");
                        console.log(chair);
                        console.log("----------------");

                        if (chair.features) {
                            const featureObj = Object.fromEntries(this.props.seatFeatures.map(key => [key, false]));
                            console.log("FeatureOBJ Before");
                            console.log(featureObj)
                            this.props.seatFeatures.forEach(feature => {
                                if (chair.features.includes(feature))
                                    featureObj[feature] = true

                            });
                            console.log("FeatureOBJ After");
                            console.log(featureObj)
                            const filteredFeatures = this.filterFeaturesSelection(featureObj);
                            this.setState({ seatProperties: featureObj, featureSeatArray: Object.keys(filteredFeatures) })
                        }

                        let exclusiveUsers = chair.reservedForClients.map(client=>client.id);
                       // alert(chair.isExclusive);
                        this.setState({
                            seatIgnoreCollision: chair.ignoreCollision,
                            seatIsExclusive: chair.isExclusive,
                            seatName: chair.name,
                            selectedSeat: chair,
                            selectedRoom: null,
                            editPoints: [],
                            roomType: chair.isExclusive ? "exclusive" : "shared",
                            exclusiveUsersIds:exclusiveUsers
                        })
                    }
                }
            };
        }
    }


    setSeatName = e => {
        this.setState({
            seatName: e.target.value
        });
    }

    setIsExclusive = e => {
        this.setState({
            seatIsExclusive: e.target.checked
        });

    }

    onSeatTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "exclusive") {
            this.setState({ seatIsExclusive: true });
        }
        else {
            this.setState({ seatIsExclusive: false });
        }
    }

    setIgnoreCollision = e => {
        this.setState({
            seatIgnoreCollision: e.target.checked
        });
    }

    /*handleExclusiveUsersChange = e => {
        let selectedId = e.target.value;

        this.setState({
            exclusiveUserSelection: selectedId
        })
    }*/

    handleExclusiveUsersChange = (event) => {
        
        //alert("change => " + JSON.stringify(event.target.value));
        let values = event.target.value;
        let newArray:Array<string>=[];
        for (let i = 0; i < values.length; i++) {
            let element = values[i];
            if(element != this.props.t("CANVAS.28")){
                newArray.push(element);
            }
            
        }

        this.setState({
            exclusiveUsersIds:newArray
        })
      };


    deleteClicked = () => {
        if (this.state.selectedSeat) {
            this.props.deleteSeat(this.state.selectedSeat.id,this.resetRooms);

        }else if(this.state.selectedRoom){
            this.props.deleteRoom(this.state.selectedRoom.id,this.resetRooms);
        }


        this.setState({
                selectedSeat:null,
                selectedRoom:null,
                selectedRoomIndex:null,
                editPoints:[],
                editMode:false
        })

    }

    addCornerClicked = () => {

        if (this.state.selectedRoom) {

            let id = this.state.selectedRoom.id;
            let rooms = this.state.rooms.slice();
            let editPoints = this.state.editPoints.slice();
            let calculatedMidX = 0;
            let calculatedMidY = 0;


            for (let i = 0; i < rooms.length; i++) {
                let room = rooms[i];
                if (room.id == id) {

                    for (let j = 0; j < room.points.length; j++) {
                        if (j % 2 == 0) {
                            calculatedMidX += room.points[j];
                        } else {
                            calculatedMidY += room.points[j];
                        }
                    }
                }
            }

            for (let i = 0; i < rooms.length; i++) {
                let room = rooms[i];
                if (room.id == id) {
                    //console.log(calculatedMidX);
                    calculatedMidX = calculatedMidX / (room.points.length / 2);
                    calculatedMidY = calculatedMidY / (room.points.length / 2);
                    //console.log(calculatedMidX);
                    let newEditPoint = { roomId: room.id, x: calculatedMidX, y: calculatedMidY };

                    room.points.push(calculatedMidX);
                    room.points.push(calculatedMidY);
                    editPoints.push(newEditPoint);
                }
            }


            this.setState({
                rooms: rooms,
                editPoints: editPoints
            })

        }

    }

    removeCornerClicked = () => {
        if (this.state.selectedRoom) {
            


            let id = this.state.selectedRoom.id;
            let rooms = this.state.rooms.slice();
            let editPoints = this.state.editPoints.slice();

            for (let i = 0; i < rooms.length; i++) {
                
                let room = rooms[i];
                if (room.id == id) {
                    if (room.points.length > 6) {
                        room.points.splice(-1, 1);
                        room.points.splice(-1, 1);

                        editPoints.splice(-1, 1);
                    }
                }
            }


            this.setState({
                rooms: rooms,
                editPoints: editPoints
            })

        }
    }

    renderExclusiveSelect = (selected) => {
  
        let formatted:Array<string> = [];

        selected.forEach(id => {
            let found = this.props.exclusiveUsers.find((client)=>client.id==id);
            if( found!= undefined){
                formatted.push(found.name);
            }
        });
        //alert("render => " + JSON.stringify(formatted));
        if(formatted.length>0){
            return formatted.join(', ');
        }else{
            return this.props.t("CANVAS.28");
        }
    }

    handleOpen = () => {
        this.setState({
            modalOpen: true
        })
    };

    handleClose = () => {
        this.setState({
            modalOpen: false
        })
    };


    render() {
        const { t } = this.props
        return (
            <div>

                <div >
                    <Grid container >
                    
                    {this.state.selectedRoom != null ?
                    <div style={{ width: "90%"}}>
                        <Grid container className="input-group" style={{ marginTop: "8px", width: "90%", display: "flex" }} >
                            <Grid item xs={4} alignContent="flex-end" alignItems="flex-end" >
                                <TextField
                                    disabled={!(this.state.drawMode || this.state.editMode)}
                                    id="name"
                                    variant="standard"
                                    label={t("CANVAS.8")}
                                    color="secondary"
                                    style={{ width: "60%" }}
                                    error={this.state.roomNameErr}
                                    helperText={this.state.roomNameErrText}
                                    onChange={this.onChangeRoomName}
                                    value={this.state.roomName}
                                />

                            </Grid>
                            <Grid item xs={4} alignContent="flex-end" alignItems="flex-end">
                                <TextField
                                    disabled={!(this.state.drawMode || this.state.editMode)}
                                    id="description"
                                    label={t("CANVAS.9")}
                                    color="secondary"
                                    style={{ width: "60%" }}
                                    onChange={this.onChangeRoomDescription}
                                    value={this.state.roomDescription}
                                />

                            </Grid>
                            <Grid item xs={4}>
                                <FormControl disabled={!(this.state.drawMode || this.state.editMode)} component="fieldset">

                                    {this.state.roomType == "exclusive" ?
                                        <div>


                                            <FormControl disabled={!(this.state.drawMode || this.state.editMode)} style={{"minWidth":"100px"}}>
                                                
                                                <Select
                                                    labelId="demo-mutiple-checkbox-label"
                                                    id="demo-mutiple-checkbox"
                                                    multiple
                                                    value={this.state.exclusiveUsersIds.length == 0 ? [t("CANVAS.28")]:this.state.exclusiveUsersIds}
                                                    onChange={this.handleExclusiveUsersChange}
                                                    input={<Input />}
                                                    renderValue={this.renderExclusiveSelect}
                                                    //MenuProps={MenuProps}
                                                >
                                                    {this.props.exclusiveUsers.map((existingClient) => (
                                                        <MenuItem key={existingClient.id} value={existingClient.id}>
                                                            <Checkbox checked={this.state.exclusiveUsersIds.filter( userId => userId === existingClient.id ).length > 0} />
                                                            <ListItemText primary={existingClient.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>

                                        : null}
                                </FormControl>

                            </Grid>
                            
                        </Grid>
                        <Grid item xs={12} className="input-group" style={{ margin: "8px 0px", width: "90%", display: "flex" }} >

                            <FormControl disabled={!(this.state.drawMode || this.state.editMode)} margin="normal" color="primary" component="fieldset">
                                <FormLabel component="legend"><Typography style={{ alignSelf: "center" }} variant="h5" color="secondary"><strong>{t("CANVAS.19")}</strong></Typography></FormLabel>
                                <FormGroup row  >
                                    {
                                        this.state.changedRoomFeatures.map(feature => (

                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={this.state.roomProperties[feature]} onChange={this.onRoomPropertyChange} name={feature} />}
                                                label={feature}
                                            />

                                        ))
                                    }


                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </div>
                    : null}
                {this.state.selectedSeat != null ?
                    <div style={{ width: "90%"}}>
                        <Grid container className="input-group" style={{ marginTop: "8px", width: "90%", display: "flex" }} >
                            <Grid item xs={4} spacing={5} alignContent="flex-end" alignItems="flex-end" >
                                <TextField
                                    disabled={!(this.state.editMode && this.state.selectedSeat != null)}
                                    id="name"
                                    label={t("CANVAS.18")}
                                    color="secondary"
                                    style={{ width: "60%" }}
                                    value={this.state.seatName}
                                    onChange={this.setSeatName}
                                />

                            </Grid>
                            <Grid item xs={4} spacing={4} >
                                <FormControl disabled={!(this.state.drawMode || this.state.editMode)} component="fieldset">
                             
                                    {this.state.roomType == "exclusive" ?
                                        <div>


                                            <FormControl disabled={!(this.state.drawMode || this.state.editMode)} style={{"minWidth":"100px"}}>
                                                
                                                <Select
                                                    labelId="demo-mutiple-checkbox-label"
                                                    id="demo-mutiple-checkbox"
                                                    multiple
                                                    value={this.state.exclusiveUsersIds.length == 0 ? [t("CANVAS.28")]:this.state.exclusiveUsersIds}
                                                    onChange={this.handleExclusiveUsersChange}
                                                    input={<Input />}
                                                    renderValue={this.renderExclusiveSelect}
                                                    //MenuProps={MenuProps}
                                                >
                                                    
                                                    {this.props.exclusiveUsers.map((existingClient) => (
                                                        <MenuItem key={existingClient.id} value={existingClient.id}>
                                                            <Checkbox checked={this.state.exclusiveUsersIds.filter( userId => userId === existingClient.id ).length > 0} />
                                                            <ListItemText primary={existingClient.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>

                                        : null}
                                </FormControl>

                            </Grid>
                            <Grid item xs={4} spacing={4} style={{ marginTop: 8 }} alignContent="flex-end" alignItems="flex-end">
                                <FormControlLabel
                                    control={<Checkbox color="primary" checked={this.state.seatIgnoreCollision} disabled={!(this.state.editMode && this.state.selectedSeat != null)} name="ergonomicSeat" />}
                                    label={t("CANVAS.16")}
                                    onChange={this.setIgnoreCollision}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="input-group" style={{ margin: "8px 0px", width: "90%", display: "flex" }} >

                            <FormControl disabled={!(this.state.drawMode || this.state.editMode)} margin="normal" color="primary" component="fieldset">
                                <FormLabel component="legend"><Typography style={{ alignSelf: "center" }} variant="h5" color="secondary"><strong>{t("CANVAS.19")}</strong></Typography></FormLabel>
                                <FormGroup row  >
                                    {
                                        this.state.changedSeatFeatures.map(feature => (

                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={this.state.seatProperties[feature]} onChange={this.onSeatPropertyChange} name={feature} />}
                                                label={feature}
                                            />

                                        ))
                                    }


                                </FormGroup>
                            </FormControl>
                        </Grid>



                        {/* <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.seatIsExclusive} disabled={!(this.state.editMode && this.state.selectedSeat != null)} name="ergonomicSeat" />}
                            label={t("CANVAS.17")}
                            onChange={this.setIsExclusive}
                        /> */}


                    </div>
                    : null}
                    <div className="input-group" style={{ marginTop: "15px",marginBottom: "15px", width: "90%", justifyContent: "center", display: "flex" }}>
                        <Typography variant="body1" ><strong>{t("CANVAS.7")}</strong></Typography>
                    </div>
                        <Grid item xs={12} spacing={2} alignContent="flex-end" alignItems="flex-end">
                            <ButtonGroup variant="contained" color="secondary" aria-label="text primary button group">
                                <Button
                                    onClick={this.editModeClikced}
                                    disabled={this.state.selectedRoom == null && this.state.selectedSeat == null}
                                    color={this.state.editMode ? "primary" : "default"}
                                    startIcon={this.state.editMode ? <CancelIcon /> : <EditIcon />}
                                >
                                    {this.state.editMode ? t("CANVAS.1") : t("CANVAS.2")}
                                </Button>
                                <Button
                                    onClick={this.state.drawMode ? this.createOfficeDoneClicked : this.createOfficeClicked}
                                    color={this.state.drawMode ? "primary" : "default"}
                                    startIcon={this.state.drawMode ? <DoneIcon /> : <CropTwoToneIcon />}
                                    disabled={this.state.editMode}
                                >
                                    {this.state.drawMode ? t("CANVAS.3") : t("CANVAS.4")}
                                </Button>
                                <Button
                                    onClick={this.addSeatClicked}
                                    disabled={!this.state.editMode}
                                    color={this.state.selectedRoom ? "primary" : "default"}
                                    startIcon={<EventSeatIcon />}
                                >
                                    {t("CANVAS.5")}
                                </Button>
                                



                                {this.state.selectedRoom != null && this.state.editMode ? <div>
                                    <Button
                                        onClick={this.addCornerClicked}
                                        variant="contained"
                                        color={"primary"}
                                        startIcon={<AddPhotoAlternateIcon />}
                                    >
                                        {t('CANVAS.26')}
                                    </Button>

                                    <Button
                                        onClick={this.removeCornerClicked}
                                        variant="contained"
                                        color={"primary"}
                                        startIcon={<RemoveCircleIcon />}
                                    >
                                        {t('CANVAS.27')}
                                    </Button>
                                </div>
                                    : null
                                }


                                <Button
                                    onClick={this.deleteClicked}
                                    variant="contained"
                                    disabled={!((this.state.selectedSeat != null || this.state.selectedRoom != null) && this.state.editMode)}
                                    color={"primary"}
                                    startIcon={<DeleteIcon />}
                                >
                                    {t('CANVAS.25')}
                                </Button>

                                <Button
                                    disabled={!this.state.editMode}
                                    onClick={this.saveButtonClicked}
                                    color={"primary"}
                                    startIcon={<SaveIcon />}
                                >
                                    {t("CANVAS.6")}
                                </Button>

                                <Button
                                    onClick={this.props.measureClicked}
                                    variant="contained"
                                    color={"default"}
                                    startIcon={<MeasureIcon style={{ transform: "rotate(-45deg)" }} />}
                                >
                                    {t('CANVAS.21')}
                                </Button>
                                <Button
                                    onClick={this.handleOpen}
                                    color="default"
                                >
                                <HelpOutlineIcon />
                                </Button>



                            </ButtonGroup>

                        </Grid>
                    </Grid>

                    <div className="canvas1" style={{ width: "90%", overflow: "auto", borderStyle: "solid", borderWidth: "2px" }}>

                        <Stage width={this.state.canvasWidth} height={this.state.canvasHeight} onMouseDown={this.handleMouseDown} style={{ "cursor": this.getCursor() }}>
                            <Layer>
                                <Image image={this.state.image} />
                            </Layer>

                            <Layer>
                                {this.state.rooms.map((room, i) => (
                                    <Line
                                        attrs={{ "name": room.id }}
                                        points={room.points}
                                        fill={room.reservedForClients.length>0 ? "#f38e45ab": "#3468fa59" } //"#3468fa59" //#ffa50099 orange
                                        strokeWidth={2}
                                        stroke={"black"}
                                        onClick={this.roomSelected}
                                        closed
                                    />

                                ))}
                            </Layer>
                        
                            <Layer>
                                {this.state.rooms.map((room, i) => (
                                    room.chairs.map((element, i) => (
                                        <Circle
                                            id={element.id}
                                            key={element.id}
                                            x={element.x}
                                            y={element.y}
                                            numPoints={5}
                                            {...(element.collision ? { fill: 'red' } : { fill: '#89b717' })}
                                            opacity={0.9}
                                            draggable={this.getDraggable(element)}
                                            onDragStart={this.handleDragStart}
                                            onDragEnd={this.handleDragEnd}
                                            radius={this.state.safeDistanceInPixels / 4}
                                            stroke='#ffffffb0'
                                            strokeWidth={(this.state.safeDistanceInPixels - (this.state.safeDistanceInPixels / 2))}
                                            dragBoundFunc={this.dragBoundFunc}
                                            onClick={this.seatSelected}
                                        />
                                    ))

                                ))}
                            </Layer>

                            <Layer attrs={{ "name": "newRoom" }}>
                                <Line
                                    points={this.state.newRoomPoints}
                                    fill="#3468fa59"
                                    strokeWidth={2}
                                    stroke={"black"}
                                    closed
                                />
                                {this.state.newRoomPoints.map((element, i) => {

                                    if (i % 2 == 0) {
                                        return (<Circle
                                            key={i}
                                            x={this.state.newRoomPoints[i]}
                                            y={this.state.newRoomPoints[i + 1]}
                                            numPoints={5}
                                            fill='white'
                                            radius={6}
                                            stroke='black'
                                            strokeWidth={3}
                                            {...this.state.editMode ? { draggable: true } : null}
                                            onDragMove={this.onCircleMove}
                                        />)
                                    }

                                })}

                            </Layer>

                            <Layer>
                                {this.state.editPoints.map((element, i) => {

                                    return <Circle
                                        id={element.roomId + "_" + i}
                                        key={i}
                                        x={this.state.editPoints[i].x}
                                        y={this.state.editPoints[i].y}
                                        numPoints={5}
                                        fill='white'
                                        radius={10}
                                        stroke='black'
                                        strokeWidth={3}
                                        {...this.state.editMode ? { draggable: true } : null}
                                        onDragMove={this.onCircleMove}
                                    />

                                })}
                            </Layer>


                            {this.state.selectedSeat ? <Layer>
                                <Circle
                                    id={this.state.selectedSeat.id}
                                    x={this.state.selectedSeat.x}
                                    y={this.state.selectedSeat.y}
                                    numPoints={5}
                                    fill='#e6720f'
                                    opacity={0.8}
                                    radius={this.state.safeDistanceInPixels / 4}
                                    stroke='black'
                                    strokeWidth={2}
                                />

                            </Layer> : null
                            }
                        </Stage>
                        <HelperModal

                            handleOpen={this.handleOpen}
                            handleClose={this.handleClose}
                            open={this.state.modalOpen}
                            
                        />
                    </div>
                </div>
                
              


            </div>
        );
    }
}

export default withTranslation()(CanvasKonvaDraw);
