import React from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '../../history/navigate';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { Typography, Grid } from '@material-ui/core';

interface Props {
    
}

export const TenantNotActivated: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
    

  document.title = t('TITLE.4');    

    return (
        <div >
            <Grid container direction="column" justify="center" alignItems="center" className="tenant-not-activated">
            <ReportProblemIcon className="not-activated-icon" color="primary"/>
            <Typography variant="h5" >{t('TENANTNOTFOUND.6')}</Typography>
            </Grid>

        </div>

    )
}
