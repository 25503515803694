import React from 'react';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { withTranslation, WithTranslation } from 'react-i18next';
import { navigate } from '../../history/navigate';
import { notifySuccess, notifyError } from '../notification/Notification';
import { AppContext } from "../../middlewares/appContext/AppContext";
import { RouteComponentProps, withRouter } from 'react-router';
import { Client } from '../../interfaces/Client';
import { ProfileForm } from './ProfileForm';
import moment from 'moment-timezone';

interface userObj {
    id:string,
    email: string,
    enabled?: boolean,
    firstname: string,
    lastname: string,
    role: string,
    salutation: string,
    password: string,
    newPassword: string,
    newPassword2: string,
    client:{
        id:string,
        name:string
    }
}

interface AuthUpdateObj {
    newImageId:string,
    password:string
}

interface State {
   user:userObj,
   isReady:boolean,
   userImage:string,
   clients:Array<Client>
}

type UserParams = {
    userId: string
};

type UserDetailProps = RouteComponentProps<UserParams>;


class Profile extends React.Component<WithTranslation & UserDetailProps, State> {

    static contextType = AppContext;
   
    constructor(props) {
        super(props);
        
        this.state = {
            isReady: false,
            clients:[],
            userImage:"",
            user: {
                email:"",
                enabled:true,
                firstname:"",
                id:"",
                password:"",
                newPassword:"",
                newPassword2:"",
                lastname:"",
                salutation:"",
                role:"",
                client:{
                    id:"",
                    name:""
                }
            }
        };

    }

    updateProfile = (updateObj:AuthUpdateObj) => {
        const context: any = this.context;
        console.log(updateObj)
        const timezone = moment.tz.guess();
         HTTP_REQUESTS.TENANT_API.UPDATE_SELF(updateObj, encodeURIComponent(timezone), (res) => {
            context.toggle()
            notifySuccess(this.props.t("PROFILE.1"))
            navigate('/dashboard');
            context?.changeUser(res)
        }, (err) => {
             notifyError(this.props.t(`PROFILE.${err.message}`));
        })
    }

    getUserImage = () => {
        const context: any = this.context;
        if(context.user && context.user.imagePresent)
        {   
            this.setState({userImage:window.location.origin + "/api/auth/info/image",isReady:true})
        }
        else {
            this.setState({isReady:true})
        }
    }
   
    componentDidMount() {
        const context: any = this.context;

        this.setState({user:context?.user},()=>{
            this.getUserImage()
        })
       

    }
    
    


    render() {

        return (
            <div className="profile-container" >
              {this.state.isReady ? <ProfileForm onSubmit={this.updateProfile} userImage={this.state.userImage} isClientAdmin={false} user={this.state.user} /> : null}
            </div>
        );
    }

}
export default withTranslation()(withRouter(Profile))
