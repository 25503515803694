import React from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {MainHeader} from '../headers/MainHeader';
import {
    createMuiTheme,
    createStyles,
    withStyles,
    makeStyles,
    Theme,
    ThemeProvider,
  } from '@material-ui/core/styles';
  import { green, purple } from '@material-ui/core/colors';
import { navigate } from '../../history/navigate';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface Props {
    
 
}
const ColorButton = withStyles((theme: Theme) => ({
    root: {
      color: theme.palette.getContrastText(purple[500]),
      backgroundColor: purple[500],
      '&:hover': {
        backgroundColor: purple[700],
      },
    },
  }))(Button);

export const RegisterType:React.FC<Props> = ()=>{
  const { t, i18n } = useTranslation();
  
  const onClick =() => {
  navigate('/register');
  }
  document.title = t('TITLE.2');

  return (
   
    <Grid item xs={12} sm={8} md={5} className="register-selection" component={Paper} elevation={6} >

        <div className="register-selection" >
         
          <div className="paper">
            <Grid  className="main-header-selection"><MainHeader/></Grid>
          
            <Grid container direction="column" className="body-message">
            <Typography component="h2" variant="h6" >
             {t('REGISTERTYPE.1')}
             <br/>
             {t('REGISTERTYPE.2')}

            </Typography>
            </Grid>
            <Grid item >
            <Button
              variant="contained"
              type="button"
              color="primary"
              fullWidth
              className="register-tenant-btn"
              onClick={onClick}
            >
             {t('REGISTERTYPE.3')}
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={onClick}
              fullWidth
              className="register-without-tenant-btn"
            >
             {t('REGISTERTYPE.4')}
             
            </Button>
            </Grid>
          </div>
        </div>
        </Grid>
        
  );
}