import React from 'react';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { navigate } from '../../history/navigate';
import AddIcon from '@material-ui/icons/Add';
import { Card } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AddOfficeIcon from '../icons/AddOfficeIcon';


interface Props {

}

export const AddNewCard: React.FC<Props> = (props) => {
    
    const { t, i18n } = useTranslation();
    const { } = props;
    

    return (
        <Card  style={{width:325,display:"flex"}}  className="card-root">
            <CardActionArea onClick={()=>{navigate('/offices/new')}}>
                <CardContent>
                    <div className="media-container">
                        <AddOfficeIcon color="primary" style={{ height: "75%", width: "75%",display:"flex",margin:"16px auto" }} />
                        <CardActions style={{ justifyContent: "center", margin: 8 }}>
                    <Typography color="textSecondary" variant="h5" >{t('OBJECTCARD.1') }</Typography>
                </CardActions>

                    </div>
                </CardContent>
               
            </CardActionArea>
        </Card>
    );
}