import React , { forwardRef } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AppContext } from "../../middlewares/appContext/AppContext";
import MaterialTable from '@material-table/core';
import moment from 'moment';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Client } from '../../interfaces/Client';
import { EditOutlined } from '@material-ui/icons';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { notifyError } from '../notification/Notification';
import { PatchedPagination } from '../utils/PatchedPagination';

interface State {
    data: Array<Client>,

}

interface Props {

    data: Array<Client>,
    onClickEdit: (id:string,editObj:Client) => void,
    deleteClientClick?: (id:string) => void
}


class ClientsTable extends React.Component<WithTranslation & Props, State> {

    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            data:[]
        };

    }



    componentDidMount() {
        this.setState({data:this.props.data})
    }

    render() {
        const { t } = this.props;
        return (
            <div className="clients-table">
                <MaterialTable
                    title=""
                    icons={{
                        Edit: forwardRef((props, ref) => <EditOutlined color="primary" {...props} ref={ref} />),
                        Delete: forwardRef((props, ref) => <DeleteOutline color="primary" {...props} ref={ref} />)
                    }}
                    columns={[
                        { title: 'Id', field: 'id',hidden:true,export:false,editable:"never"},
                        { title: 'Name', field: 'name'},
                        
                    ]}
                    data={this.props.data? this.props.data : this.state.data}
                    options={{
                        draggable:false,
                        search:true,
                        filtering: false,
                        headerStyle:{fontWeight:"bold",textAlign:"left"},
                        actionsColumnIndex:-1,
                        pageSize:10,
                    }}

                    // actions={[
                    //     {
                    //       icon: ()=> {return <EditOutlined color="primary"/>},
                    //       tooltip: "Edit Client",
                    //       onClick: (event, rowData) => {if(this.props.onClickEdit ) this.props.onClickEdit(rowData['id']) }
                    //     }
                    //   ]}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            {
                                if (newData.name == "" || newData.name == undefined) {
                                  notifyError(this.props.t('CLIENT.16'))
                                  reject();
                                }else if (newData.name===oldData.name){
                                    reject();
                                } else {
                                    
                                    if(this.props.onClickEdit && oldData )
                                    {
                                        this.props.onClickEdit(oldData['id'],newData)
                                        resolve(true);
                                    }
                                         
                                }
                            }

                        }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                {this.props.deleteClientClick ? this.props.deleteClientClick(oldData['id']):console.log("")}
                                setTimeout(() => {
                                    resolve(true);
                                    this.setState((prevState) => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        return {...prevState, data};
                                    });
                                }, 600);
                            }),
                    }}
                   
                    localization={{
                        header:{
                            actions:t('USERCRUD.17')
                        },
                        body:{
                            deleteTooltip:t('CLIENT.20'),
                            editTooltip:t('CLIENT.19'),
                            emptyDataSourceMessage:t('USERSTABLE.3'),
                            editRow: {
                                deleteText: t('USERSTABLE.4'),
                                cancelTooltip: t('USERSTABLE.5'),
                                saveTooltip: t('USERSTABLE.6')
                            }
                        },
                        toolbar: {
                            exportTitle: 'Export',
                            exportAriaLabel: 'Export',
                            searchTooltip: t('USERSTABLE.7'),
                            searchPlaceholder: t('USERSTABLE.7')
                        },
                        pagination: {
                            labelDisplayedRows:  t('USERSTABLE.8'),
                            labelRowsSelect:  t('USERSTABLE.9'),
                            labelRowsPerPage:  t('USERSTABLE.10'),
                            firstAriaLabel:  t('USERSTABLE.11'),
                            firstTooltip:  t('USERSTABLE.11'),
                            previousAriaLabel:  t('USERSTABLE.12'),
                            previousTooltip:  t('USERSTABLE.12'),
                            nextAriaLabel:  t('USERSTABLE.13'),
                            nextTooltip:  t('USERSTABLE.13'),
                            lastAriaLabel:  t('USERSTABLE.14'),
                            lastTooltip:  t('USERSTABLE.14'),
                        },
                    }}
                    components={{Pagination:PatchedPagination}}
                    
                    
                />
            </div>
        );
    }

}
export default withTranslation()(ClientsTable);
