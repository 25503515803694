import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';


interface Props {
  open:boolean
  confirm:string,
  cancel:string,
  header:string,
  text:string,
  confirmCB: () => void,
  cancelCB: () => void
}


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const GeneralDialog: React.FC<Props> = (props) => {

  const {open,header,confirm,cancel,text,confirmCB,cancelCB} = props;

  
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="dialog-slide-title"
        aria-describedby="dialog-slide-description"
      >
        <DialogTitle id="dialog-slide-title">{header}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-slide-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelCB} variant="text">
            {cancel}
          </Button>
          <Button onClick={confirmCB} variant="contained" color="primary">
            {confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
