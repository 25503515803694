import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '../menu/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Collapse, IconButton } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { navigate } from '../../history/navigate';
import { Floor } from '../../interfaces/Floor';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import AddIcon from '@material-ui/icons/Add';
import { notifyError } from '../notification/Notification';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

interface MenuObj {
  id: string,
  name: string,
  level: number,
  children: Array<Floor>
}

interface State {
  isReady: Boolean,
  forNew: boolean,
  menuObj: Array<MenuObj>,
  blankArray: Array<Object>,
  officeId: string,
  floorId:string,
  clicked:boolean,
  selectedIndex:string
}
interface Props {

  Component: React.ComponentType

}

type OfficeEditorParams = {
  officeId: string,
  floorId: string // parameters will always be a string (even if they are numerical)
};

type OfficeDetailProps = RouteComponentProps<OfficeEditorParams>;

class ClippedDrawer extends React.Component<WithTranslation & Props & OfficeDetailProps, State> {
  
  constructor(props) {
    super(props)
    this.state = {
      isReady: false,
      menuObj: Array<MenuObj>(),
      forNew: false,
      blankArray: Array<Object>(),
      officeId: "",
      floorId:"",
      clicked:false,
      selectedIndex:""
    }
  }


  componentDidMount() {
    if(this.props.match.params) {
      let params = this.props.match.params;
        console.log(params)

        let hash = window.location.hash;
        var res = hash.split("/");
        let officeId = res[2];
        let floorId = res[3];
      if(!this.props.match.params.floorId || params.floorId == "")
      {
        this.setState({officeId:officeId},this.getMenuData)
        
      }
      else {

        this.setState({officeId:officeId,floorId:floorId,selectedIndex:floorId,clicked:true},()=>{
          this.getMenuData();
          
        })
      }

      
    }

  }
  getMenuData (){
    let menu: MenuObj = { id: "", name: "", level: 1, children: Array<Floor>() }
      if(this.state.officeId != "new")
      {

        HTTP_REQUESTS.OFFICE_API.GET_OFFICE(this.state.officeId, (res) => {
          try {
            menu.id = res.id;
            menu.name = res.name;
            HTTP_REQUESTS.OFFICE_API.GET_ALL_FLOORS(this.state.officeId, (res) => {
              menu.children = res;
              menu.children.map(floor=>floor.level=2)
             
             
               this.setState(prevState => ({
                      menuObj: [...prevState.menuObj, menu],
                      isReady: true
                    }),()=>{
                      this.handleClick(this.state.menuObj[0])
                    })
             
            }, (err) => {
              
            })
  
          } catch (error) {
  
          }
  
        }, (err) => {
  
        })
      }
  }
  // this method sets the current state of a menu item i.e whether it is in expanded or collapsed or a collapsed state
  handleClick(item) {
    console.log(item)
    this.setState(prevState => (
      { [item.id]: !prevState[item.id] ,
      clicked:!prevState.clicked}
    ) as Pick<State, keyof State>)


  }

  handleGo(item) {
    this.setState({selectedIndex:item.id})
    switch (item.level) {
      case 1:
        navigate('/offices/'+this.state.officeId);   
        break;
      case 2:
        navigate('/officeeditor/' + this.state.officeId + '/' + item.id);
        break;
      default: window.open('/', '_self');
    }
  }

  handleUploadPlan (officeId) {
    if(officeId != "new"){
      navigate('/offices/'+officeId+'/uploadPlan')
    }
    else {
      notifyError(this.props.t("OFFICE_MESSAGES.12"))
    }
     
    
    
  }

  handler(children) {

    const { state } = this
    if (children.length == 0 && children.level !== 2) {


    }
    else {
      return children.map((subOption) => {
        if (!subOption.children) {

          return (
            <div key={subOption.id}>
              <ListItem  selected={this.state.selectedIndex === subOption.id} style={{ paddingLeft: (24 * subOption.level) }} button key={subOption.id}>
                <ListItemText className={this.state.selectedIndex === subOption.id  ? "selected-menu-item" :"not-selected"} onClick={()=>this.handleGo(subOption)} primary={subOption.name} />
              </ListItem>
              <Divider></Divider>
            </div>
          )
        }
        return (
          <div key={subOption.id} >

            <ListItem   selected={this.state.selectedIndex === subOption.id}style={{ paddingLeft: (8 * subOption.level) }} button >
              {state[subOption.id] ? <IconButton onClick={(e) => { e.stopPropagation(); this.handleClick(subOption) }}><ExpandLess /> </IconButton> : <IconButton onClick={() => (this.handleClick(subOption))}><ExpandMore /> </IconButton>}
              { <ListItemText className={this.state.selectedIndex === subOption.id ? "selected-menu-item" :"not-selected"} onClick={()=>this.handleGo(subOption)}  primary={subOption.name} />  }             
            </ListItem>
            <Divider></Divider>
            <Collapse in={state[subOption.id]} timeout="auto" unmountOnExit >
              {this.handler(subOption.children)}
            </Collapse>

          </div>
        )
      })
    }

  }

  render() {


    const {  Component,t } = this.props
    const { state } = this
    return (
      <div className="menu-root">
        <CssBaseline />
        <AppBar isRelative={false} />
        <Drawer
          className="drawer"
          variant="permanent"
          classes={{
            paper: "paper",
          }}
        >
          <Toolbar />
          
              
          <div className="drawerContainer">
            <List className="list" >

              {state.forNew ? null :state.isReady?this.handler(state.menuObj):null}
              <div key="new">
                <ListItem className="list-item" button key="new">
                  <AddIcon color="primary" /><ListItemText className="new-floor-text" primary={t("LAYOUTS.1")} onClick={()=>{this.handleUploadPlan(state.officeId)}} />
                </ListItem>
              </div>
            </List>


          </div>
        </Drawer>
        <main className="content">
          <Toolbar />
          <Component />
        </main>
      </div>
    );
  }

}
export default withTranslation()(withRouter(ClippedDrawer))