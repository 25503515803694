import React, { useState, useEffect } from 'react';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { notifyError, notifySuccess } from '../notification/Notification';
import { useTranslation } from 'react-i18next';
import { UploadPlanForm } from './UploadPlanForm';
import { navigate } from '../../history/navigate';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Floor } from '../../interfaces/Floor';
import { Client } from '../../interfaces/Client';
import { scrollToTop } from '../utils/Utils';


type OfficeEditorParams = {
  officeId: string,
  floorId: string // parameters will always be a string (even if they are numerical)
};

type OfficeDetailProps = RouteComponentProps<OfficeEditorParams>;


interface Props {

}

interface State {

  officeId: string,
  floorId: string,
  isNew: boolean,
  isReady: boolean,
  floor: Floor,
  floorPlan: File | null,
  floorImage: File | null,
  clients: Array<Client>,
  openConfirmModal:boolean,
  isLoading: boolean

}

class UploadPlan extends React.Component<WithTranslation & OfficeDetailProps, State> {

  constructor(props) {
    super(props);

    this.state = {
      officeId: "",
      floorId: "",
      isNew: false,
      isLoading: false,
      floor: {
        description: "",
        floorplanPresent: false,
        floorplanScale: {
          length: 0,
          p1: {
            x: 0,
            y: 0,
          },
          p2: {
            x: 0,
            y: 0
          }
        },
        floorplanScalePixelPerMeter: 0,
        id: "",
        children: [],
        name: "",
        level: 0,
        officeId: "",
        images: [],
        reservedForClients: [],
        exclusiveRoomsAvailable:0,
        exclusiveSeatsAvailable:0,
        seatsAvailable:0,
        sharedSeatsAvailable:0
      },
      isReady: false,
      floorPlan: null,
      clients: [],
      floorImage:null,
      openConfirmModal:false
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  onDeleteFloor = () => {
    //TODO(Kaan): Add delete floor api call
    HTTP_REQUESTS.OFFICE_API.DELETE_FLOOR(this.state.officeId,this.state.floorId, (success) => {
      notifySuccess(this.props.t('UPLOAD.11'));
      navigate(`/user/offices/${this.state.officeId}`)
    }, (error) => {
      notifyError(this.props.t('UPLOAD.12'))
    })
    this.handleCloseModal();
  }


  onSubmitFloor = (floorObj) => {


    if (this.state.isNew) {
      this.setState({isLoading: true})
      HTTP_REQUESTS.OFFICE_API.CREATE_FLOOR(this.state.officeId, floorObj, (success) => {
        notifySuccess(this.props.t('UPLOAD.15'));
        navigate('/officemeasure/' + success.officeId + '/' + success.id);
        this.setState({isLoading: false})
      }, (error) => {
        if (error.message) {
          this.setState({isLoading: false})
          notifyError(this.props.t('UPLOAD.13'))
        }
      })
    }
    else {
      this.setState({isLoading: true})
      HTTP_REQUESTS.OFFICE_API.UPDATE_FLOOR(this.state.officeId, this.state.floorId, floorObj, (success) => {
        notifySuccess(this.props.t('UPLOAD.16'));
        navigate('/user/offices/' + success.officeId);
        this.setState({isLoading: false});
      }, (error) => {
        if (error.message) {
          this.setState({isLoading: false});
          notifyError(this.props.t('UPLOAD.14'))
        }
      })
    }


  }

  getFloorDetail() {
    if (this.state.floorId === "uploadPlan") {
      this.setState({ isNew: true, isReady: true })
    }
    else {
      HTTP_REQUESTS.OFFICE_API.GET_FLOOR(this.state.officeId, this.state.floorId, (resFloor) => {

        HTTP_REQUESTS.OFFICE_API.GET_FLOORPLAN(this.state.officeId, this.state.floorId, (resPlan) => {

          this.setState({ floor: resFloor, floorPlan: resPlan},()=>{this.getFloorImage()});

        }, (err) => {
          this.setState({floor: resFloor },()=>{this.getFloorImage()});
        })

      }, (err) => {
        
      })
    }

  }

  getClients() {

    HTTP_REQUESTS.USERADMIN_API.GET_ALL_CLIENTS((res) => {
      this.setState({ clients: res })
    }, (err) => {

    })


  }

 getFloorImage () {
   if(this.state.floor.images.length>0){

    // HTTP_REQUESTS.OFFICE_API.GET_FLOOR_IMAGE(this.state.officeId, this.state.floorId, this.state.floor.images[0].id, (resFloor) => {
    //
    //   this.setState({floorImage:resFloor,isReady: true})
    // }, (err) => {
    //
    // })
   fetch(this.state.floor.images[0].uri)
       .then(res => res.blob())
       .then(blob => {
         let file = new File([blob], 'floorImage.jpg', {type: blob.type});
         this.setState({floorImage:file,isReady: true});
       })

   }
   else {
    this.setState({isReady: true });
   }
 }

  handleOpenModal() {
    this.setState({openConfirmModal:true})
  }

  handleCloseModal() {
    this.setState({openConfirmModal:false})
  } 

  componentDidMount() {
    
    document.title = this.props.t('TITLE.9');
    scrollToTop();
    this.getClients();
    this.setState({
      officeId: this.props.match.params.officeId,
      floorId: this.props.match.params.floorId,
    }, () => {
      this.getFloorDetail();
      
    })

  }



  render() {

    return (
      <div className="upload-plan-container">
        {this.state.isReady ? 
          <UploadPlanForm 
            onSubmit={this.onSubmitFloor}
            onDelete={this.onDeleteFloor}
            handleOpenModal={this.handleOpenModal}
            handleCloseModal={this.handleCloseModal}
            openConfirmModal={this.state.openConfirmModal}
            floorImageFile={this.state.floorImage} 
            exclusiveUsers={this.state.clients} 
            floor={this.state.floor} 
            floorPlan={this.state.floorPlan} 
            isNew={this.state.isNew}
            isLoading={this.state.isLoading}
          /> : null}
      </div>
    )

  }



}
export default withTranslation()(withRouter(UploadPlan))
