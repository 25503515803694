import React from 'react';
import { useTranslation } from 'react-i18next';
import { PropertyCard } from '../propertyCard/PropertyCard';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Office } from '../../interfaces/Office';

interface Props {
  office: Office | null
}

export const PropertyCardList: React.FC<Props> = (props) => {

  const { t, i18n } = useTranslation();
  const { office } = props;

  let length =0;
  if(office && office.floors){
    length = office.floors.length;
  }


  const settings = {
    dots: false,
    infinite: length > 3,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 961,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 861,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 601,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>
    {office != null && office.floors != null ? 
       <Slider {...settings}> 
       {office?.floors.map((floor, index) =>
         <div> <PropertyCard officeID={office.id} details={floor} /> </div> )}
     </Slider>
      :null}   
    </div>
    
  )

 
}