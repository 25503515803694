import React, {useState} from 'react'
import TextField from '@material-ui/core/TextField'
import moment, { Moment } from 'moment'
import * as _ from 'lodash'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {Button, Grid, IconButton} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import CheckIcon from '@material-ui/icons/Check';

export function isDateInRange(dateFrom:string, dateTo:string, dateToBeFiltered:Moment) {
  
  let dateFromMoment:Moment = moment(dateFrom);
  let dateToMoment:Moment = moment(dateTo);
  let dateToBeFilteredMoment:Moment = moment(dateToBeFiltered);

  if (!dateToBeFilteredMoment) {
    return !(dateFromMoment || dateToMoment)
  }
  if (!(dateFromMoment || dateToMoment)) {
    return true
  } else if (!dateFromMoment) {
    return dateToBeFilteredMoment.isSameOrBefore(dateToMoment)
  } else if (!dateToMoment) {
    return dateToBeFilteredMoment.isSameOrAfter(dateFromMoment)
  }
  return dateToBeFilteredMoment.isSameOrAfter(dateFromMoment)
    && dateToBeFilteredMoment.isSameOrBefore(dateToMoment)

  
}


export const FilterNumericBetween = (props) => {
  const { columnDef, onFilterChanged, from, to} = props
  const dateFormat:string = "DD-MM-YYYY HH:mm";

  let tempDate:string = '-01-01T00:00:00.000Z';
  const [_from, setFrom] = useState((from) && (from !==(parseInt(to.substring(0,4))-30).toString().concat(tempDate).toString()) ? from : '');
  const [_to, setTo] = useState( (to) && (to !==(parseInt(from.substring(0,4))+30).toString().concat(tempDate).toString()) ? to : '' );

  const { t } = useTranslation();
  const clearFilter = () => {
    if ((_from.length > 0) || (_to.length > 0)){
      onFilterChanged(columnDef.tableData.id, {from:'', to:''})
    }
  }
  const filterChanged = () => {
    if ((_from.length > 0 || _to.length > 0) && (from !== _from || to !== _to) &&
        !(to && _to === '' && from ===_from) && !(from && _from === '' && to ===_to) ) {
      if(_from.length===0){
        tempDate =(parseInt(_to.substring(0,4))-30).toString().concat(tempDate).toString();
      }else if (_to.length===0){
        tempDate=(parseInt(_from.substring(0,4))+30).toString().concat(tempDate).toString();
      }
      onFilterChanged(columnDef.tableData.id, {from: _from ? _from : tempDate, to: _to ? _to : tempDate})
    }
  }
  return (
    <>
    <Grid container className={"table-filter"}  direction="row">
      <Grid container xs={12} lg={11} className={'leftInputs'} item direction="column">
        <small>{t('BOOKINGSTABLE.9')}</small>
      <TextField
        type={'date'}
        defaultValue={moment().format(dateFormat)}
        value={moment(_from).format("YYYY-MM-DD") || ''}
        placeholder={columnDef.filterPlaceholder || 'From'}
        onChange={(event) => {
          setFrom(event.target.value);
        }}
      />
      <small>{t('BOOKINGSTABLE.10')}</small>
      <TextField
        type={'date'}
        defaultValue={moment().format(dateFormat)}
        value={moment(_to).format("YYYY-MM-DD") || ''}
        placeholder={columnDef.filterPlaceholder || 'To'}
        onChange={(event) => {
          setTo(event.target.value);
        }}
      />
      </Grid>
      <Grid container xs={12} lg={1} item justifyContent="center" direction="column">
        <IconButton onClick={() => filterChanged()} className={"filterButton"}>
          <CheckIcon />
        </IconButton>
        <IconButton onClick={() => clearFilter()} className={"filterButton"}>
            <CloseIcon color="secondary" />
          </IconButton>
      </Grid>
    </Grid>

      {/* <MuiPickersUtilsProvider utils={MomentUtils}>

       <KeyboardDatePicker
          disableToolbar
          variant="inline"
          margin="dense"
          id="date-picker-inline"
          label="From"
          value={_.get(columnDef, ['tableData', 'filterValue', 'dateFrom']) || ''}
          onChange={(event) => {
            let value = {...columnDef.tableData.filterValue}
            value.dateFrom = event
            onFilterChanged(columnDef.tableData.id, value)
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          margin="dense"
          id="date-picker-inline"
          label="To"
          value={_.get(columnDef, ['tableData', 'filterValue', 'dateTo']) || ''}
          onChange={(event) => {
            let value = {...columnDef.tableData.filterValue}
            value.dateTo = event
            onFilterChanged(columnDef.tableData.id, value)
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider> */}
      
      
      
    </>
  )
}
