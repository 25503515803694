import React, { Component, useState, useEffect } from 'react';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { navigate } from '../../history/navigate';
import {  notifyError } from '../notification/Notification';

interface Props {

}

export const ForgotPassword: React.FC<Props> = () => {

    const submit = (email) => {

        let forgotPasswordRequestData={
            email:email
        };

        HTTP_REQUESTS.TENANT_API.POST_FORGOT_PASSWORD(forgotPasswordRequestData, (res) => {
                navigate('/forgotPasswordSuccess');
            }, (err) => {
                notifyError(err.message);
            });

    }

    return (<ForgotPasswordForm onSubmitClick={submit} />)
   
}