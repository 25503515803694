import React from 'react';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { withTranslation, WithTranslation } from 'react-i18next';
import { navigate } from '../../history/navigate';
import { notifySuccess, notifyError } from '../notification/Notification';
import { AppContext } from "../../middlewares/appContext/AppContext";
import { Button, FormControl, FormHelperText, Grid, InputLabel, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { Clear, Close, EditOutlined, PersonAddOutlined, SaveAltOutlined, SaveOutlined } from '@material-ui/icons';
import { Client } from '../../interfaces/Client';
import { RouteComponentProps, withRouter } from 'react-router';
import { InviteClient } from '../../interfaces/InviteClient';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

interface State {
    clientId: string,
    client: Client,
    isNew: boolean,
    email: string,
    password: string,
    salutation: string,
    firstName: string,
    lastName: string,
    role: string,
    enabled: boolean,
    clientNameErr: boolean,
    clientNameErrMsg: string,
    emailErr: boolean,
    emailErrMsg: string,
    firstNameErr: boolean,
    firstNameMsg: string,
    lastNameErr: boolean,
    lastNameMsg: string,
    salutationErr: boolean,
    salutationErrMsg: string
}

interface Props {
    client: Client,
    isNew: boolean,
    onUpdateClient: (id: string, editObj) => void,
    onCreateClient: (clientObj: InviteClient) => void
    isLoading: boolean
}

type ClientParams = {
    clientId: string
};

type ClientDetailProps = RouteComponentProps<ClientParams>;


class ClientsForm extends React.Component<WithTranslation & Props & ClientDetailProps, State> {

    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            clientId: "",
            client: { id: "", name: "" },
            isNew: false,
            enabled: true,
            firstName: "",
            lastName: "",
            role: "Client User",
            email: "",
            password: "",
            salutation: " ",
            clientNameErr: false,
            clientNameErrMsg: "",
            emailErr: false,
            emailErrMsg: "",
            firstNameErr: false,
            firstNameMsg: "",
            lastNameErr: false,
            lastNameMsg: "",
            salutationErr: false,
            salutationErrMsg: ""


        };

    }


    onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            client: {
                ...this.state.client,
                name: e.target.value
            },
            clientNameErrMsg: "",
            clientNameErr: false
        })

    }

    onChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ firstName: e.target.value, firstNameErr: false, firstNameMsg: "" })
    }
    onChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ lastName: e.target.value, lastNameErr: false, lastNameMsg: "" })
    }
    onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ email: e.target.value, emailErr: false, emailErrMsg: "" })
    }
    onChangeSalutation = (e: any) => {
        this.setState({ salutation: e.target.value, salutationErr: false, salutationErrMsg: "" })
    }

    isEmailValid = () => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(this.state.email)) {

            return true
        }
        else {
            return false
        }
    }

    checkValidationClient = () => {

        let formValid = true;

        if (this.state.client.name == "" || this.state.client.name == null) {
            formValid = false;
            this.setState({ clientNameErrMsg: this.props.t('CLIENT.17'), clientNameErr: true })
        }

        if (formValid) {
            let editObj = {
                name: this.state.client.name
            }

            this.editClient(this.state.client.id, editObj);
        }
    }



    checkValidationInvite = () => {

        let formValid = true;

        if (this.state.client.name == "" || this.state.client.name == null) {
            formValid = false;
            this.setState({ clientNameErrMsg: this.props.t("CLIENT.4"), clientNameErr: true })
        }

        if (this.state.email == null || this.isEmailValid() == false) {

            formValid = false;
            this.setState({ emailErrMsg: this.props.t("CLIENT.5"), emailErr: true })

        }

        if (this.state.firstName == "" || this.state.firstName == null) {

            formValid = false;
            this.setState({ firstNameMsg: this.props.t("CLIENT.6"), firstNameErr: true });

        }

        if (this.state.lastName == "" || this.state.lastName == null) {

            formValid = false;
            this.setState({ lastNameMsg: this.props.t("CLIENT.7"), lastNameErr: true });

        }

        if (this.state.salutation == " " || this.state.salutation == null) {

            formValid = false;
            this.setState({ salutationErrMsg: this.props.t("CLIENT.8"), salutationErr: true });
        }

        if (formValid) {
            let clientId = this.state.isNew ? "" : this.state.client.id;
            let clientObj: InviteClient = {
                client: {
                    name: this.state.client.name
                },
                initialUser: {
                    clientId: clientId,
                    email: this.state.email,
                    enabled: true,
                    firstname: this.state.firstName,
                    lastname: this.state.lastName,
                    password: "*",
                    role: "*",
                    salutation: this.state.salutation
                }
            }
            this.createClient(clientObj);
        }
    }

    createClient = (clientObj: InviteClient) => {
        if (this.props.onCreateClient) {
            this.props.onCreateClient(clientObj)
        }
    }

    editClient = (id: string, editObj) => {
        if (this.props.onUpdateClient) {
            this.props.onUpdateClient(id, editObj)
        }
    }

    cancelCreate = () => {
        navigate("/clients");
    }

    componentDidMount() {

        if (!this.props.isNew) {
            this.setState({ client: this.props.client })
        }

    }

    render() {
        const { t } = this.props;
        const { firstName, lastName, salutation, email, enabled, password, role } = this.state;
        return (
            <div className="clients-crud-form">
                <Grid className="client-name-container" container justify="space-between" xs={12}>
                    <Grid item >
                        <Typography className="client-header" variant="h5" color="secondary">{this.props.isNew ? t("CLIENT.14") : this.props.client.name}</Typography>
                    </Grid>

                </Grid>
                <Grid container xs={12} justify="space-between">
                    <Grid item xs={10}>
                    <TextField
                            autoComplete="off"
                            name="name"
                            fullWidth
                            required
                            id="name"
                            label={t("CLIENT.15")}
                            color="secondary"
                            value={this.state.client.name}
                            onChange={this.onChangeName}
                            error={this.state.clientNameErr}
                            helperText={this.state.clientNameErrMsg}
                        />
                    </Grid>
                    <Grid item xs={1}>
                    {this.props.isNew ? 
                        <Button variant="contained" onClick={this.cancelCreate} className="add-client-btn" > 
                            <Close className="add-icon" color="primary" />{t('USERCRUD.19')} 
                        </Button> :
                   
                        <Button variant="contained" onClick={this.checkValidationClient} className="add-client-btn" > 
                            <SaveOutlined className="add-icon" color="primary" />{"t('USERCRUD.10')"} 
                        </Button>
                   
                    } 
                    </Grid>
                    
                        
                </Grid>
                <Grid container className="invite-container" xs={12} direction="column">

                    <Grid item >
                        <Typography className="client-header" variant="h5" color="secondary">{t("CLIENT.3")}</Typography>
                    </Grid>

                    <form className="invite-info-form" noValidate>
                        <Grid container spacing={3} direction="row" className="first-row">

                            <Grid className="select-grid" item xs={12} >
                                <FormControl className="select"
                                    color="secondary"
                                    error={this.state.salutationErr}
                                >
                                    <InputLabel>{t('USERCRUD.1')}</InputLabel>
                                    <Select
                                        onChange={this.onChangeSalutation}
                                        defaultValue={salutation}
                                        inputProps={{ 'aria-label': '1' }}
                                    >
                                        <MenuItem value={' '}><em>{t('USERCRUD.14')}</em></MenuItem>
                                        <MenuItem value={"Mr"}>{t('USERCRUD.11')}</MenuItem>
                                        <MenuItem value={"Mrs"}>{t('USERCRUD.12')}</MenuItem>
                                    </Select>
                                    <FormHelperText color="primary" >{this.state.salutationErr ? this.state.salutationErrMsg : null}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className="unset-left-padding">
                                <TextField
                                    autoComplete="off"
                                    name="firstName"
                                    fullWidth
                                    id="firstName"
                                    label={t('USERCRUD.2')}
                                    color="secondary"
                                    value={firstName}
                                    onChange={this.onChangeFirstName}
                                    error={this.state.firstNameErr}
                                    helperText={this.state.firstNameMsg}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    fullWidth
                                    id="lastName"
                                    label={t('USERCRUD.3')}
                                    name="lastName"
                                    autoComplete="off"
                                    color="secondary"
                                    value={lastName}
                                    onChange={this.onChangeLastName}
                                    error={this.state.lastNameErr}
                                    helperText={this.state.lastNameMsg}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-end" className="second-row">
                            <Grid item xs={8} spacing={3} className="unset-left-padding">
                                <TextField
                                    color="secondary"
                                    fullWidth
                                    id="email"
                                    label={t('USERCRUD.4')}
                                    name="email"
                                    autoComplete="off"
                                    value={email}
                                    className="mail-input"
                                    onChange={this.onChangeEmail}
                                    error={this.state.emailErr}
                                    helperText={this.state.emailErrMsg}
                                />
                            </Grid>

                        </Grid>

                    </form>
                    <Grid container xs={12} justify="space-between">
                        <Grid item xs={10}></Grid>
                        <Grid item xs={1}>
                        <Button variant="contained" className="add-client-btn" onClick={this.checkValidationInvite} disabled={this.props.isLoading} > <SaveOutlined className="add-icon" color="primary" />{t('USERCRUD.20')} </Button>

                        </Grid>
                    </Grid>
                </Grid>


            </div>
        );
    }

}
export default withTranslation()(withRouter(ClientsForm))