import React, {Component} from 'react'
import {withTranslation, WithTranslation} from 'react-i18next';
import {Calendar, momentLocalizer, Views} from 'react-big-calendar'
import moment, {Moment} from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {Booking} from '../../interfaces/Booking';
import {BookingPopover} from './BookingPopover';
import {notifyError} from '../notification/Notification';
import {OpeningHours} from '../../interfaces/OpeningHours';

interface State {
  localizer: any,
  openBookingModal: boolean,
  popoverAnchorEl: HTMLButtonElement | null,
  eventStartTime: Moment,
  eventEndTime: Moment,
  calendarInitialTime: Date,
  currentLang: string | null,
  eventSlots?: any,
    calendarView:string
}

interface Props {
    events: Array<Booking>,
    onCreateBooking: (booking: any) => void,
    resourceId: string,
    seatFeatures: Array<string>,
    roomFeatures: Array<string>,
    selectedSeatFeatures: Array<string>,
    selectedRoomFeatures: Array<string>,
    onChangeSeatFeatures: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onChangeRoomFeatures: (event: React.ChangeEvent<HTMLInputElement>) => void,
    workingHours: OpeningHours,
    isLoading: boolean
}
const mediaQuery = '(max-width: 600px)';
const mediaQueryList = window.matchMedia(mediaQuery);
class ResourceCalendar extends Component<WithTranslation & Props, State> {
    constructor(props) {
        super(props)
    
        this.state = {
            localizer: momentLocalizer(moment),
            openBookingModal: false,
            popoverAnchorEl: null,
            eventStartTime: moment(),
            eventEndTime:moment(),
            calendarInitialTime: moment().set('hour', 8).toDate(),
            currentLang: localStorage.getItem('language') ? localStorage.getItem('language') : 'de',
            calendarView: mediaQueryList.matches ?  "day" :"week"
        }
        
        moment.locale(this.state.currentLang? this.state.currentLang: 'de', {
            week: {
                dow: 1,
                doy: 1,
            },
        })
    }

    componentDidMount() {
        const handleChange = (event) => {
            this.setState({calendarView:event.matches?"day" :"week"})
        }
        mediaQueryList.addEventListener('change', handleChange)
        return () => {
            // This is called the cleanup phase aka beforeUnmount
            mediaQueryList.removeEventListener('change', handleChange)
        }
    }

    Event = ({event}) => {
        return(
            <span>
                {event.title ? event.title : this.props.t('BOOKINGS.Reserved')}
            </span>
        )
    }
    isAllDaySelection = ():boolean => {
        return (this.state.eventSlots.length === 1)
    }
    afterOnSelectedCheck = ():void => {
      let datesValid = true
      if(this.isAllDaySelection()) {
          const eventStartTime =  this.state.eventStartTime;
          const eventEndTime =  this.state.eventStartTime;

          const startDateDay:string = eventStartTime.format('dddd');
          const endDateDay:string = eventEndTime.format('dddd');

          const startDate:string = eventStartTime.format('MM-DD-YYYY');
          const endDate:string = eventEndTime.format('MM-DD-YYYY');

          const openingTime = this.getOpeningTime(startDateDay);
          const closingTime = this.getClosingTime(endDateDay);

          const startDateTime: Moment = moment(`${startDate} ${openingTime}`);
          const endDateTime: Moment = moment(`${endDate} ${closingTime}`);

          this.setState({
              eventStartTime : startDateTime,
              eventEndTime : endDateTime,
          });
      } else {
          if(this.checkIfPastDateSelected(this.state.eventStartTime, this.state.eventEndTime)){
              datesValid = false;
              notifyError(this.props.t('BOOKINGS.ERRORS.1'));
          }
          else if(this.isOfficeClosedOnSelectedDates(this.state.eventStartTime, this.state.eventEndTime)) {
              datesValid = false;
              notifyError(this.props.t('BOOKINGS.ERRORS.2'));
          }
      }
      if(datesValid) {
          this.handleOpenBookingModal();
      }
    }

    onSelected = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({popoverAnchorEl:event.currentTarget,
            eventStartTime: moment(event['start']), 
            eventEndTime: moment(event['end']),
            eventSlots: event['slots']},this.afterOnSelectedCheck)
    }

    handleOpenBookingModal = () => {
        this.setState({openBookingModal:true});
    }

    handleCloseBookingModal = () => {
        this.setState({openBookingModal:false, popoverAnchorEl: null});
    }

    checkIfPastDateSelected = (start: Moment, end: Moment): boolean => {
        return start.isBefore(moment());
    }

    isOfficeClosedOnSelectedDates(start: Moment, end: Moment) {
      const startDateDay:string = start.format('dddd');
      const endDateDay:string = end.format('dddd');

      const startOpenTimeByDay = this.getOpeningTime(startDateDay);
      let splittedStartOpenTime = startOpenTimeByDay.split(':')
      let startOpenTimeHour = Number(splittedStartOpenTime[0]);
      let startOpenTimeMin = Number(splittedStartOpenTime[1]);
      const finalStartTimeOfOfficeOnStart = moment(start).set({"hour": startOpenTimeHour, "minute": startOpenTimeMin});
      
      const startCloseTimeByDay = this.getClosingTime(startDateDay);
      let splittedStartCloseTime = startCloseTimeByDay.split(':')
      let startCloseTimeHour = Number(splittedStartCloseTime[0]);
      let startCloseTimeMinute = Number(splittedStartCloseTime[1]);
      const finalCloseTimeOfOfficeOnStart = moment(start).set({"hour": startCloseTimeHour, "minute": startCloseTimeMinute});
      

      if(startOpenTimeByDay === "" || startCloseTimeByDay === ""){
        return true
      }
      /**
       * Start or End outside of a that day's working hours return true
       * No need to check with office end date, due to component do not able to selected end date as tomorrow or etc..
      */
      else return start.isBefore(finalStartTimeOfOfficeOnStart) || end.isAfter(finalCloseTimeOfOfficeOnStart);
    }

    //TODO (Kaan): Make these getting openning and closing times of office functions generic

    getClosingTime = (day:string):string => {
        const openingHours = this.props.workingHours;
        if(openingHours) {
          switch (day) {
            case 'Monday':
              return openingHours.moTo
            case 'Tuesday':
              return openingHours.tuTo
            case 'Wednesday':
              return openingHours.weTo
            case 'Thursday':
              return openingHours.thTo
            case 'Friday':
              return openingHours.frTo
            case 'Saturday':
              return openingHours.saTo
            case 'Sunday':
              return openingHours.suTo
            default:
              return "19:00"
          }
        }
        else {
          return "18:00"
        }
    }

    getOpeningTime = (day:string):string => {
        const openingHours = this.props.workingHours;

        if(openingHours) {
          switch (day) {
            case 'Monday':
              return openingHours.moFrom
            case 'Tuesday':
              return openingHours.tuFrom
            case 'Wednesday':
              return openingHours.weFrom
            case 'Thursday':
              return openingHours.thFrom
            case 'Friday':
              return openingHours.frFrom
            case 'Saturday':
              return openingHours.saFrom
            case 'Sunday':
              return openingHours.suFrom
            default:
              return "08:00"
          }
        }
        else {
          return "07:00"
        }
      }

    getTranslations = (): any => {
        const { t } = this.props;
        return {
            next: t('CALENDAR.RCALENDAR.Next'),
            previous: t('CALENDAR.RCALENDAR.Back'),
            today: t('CALENDAR.RCALENDAR.Today'),
            month: t('CALENDAR.RCALENDAR.Month'),
            week: t('CALENDAR.RCALENDAR.Week'),
            day: t('CALENDAR.RCALENDAR.Day'),
            agenda: t('CALENDAR.RCALENDAR.Agenda'),
            noEventsInRange: t('CALENDAR.RCALENDAR.No Events')
        };
    }
    
    render() {
        return (
            <div style={{padding:16}}>
                <Calendar
                    selectable
                    showMultiDayTimes = {true}
                    scrollToTime={this.state.calendarInitialTime}
                    events={this.props.events}
                    localizer={this.state.localizer}
                    onSelectSlot={this.onSelected}
                    view={this.state.calendarView}
                    views={['week', 'day', 'agenda']}
                    startAccessor="startDateTime"
                    endAccessor="endDateTime"
                    messages={this.getTranslations()}
                    onView={(calendarView)=>{
                        this.setState({calendarView})
                    }}
                    formats = {{
                        eventTimeRangeFormat: range =>
                          `${moment(range.start).format('HH:mm')} – ${moment(range.end).format('HH:mm')}`,
                        timeGutterFormat:'HH:mm',
                        selectRangeFormat: range =>
                        `${moment(range.start).format('HH:mm')} – ${moment(range.end).format('HH:mm')}`,
                        agendaTimeRangeFormat:range =>
                        `${moment(range.start).format('HH:mm')} – ${moment(range.end).format('HH:mm')}`
                    ,}}
                    components={{
                        day: {
                            event: this.Event,
                        },
                        week: {
                            event: this.Event,
                        },
                        month: {
                            event: this.Event,
                        },
                        agenda: {
                            event: this.Event,
                        }
                    }}
                />
            {
                this.state.openBookingModal ? 
                    <BookingPopover 
                        openBookingModal={this.state.openBookingModal} 
                        handleCloseBookingModal={this.handleCloseBookingModal}
                        anchorEl={this.state.popoverAnchorEl}
                        startTime={this.state.eventStartTime}
                        endTime={this.state.eventEndTime}
                        onCreateBooking={this.props.onCreateBooking}
                        resourceId={this.props.resourceId}
                        seatFeatures = {this.props.seatFeatures}
                        roomFeatures = {this.props.roomFeatures}
                        selectedSeatFeatures={this.props.selectedSeatFeatures}
                        selectedRoomFeatures={this.props.selectedRoomFeatures}
                        onChangeSeatFeatures = {this.props.onChangeSeatFeatures}
                        onChangeRoomFeatures = {this.props.onChangeRoomFeatures}
                        disableDatePickers = {(this.state.eventSlots.length === 1) ? true : false}
                        isLoading = {this.props.isLoading}
                    />
                : 
                null
            }
            
            </div>
        )
    }
}
export default withTranslation() (ResourceCalendar);
