import React, { Component, useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, IconButton, Typography, Grid, TextField, InputAdornment } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import { HTTP_REQUESTS } from '../../../backendcomm/services/httpRequestService';
import { navigate } from '../../../history/navigate';
import moment, { Moment } from 'moment';
import GenericButton from '../../button/GenericButton';
import CalendarToday from '@material-ui/icons/CalendarToday';
import { english } from 'flatpickr/dist/l10n/default';
import { German } from 'flatpickr/dist/l10n/de';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_red.css";
import { BookingDto } from '../../../interfaces/BookingDto.class';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

interface Props {
    open: boolean,
    handleClose: () => void,
    seatId: string
    startDateTime: any,
    endDateTime: any,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {

            width: "45%",
            height: "35%",
            backgroundColor: "#ffffff",

        },
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: "100%",
            marginTop: "16px"
        },
        formControl: {
            margin: theme.spacing(3),
        },
    }),
);


export const ExtendBookingModal: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const [doNotRemind, setDoNotRemind] = React.useState<boolean>(false);
    const [selectedDateStart, setSelectedDateStart] = React.useState<Moment>(props.startDateTime);
    const [selectedDateEnd, setSelectedDateEnd] = React.useState<Moment>(props.endDateTime);
    const [startError, setStartError] = React.useState<boolean>(false);
    const [endError, setEndError] = React.useState<boolean>(false);
    const [startErrorMsg, setStartErrorMsg] = React.useState<string>('');
    const [endErrorMsg, setEndErrorMsg] = React.useState<string>('');
    const en = {
        ...english,
        firstDayOfWeek: 1
    }

    useEffect(() => {

    }, []);

    const handleNavSchedule = (seatId: string): void => {
        navigate(`/officereservation/seat/${seatId}/schedule`)
    }

    const onChangeReminder = (event: React.ChangeEvent<any>): void => {
        setDoNotRemind(event.target.checked)
        localStorage.setItem("openExtendModal", JSON.stringify(!event.target.checked))
    }


    const onSaveClick = () => {
        if (props.seatId) {
            const bookingObject = {
                startDateTime: moment(selectedDateStart).utc(true),
                endDateTime: moment(selectedDateEnd).utc(true),
                rrule: null,
                seatId: props.seatId
            }
            HTTP_REQUESTS.CLIENT_API.CREATE_BOOKING(bookingObject, (res) => {
                if (props.seatId) {
                    props.handleClose();
                    navigate("/user/bookings");
                }

            }, (err) => {
                if (props.seatId) {
                }

            });


        } else {
            //alert("Room booking not implemented");
        }
    }

    return (

        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classes.paper}>
                        <div style={{ margin: "4px", alignItems: "baseline", display: "flex", width: "99%", height: "5vh", justifyContent: "space-between" }}>
                            <Typography style={{ alignSelf: "center", fontSize: "1vw" }} variant="body1" color="primary">
                            </Typography>
                            <IconButton onClick={props.handleClose} style={{ alignItems: "start" }} >
                                <CloseIcon color="secondary" />
                            </IconButton>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "14vh", width: "100%", backgroundColor: "#f2f2f2" }}>
                            <div>
                                <Typography style={{ alignSelf: "center", fontSize: "1vw" }} variant="body1" color="primary">
                                    {moment(props.startDateTime).format("DD-MM-YYYY HH:mm")} /  {moment(props.endDateTime).format("DD-MM-YYYY HH:mm")}
                                </Typography>
                            </div>
                            <div >
                                {/* <strong>{t('BOOKINGS.1')}</strong> */}
                                <strong>{t('BOOKINGS.12')}</strong>
                            </div>

                            <div style={{ color: "#5db301", justifySelf: "center" }}><strong>{t('BOOKINGS.2')}!</strong></div>
                            {/* TODO (Kaan): Change icon button to text button ?? or add a helper text with localization */}
                            <div style={{ color: "#5db301", justifySelf: "center" }}>
                                <IconButton onClick={() => { handleNavSchedule(props.seatId ? props.seatId : "") }} style={{ alignItems: "start" }} >
                                    <CalendarToday color="primary" />
                                </IconButton>
                            </div>
                            {/* End of TODO (Kaan) */}
                        </div>
                        <div className={classes.root}>
                            <Grid container justifyContent='space-evenly'>
                                <Grid item xs={12} md={4} alignItems="center">
                                    <Flatpickr
                                        options={{
                                            dateFormat: "d-m-Y H:i",
                                            defaultDate: props.startDateTime.format("DD-MM-YYYY HH:mm"),
                                            enableTime: true,
                                            noCalendar: true,
                                            // minTime: props.startMinTime,
                                            minDate: moment().format("DD-MM-YYYY"),
                                            // maxTime: props.startMaxTime,
                                            time_24hr: true,
                                            minuteIncrement: 15,
                                            locale: localStorage.getItem('language') === 'en' ? en : German
                                        }}
                                        onChange={date => {
                                            setSelectedDateStart(moment(date[0]));
                                        }}
                                        render={({ defaultValue }, ref) => {
                                            return (
                                                <div>
                                                    <TextField
                                                        style={{ marginRight: "16px" }}
                                                        color="secondary"
                                                        label={t('FILTERBAR.1')}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end"><CalendarTodayIcon fontSize="small" color="primary" /> </InputAdornment>,
                                                        }}
                                                        value={selectedDateStart.format("DD-MM-YYYY HH:mm")} ref={ref}
                                                    />

                                                </div>
                                            )
                                        }}
                                    />{startError ? <small style={{ color: 'red' }}> {startErrorMsg} </small> : null}
                                </Grid>
                                <Grid item xs={12} md={4} alignItems="center" justifyContent="center">
                                    <Flatpickr
                                        options={{
                                            dateFormat: "d-m-Y H:i",
                                            defaultDate: moment(props.startDateTime).add(30, 'minutes').format("DD-MM-YYYY HH:mm"),
                                            enableTime: true,
                                            noCalendar: true,
                                            // minTime: props.startMinTime,
                                            minDate: props.startDateTime.format("DD-MM-YYYY"),
                                            // maxTime: props.startMaxTime,
                                            time_24hr: true,
                                            minuteIncrement: 15,
                                            locale: localStorage.getItem('language') === 'en' ? en : German
                                        }}
                                        onChange={date => {
                                            setSelectedDateEnd(moment(date[0]));
                                        }}
                                        render={({ defaultValue }, ref) => {
                                            return (
                                                <div>
                                                    <TextField
                                                        style={{ marginRight: "16px" }}
                                                        color="secondary"
                                                        label={t('FILTERBAR.2')}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end"><CalendarTodayIcon fontSize="small" color="primary" /> </InputAdornment>,
                                                        }}
                                                        value={selectedDateEnd.format("DD-MM-YYYY HH:mm")} ref={ref}
                                                    />

                                                </div>
                                            )
                                        }}
                                    />{endError ? <small style={{ color: 'red' }}> {endErrorMsg} </small> : null}
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.root}>

                        <Grid style={{ margin:'0 auto', width: "80%", display: "flex", justifyContent: "space-evenly" }} container justifyContent='space-evenly' alignItems='center'>
                            <Grid item xs={12} md={4}>
                                <FormControlLabel
                                    control={<Checkbox color="primary" checked={doNotRemind} name="doNotRemind" />}
                                    label={"FILTERBAR.15"}
                                    onChange={onChangeReminder}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Button color="secondary" onClick={props.handleClose} variant="outlined" type="button" >{t('BOOKINGS.9')}</Button>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <GenericButton label={'Extend Booking'} onClick={onSaveClick} />
                            </Grid>
                        </Grid>
                        </div>

                    </div>
                </Fade>
            </Modal>
        </div>
    );



}