import React, { useContext, useEffect, useState} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { AppContext } from "../../middlewares/appContext/AppContext";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ImageGridList } from "../imageGridList/ImageGridList";
import Typography from '@material-ui/core/Typography';
import {  notifyError, notifySuccess } from '../notification/Notification';
import { FormHelperText, InputAdornment } from '@material-ui/core';
import RichTextEditor from 'react-rte';

interface Address{
    city: string | null,
    street: string | null,
    zip: string | null
}

interface Image{
    description: string,
    id: string,
    uri: string,
}

interface OpeningHours{
    frFrom: string | null,
    frTo: string| null,
    moFrom: string| null,
    moTo: string | null,
    saFrom: string | null,
    saTo: string | null,
    suFrom: string | null,
    suTo: string | null,
    thFrom: string | null,
    thTo: string | null,
    tuFrom: string | null,
    tuTo: string | null,
    weFrom: string | null,
    weTo: string | null
}

interface OfficeObject{
    address: Address,
    description: string | null,
    hygieneMeasuresDescription: string | null,
    hygieneMeasuresDistance: string | null,
    mapLink: string | null,
    name: string | null,
    openingHours: OpeningHours | null,
    images: Array<Image> | null,
    id: string | null,
}

interface Props{
    office: OfficeObject | null;
    isEmpty: Boolean,
    onSubmit: (officeObj,images) => void,
    isNew:Boolean,
    isLoading: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        marginLeft:100
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    timeField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        //width: '5vw'
        fullWidth: true
    },
  }),
);

const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
      {label: 'Italic', style: 'ITALIC'},
      {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
      {label: 'Normal', style: 'unstyled'},
      {label: 'Heading Large', style: 'header-one'},
      {label: 'Heading Medium', style: 'header-two'},
      {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'UL', style: 'unordered-list-item'},
      {label: 'OL', style: 'ordered-list-item'}
    ]
  };

export const PropertyForm: React.FC<Props> = (props) => {
    const { t, i18n } = useTranslation();
    const context = useContext(AppContext);
    const classes = useStyles();

    const {isLoading, office, onSubmit } = props;

    const [images, setImages] = useState(Array<Image>());

    const [mondayFlag, setMondayFlag] = React.useState((office?.openingHours?.moFrom) ? true: false );
    const [tuesdayFlag, setTuesdayFlag] = React.useState((office?.openingHours?.tuFrom) ? true: false );
    const [wednesdayFlag, setWednesdayFlag] = React.useState((office?.openingHours?.weFrom) ? true: false );
    const [thursdayFlag, setThursdayFlag] = React.useState((office?.openingHours?.thFrom) ? true: false );
    const [fridayFlag, setFridayFlag] = React.useState((office?.openingHours?.frFrom) ? true: false );
    const [saturdayFlag, setSaturdayFlag] = React.useState((office?.openingHours?.saFrom) ? true: false );
    const [sundayFlag, setSundayFlag] = React.useState((office?.openingHours?.suFrom) ? true: false );

    const [name, setName] = React.useState(office?.name);
    const setNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName( event.target.value);
        setNameErr(false);
        setNameErrText("");
    }

    const [street, setStreet] = React.useState(office?.address?.street);
    const setStreetChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStreet( event.target.value);
        setStreetErr(false);
        setStreetErrText("");
    }

    const [postcode, setPostcode] = React.useState(office?.address?.zip);
    const setPostcodeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPostcode( event.target.value);
        setPostCodeErr(false);
        setPostCodeErrText("");
    }

    const [position, setPosition] = React.useState(office?.address?.city);
    const setPositionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPosition( event.target.value);
        setPositionErr(false);
        setPositionErrText("");
    }

    const [googleplaces, setGooglePlaces] = React.useState(office?.mapLink || "");
    const setGoogleplacesChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGooglePlaces( event.target.value);
    }

    const [description, setDescription] = React.useState(office?.description || "");
    const setDescriptionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription( event.target.value);
    }

    const [hygienedescription, setHygienedescription] = React.useState(office?.hygieneMeasuresDescription || "");
    const setHygienedescriptionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHygienedescription( event.target.value);
    }

    const [minimumdistance, setMinimumdistance] = React.useState(office?.hygieneMeasuresDistance || 0);
    const setMinimumdistanceChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMinimumdistance( event.target.value);
    }
    
    const [mondaystart, setMondayStart] = React.useState(office?.openingHours?.moFrom || '08:00');
    const mondayStartChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMondayStart( event.target.value );
    };

    const [mondayend, setMondayEnd] = React.useState(office?.openingHours?.moTo || '18:00');
    const mondayEndChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMondayEnd( event.target.value );
    };

    const [tuesdaystart, setTuesdayStart] = React.useState(office?.openingHours?.tuFrom || '08:00');
    const tuesdayStartChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTuesdayStart( event.target.value );
    };
    
    const [tuesdayend, setTuesdayEnd] = React.useState(office?.openingHours?.tuTo || '18:00');
    const tuesdayEndChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTuesdayEnd( event.target.value );
    };

    const [wednesdaystart, setWednesdayStart] = React.useState(office?.openingHours?.weFrom || '08:00');
    const wednesdayStartChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWednesdayStart( event.target.value );
    };
    
    const [wednesdayend, setWednesdayEnd] = React.useState(office?.openingHours?.weTo || '18:00');
    const wednesdayEndChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWednesdayEnd( event.target.value );
    };

    const [thursdaystart, setThursdayStart] = React.useState(office?.openingHours?.thFrom || '08:00');
    const thursdayStartChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setThursdayStart( event.target.value );
    };
    
    const [thursdayend, setThursdayEnd] = React.useState(office?.openingHours?.thTo || '18:00');
    const thursdayEndChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setThursdayEnd( event.target.value );
    };

    const [fridaystart, setFridayStart] = React.useState(office?.openingHours?.frFrom || '08:00');
    const fridayStartChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFridayStart( event.target.value );
    };
    
    const [fridayend, setFridayEnd] = React.useState(office?.openingHours?.frTo || '18:00');
    const fridayEndChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFridayEnd( event.target.value );
    };

    const [saturdaystart, setSaturdayStart] = React.useState(office?.openingHours?.saFrom || '08:00');
    const saturdayStartChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSaturdayStart( event.target.value );
    };
    
    const [saturdayend, setSaturdayEnd] = React.useState(office?.openingHours?.saTo || '18:00');
    const saturdayEndChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSaturdayEnd( event.target.value );
    };

    const [sundaystart, setSundayStart] = React.useState(office?.openingHours?.suFrom || '08:00');
    const sundayStartChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSundayStart( event.target.value );
    };
    
    const [sundayend, setSundayEnd] = React.useState(office?.openingHours?.suTo || '18:00');
    const sundayEndChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSundayEnd( event.target.value );
    };

    const [checked, setChecked] = React.useState(false);
    const [hygiene, setHygiene] = React.useState(false);
    
    const [state, setState] = React.useState({
        checkedMonday: mondayFlag,
        checkedTuesday: tuesdayFlag, 
        checkedWednesday: wednesdayFlag, 
        checkedThursday: thursdayFlag, 
        checkedFriday: fridayFlag, 
        checkedSaturday: saturdayFlag, 
        checkedSunday: sundayFlag,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => { setChecked(event.target.checked); };
    const handleHygiene = (event: React.ChangeEvent<HTMLInputElement>) => { setHygiene(event.target.checked); setHygieneErr(false);setHygieneErrText("")};
    const handleDays = (event: React.ChangeEvent<HTMLInputElement>) => {        
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const { checkedMonday, checkedTuesday, checkedWednesday, checkedThursday, checkedFriday, checkedSaturday, checkedSunday} = state;

    //VALIDATIONS
    
    const [nameErr,setNameErr]                  = useState<boolean>(false);
    const [nameErrText,setNameErrText]          = useState<String>("");

    const [streetErr,setStreetErr]              = useState<boolean>(false);
    const [streetErrText,setStreetErrText]      = useState<String>("");

    const [positionErr,setPositionErr]          = useState<boolean>(false);
    const [positionErrText,setPositionErrText]  = useState<String>("");

    const [postCodeErr,setPostCodeErr]          = useState<boolean>(false);
    const [postCodeErrText,setPostCodeErrText]  = useState<String>("");

    const [hygieneErr, setHygieneErr]           = useState<boolean>(false); 
    const [hygieneErrText, setHygieneErrText]   = useState<String>(""); 

    //END OF VALIDATIONS

    //RICH-TEXT EDITOR STATES
    const [officeDescRich, setOfficeDescRich] = useState<any>(RichTextEditor.createValueFromString( office?.description ? office.description : "", 'html'));
    const [hygieneDescRich, setHygieneDescRich] = useState<any>(RichTextEditor.createValueFromString( office?.hygieneMeasuresDescription ? office.hygieneMeasuresDescription : "", 'html'));

    const handleOfficeDescRich = (value:any) => {
        setOfficeDescRich(value)
    }
    
    const handleHygieneDescRich = (value:any) => {
        setHygieneDescRich(value)
    }
    //END OF RICH-TEXT EDITOR STATES
    
    function putOffice(){
         let addr = {
            city: position,
            street: street,
            zip: postcode
        }
        let hours = {
            frFrom: checkedFriday ? fridaystart: "",
            frTo: checkedFriday ? fridayend: "" ,
            moFrom: checkedMonday? mondaystart: "",
            moTo: checkedMonday? mondayend: "",
            saFrom: checkedSaturday? saturdaystart: "",
            saTo: checkedSaturday? saturdayend: "",
            suFrom: checkedSunday? sundaystart: "",
            suTo: checkedSunday? sundayend: "",
            thFrom: checkedThursday? thursdaystart: "",
            thTo: checkedThursday? thursdayend: "",
            tuFrom: checkedTuesday? tuesdaystart: "",
            tuTo: checkedTuesday? tuesdayend: "",
            weFrom: checkedWednesday? wednesdaystart: "",
            weTo: checkedWednesday? wednesdayend: ""
        }
        let obj = {
            address: addr,
            openingHours: hours,
            description: officeDescRich.toString('html'),
            hygieneMeasuresDescription: hygieneDescRich.toString('html'),
            hygieneMeasuresDistance: Number(minimumdistance),
            mapLink: googleplaces,
            name: name,
            images:Array<Image>()
        }
       return obj;
    }

    const callbackFunction = (childData) => {

        console.log("It comes from child:")
        console.log(childData);
        setImages(childData)
    }

    const checkValidations = () => {

        let formValid=true;

        if(!street || street ==="" ){
            setStreetErr(true);
            setStreetErrText(t('PROPERTY.3'));
            formValid=false
        }
        else{
            setStreetErr(false);
        }
        if(!position || position ==="" ){
            setPositionErr(true);
            setPositionErrText(t('PROPERTY.4'));
            formValid=false
        }
        else{
            setPositionErr(false);
        }
        if(!postcode ||postcode ==="" ){
            setPostCodeErr(true);
            //setZipCodeErrText(t('PROPERTY.5'));
            formValid=false
        }
        else{
            setPostCodeErr(false);
        }
        if(!name || name ==="" ){
            setNameErr(true);
            setNameErrText(t('PROPERTY.6'));
            formValid=false
        }
        else{
            setNameErr(false);
        }
        if(hygiene && (Number(minimumdistance) === 0)){
            setHygieneErr(true);
            setHygieneErrText(t('PROPERTY.7'));
            formValid=false
        }
        else{
            setHygieneErr(false);
        }
        return formValid
    }

    const scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
          window.requestAnimationFrame(scrollToTop);
          window.scrollTo(0, c - c / 8);
        }
      };

    const onSubmitClicked = (images) => {
       let officeData = putOffice();
       if(checkValidations())
       {
       
        onSubmit(officeData,images);
       }
       else {
           notifyError(t('PROPERTY.8'));
           scrollToTop();
       }
       
    }

    useEffect(() => {
        
      },[]);

    return (
        <div className={classes.root}>
            <Grid container justify="center" alignItems="center" style={{ placeContent:"baseline", marginBottom: "3vh"}}>
                <Grid item xs={9} style={{marginBottom: "2vh"}} spacing={2}>
                    <TextField
                        id="name-field"
                        label={t('PROPERTY.9')}
                        placeholder=" "
                        error={nameErr}
                        helperText={nameErrText}
                        fullWidth
                        margin="normal"
                        className="Property-textField"
                        value={name}
                        inputProps={{
                            style: {
                                height:"10vh",
                                fontSize:"3rem",
                                color:"rgba(0, 0, 0, 0.55)"
                            },
                        }}
                        onChange={setNameChanged}
                    />
                </Grid>
                <Grid item xs={12} spacing={2}>
                    <Typography  className="dropzone-header" color="secondary" variant="h4">{t('PROPERTY.23')}</Typography>
                </Grid>
                <Grid container xs={12}>
                    <Grid container xs={5} spacing={2}>
                        <Grid container xs={12}>
                            <Grid item xs={6}>
                                <TextField
                                    id="street-field"
                                    label={t('PROPERTY.10')}
                                    /* style={{ width: '20vw' }} */
                                    placeholder=""
                                    error={streetErr}
                                    helperText={streetErrText}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className="Property-textField"
                                    value={street}
                                    onChange={setStreetChanged}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid item xs={2}>
                                <TextField
                                    label={t('PROPERTY.12')}
                                    id="postcode-field"
                                    error={postCodeErr}
                                    helperText={postCodeErrText}
                                    fullWidth
                                    defaultValue=" "
                                    /* className="Property-textField" */
                                    margin="normal"
                                    value={postcode}
                                    onChange={setPostcodeChanged}
                                />            
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label={t('PROPERTY.13')}
                                    error={positionErr}
                                    helperText={positionErrText}
                                    fullWidth
                                    id="position-field"
                                    defaultValue=" "
                                    /* className="Property-textField" */
                                    margin="normal"
                                    /* style={{ width: '13.5vw' }} */
                                    value={position}
                                    onChange={setPositionChanged}
                                />
                            </Grid>
                            <Grid item xs={6}></Grid>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid item xs={6}>
                                <TextField
                                    id="googleplaces-field"
                                    label={t('PROPERTY.15')}
                                    placeholder=""
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={googleplaces}
                                    onChange={setGoogleplacesChanged}
                                />
                            </Grid>
                            <Grid item xs={6}></Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={7}>
                        <Grid item xs={5}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    name="checkedB"
                                    color="primary"
                                    />
                                }
                                label={t('PROPERTY.11')}
                            />
                        </Grid>
                        <Grid item xs={7}></Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}>
                            {/* <TextField
                                id="description"
                                label={t('PROPERTY.14')}
                                multiline
                                fullWidth
                                rows={10}
                                defaultValue=""
                                value={description}
                                onChange={setDescriptionChanged}
                            /> */}
                            <Typography variant="h5" color="secondary" >{t('PROPERTY.14')}</Typography>
                            <RichTextEditor value={officeDescRich} toolbarConfig={toolbarConfig} onChange={handleOfficeDescRich}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}> </Grid>
                <Grid item xs={12}> </Grid>
            </Grid>

            <Grid xs={12} container justify="center" alignItems="center" style={{ placeContent:"baseline"}}>
                <Grid xs={12} container direction="row" spacing={2} >
                    <Grid item xs={5}>
                        <Typography  className="dropzone-header" color="secondary" variant="h4">{t('PROPERTY.26')}</Typography>
                        <FormHelperText>{t("PROPERTY.32")}</FormHelperText>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography  className="dropzone-header" color="secondary" variant="h4">{t('PROPERTY.27')}</Typography>
                    </Grid>
                    <Grid item></Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid container xs={5} direction="row" spacing={2}>
                        <Grid container xs={12}> 
                            <Grid item xs={4}>
                                <FormControlLabel 
                                    control={ 
                                        <Checkbox
                                            checked={checkedMonday}
                                            onChange={handleDays}
                                            name="checkedMonday"
                                            color="primary"
                                            />
                                        }
                                    label={t('PROPERTY.16')}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <form className="Property.main" noValidate>
                                    <TextField
                                        id="start-time-Monday"
                                        label={t('PROPERTY.24')}
                                        type="time"
                                        className={classes.timeField}
                                        defaultValue="08:00"
                                        InputLabelProps={{shrink: true}}
                                        inputProps={{step: 300}} // 5 min
                                        value={mondaystart}
                                        onChange={mondayStartChanged}
                                        disabled={!checkedMonday}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={4}>
                                <form className="Property.main" noValidate>
                                    <TextField
                                        id="End.time.Monday"
                                        label={t('PROPERTY.25')}
                                        type="time"
                                        defaultValue="18:00"
                                        className={classes.timeField}
                                        InputLabelProps={{shrink: true}}
                                        inputProps={{step: 300}}
                                        value={mondayend}
                                        onChange={mondayEndChanged}
                                        disabled={!checkedMonday}
                                    />
                                </form>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid container xs={12}> 
                            <Grid item xs={4}>
                                <FormControlLabel 
                                    control={ 
                                        <Checkbox
                                            checked={checkedTuesday}
                                            onChange={handleDays}
                                            name="checkedTuesday"
                                            color="primary"
                                            />
                                        }
                                    label={t('PROPERTY.17')}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <form className="Property.main" noValidate>
                                    <TextField
                                        id="start-time-Tuesday"
                                        label={t('PROPERTY.24')}
                                        type="time"
                                        className={classes.timeField}
                                        defaultValue="08:00"
                                        InputLabelProps={{shrink: true}}
                                        inputProps={{step: 300}} // 5 min
                                        value={tuesdaystart}
                                        onChange={tuesdayStartChanged}
                                        disabled={!checkedTuesday}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={4}>
                                <form className="Property.main" noValidate>
                                    <TextField
                                        id="End.time.Tuesday"
                                        label={t('PROPERTY.25')}
                                        type="time"
                                        defaultValue="18:00"
                                        className={classes.timeField}
                                        InputLabelProps={{shrink: true}}
                                        inputProps={{step: 300}}
                                        value={tuesdayend}
                                        onChange={tuesdayEndChanged}
                                        disabled={!checkedTuesday}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={2}></Grid>{/* empty space  */}
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid container xs={12}> 
                            <Grid item xs={4}>
                                <FormControlLabel 
                                    control={
                                        <Checkbox
                                        checked={checkedWednesday}
                                        onChange={handleDays}
                                        name="checkedWednesday"
                                        color="primary"
                                        />
                                    }
                                    label={t('PROPERTY.18')}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <form className="Property.main" noValidate>
                                    <TextField
                                        id="start-time-Wednesday"
                                        label={t('PROPERTY.24')}
                                        type="time"
                                        defaultValue="08:00"
                                        className={classes.timeField}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        inputProps={{
                                        step: 300, // 5 min
                                        }}
                                        value={wednesdaystart}
                                        onChange={wednesdayStartChanged}
                                        disabled={!checkedWednesday}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={4}>
                                <form className="Property.main" noValidate>
                                    <TextField
                                        id="End.time.Wednesday"
                                        label={t('PROPERTY.25')}
                                        type="time"
                                        defaultValue="18:00"
                                        className={classes.timeField}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        inputProps={{
                                        step: 300, // 5 min
                                        }}
                                        value={wednesdayend}
                                        onChange={wednesdayEndChanged}
                                        disabled={!checkedWednesday}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={2}></Grid>{/* empty space  */}
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid container xs={12}> 
                            <Grid item xs={4}>
                                <FormControlLabel 
                                    control={
                                        <Checkbox
                                        checked={checkedThursday}
                                        onChange={handleDays}
                                        name="checkedThursday"
                                        color="primary"
                                        />
                                    }
                                    label={t('PROPERTY.19')}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <form className="Property.main" noValidate>
                                    <TextField
                                        id="start-time-thursday"
                                        label={t('PROPERTY.24')}
                                        type="time"
                                        defaultValue="08:00"
                                        className={classes.timeField}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        inputProps={{
                                        step: 300, // 5 min
                                        }}
                                        value={thursdaystart}
                                        onChange={thursdayStartChanged}
                                        disabled={!checkedThursday}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={4}>
                                <form className="Property.main" noValidate>
                                    <TextField
                                        id="End.time.thursday"
                                        label={t('PROPERTY.25')}
                                        type="time"
                                        defaultValue="18:00"
                                        className={classes.timeField}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        inputProps={{
                                        step: 300, // 5 min
                                        }}
                                        value={thursdayend}
                                        onChange={thursdayEndChanged}
                                        disabled={!checkedThursday}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={2}></Grid>{/* empty space  */}
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid container xs={12}> 
                            <Grid item xs={4}>
                                <FormControlLabel 
                                    control={
                                        <Checkbox
                                        checked={checkedFriday}
                                        onChange={handleDays}
                                        name="checkedFriday"
                                        color="primary"
                                        />
                                    }
                                    label={t('PROPERTY.20')}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <form className="Property.main" noValidate>
                                    <TextField
                                        id="start-time-friday"
                                        label={t('PROPERTY.24')}
                                        type="time"
                                        defaultValue="08:00"
                                        className={classes.timeField}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        inputProps={{
                                        step: 300, // 5 min
                                        }}
                                        value={fridaystart}
                                        onChange={fridayStartChanged}
                                        disabled={!checkedFriday}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={4}>
                                <form className="Property.main" noValidate>
                                    <TextField
                                        id="End.time.friday"
                                        label={t('PROPERTY.25')}
                                        type="time"
                                        defaultValue="18:00"
                                        className={classes.timeField}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        inputProps={{
                                        step: 300, // 5 min
                                        }}
                                        value={fridayend}
                                        onChange={fridayEndChanged}
                                        disabled={!checkedFriday}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={2}></Grid>{/* empty space  */}
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid container xs={12}> 
                            <Grid item xs={4}>
                                <FormControlLabel 
                                    control={
                                        <Checkbox
                                        checked={checkedSaturday}
                                        onChange={handleDays}
                                        name="checkedSaturday"
                                        color="primary"
                                        />
                                    }
                                    label={t('PROPERTY.21')}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <form className="Property.main" noValidate>
                                    <TextField
                                        id="start-time-saturday"
                                        label={t('PROPERTY.24')}
                                        type="time"
                                        defaultValue="08:00"
                                        className={classes.timeField}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        inputProps={{
                                        step: 300, // 5 min
                                        }}
                                        value={saturdaystart}
                                        onChange={saturdayStartChanged}
                                        disabled={!checkedSaturday}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={4}>
                                <form className="Property.main" noValidate>
                                    <TextField
                                        id="End.time.saturday"
                                        label={t('PROPERTY.25')}
                                        type="time"
                                        defaultValue="18:00"
                                        className={classes.timeField}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        inputProps={{
                                        step: 300, // 5 min
                                        }}
                                        value={saturdayend}
                                        onChange={saturdayEndChanged}
                                        disabled={!checkedSaturday}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={2}></Grid>{/* empty space  */}
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid container xs={12}> 
                            <Grid item xs={4}>
                                <FormControlLabel 
                                    control={
                                        <Checkbox
                                        checked={checkedSunday}
                                        onChange={handleDays}
                                        name="checkedSunday"
                                        color="primary"
                                        />
                                    }
                                    label={t('PROPERTY.22')}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <form className="Property.main" noValidate>
                                    <TextField
                                        id="start-time-sunday"
                                        label={t('PROPERTY.24')}
                                        type="time"
                                        defaultValue="08:00"
                                        className={classes.timeField}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        inputProps={{
                                        step: 300, // 5 min
                                        }}
                                        value={sundaystart}
                                        onChange={sundayStartChanged}
                                        disabled={!checkedSunday}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={4}>
                                <form className="Property.main" noValidate>
                                    <TextField
                                        id="End.time.sunday"
                                        label={t('PROPERTY.25')}
                                        type="time"
                                        defaultValue="18:00"
                                        className={classes.timeField}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        inputProps={{
                                        step: 300, // 5 min
                                        }}
                                        value={sundayend}
                                        onChange={sundayEndChanged}
                                        disabled={!checkedSunday}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={2}></Grid>{/* empty space  */}
                        </Grid>
                    </Grid>
                    <Grid container xs={7} direction="row">
                        <Grid item xs={5}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={hygiene}
                                        onChange={handleHygiene}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }    
                                label={t('PROPERTY.28')}
                            />
                        </Grid>
                        <Grid item xs={7}></Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="mindistance-field"
                                label={t('PROPERTY.29')}
                                type="number"
                                disabled={!hygiene}
                                error={hygieneErr}
                                helperText={hygieneErrText}
                                fullWidth
                                InputProps={{
                                    endAdornment:<InputAdornment position="end">m</InputAdornment>,
                                }}
                                inputProps={{ 
                                    min: 0,
                                    max: 100, 
                                    step: 0.50,
                                    style:{
                                        textAlign:"center"
                                    }
                                }}
                                defaultValue={0}
                                value={minimumdistance}
                                onChange={setMinimumdistanceChanged}
                                //style={{ width: '16vw'}}
                            />
                        </Grid>
                        <Grid item xs={7}></Grid>
                        <Grid item xs={12}>
                            {/* <TextField
                                id="hygienedescription-field"
                                label={t('PROPERTY.30')}
                                multiline
                                rows={10}
                                fullWidth
                                defaultValue=""
                                value={hygienedescription}
                                onChange={setHygienedescriptionChanged}
                                //style={{ width: '16vw' }}
                            />      */}
                            <Typography variant="h5" color="secondary">{t('PROPERTY.30')}</Typography>
                            <RichTextEditor value={hygieneDescRich} toolbarConfig={toolbarConfig} onChange={handleHygieneDescRich}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container style={{ placeContent:"baseline", height: '6vh',marginTop:"120px"}} >
                <Typography  className="dropzone-header" color="secondary" variant="h4">{t('PROPERTY.31')}</Typography>
            </Grid>
             <Grid container >
                <div className="image-grid-list">
                    <ImageGridList isLoading={isLoading} onSubmitClick={onSubmitClicked} imageCallback={callbackFunction} imgArray={office?.images}/>
                </div> 
            </Grid> 
        </div>
        
    )
}
