import React, { Component, useState, useEffect } from 'react';

import OfficeEditor from './OfficeEditor';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { RouteComponentProps, withRouter } from 'react-router';
import { notifySuccess, notifyError } from '../notification/Notification';
import { navigate } from "../../history/navigate";
import { withTranslation, WithTranslation } from 'react-i18next';

type OfficeEditorParams = {
    officeId: string,
    floorId: string // parameters will always be a string (even if they are numerical)
};

type OfficeDetailProps = RouteComponentProps<OfficeEditorParams>;

interface State {
    rooms: Array<{ id: string, name: string, description: string, features: Array<string>, isNew: boolean, isExclusive: boolean, points: Array<number>,reservationForClientsEditable:boolean,reservedForClients:Array<{id:string,name:string}>, chairs: Array<{ id: string, x: number, y: number, collision: boolean, roomId: string, isExclusive: boolean, features: Array<string>, ignoreCollision: boolean, name: string,reservationForClientsEditable:boolean,reservedForClients:Array<{id:string,name:string}> }>, layer: any }>,
    officeId: string,
    floorId: string,
    imageUrl: string | null,
    requestReturned: boolean,
    safeDistanceInPixels: number,
    safeDistanceInPixelsReturned: boolean,
    roomFeatures: Array<string>,
    seatFeatures: Array<string>,
    exclusiveUsers:Array<{id:string,name:string}>
}



class OfficeEditorDataHandler extends React.Component<WithTranslation & OfficeDetailProps, State> {


    constructor(props) {
        super(props);

        this.state = {
            rooms: [],
            officeId: "",
            floorId: "",
            imageUrl: null,
            requestReturned: false,
            safeDistanceInPixels: 0,
            safeDistanceInPixelsReturned: false,
            roomFeatures: [],
            seatFeatures: [],
            exclusiveUsers:[]
        };

    }

    getPageData() {
        let hash = window.location.hash;
        var res = hash.split("/");
        

        let officeId = res[2];
        let floorId = res[3];

        if (this.state.officeId && this.state.floorId) {

            // this.setState({
            //     officeId: officeId,
            //     floorId: floorId,
            // });




            HTTP_REQUESTS.OFFICE_API.GET_OFFICE(officeId, (res_office) => {
                //console.log(res_office.hygieneMeasuresDistance);
                HTTP_REQUESTS.OFFICE_API.GET_FLOOR(officeId, floorId, (res_floorId) => {
                    //console.log(res_floorId.floorplanScalePixelPerMeter);
                    let hygieneMeasuresDistance = res_office.hygieneMeasuresDistance;
                    if(hygieneMeasuresDistance == 0){
                        hygieneMeasuresDistance = 0.5;
                    }

                    this.setState({
                        safeDistanceInPixels: hygieneMeasuresDistance * res_floorId.floorplanScalePixelPerMeter,
                        safeDistanceInPixelsReturned: true
                    })

                }, (err) => {

                });
            }, (err) => {

            });



            this.getRoomAndSeats(officeId, floorId);



            HTTP_REQUESTS.OFFICE_API.GET_FLOORPLAN(officeId, floorId, (res) => {

                let blob = new Blob(
                    [res],
                    { type: 'image/png' }
                );
                let image = URL.createObjectURL(blob);
                this.setState({
                    imageUrl: image
                })
            }, (err) => {
                this.setState({
                    imageUrl: null
                })
                notifyError(this.props.t("OFFICE_MESSAGES.11"));
            });

            HTTP_REQUESTS.OFFICE_API.GET_FEATURES_ROOMS((res) => {
                this.setState({ roomFeatures: res })
            }, (err) => {

            })

            HTTP_REQUESTS.OFFICE_API.GET_FEATURES_SEATS((res) => {
                this.setState({ seatFeatures: res })
            }, (err) => {

            })


            HTTP_REQUESTS.USERADMIN_API.GET_ALL_CLIENTS((res) => {
                this.setState({ exclusiveUsers: res })
            }, (err) => {

            })

        }

    }


    getRoomAndSeats = (officeId, floorId, callback?) =>{
       
        let rooms = [];
        let seats = [];

        HTTP_REQUESTS.OFFICE_API.GET_ROOMS(officeId, floorId, (res) => {
            rooms = res;
            HTTP_REQUESTS.OFFICE_API.GET_SEATS(officeId, floorId, (res) => {
                seats = res;
                
                this.modifyData(rooms, seats, callback);
            }, (err) => {

            });
        }, (err) => {

        });

    }
    

    componentDidUpdate(prevProps) {

        if (prevProps.match.params.floorId !== this.props.match.params.floorId) {

            this.setState({ officeId: this.props.match.params.officeId, floorId: this.props.match.params.floorId, requestReturned: false },
                () => this.getPageData())
        }
    }

    componentDidMount = () => {

        this.setState({ officeId: this.props.match.params.officeId, floorId: this.props.match.params.floorId, requestReturned: false },
            () => this.getPageData())



        ///office/{officeId}/floor/{floorId}/rooms



    }


    modifyData(rooms, seats, callback?) {

        rooms.forEach(room => {
            room.layer = null;
            room.isNew = false;
            room.isExclusive = room.reservationForClientsEditable;
            let oldPoints = room.points.slice();
            room.points = [];
            room.chairs = [];
            oldPoints.forEach(point => {
                room.points.push(point.x);
                room.points.push(point.y);
            });

            seats.forEach(seat => {
                if (room.id == seat.roomId) {
                    let chair = { "id": seat.id, "x": seat.point.x, "y": seat.point.y, "collision": false, "roomId": room.id, isExclusive: seat.reservationForClientsEditable, ignoreCollision: false, name: seat.name, features: seat.features,reservationForClientsEditable:seat.reservationForClientsEditable,reservedForClients:seat.reservedForClients};
                    room.chairs.push(chair);
                }
            });

        });
       
        this.setState({
            rooms: rooms,
            requestReturned: true
        },()=> {
            if(callback){
                callback();
            }
        });
    }

    saveCurrentRoomAction = (room, name, description, type, features, exclusiveUserIds, callback) => {

        let newPoints = this.handleNewPoints(room.points);
        let roomToSend = {
            "description": description,
            "name": name,
            "points": newPoints,
            "type": type,
            "features": features,
            "reservedForClients":exclusiveUserIds
        }


            HTTP_REQUESTS.OFFICE_API.UPDATE_ROOM(this.state.officeId, this.state.floorId, room.id, roomToSend, (res) => {
                if(room.chairs.length>0){
                    this.saveSeats(room, callback);
                }else{
                    this.getRoomAndSeats(this.state.officeId, this.state.floorId, callback);
            
                    notifySuccess(this.props.t("OFFICE_MESSAGES.1"));
                }
            }, (err) => {
                notifyError(this.props.t("OFFICE_MESSAGES.6"));
                this.getRoomAndSeats(this.state.officeId, this.state.floorId, callback);
            });
        

    }

    saveSeats = (room, callback) => {
        let request_counter = 0;
        let total_request_count = room.chairs.length;
       
        room.chairs.forEach(seat => {
            let seatToSend = {
                name: seat.name ? seat.name : "",
                features: seat.features,
                point: {
                    x: seat.x,
                    y: seat.y
                }
            }

            if (seat.id.includes("newSeat")) {

                HTTP_REQUESTS.OFFICE_API.CREATE_NEW_SEAT(this.state.officeId, this.state.floorId, seatToSend, (res) => {
                    request_counter += 1;
                    if (request_counter == total_request_count) {
                        this.getRoomAndSeats(this.state.officeId, this.state.floorId, callback);
            
                        notifySuccess(this.props.t("OFFICE_MESSAGES.1"));
                        
                    }
                }, (err) => {
                        notifyError(this.props.t("OFFICE_MESSAGES.7"));
                        this.getRoomAndSeats(this.state.officeId, this.state.floorId, callback);
                });

            } else {
                HTTP_REQUESTS.OFFICE_API.UPDATE_SEAT(this.state.officeId, this.state.floorId, seat.id, seatToSend, (res) => {
                    request_counter += 1;
                    if (request_counter == total_request_count) {
                        this.getRoomAndSeats(this.state.officeId, this.state.floorId, callback);
            
                        notifySuccess(this.props.t("OFFICE_MESSAGES.1"));
                    }
                }, (err) => {
                        this.getRoomAndSeats(this.state.officeId, this.state.floorId, callback);

                        notifyError(this.props.t("OFFICE_MESSAGES.7"));
                });
                
            }
        });
    }


    saveCurrentSeatAction = (seat, name, ignoreCollision, features, exclusiveUserIds, callback) => {

        let seatToSend = {
            name: name,
            point: {
                x: seat.x,
                y: seat.y
            },
            features: features,
            reservedForClients:exclusiveUserIds
        }
        HTTP_REQUESTS.OFFICE_API.UPDATE_SEAT(this.state.officeId, this.state.floorId, seat.id, seatToSend, (res) => {
            this.getRoomAndSeats(this.state.officeId, this.state.floorId, callback);
            
            notifySuccess(this.props.t("OFFICE_MESSAGES.2"));
        }, (err) => {
            this.getRoomAndSeats(this.state.officeId, this.state.floorId, callback);
            notifyError(this.props.t("OFFICE_MESSAGES.8"));
        });


    }

    handleNewPoints(pointsArray) {

        let modifiedPoints = Array();

        for (let i = 0; i < pointsArray.length; i++) {
            if (i % 2 == 0) {
                let newPoint = { x: pointsArray[i], y: pointsArray[i + 1] };
                modifiedPoints.push(newPoint);
            }
        }
        return modifiedPoints;
    }

    saveNewRoom = (newRoom,callback) => {

        let newPoints = this.handleNewPoints(newRoom.points);
        let roomToSend = {
            "description": newRoom.description,
            "name": newRoom.name,
            "points": newPoints,
            "features": []
        };

        HTTP_REQUESTS.OFFICE_API.CREATE_NEW_ROOM(this.state.officeId, this.state.floorId, roomToSend, (res) => {

            this.getRoomAndSeats(this.state.officeId, this.state.floorId, callback);
            
            notifySuccess(this.props.t("OFFICE_MESSAGES.3"));
            
        }, (err) => {
            this.getRoomAndSeats(this.state.officeId, this.state.floorId, callback);
            notifyError(this.props.t("OFFICE_MESSAGES.9"));
        });

    }

    measureClicked = () => {
        navigate("/officemeasure/"+this.state.officeId + "/" + this.state.floorId);
    }

    deleteSeat = (seatId:string, callback) => {
        //alert(seatId);

        HTTP_REQUESTS.OFFICE_API.DELETE_SEAT(this.state.officeId, this.state.floorId, seatId, (res) => {
            this.getRoomAndSeats(this.state.officeId, this.state.floorId, callback);
            notifySuccess(this.props.t("OFFICE_MESSAGES.4"));
            
        }, (err) => {
            this.getRoomAndSeats(this.state.officeId, this.state.floorId, callback);
            notifyError(this.props.t("OFFICE_MESSAGES.10"));
        });

    }

    deleteRoom = (roomId:string, callback) => {
        //alert(seatId);

        HTTP_REQUESTS.OFFICE_API.DELETE_ROOM(this.state.officeId, this.state.floorId, roomId, (res) => {
            this.getRoomAndSeats(this.state.officeId, this.state.floorId, callback);
            notifySuccess(this.props.t("OFFICE_MESSAGES.5"));

        }, (err) => {
            this.getRoomAndSeats(this.state.officeId, this.state.floorId, callback);
            
            if(err.message == this.props.t('OFFICE_MESSAGES.13')){
                notifyError(this.props.t('OFFICE_MESSAGES.13'));
            }else{
                notifyError(this.props.t('OFFICE_MESSAGES.14'));
            }

        });

    }

    render() {
        return (
            <div>
                {this.state.requestReturned && this.state.imageUrl && this.state.safeDistanceInPixelsReturned ? <OfficeEditor
                    rooms={this.state.rooms}
                    saveCurrentRoomAction={this.saveCurrentRoomAction}
                    saveCurrentSeatAction={this.saveCurrentSeatAction}
                    imageUrl={this.state.imageUrl}
                    safeDistanceInPixels={this.state.safeDistanceInPixels}
                    roomFeatures={this.state.roomFeatures}
                    seatFeatures={this.state.seatFeatures}
                    saveNewRoom={this.saveNewRoom}
                    exclusiveUsers={this.state.exclusiveUsers}
                    measureClicked={this.measureClicked}
                    deleteSeat={this.deleteSeat}
                    deleteRoom={this.deleteRoom}
                /> : null}
            </div>
        );
    }
}

export default withTranslation()(withRouter(OfficeEditorDataHandler));
