import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';



interface Props {
    
}
 

export const ForgotDomainHeader:React.FC<Props> = ()=>{
  const { t, i18n } = useTranslation();

    return (
        <div className="main-header">
          <Typography color="primary" component="h1" variant="h3" >
            {t('TENANTNOTFOUND.3')}
          </Typography>
        </div>
    )
}
