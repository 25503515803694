import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';



interface Props {
    
}
 

export const MainHeader:React.FC<Props> = ()=>{
  const { t, i18n } = useTranslation();
  
    return (
        <div className="main-header">
          <Typography color="primary" component="h1" variant="h3" >
           {t('HEADER.1')} <br/>{t('HEADER.2')}
          </Typography>
        </div>
    )
}
