import { Office } from "../../interfaces/Office";
import { ShortOffice } from "../../interfaces/ShortOffice";

export const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
};

export const getShortOfficeArray = (offices:Array<Office>):Array<ShortOffice> => {
  let shortOfficeArray = offices.map(office => ({ id: office.id, openingHours: office.openingHours }));
  return shortOfficeArray;
}

export const removeNullandUndefined = (filter) => {
  return Object.fromEntries(Object.entries(filter).filter(([_, v]) => (v !=  '')))
}

export const generateIdByTime = () => {
  return new Date().getTime().toString();
}
