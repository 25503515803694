import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AppContext } from "../../middlewares/appContext/AppContext";
import MaterialTable, { MTableToolbar } from '@material-table/core';
import moment, { Moment } from 'moment';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { FilterNumericBetween, isDateInRange } from './CustomFilter';
import FilterIcon from '../icons/FilterIcon';
import { CsvBuilder } from 'filefy';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { PatchedPagination } from '../utils/PatchedPagination';
import { CustomConfirmDialog } from '../core/modals/CustomConfirmDialog';
import {BookingFilter} from './Bookings';
import { ExportCsv, ExportPdf } from '@material-table/exporters';

require("jspdf-autotable");
var jsPDF = typeof window !== "undefined" ? require("jspdf").jsPDF : null;

interface State {
    data: Array<Bookings>,
    filtering:boolean,
    title:string,
    openDeleteConfirm: boolean,
    isDeleteRecurred: boolean,
    bookingToDelete:any
}

interface Props {

    data?: Array<Bookings>,
    totalBookings:number,
    onClickDelete: (id:string) => void,
    onClickShow?: (officeId:string,floorId:string) => void,
    onClickRecurredDelete: (id: string) => void,
    filter?:BookingFilter,
    onChangePage:(event: any) => void,
    onChangeSize:(event: any) => void,
    onFilterChange:(filter: any) => void
    onOrderChange:(orderBy: number, orderDirection: "desc" | "asc") => void
}

interface Bookings {
    id: string,
    officeName: string,
    floorName: string,
    seatName: string
    startDateTime: Moment,
    endDateTime: Moment,
    rrule: string,
    recurringBookingId: string
}


const dateFormat:string = "DD-MM-YYYY HH:mm";

class BookingTable extends React.Component<WithTranslation & Props, State> {

    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            data:[],
            filtering:false,
            title:"Bookings",
            openDeleteConfirm: false,
            isDeleteRecurred: false,
            bookingToDelete: {
                endDateTime: moment(),
                startDateTime: moment(),
                floorName: "",
                id: "",
                officeName: "",
                recurringBookingId: "",
                rrule: "",
                seatName: ""
            }
        };
        this.closeDeleteConfirmModal = this.closeDeleteConfirmModal.bind(this);
        this.openDeleteConfirmModal = this.openDeleteConfirmModal.bind(this);
    }



    componentDidMount() {

    }

    openDeleteConfirmModal(): void {
        this.setState({openDeleteConfirm: true});
    }
    
    closeDeleteConfirmModal(): void {
        this.setState({openDeleteConfirm: false});
    }

    exportPdf = (allColumns, allData) => {
        const columns = allColumns.filter(columnDef => columnDef["export"] !== false);
        const exportedData= allData.map(rowData => columns.map(columnDef =>{
            if(columnDef.field==='startDateTime' || columnDef.field === 'endDateTime')
            {
                if(rowData[columnDef.field] instanceof moment){
                    let dateData = rowData[columnDef.field].format(dateFormat)
                    return dateData
                }
                else {
                    let dateData = moment(rowData[columnDef.field]).format(dateFormat)
                    return dateData 
                }
            }
            else {
                return rowData[columnDef.field]
            }
        }));

        var content = {
            startY: 50,
            head: [columns.map(function (columnDef) {
              return columnDef.title;
            })],
            body: exportedData
          };
          var doc = new jsPDF("portrait", "pt", "A4");
          doc.setFontSize(15);
          doc.text(this.state.title, 40, 40);
          doc.autoTable(content);
          doc.save(`${moment().format('DD-MM-YYYY HH:mm')} Bookings.pdf`);
    }

    exportCsv = (allColumns, allData) => {
        const columns = allColumns.filter(columnDef => columnDef["export"] !== false);
        const exportedData= allData.map(rowData => columns.map(columnDef =>{
            if(columnDef.field==='startDateTime' || columnDef.field === 'endDateTime')
            {
                if(rowData[columnDef.field] instanceof moment){
                    let dateData = rowData[columnDef.field].format(dateFormat)
                    return dateData
                }
                else {
                    let dateData = moment(rowData[columnDef.field]).format(dateFormat)
                    return dateData 
                }
            }
            else {
                return rowData[columnDef.field]
            }
            
        }));
        let csvBuilder = new CsvBuilder(`${moment().format(dateFormat)} Bookings.csv`)
          .setDelimeter(',')
          .setColumns(columns.map(columnDef => columnDef.title))
          .addRows(exportedData)
          .exportFile();
      }

    setFilterFromTable = (filter) => {
        const dateFilter = {from: filter[0].value.from, to: filter[0].value.to};
        this.props.onFilterChange(dateFilter);
    }
    render() {
        const { t } = this.props;  
        return (
            <div className="export-wrapper">
                <fieldset style={{border: 'none'}}>

                <MaterialTable
                    style={{maxWidth: '90vw', overflow: 'auto'}}
                    title=""
                    columns={[
                        { title: 'Id', field: 'id',hidden:true,export:false},
                        { title: t('BOOKINGSTABLE.8'), field: 'userName', filtering:false},
                        { title: t('BOOKINGSTABLE.3'), field: 'officeName', filtering:false},
                        { title: t('BOOKINGSTABLE.4'), field: 'floorName', filtering:false},
                        { title: t('BOOKINGSTABLE.5'), field: 'seatName', filtering:false},
                        { title: t('BOOKINGSTABLE.13'),field: 'seatFeatures', filtering:false, sorting:false},
                        { title: t('BOOKINGSTABLE.14'),field: 'roomFeatures', filtering:false, sorting:false},
                        { title: t('BOOKINGSTABLE.6'), field: 'startDateTime', filtering:true,
                        render: rowData => moment(rowData.startDateTime).format(dateFormat),
                        filterComponent: (props) => <FilterNumericBetween {...props} from={this.props.filter?.from} to={this.props.filter?.to} />,
                        filterPlaceholder:"From ",
                        customFilterAndSearch: (filter, rowData) => isDateInRange(filter.dateFrom, filter.dateTo, rowData.startDateTime)},
                        { title: t('BOOKINGSTABLE.7'), field: 'endDateTime', filtering:false,
                        filterComponent: (props) => <FilterNumericBetween {...props} from={this.props.filter?.from} to={this.props.filter?.to} />,
                        customFilterAndSearch: (filter, rowData) => isDateInRange(filter.dateFrom, filter.dateTo, rowData.endDateTime),
                        filterPlaceholder:"To",
                        render: rowData => moment(rowData.endDateTime).format(dateFormat)}
                    ]}
                    data={this.props.data? this.props.data : []}
                    options={{
                        draggable:false,
                        search:true,
                        filtering: this.state.filtering,
                        headerStyle:{fontWeight:"bold",textAlign:"left"},
                        actionsColumnIndex:-1,
                        pageSize:this.props.filter?.size,
                        pageSizeOptions:[5,10,20,50,100,this.props.totalBookings],
                        exportAllData:true,
                        paging:true,
                        thirdSortClick: false,
                        exportMenu: [
                            {
                            
                                label: t('EXPORT.CSV'),
                                exportFunc: (cols, data) => ExportCsv(cols, data, `${moment().format('DD-MM-YYYY HH:mm')}_bookings`)
                            },
                            {
                                label: t('EXPORT.PDF'),
                                exportFunc: (cols, data) => ExportPdf(cols, data, `${moment().format('DD-MM-YYYY HH:mm')}_bookings`)
                            }
                        ]
                    }}
                    totalCount={this.props.totalBookings}
                    page={this.props.filter?.page}
                    onPageChange={this.props.onChangePage}
                    onRowsPerPageChange={this.props.onChangeSize}
                    onOrderChange={this.props.onOrderChange}
                    actions={[
                        (rowData) => ({
                            icon: ()=> {return <InfoOutlinedIcon color={rowData['floorId'] ?  "primary" :"secondary"}></InfoOutlinedIcon>},
                            disabled: rowData['floorId'] ?  false :true ,
                            tooltip: rowData['floorId'] ?  t('BOOKINGSTABLE.2') :t('BOOKINGSTABLE.12'),
                            onClick: (event, rowData) => {if(this.props.onClickShow ) this.props.onClickShow(rowData['officeId'],rowData['floorId']) }
                        }),
                        (rowData) => ({
                            icon: ()=> {return <DeleteOutline color={rowData['floorId'] ?  "primary" :"secondary"}></DeleteOutline>},
                            tooltip: rowData['floorId'] === "" ?  t('BOOKINGSTABLE.12') :t('BOOKINGSTABLE.DeleteBooking'),
                            disabled: rowData['floorId'] ?  false :true ,
                            onClick: (event, rowData) => {
                                this.setState({openDeleteConfirm: true, bookingToDelete: rowData, isDeleteRecurred: rowData['recurringBookingId'] ? true: false})
                            }
                          }),
                        {
                            icon: ()=> 
                            {
                                return <Button 
                                            variant="text"
                                            className="dh-tt-none"
                                            startIcon={<FilterIcon color={this.props.filter?.from || this.props.filter?.to ? "primary":"secondary"}/>}>
                                            <Typography variant={"body2"}>{t('BOOKINGSTABLE.11')}</Typography>
                                        </Button> 
                            },
                            position:"toolbar",
                            onClick: () => this.setState({ filtering: !this.state.filtering }),
                        }
                    ]}
                    localization={{
                        header:{
                            actions:t('USERCRUD.17')
                        },
                        body:{
                            deleteTooltip:t('BOOKINGSTABLE.1'),
                            editTooltip:t('BOOKINGSTABLE.2'),
                            emptyDataSourceMessage:t('USERSTABLE.3'),
                            editRow: {
                                deleteText: t('USERSTABLE.4'),
                                cancelTooltip: t('USERSTABLE.5'),
                                saveTooltip: t('USERSTABLE.6')
                            }
                        },
                        toolbar: {
                            exportTitle: 'Export',
                            exportAriaLabel: 'Export',
                            searchTooltip: t('USERSTABLE.7'),
                            searchPlaceholder: t('USERSTABLE.7')
                        },
                        pagination: {
                            labelDisplayedRows:  t('USERSTABLE.8'),
                            labelRowsSelect:  t('USERSTABLE.9'),
                            labelRowsPerPage:  t('USERSTABLE.10'),
                            firstAriaLabel:  t('USERSTABLE.11'),
                            firstTooltip:  t('USERSTABLE.11'),
                            previousAriaLabel:  t('USERSTABLE.12'),
                            previousTooltip:  t('USERSTABLE.12'),
                            nextAriaLabel:  t('USERSTABLE.13'),
                            nextTooltip:  t('USERSTABLE.13'),
                            lastAriaLabel:  t('USERSTABLE.14'),
                            lastTooltip:  t('USERSTABLE.14'),
                        },
                    }}
                    onFilterChange={(filterData) => { this.setFilterFromTable(filterData) } }
                    components={{
                        Toolbar: props => {
                            let overridedProps={};
                            overridedProps['exportPdf'] = ()=>{this.exportPdf(props.columns,props.data)}
                            let mergedProps = {...props,...overridedProps}
                            return (
                                <div>
                                  <MTableToolbar {...mergedProps} />
                                </div>
                            )
                        },
                        Pagination:PatchedPagination
                      }}

                />
                </fieldset>
                  {this.state.openDeleteConfirm && this.props.onClickDelete && this.props.onClickRecurredDelete ?
                    <CustomConfirmDialog 
                            cancel={t('BOOKINGSTABLE.DELETEDIALOG.CANCEL')}
                            cancelCB={this.closeDeleteConfirmModal}
                            confirm={t('BOOKINGSTABLE.DELETEDIALOG.CONFIRM')}
                            confirmAll={t('BOOKINGSTABLE.DELETEDIALOG.CONFIRMALL')}
                            text={t('BOOKINGSTABLE.DELETEDIALOG.TEXT')}
                            textAll={`${t('BOOKINGSTABLE.DELETEDIALOG.TEXTALL')}.`}
                            open={this.state.openDeleteConfirm}
                            header={t('BOOKINGSTABLE.DELETEDIALOG.HEADER')}
                            confirmCB={this.props.onClickDelete}
                            confirmRecurring={t('BOOKINGSTABLE.DELETEDIALOG.CONFIRMRECURRING')}
                            isRecurred={this.state.isDeleteRecurred}
                            confirmCBAll={this.props.onClickRecurredDelete}
                            toDeleteObject={this.state.bookingToDelete}
                    /> :null}
            </div>
        
          
        );
    }

}
export default withTranslation()(BookingTable);
