import { Button, Chip, Divider, FormHelperText, Grid, TextField, Typography } from '@material-ui/core';
import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { CustomConfirmDialog } from '../core/modals/CustomConfirmDialog';
import { notifyError, notifySuccess } from '../notification/Notification';

interface State {
    features: Array<string>,
    roomFeatures: Array<string>,
    loading: boolean,
    openConfirmDialog: boolean,
    selectedFeature: string,
    newFeature: string,
    newFeatureError: boolean
    newFeatureErrorText: string,
    newRoomFeature: string,
    newRoomFeatureError: boolean,
    newRoomFeatureErrorText: string,
    selectedType: string
}

const FEATURE_TYPE = {
    ROOM: "ROOM",
    SEAT: "SEAT"
}

class Settings extends Component<WithTranslation, State> {

    constructor(props) {
        super(props)

        this.state = {
            features: [],
            roomFeatures: [],
            loading: false,
            openConfirmDialog: false,
            selectedFeature: "",
            newFeature: "",
            newFeatureError: false,
            newFeatureErrorText: "",
            newRoomFeature: "",
            newRoomFeatureError: false,
            newRoomFeatureErrorText: "",
            selectedType: ""
        }

        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.deleteFeature = this.deleteFeature.bind(this);
        this.onChangeNewFeature = this.onChangeNewFeature.bind(this);
        this.onChangeNewRoomFeature = this.onChangeNewRoomFeature.bind(this);
        this.checkValidation = this.checkValidation.bind(this);
        this.checkValidationForRoom = this.checkValidationForRoom.bind(this);
    }

    componentDidMount() {
        document.title = this.props.t("SETTINGS.1");
        this.getFeatures();
    }

    getFeatures(): void {
        this.getSeatFeatures();
        this.getRoomFeatures();
    }

    getSeatFeatures(): void {
        HTTP_REQUESTS.OFFICE_API.GET_FEATURES_SEATS((success) => {
            this.setState({ features: success, loading: true });
        }, (error) => {
            notifyError(error.message);
        })
    }

    getRoomFeatures(): void {
        HTTP_REQUESTS.OFFICE_API.GET_FEATURES_ROOMS((success) => {
            this.setState({ roomFeatures: success, loading: true });
        }, (error) => {
            notifyError(error.message);
        })
    }

    handleDeleteFeature(feature: string, type: string): void {
        this.setState({ selectedFeature: feature, selectedType: type})
        this.showConfirmModal();
    }

    onChangeNewFeature(event) {
        this.setState({ newFeature: event.target.value, newFeatureError: false ,newFeatureErrorText: "" })
    }

    onChangeNewRoomFeature(event) {
        this.setState({ newRoomFeature: event.target.value, newRoomFeatureError: false ,newRoomFeatureErrorText: "" })
    }

    checkValidation() {

        let formValid = true

        if (!this.state.newFeature || this.state.newFeature === "") {
            formValid = false
            this.setState({newFeatureError:true ,newFeatureErrorText: this.props.t("SETTINGS.5") })
        }
        else if (this.state.newFeature.length < 3) {
            formValid = false
            this.setState({ newFeatureError:true ,newFeatureErrorText: this.props.t("SETTINGS.6") })
        }

        if (formValid) {
            this.createNewFeature(this.state.newFeature);
        }
    }

    checkValidationForRoom(): void {
        let formValidRoom = true

        if (!this.state.newRoomFeature || this.state.newRoomFeature === "") {
            formValidRoom = false
            this.setState({newRoomFeatureError:true ,newRoomFeatureErrorText: this.props.t("SETTINGS.5") })
        }
        else if (this.state.newRoomFeature.length < 2) {
            formValidRoom = false
            this.setState({ newRoomFeatureError:true ,newRoomFeatureErrorText: this.props.t("SETTINGS.6") })
        }

        if (formValidRoom) {
            this.createNewRoomFeature(this.state.newRoomFeature);
        }
    }

    createNewFeature(feature: string): void {
        HTTP_REQUESTS.OFFICE_API.CREATE_NEW_SEAT_FEATURE(feature, () => {
            this.setLoading(true);
            this.getSeatFeatures();
            this.setState({newFeature:""});
            notifySuccess(this.props.t("SETTINGS.7"));
        }, error => {
            if (error.message) {
                notifyError(error.message)
            }
        })
    }

    createNewRoomFeature(feature: string): void {
        HTTP_REQUESTS.OFFICE_API.CREATE_NEW_ROOM_FEATURE(feature, () => {
            this.setLoading(true);
            this.getRoomFeatures();
            this.setState({newRoomFeature:""});
            notifySuccess(this.props.t("SETTINGS.7"));
        }, error => {
            if (error.message) {
                notifyError(error.message)
            }
        })
    }

    deleteFeature(name: string): void {
        switch (this.state.selectedType) {
            case 'SEAT':
                this.deleteSeatFeature(name);
                break;
            case 'ROOM':
                this.deleteRoomFeature(name);
                break;
            default:
                console.log('Feature type not found: ', this.state.selectedType);
                break;
        }
    }

    deleteSeatFeature(name: string): void {
        HTTP_REQUESTS.OFFICE_API.DELETE_ONE_SEAT_FEATURE(name, () => {
            this.setLoading(false);
            this.getSeatFeatures();
            notifySuccess(this.props.t("SETTINGS.4"))
        }, (err) => {
            if (err.message)
                notifyError(err.message)
        })
    }

    deleteRoomFeature(name: string): void {
        HTTP_REQUESTS.OFFICE_API.DELETE_ONE_ROOM_FEATURE(name, () => {
            this.setLoading(false);
            this.getRoomFeatures();
            notifySuccess(this.props.t("SETTINGS.4"))
        }, (err) => {
            if (err.message)
                notifyError(err.message)
        })
    }

    setLoading(value: boolean) {
        this.setState({ loading: value });
    }

    showConfirmModal() {
        this.setState({ openConfirmDialog: true });
    }

    closeConfirmModal() {
        this.setState({ openConfirmDialog: false });
    }

    addNewSeatFeatureComponent() {
        return (<div style={{display:'flex',alignItems:"center"}}> 
            <Grid container direction='column'>
                <TextField
                    variant="filled"
                    color='secondary'
                    name='newFeature'
                    label={this.props.t("SETTINGS.3")}
                    value={this.state.newFeature}
                    onChange={this.onChangeNewFeature}
                    error={this.state.newFeatureError}
                    style={{ margin: 8 }}
                />
                {
                    this.state.newFeatureError ?
                        <FormHelperText>{this.state.newFeatureErrorText}</FormHelperText>
                        : null
                }
            </Grid>
            <Grid container>
                <Button variant='text' color="primary" onClick={this.checkValidation}>{this.props.t("SETTINGS.8")}</Button>
            </Grid>
            
        </div>)
    }

    addNewRoomFeatureComponent() {
        return (<div style={{display:'flex',alignItems:"center"}}>
            <Grid container direction='column'>
                <TextField
                    variant="filled"
                    color='secondary'
                    name='newRoomFeature'
                    label={this.props.t("SETTINGS.3")}
                    value={this.state.newRoomFeature}
                    onChange={this.onChangeNewRoomFeature}
                    error={this.state.newRoomFeatureError}
                    style={{ margin: 8 }}
                />
                {
                    this.state.newRoomFeatureError ?
                        <FormHelperText>{this.state.newRoomFeatureErrorText}</FormHelperText>
                        : null
                }
            </Grid>
            <Grid container>
                <Button variant='text' color="primary" onClick={this.checkValidationForRoom}>{this.props.t("SETTINGS.8")}</Button>
            </Grid>

        </div>)

    }

    render() {
        const { t } = this.props
        return (
            <div className='settings-wrapper'>
                {this.state.loading ?
                    <Grid container direction='column' >
                        <Grid item xs={12} md={6} className='mt-1' >
                            <Typography color='secondary' variant='h4'>{t('SETTINGS.1')}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6} className='mt-1' >
                            <Typography color='secondary' variant='h5'>{t('SETTINGS.2')}</Typography>
                        </Grid>
                        <Grid container >
                            {this.state.features.map((feature) => (
                                <Chip
                                    label={feature}
                                    onDelete={() => this.handleDeleteFeature(feature, FEATURE_TYPE.SEAT)}
                                    color="primary"
                                    id={feature}
                                    variant="outlined"
                                    style={{ margin: 8 }}
                                />
                            ))}
                            {this.addNewSeatFeatureComponent()}
                        </Grid>
                        <Grid item xs={12} className='mt-1' >
                            <Divider></Divider>
                        </Grid>

                        <Grid item xs={12} md={6} className='mt-1' >
                            <Typography color='secondary' variant='h5'>{t('SETTINGS.9')}</Typography>
                        </Grid>
                        <Grid container >
                            {this.state.roomFeatures.map((feature) => (
                                <Chip
                                    label={feature}
                                    onDelete={() => this.handleDeleteFeature(feature, FEATURE_TYPE.ROOM)}
                                    color="primary"
                                    id={feature}
                                    variant="outlined"
                                    style={{ margin: 8 }}
                                />
                            ))}
                            {this.addNewRoomFeatureComponent()}
                        </Grid>
                        <Grid item xs={12} className='mt-1' >
                            <Divider></Divider>
                        </Grid>

                    </Grid> : null}

                {this.state.openConfirmDialog ?
                    <CustomConfirmDialog
                        cancelCB={this.closeConfirmModal}
                        confirmCB={this.deleteFeature}
                        open={this.state.openConfirmDialog}
                        text={t('SETTINGS.10') + this.state.selectedFeature}
                        isRecurred={false}
                        cancel={t('SETTINGS.11')}
                        confirm={t('SETTINGS.12')}
                        header={t('SETTINGS.12')}
                        id={this.state.selectedFeature}
                    /> : null}
            </div>
        )
    }
}
export default withTranslation()(Settings);
