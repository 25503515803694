import React, { useContext, useEffect ,useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from "../../middlewares/appContext/AppContext";
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { PropertyForm } from './PropertyForm';
import { notifyError } from '../notification/Notification';
import { navigate } from '../../history/navigate';
import { notifySuccess } from '../notification/Notification';
import { Office } from '../../interfaces/Office';
import { scrollToTop } from '../utils/Utils';

interface Address {
    city: string,
    street: string,
    zip: string
}

interface Image {
    description: string,
    id: string,
    uri: string,
}

interface OpeningHours {
    frFrom: string,
    frTo: string,
    moFrom: string,
    moTo: string,
    saFrom: string,
    saTo: string,
    suFrom: string,
    suTo: string,
    thFrom: string,
    thTo: string,
    tuFrom: string,
    tuTo: string,
    weFrom: string,
    weTo: string
}
//TODO: hygieneMeasuresDistance should be number
interface OfficeObject {
    address: Address,
    description: string,
    hygieneMeasuresDescription: string,
    hygieneMeasuresDistance: string,
    mapLink: string,
    name: string,
    openingHours: OpeningHours,
    images: Array<Image> | null,
    id: string | null,

}

interface Props {
    office: OfficeObject | null
    isEmpty: boolean,
    isLoading: boolean
}

export const Property: React.FC<{}> = () => {
    const { t, i18n } = useTranslation();
    const context = useContext(AppContext);
    //const classes = useStyles();
    const [objArray,setObjArray] = useState(Array<Office>());
    document.title = t('TITLE.7');

    useEffect(() => {
      HTTP_REQUESTS.OFFICE_API.GET_DETAILS_ALL_OFFICES((res)=> {
        setObjArray(res);
      },err=> {
        notifyError(t('PROPERTY.1'))
      })
    }, []);
    
    console.log(context);

    const [isReady, setIsReady] = React.useState(false);
    const [isNew, setIsNew] = React.useState(false);
    const [offID, setOffID] = React.useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [office, setOffice] = React.useState(null);

    const onSubmit = (officeObj, images) => {
        if (isNew) {
            setIsLoading(true);
            HTTP_REQUESTS.OFFICE_API.POST_OFFICE(officeObj, res => {
                let officeId = res.id
                officeObj['images'] = images;
                HTTP_REQUESTS.OFFICE_API.UPDATE_OFFICE(officeId, officeObj, res => {
                    navigate('/offices/' + officeId + '/uploadPlan');
                    setIsLoading(false);
                }, err => {
                    if (err.message)
                        notifyError(err.message)
                    setIsLoading(false);
                });
            }, err => {
                if (err.message)
                    notifyError(err.message)
                setIsLoading(false);
            })
        } else {
            setIsLoading(true);
            officeObj['images'] = images;
            HTTP_REQUESTS.OFFICE_API.UPDATE_OFFICE(offID, officeObj, res => {
                setOffice(res);
                setIsLoading(false);
                notifySuccess(t('PROPERTY.2'));
            }, err => {
                if (err.message)
                    notifyError(err.message)
                setIsLoading(false);
            })
        }
    }

    const checkIfNew =() => {
        let hash = window.location.hash;
        var res = hash.split("/");
        if(res[2]==="new"){
            
            return true
        }
        else {
            setOffID(res[2]);
            return false
        }
    }

    useEffect(() => {
        
        let hash = window.location.hash;
        var res = hash.split("/");
        
        if (!checkIfNew()) {
            HTTP_REQUESTS.OFFICE_API.GET_OFFICE(res[2], (res) => {
                setOffice(res);
                setIsReady(true);
            }, (err) => {
                notifyError(err.message);
            });
        }
        else {
            setIsReady(true);
            setIsNew(true);
            scrollToTop();
        }

    }, []);

    return (
        <div>
            {isReady ? <PropertyForm isLoading={isLoading} office={office} onSubmit={onSubmit} isNew={isNew}
                                     isEmpty={false}/> : null}
        </div>
    )
}
