import React, { Component, useState, useEffect } from 'react';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { RegisterForm } from './RegisterForm';
import { navigate } from '../../history/navigate';
import { Buffer } from 'buffer';
import { Console } from 'console';
import { notifySuccess, notifyError } from '../notification/Notification';
import {configObj} from '../../interfaces/Config';
import { useTranslation } from 'react-i18next';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
 

interface Props {
 
}
 
export const Register: React.FC<Props> = () => {

  const { t, i18n } = useTranslation();
 
  const [config, setConfig] = useState<configObj | null>(null);
  const [captcha, setCaptcha] = useState("");
  const [captchaError, setCaptchaError] = useState(false);
  const [subDomainError, setSubdomainError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [pwdError, setPwdError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getCaptcha = () => {
      HTTP_REQUESTS.TENANT_API.GET_CAPTCHA((res) => {
      let blob = new Blob(
        [res], 
        { type:'image/png' }
      )
      let image = URL.createObjectURL(blob)
      setCaptcha(image)
    }, (err) => {
      console.log(err);
    });
  }
 
  document.title = t('TITLE.2');


  useEffect(() => {
    if (!config) {
      HTTP_REQUESTS.TENANT_API.GET_CONFIG((res) => {
        //console.log(res);
        //alert('config here');
        //res.captchaRequired = false;
        let castedConfig = res as configObj;
        if(res.captchaRequired){
          getCaptcha();
        }
        setConfig(castedConfig);
      }, (err) => {
        alert('cors - error');
      });
    }
  },[]);

  const submit = (register_params) => {
    
    HTTP_REQUESTS.TENANT_API.POST_TENANT_REGISTER(register_params, (res) => {
      navigate('/registerSuccess');
    }, (err) => {
      if( err.relatedField){
        setErrorMessage(err.message);
        switch(err.relatedField){
          case 'email':
            setEmailError(true);
            break;
          case 'password':
            setPwdError(true);
            break;
          case 'captcha':
            setCaptchaError(true);
            break;
          case 'customSubdomain':
            setSubdomainError(true);
            break;
          default:
            break;
        }
      }
      if( err.errors && err.errors.length > 0)
      {
        for(let i = 0; i < err.errors.length; i++){
          switch (err.errors[i].field) {
            case 'email':
              setEmailError(true);
              //setEmailErrorText(err.errors[i].defaultMessage)      
              setErrorMessage(err.errors[i].defaultMessage);
              break;
            default:
              break;
          }
        }
      } 
      if(config?.captchaRequired){
        getCaptcha();
      }
      
    });
 
  }
 
  if (config) {
    return ( <RegisterForm errorMessage={errorMessage} emailError={emailError} pwdError={pwdError} captchaError={captchaError} subDomainError={subDomainError} onSubmitClick={submit} config={config} captcha={captcha} getCaptcha={getCaptcha} />)
  } else {
    return (null)
  }
 
}