import React from 'react';
import Konva from 'konva';
import { Stage, Layer, Star, Text, Image, KonvaNodeComponent, Circle, Line, RegularPolygon } from 'react-konva';
import Button from '@material-ui/core/Button';
import CropTwoToneIcon from '@material-ui/icons/CropTwoTone';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { Grid, ButtonGroup, TextField, Typography, InputAdornment } from '@material-ui/core';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import DoneIcon from '@material-ui/icons/Done';
import CheckIcon from '@material-ui/icons/Check';
import MeasureIcon from '../icons/MeasureIcon';
import { withTranslation, WithTranslation } from 'react-i18next';

interface State {
    image: HTMLImageElement,
    canvasWidth: number,
    canvasHeight: number,
    points: Array<number>,
    beginPoint: { x: number, y: number },
    endPoint: { x: number, y: number },
    constYPos: number,
    enteredLength: number,
    lengthInput:boolean,
    lengthError:boolean,
    lengthErrorText:string
}

interface Props {
    floor: {
        description: string,
        floorplanPresent: boolean,
        floorplanScale: {
            length: number,
            p1: {
                x: number,
                y: number
            },
            p2: {
                x: number,
                y: number
            }
        },
        floorplanScalePixelPerMeter: number,
        id: string,
        name: string,
        officeId: string
    } | null,

    saveButtonClicked: (obj) => void,
    scaleExists: boolean,
    imageUrl: string | null,
}


class CanvasKonvaDraw extends React.Component< WithTranslation & Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            image: new window.Image(),
            canvasWidth: 1000,
            canvasHeight: 1000,
            points: [0, 0, 0, 0],
            beginPoint: { x: 0, y: 0 },
            endPoint: { x: 0, y: 0 },
            constYPos: 0,
            enteredLength: 0,
            lengthInput:false,
            lengthError:false,
            lengthErrorText:""
        };

    }

    componentDidMount = () => {
        document.title = this.props.t('TITLE.12');

        if (this.props.imageUrl) {

            const image = new window.Image();
            image.src = this.props.imageUrl;
            image.onload = () => {
                let constYPos = image.naturalHeight / 10;

                let beginPoint = { x: 0, y: constYPos };
                let endPoint = { x: image.naturalWidth, y: constYPos };


                let enteredLength = 0;

                if (this.props.floor) {
                    let floorplanScale = this.props.floor.floorplanScale;

                    if (this.props.scaleExists) {

                        beginPoint = floorplanScale.p1;
                        endPoint = floorplanScale.p2;
                        enteredLength = floorplanScale.length;
                    }
                }

                let pointsForLine = [beginPoint.x, beginPoint.y, endPoint.x, endPoint.y];
                console.log(pointsForLine);

                this.setState({
                    image: image,
                    canvasWidth: image.naturalWidth,
                    canvasHeight: image.naturalHeight,
                    points: pointsForLine,
                    beginPoint: beginPoint,
                    endPoint: endPoint,
                    constYPos: constYPos,
                    enteredLength: enteredLength
                });
            }
        }
    }

    onStartCircleMove = e => {

        let x = e.target._lastPos.x - 20;
        let y = e.target._lastPos.y;

        let points = this.state.points.slice();
        points[0] = x;
        //points[1] = y;

        this.setState({
            points: points
        })

    }

    onEndCircleMove = e => {

        let x = e.target._lastPos.x + 20;
        let y = e.target._lastPos.y;

        let points = this.state.points.slice();
        points[2] = x;
        //points[3] = y;

        this.setState({
            points: points
        })

    }

    dragBoundFunc = (pos) => {
        pos.y = this.state.constYPos;

        if (pos.x < 20) {
            pos.x = 20;
        } else if (pos.x +20> this.state.canvasWidth) {
            pos.x = this.state.canvasWidth - 20;
        }
        return pos;
    }

    onTextClick = (e) => {
        let stage = e.target.getStage();
        let stageBox = stage.container().getBoundingClientRect();
        let left = stageBox.left;
        let top = stageBox.top;


        let x = e.target.attrs.x;
        let y = e.target.attrs.y;


    }

    onLengthChange = e => {

        const re = /[+-]?([0-9]*[.])?[0-9]+/;
        //alert(re.test(e.target.value));
        // if value is not blank, then test the regex

        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ enteredLength: e.target.value , lengthError:false, lengthErrorText:""})
        }
    }

    saveButtonClicked = e => {

            if (this.props.floor) {

                let obj = {
                    description: this.props.floor.description,
                    name: this.props.floor.name,
                    newFloorplanScale: {
                        length: String(this.state.enteredLength).replace(",","."),
                        p1: {
                            x: this.state.points[0],
                            y: this.state.points[1]
                        },
                        p2: {
                            x: this.state.points[2],
                            y: this.state.points[3]
                        }
                    }
                }

                if(Number(this.state.enteredLength) === 0 || Number(this.state.enteredLength) === 0.0)
                {
                    this.setState({lengthError:true,lengthErrorText:this.props.t("CANVAS.29")})
                }
                else {
                    this.props.saveButtonClicked(obj);
                }
 
            }
        

      
    }


    render() {
        return (
            <div>
                <div style={{width:"90%",display:"flex",margin:"8px 0px",justifyContent:"space-between"}} className="input-group">
                    <Typography color="secondary" variant="h4">{this.props.t("CANVAS.31")}</Typography>
                    
                            <TextField
                            id="length"
                            label={this.props.t('CANVAS.20')}
                            color="secondary"
                            variant="standard"
                            InputProps={{
                                endAdornment:<InputAdornment position="end">m</InputAdornment>,
                                
                             }}
                          
                            value={this.state.enteredLength}
                            onChange={this.onLengthChange}
                            disabled={this.state.lengthInput}
                            style={{margin:"8px"}}
                            error={this.state.lengthError}
                            helperText={this.state.lengthError ? this.state.lengthErrorText: null}
                        />
                   

                    <Button
                        onClick={this.saveButtonClicked}
                        variant="contained"
                        
                        style={{backgroundColor:"#e7e7e7",textTransform:"none",margin:"8px"}}
                        startIcon={<MeasureIcon style={{ transform: "rotate(-45deg)"}} color="primary" />}
                    >
                       {this.props.t('CANVAS.21')}
                    </Button>

                </div>
                <div className="input-group" style={{marginTop:"8px",width:"90%",justifyContent:"center",display:"flex"}}>
                    <Typography variant="body1" ><strong>{this.props.t('CANVAS.22')}</strong></Typography>
                </div>
                <div className="canvas1" style={{ width: "90%", overflow: "auto", borderStyle: "solid", borderWidth: "2px" }}>
                   
                    <Stage width={this.state.canvasWidth} height={this.state.canvasHeight} >
                        <Layer>
                            <Image image={this.state.image} />
                        </Layer>

                        <Layer attrs={{ "name": "line" }}>
                            <Line
                                points={this.state.points}
                                fill="#3468fa59"
                                strokeWidth={4}
                                stroke={"red"}
                            />

                            <RegularPolygon
                                id={"begin"}
                                key={"begin"}
                                x={this.state.beginPoint.x + 20}
                                y={this.state.beginPoint.y}
                                sides= {3}
                                radius= {20}
                                fill= 'white'
                                stroke= 'black'
                                onDragMove={this.onStartCircleMove}
                                dragBoundFunc={this.dragBoundFunc}
                                draggable
                                rotation={270}
                            />

                           
                            <RegularPolygon
                                id={"end"}
                                key={"end"}
                                x={this.state.endPoint.x -20}
                                y={this.state.endPoint.y}
                                sides= {3}
                                radius= {20}
                                fill= 'white'
                                stroke= 'black'
                                onDragMove={this.onEndCircleMove}
                                dragBoundFunc={this.dragBoundFunc}
                                draggable
                                rotation={90}

                            />
                          


                        </Layer>
                    </Stage>

                </div>
               

            </div>

        );
    }
}

export default withTranslation()(CanvasKonvaDraw);