import React, { Component, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectCard } from '../objectCard/ObjectCard';
import {Office} from '../../interfaces/Office'
import { AddNewCard } from '../objectCard/AddNewCard';
import { AppContext } from "../../middlewares/appContext/AppContext";
import Grid from "@material-ui/core/Grid";

interface Props {
  data:Array<Office>
}


export const ObjectList: React.FC<Props> = (props) => {

  const { t, i18n } = useTranslation();
  const {data} = props;
  const [config, setConfig] = useState();
  const context = useContext(AppContext);

  const sampledata = require('./dummy.json')

  let roomObj = {


    "address": {
      "city": "",
      "street": "",
      "zip": ""
    },
    "description": "",
    "hygieneMeasuresDescription": "",
    "hygieneMeasuresDistance": 0,
    "id": "",
    "images": [
      {
        "description": "",
        "uri": ""
      }
    ],
    "mapLink": "",
    "name": "",
    "openingHours": {
      "frFrom": "",
      "frTo": "",
      "moFrom": "",
      "moTo": "",
      "saFrom": "",
      "saTo": "",
      "suFrom": "",
      "suTo": "",
      "thFrom": "",
      "thTo": "",
      "tuFrom": "",
      "tuTo": "",
      "weFrom": "",
      "weTo": ""
    }

  }
  const [room, setUser] = useState(roomObj);

  useEffect(() => {

  }, []);


  return (
    <Grid container justifyContent={"space-evenly"}>
      {
        data.map((room, index) =>
          <Grid item key={index}><ObjectCard imageIndex={`${index + 1}`} details={room} /></Grid>)
      }

        {context && context?.user.permissions.includes('MANAGE_OFFICES')?<AddNewCard/>:null}
    </Grid>
  )


}