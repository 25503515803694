import React, { useContext, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { AppContext } from "../../middlewares/appContext/AppContext";
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { DropzoneArea, FileObject } from 'material-ui-dropzone';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { IconButton } from '@material-ui/core';
import {  notifyError } from '../notification/Notification';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import GenericButton from '../button/GenericButton';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

interface Address{
  city: string | null,
  street: string | null,
  zip: string | null
}

interface Image{
  description: string,
  id: string,
  uri: string,
}

interface OpeningHours{
  frFrom: string | null,
  frTo: string| null,
  moFrom: string| null,
  moTo: string | null,
  saFrom: string | null,
  saTo: string | null,
  suFrom: string | null,
  suTo: string | null,
  thFrom: string | null,
  thTo: string | null,
  tuFrom: string | null,
  tuTo: string | null,
  weFrom: string | null,
  weTo: string | null
}

interface OfficeObject{
  address: Address,
  description: string | null,
  hygieneMeasuresDescription: string | null,
  hygieneMeasuresDistance: string | null,
  mapLink: string | null,
  name: string | null,
  openingHours: OpeningHours | null,
  images: Array<Image> | null,
  id: string | null,
}

interface Props {
  imgArray: Array<Image> | null | undefined,
  imageCallback: (arrImg) => void,
  onSubmitClick:(arrImg) => void,
  isLoading: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'hidden',
    },
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    card: {
      maxWidth: 345,
      marginRight: '5px',
      marginLeft: '5px',
      marginTop: '3px',
      marginBottom: '3px'
    },
    media: {
      height: 235,
      justifyContent: 'center',
      maxWidth: '100%',
      maxHeight: '100%',
      margin: 'auto',
      display: 'block',
    },
  }),
);

const grid:number = 2

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  cursor:'pointer',
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  display: 'flex',
  padding: grid,
  overflow: 'hidden',
  flexWrap: 'wrap'
});

export const ImageGridList: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const context = useContext(AppContext);
  const classes = useStyles();
  const { isLoading,imgArray,imageCallback,onSubmitClick } = props;
  const [images, setImages] = useState(Array<Image>());
  const [officeID, setOfficeID] = useState<null | string>('');
  const [orderArray, setOrderArray] = useState(Array<string>());


  const addImage = (image :Image)=> {
    let newObj:Image = { description: image.description,  id:image.id, uri:image.uri}
    let newVersion=  addtoState (newObj)
    setImages(newVersion);
  }

  const addtoState = (x) => {
    return (prevState)=>[...prevState,x];
  }

  useEffect(() => {
    if ( imgArray != null){
      setImages(imgArray);
    }

    let hash = window.location.hash;
    var res = hash.split("/");
    if(res[2]!=="new"){
      setOfficeID(res[2]);
    }
  },[]);

  const removeImage = (index) => {
    var temp = images.filter((_, i) => i !== index);
    setImages(temp);
  }

  function work(){
    onSubmitClick(images);
  }

  function blobToFile(theBlob, fileName){
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  function onChangeDesc(e: React.ChangeEvent<HTMLInputElement>, index){
    let temp = e.target.value;
    let tempArray = images.slice();
    tempArray[index].description = temp;
    setImages(tempArray);
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items:Image[] = reorder(
      images,
      result.source.index,
      result.destination.index
    );
    setImages(items)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result:Image[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    console.log(result)
    setOrderedArray(result)
    return result;
  };

  const setOrderedArray = (images:Image[]) =>{
    let orderedArray:Array<string> = images.map(image=>image.id)
    setOrderArray(orderedArray);
  }

  return (
    <div className="main">
      <Grid container>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  <DropzoneArea 
                    acceptedFiles={['image/*']}
                    onDrop={(files) => files.forEach(file => { HTTP_REQUESTS.TEMPIMAGE_API.POST_IMAGE(file,(res) => {console.log(res); addImage(res);}, (err) => {notifyError(err.message);})})}
                    filesLimit={10}
                    maxFileSize={104857600}
                    showFileNames={false}
                    showPreviews={false}
                    getFileLimitExceedMessage = {(filesLimit:number)=>{return t("DROPZONE.1")}}
                    getFileAddedMessage = {(fileName:string)=>{return t("DROPZONE.2")}}
                    getFileRemovedMessage = {(fileName:string)=>{return t("DROPZONE.3")}}
                    getDropRejectMessage = {(rejectedFile:File)=> {return t("DROPZONE.4")}}
                    dropzoneText={t('IMAGEGRIDLIST.1')}
                    showPreviewsInDropzone={false}
                    inputProps={{
                      style:{
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                        height:200,
                        maxWidth:200,
                      }
                    }}
                  />
                  {images.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        
                          <Card className={classes.card} ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}>
                              <CardMedia
                                className={classes.media}
                                image={item.uri}
                              > 
                              <IconButton className='delete' type="button" color="primary" onClick={() => removeImage(index)}>
                                <DeleteOutlineOutlinedIcon/>
                              </IconButton> </CardMedia>
                              <TextField
                              id={"description_id" + index}
                              name={"description_name" + index}
                              placeholder= {t('IMAGEGRIDLIST.2')}
                              margin="none"
                              style={{width:225}}
                              value={images[index].description}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>)=>onChangeDesc(e,index)}
                              />    
                          </Card>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
      </Grid>
      <div style={{width:"100%",display:"flex",justifyContent:"right",marginTop:"8px"}}>
        <GenericButton label={officeID? 'IMAGEGRIDLIST.3' : 'IMAGEGRIDLIST.4'} disabled={isLoading} onClick={work}/>
        {/* <Button variant="contained" color="primary" type="button" onClick = {work} > {officeID? t('IMAGEGRIDLIST.3') : t('IMAGEGRIDLIST.4')} </Button> */}
      </div> 
    </div>
  ) 
}
