import React, {Component} from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { OpeningHours } from '../../../interfaces/OpeningHours';
import { WorkingHours } from '../../offices/WorkingHours';
import { BookingFeatures } from '../bookingFeatures/BookingFeatures';
import { HTTP_REQUESTS } from '../../../backendcomm/services/httpRequestService';
import { Booking,convertForCalendarObject } from '../../../interfaces/Booking';
import ResourceCalendar from '../../calendar/ResourceCalendar';
import { navigate } from '../../../history/navigate';
import GenericButton from '../../button/GenericButton';
import {notifySuccess, notifyWarn} from '../../notification/Notification';
import NavigateService from '../../../services/navigate.service';
import {AppContext} from '../../../middlewares/appContext/AppContext';

const styles = theme => createStyles({
  appBar: {
    position: 'relative',
    display: 'flex',
    textAlign: 'center'
  },
  title: {
    marginRight: theme.spacing(2),
    flex: 1
  },
  body: {
    padding: theme.spacing(1),
    display: 'flex'
  },
  information: {
    padding: theme.spacing(2)
  }
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface State {
  selectedSeatFeatures: Array<string>,
  selectedRoomFeatures: Array<string>,
  events: Array<Booking>,
  officeName: string,
  floorName: string,
  roomName: string,
  seatDetails: any,
  roomDetails: any,
  seatFeatures: Array<string>,
  roomFeatures: Array<string>,
  isReady: boolean,
  isLoading: boolean
}

interface Props {
  open: boolean,
  handleClose: () => void,
  handleCreateBooking?: () => void,
  selectedSeat: string,
  bookingType: Component,
  workingHours: OpeningHours | null,
}

class BookingDialog extends React.Component<WithTranslation & WithStyles & Props, State> {
  static contextType = AppContext;

  constructor(props) {
    super(props)
    this.state = {
      selectedSeatFeatures: [],
      selectedRoomFeatures: [],
      seatFeatures: [],
      roomFeatures: [],
      events:[],
      officeName: "",
      floorName: "",
      roomName: "",
      seatDetails: null,
      roomDetails: null,
      isReady: false,
      isLoading: false
    }
    this.onChangeRoomFeatures = this.onChangeRoomFeatures.bind(this);
    this.onChangeSeatFeatures = this.onChangeSeatFeatures.bind(this);
  }

  componentDidMount() {
    this.getSeatBookings();
}

  getSeatBookings() {
      HTTP_REQUESTS.CLIENT_API.GET_SEATS_BOOKINGS(this.props.selectedSeat,res=>{
          this.setState({events:convertForCalendarObject(res.seatBookings),
            floorName:res.floorName,
            roomName:res.roomName,
            officeName: res.officeName,
            seatDetails: res.seatDetails,
            roomDetails: res.seatDetails.roomDetails,
            seatFeatures: res.seatDetails.features,
            roomFeatures: res.seatDetails.roomDetails.features,
            isReady: true
            })
      },err => {
          console.error("getSeatBookingsError", err)
      })
  }

  createBookingForSeat(booking): void {
    console.log(booking,"bookings");
    this.setState({isLoading: true})
      HTTP_REQUESTS.CLIENT_API.CREATE_BOOKING(booking,bookings=>{
        this.setState({isLoading: false})
        notifySuccess(this.props.t('BOOKINGS.Event_Created'));
        navigate("/user/bookings");
      },err => {
          notifyWarn(this.props.t(`BOOKINGS.ERRORS.Der gewählte Platz ist in diesem Zeitraum nicht mehr verfügbar`));
        this.setState({isLoading: false})
      })
  }
  onChangeSeatFeatures(event: React.ChangeEvent<HTMLInputElement>){
    const checkedBoxes = [...this.state.selectedSeatFeatures];
    if(event.target.checked) {
      checkedBoxes.push(event.target.name)
    } else {
      const index = checkedBoxes.findIndex((ch) => ch === event.target.name);
      checkedBoxes.splice(index, 1);
    }
    this.setState({selectedSeatFeatures: checkedBoxes});
  }

  onChangeRoomFeatures(event: React.ChangeEvent<HTMLInputElement>) {
    const checkedBoxes = [...this.state.selectedRoomFeatures];
    if(event.target.checked) {
      checkedBoxes.push(event.target.name)
    } else {
      const index = checkedBoxes.findIndex((ch) => ch === event.target.name);
      checkedBoxes.splice(index, 1);
    }
    this.setState({selectedRoomFeatures: checkedBoxes});
  }

  getDialogHeader(){
    return <strong>{this.state.roomName} - {this.state.seatDetails?.name}</strong>
  }
  onBookingsClicked = (id) => {
    const context: any = this.context;
    const rootPath = NavigateService.rootPath(context?.user.role);
    navigate(`${rootPath}/bookings/?seatId=${id}`);
  }

  render() {
    const { open, handleClose, classes, workingHours } = this.props;
    return (
      <div>
        {
          this.state.isReady ? 
          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <Typography variant="h6" className={classes.title}>
                  { this.getDialogHeader() }
                </Typography>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Grid container>
              <Grid item xs={12} md={10}>
              <div className='dh-calendar'>
                <ResourceCalendar 
                  events={this.state.events}
                  seatFeatures={this.state.seatFeatures}
                  roomFeatures={this.state.roomFeatures}
                  resourceId={this.props.selectedSeat}
                  onCreateBooking={this.createBookingForSeat.bind(this)}
                  selectedSeatFeatures={this.state.selectedSeatFeatures}
                  selectedRoomFeatures={this.state.selectedRoomFeatures}
                  workingHours = {workingHours}
                  isLoading = {this.state.isLoading}
                  onChangeSeatFeatures = {this.onChangeSeatFeatures}
                  onChangeRoomFeatures = {this.onChangeRoomFeatures}/>
              </div>
              </Grid>
              <Grid item className={classes.information} xs={12} md={2}>
                <Typography variant='h5' color='secondary' className={classes.title}>{this.state.officeName}</Typography>
                <Typography variant='body2' color='secondary' > {this.state.floorName} </Typography>
                {workingHours ? <WorkingHours data={workingHours} /> : null}
                {(this.state.seatDetails.features || this.state.seatDetails.roomDetails.features )? <BookingFeatures seatFeatures={this.state.seatDetails.features} roomFeatures={this.state.roomDetails.features} selection={false} /> : null}
                <GenericButton onClick={()=>this.onBookingsClicked(this.props.selectedSeat)} label={'OBJECTCARD.6'}/>
              </Grid>
            </Grid>          
          </Dialog>
        :null}
      </div>
    );
  }

}
export default withStyles(styles, { withTheme: true })(withTranslation()(BookingDialog));
