import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

import {ForgotPasswordHeader} from '../headers/ForgotPasswordHeader';
import { navigate } from '../../history/navigate';

interface Props {
  onSubmitClick: (email: string) => void;
}
 

export const ChangePasswordForm:React.FC<Props> = (props)=>{
  const { t, i18n } = useTranslation();
  document.title = t('TITLE.3');

  const { onSubmitClick} = props;

  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [pwdErr, setPwdErr] = useState<boolean>(false);
  const [rePwdErr, setRePwdErr] = useState<boolean>(false);
  const [passErrMsg,setPassErrMsg] = useState('');
  const [rePwdErrMsg,setRePwdErrMsg] = useState('');

  const onResetClick = () => {
    if(checkValidation() && checkValidationMatch())
    onSubmitClick(password)
  }

  const passwordChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setPwdErr(false);
  }

  const passwordCheckChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRePassword(e.target.value);
    setRePwdErr(false);
  }

  const onLoginClick = () => {
    navigate("/login");
  }

  const checkValidation = () => {

    let formValid=true;

    if( !password || password === "" ){
      setPwdErr(true);
      setPassErrMsg(t('INPUT_VALIDATIONS.6'))
      formValid = false;
    }
    else{
      setPwdErr(false);
    }
    if( !rePassword || rePassword === "" ){
      setRePwdErr(true);
      setRePwdErrMsg(t('INPUT_VALIDATIONS.6'))
      formValid = false;
    }
    else{
      setRePwdErr(false);
    }

    return formValid
  }

  const checkValidationMatch = () => {

    let formValid=true;

    
    if( password !== rePassword ){
      setRePwdErr(true);
      setRePwdErrMsg(t('INPUT_VALIDATIONS.9'))
      formValid = false;
    }
    else{
      setRePwdErr(false);
    }

    return formValid;
}

  const onEnterPress = (e) => {
    if (e.key === 'Enter'){

      if(checkValidation() && checkValidationMatch())
      onResetClick();
    }
  }

  return (
   
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} >

        <div className="paper-login">
         
          <form className="form" onKeyPress={onEnterPress} noValidate>
          <ForgotPasswordHeader/>
            <TextField
              
              margin="normal"
              required
              fullWidth
              id="password"
              label={t('FORGOTPASSWORD.1')}
              name="password"
              type="password"
              autoComplete="password"
              autoFocus
              color="secondary"
              onChange={passwordChanged}
              value={password}
              error={pwdErr}
              helperText={ pwdErr? passErrMsg:null}
            />

            <TextField
              
              margin="normal"
              required
              fullWidth
              id="passwordCheck"
              label={t('USERCRUD.21')}
              name="passwordCheck"
              type="password"
              autoComplete="passwordCheck"
              autoFocus
              color="secondary"
              value={rePassword}
              onChange={passwordCheckChanged}
              error={rePwdErr}
              helperText={ rePwdErr? rePwdErrMsg:null}
              
            />
            <Grid container justify="center">
            <Button
              variant="contained"
              type="button"
              color="primary"
              className="submit"
              onClick={onResetClick}
            >
              {t('FORGOTPASSWORD.2')}
            </Button>
            </Grid>
            <Grid container justify="flex-end">
              <Link onClick={onLoginClick} >
              {t('FORGOTPASSWORD.3')}
              </Link>
            </Grid>
          </form>
        </div>
        </Grid>
        
  );
}