import React, { useContext,useEffect } from 'react';
import { AppContext } from "../../middlewares/appContext/AppContext";
import {HTTP_REQUESTS} from '../../backendcomm/services/httpRequestService';
import { useTranslation } from 'react-i18next';
import { navigate } from '../../history/navigate';

interface Props {

}

export const Home: React.FC<{}> = () => {
    const { t, i18n } = useTranslation();
    const context = useContext(AppContext);
    //console.log(context);
    document.title = t('TITLE.5');    

    /*notifySuccess('Success message');
    notifyError('Error message');
    notifyInfo('Info message');
    notifyWarn('Warn message');*/


    const logoutClick = () =>{

        HTTP_REQUESTS.TENANT_API.LOGOUT((res) => {
            //alert('logged out returning to login page '); Test
            window.open('/login','_self');
        }, (err) => {
            alert('logout error');
        });

    }

    const hasPermission = (key:string) => {
        if(context && context.user.permissions.includes(key))
        {
            return true;
        }
        else {
            return false;
        }
    }

    useEffect(() => {
        if(hasPermission("MANAGE_OFFICES") && context?.user && context.user.role === "ADMIN")
        {
            navigate("/offices");
        }
        else if(hasPermission("BOOK_OFFICES"))
        {
            navigate("/user/offices");
        }
        else {
            navigate("/profile");
        }
    }, [])


    return (
        <div>
            {/* <MenuBar/> 
            <p> {'DASHBOARD HIER'}</p>
            <div>
                LOGGED IN USER INFO => {JSON.stringify(context)}
            </div> */}
        </div>
    )
}