import React, { useContext } from 'react';
import { AppContext } from "../../middlewares/appContext/AppContext";

interface Props {

}

export const UserInfo: React.FC<Props> = () => {

    const context = useContext(AppContext);
    
    return (
        <div className="user-info">
           <em>{context!.user.firstname} {context!.user.lastname}</em> 
        </div>

    );
}