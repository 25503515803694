import React, { useState, useEffect } from 'react';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { ChangePasswordForm } from './ChangePasswordForm';
import { ForgotPasswordValidateError } from './ForgotPasswordValidateError';
import { navigate } from '../../history/navigate';
import { notifyError } from '../notification/Notification';


interface Props {

}

export const ChangePassword: React.FC<Props> = () => {

    const [verified, setVerified] = useState(Boolean);
    const [requestReturned, setrequestReturned] = useState(false);

    let code = "";

    try {
        let url = window.location.href;
        let searchParams = url.substring(url.indexOf("?") + 1);
        code = searchParams.replace('code=', '');
    } catch (error) {

    }
    //alert(code);
    useEffect(() => {
        HTTP_REQUESTS.TENANT_API.POST_RESET_PASSWORD_VERIFY(code, (res) => {
            setVerified(true);
            setrequestReturned(true);
        }, (err) => {
            setVerified(false);
            setrequestReturned(true);

        });
    }, []);




    const submit = (password) => {
       
        HTTP_REQUESTS.TENANT_API.POST_FORGOT_PASSWORD_RESET({"newPassword":password, "token":code}, (res) => {
            //console.log(res);
            navigate('/forgotPasswordSuccessCompleted');
        }, (err) => {
            notifyError(err.message);
        });

    }

    if(requestReturned){
        if (verified) {
            return (<ChangePasswordForm onSubmitClick={submit} />)
        } else {
            return (<ForgotPasswordValidateError />)
        }
    }else{
        return null;
    }
   

}