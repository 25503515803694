import React from 'react';
import { Redirect } from "react-router-dom";
import { AppContext } from "../appContext/AppContext";
import { User } from '../../interfaces/User';
import { CustomSpinner } from '../../components/spinner/CustomSpinner';
import AppBar from '../../components/menu/AppBar';
import MenuBar from '../../components/menu/MenuBar';
import ClippedDrawer from '../../components/layouts/ClippedDrawer';
import { Office } from '../../interfaces/Office';
import { ShortOffice } from '../../interfaces/ShortOffice';

interface Props {
    isAuthenticated: boolean;
    isLoading: boolean;
    Component: React.ComponentType;
    hasMenuBar: boolean
    path: string;
    exact: boolean
    authenticatedUserData: User | null;
    tenantNotFound: boolean
}


export const PrivateRoute: React.FC<Props> = (props) => {

    const { Component, isAuthenticated, isLoading, authenticatedUserData, hasMenuBar, tenantNotFound } = props;
    const authenticatedUser = authenticatedUserData as User;

    const [profilePic, setProfilePic] = React.useState(window.location.origin+"/api/auth/info/image")
    const language = localStorage.getItem("language")
    const [lang, setLang] = React.useState<string>(language ? language : "de")
    const [user, setUser] = React.useState<User>(authenticatedUser)
    const _searchType = localStorage.getItem('searchType');
    const [searchType, setSearchType] = React.useState<string>(_searchType ? _searchType : 'exact');

    const [allOffices,setAllOffices] = React.useState<Array<ShortOffice>>();

    if (tenantNotFound) {
        return <Redirect to="/tenantNotFound" />
    }

    if (isLoading) {
        return <div><CustomSpinner /></div>
    }

    if (!isAuthenticated) {
        return <Redirect to="/login" />
    }

    if (authenticatedUser.role == "USER") {
        return <Redirect to="/user/dashboard" />
    }

    const onToggle = ()=>{
        setProfilePic("");
        setProfilePic(window.location.origin+"/api/auth/info/image")
    }

    const onLangChange = (language:string) => {
        let lang = localStorage.getItem("language")
        if(lang)
            setLang(language)
    }

    const onUserChange = (user: User) => {
        console.log('onUserChange', user);
        if(user)
            setUser(user)
    }

    const x  = (user: User) => {
        if(user)
            setUser(user);
    }

    const onSearchTypeChange = (searchType:string) => {
        if(searchType)
        {
            setSearchType(searchType)
        }
    }

    const updateAllOffices = (offices:Array<ShortOffice>)=>{
        console.log("Admin",offices)
        setAllOffices(offices)
    }

    const contextValue = {
        user:(user) ? user : authenticatedUser,
        toggle:onToggle,
        imageUrl:profilePic,
        lang:lang,
        changeLang:onLangChange,
        changeUser: onUserChange,
        searchType:searchType,
        changeSearchType:onSearchTypeChange,
        updateAllOffices:updateAllOffices,
        allOffices:allOffices
    }

    return <AppContext.Provider value={contextValue}  >
        {hasMenuBar ? <ClippedDrawer Component={Component} /> : (<div id="without-leftMenu"><AppBar  isRelative={!hasMenuBar} /><Component /></div>)}
    </AppContext.Provider>

}
