export interface Booking {
    endDateTime: string | Date
    startDateTime: string | Date
    floorId: string
    floorName:string
    id: string
    officeId: string
    officeName: string
    seatName: string
    userName: string
    title ?: string
    rRule ?: string
    allDay ?: boolean
}

export const convertForCalendarObject = (bookings:Array<Booking>):Array<Booking> =>{
    if(bookings.length)
    {
        bookings.forEach(booking => {
            booking.endDateTime = new Date(booking.endDateTime);
            booking.startDateTime = new Date(booking.startDateTime);
            booking.title = booking.userName
        });
        return bookings
    }
    else {
        return []
    }

}
