/** @format */

// @ts-nocheck

import React, { Component, useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useTranslation } from "react-i18next";
import { HTTP_REQUESTS } from "../../backendcomm/services/httpRequestService";
import { navigate } from "../../history/navigate";
import moment, { Moment } from "moment";
import GenericButton from "../button/GenericButton";
import CalendarToday from "@material-ui/icons/CalendarToday";

interface Props {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  handleNavSchedule: (seatId: string) => void;
  selectedSeat: {
    id: string;
    name: string;
    point: { x: number; y: number };
    available: boolean;
    roomId: string;
    features: Array<string>;
  } | null;
  selectedRoom: {
    id: string;
    name: string;
    description: string;
    isNew: boolean;
    isExclusive: boolean;
    features: Array<string>;
    points: Array<number>;
    available: boolean;
    seats: Array<{
      id: string;
      point: { x: number; y: number };
      available: boolean;
      roomId: string;
    }>;
  } | null;
  roomFeatures: Array<string>;
  seatFeatures: Array<string>;
  startDateTime: Moment;
  endDateTime: Moment;
  bookingSavedStatus: (saved: boolean, seatId: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "45%",
      height: "60%",
      backgroundColor: "#ffffff",
    },
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    formControl: {
      margin: theme.spacing(3),
    },
  })
);

export const BookingModal: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  useEffect(() => {}, []);

  const getCheckedStatus = (feature: string) => {
    if (props.selectedSeat) {
      return props.selectedSeat.features.includes(feature);
    } else if (props.selectedRoom) {
      return props.selectedRoom.features.includes(feature);
    }
  };

  const onSaveClick = () => {
    if (props.selectedSeat) {
      let bookingToCreate = {
        startDateTime: props.startDateTime,
        endDateTime: props.endDateTime,
        seatId: props.selectedSeat.id,
      };
      HTTP_REQUESTS.CLIENT_API.CREATE_BOOKING(
        bookingToCreate,
        (res) => {
          if (props.selectedSeat) {
            props.bookingSavedStatus(true, props.selectedSeat.id);
            navigate("/user/bookings");
          }
        },
        (err) => {
          if (props.selectedSeat) {
            props.bookingSavedStatus(false, props.selectedSeat.id);
          }
        }
      );
    } else {
      //alert("Room booking not implemented");
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <div
              style={{
                margin: "4px",
                alignItems: "baseline",
                display: "flex",
                width: "99%",
                height: "5vh",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{ alignSelf: "center", fontSize: "1vw" }}
                variant="body1"
                color="primary"
              >
                <strong>
                  {props.selectedSeat
                    ? props.selectedSeat.name != "seat_name"
                      ? props.selectedSeat.name
                      : null
                    : null}{" "}
                  {props.selectedRoom ? props.selectedRoom.name : null}
                </strong>
              </Typography>
              <IconButton
                onClick={props.handleClose}
                style={{ alignItems: "start" }}
              >
                <CloseIcon color="secondary" />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "14vh",
                width: "100%",
                backgroundColor: "#f2f2f2",
              }}
            >
              <div>
                <Typography
                  style={{ alignSelf: "center", fontSize: "1vw" }}
                  variant="body1"
                  color="primary"
                >
                  {moment(props.startDateTime).format("DD-MM-YYYY HH:mm")} /{" "}
                  {moment(props.endDateTime).format("DD-MM-YYYY HH:mm")}
                </Typography>
              </div>
              <div>
                <strong>{t("BOOKINGS.1")}</strong>
              </div>

              <div style={{ color: "#5db301", justifySelf: "center" }}>
                <strong>{t("BOOKINGS.2")}!</strong>
              </div>
              {/* TODO (Kaan): Change icon button to text button ?? or add a helper text with localization */}
              <div style={{ color: "#5db301", justifySelf: "center" }}>
                <IconButton
                  onClick={() => {
                    props.handleNavSchedule(
                      props.selectedSeat ? props.selectedSeat.id : ""
                    );
                  }}
                  style={{ alignItems: "start" }}
                >
                  <CalendarToday color="primary" />
                </IconButton>
              </div>
              {/* End of TODO (Kaan) */}
            </div>
            <div className={classes.root}>
              <FormControl
                color="primary"
                component="fieldset"
                className={classes.formControl}
              >
                <FormLabel>
                  <Typography
                    style={{ alignSelf: "center", marginBottom: 8 }}
                    variant="h6"
                    color="secondary"
                  >
                    <strong>{t("BOOKINGS.3")}</strong>
                  </Typography>
                </FormLabel>
                <FormGroup>
                  {props.selectedRoom
                    ? props.roomFeatures.map((feature, i) =>
                        getCheckedStatus(feature) ? <li>{feature}</li> : null
                      )
                    : null}

                  {props.selectedSeat
                    ? props.seatFeatures.map((feature, i) =>
                        getCheckedStatus(feature) ? <li>{feature}</li> : null
                      )
                    : null}
                </FormGroup>
              </FormControl>
            </div>
            <div
              style={{
                margin: "0 auto",
                width: "60%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              {/* <Button color="primary" variant="contained" onClick={onSaveClick} type="button" >{t('BOOKINGS.8')}</Button> */}
              <GenericButton label={"BOOKINGS.8"} onClick={onSaveClick} />
              <Button
                color="secondary"
                onClick={props.handleClose}
                variant="outlined"
                type="button"
              >
                {t("BOOKINGS.9")}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
