import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { navigate } from '../../history/navigate';
import { useTranslation } from 'react-i18next';
import GenericButton from '../button/GenericButton';
import { AppContext } from "../../middlewares/appContext/AppContext";
import { Floor } from '../../interfaces/Floor';
import NavigateService from '../../services/navigate.service';
import defaultFloorImage from '../images/room1.png';
import {Grid} from "@material-ui/core";


const useStyles = makeStyles({
  root: {
    maxWidth: 345,

  },
  media: {
    height: 250,
    
  },
});

interface Image{
    description : string, 
    uri: string,
    id: string
}

interface Address{
  city: string,
  street: string,
  zip: string
}

interface OpeningHours{
  frFrom: string,
  frTo: string,
  moFrom: string,
  moTo: string,
  saFrom: string,
  saTo: string,
  suFrom: string,
  suTo: string,
  thFrom: string,
  thTo: string,
  tuFrom: string,
  tuTo: string,
  weFrom: string,
  weTo: string
}

interface Props {
  details: Floor,
  officeID: string | null
}

export const PropertyCard: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { details, officeID } = props;
  const { t, i18n } = useTranslation();
  const context = useContext(AppContext);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2
  };

  const hasManageOffice = () => {
    return (context && context.user.permissions.includes("MANAGE_OFFICES"))
  }

  function buttonClick(floorId){
    navigate('/officereservation/'+officeID+'/'+ floorId);
  }

  const onEditClick = (floorId) => {
    navigate('/offices/'+officeID+'/'+ floorId);
  }
  const onBookingsClicked = (id) => {
    const rootPath = NavigateService.rootPath(context?.user.role);
    navigate(`${rootPath}/bookings/?floorId=${id}`);
  }
  return (
    <Card className="card-root">
      <CardActionArea onClick={()=>{buttonClick(details.id)}} >
        <div className="media-container">
        <CardMedia
          className="card-media"
          component="img"
          alt={details.name}
          image={ (details && details.images.length > 0) ? process.env.PUBLIC_URL + details.images[0].uri +'?w=295' : defaultFloorImage}
          title={details.name}
        />
        </div>
        <CardContent>
          <Grid container spacing={2} xs={12}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6" component="h2" color="primary">
                <strong>{details.name}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" component="p">
                <br/>
                {t('OBJECTCARD.3')}: {details.seatsAvailable}
              </Typography>
            </Grid>
          </Grid>


        </CardContent>
      </CardActionArea>
      <CardActions style={{justifyContent:"space-evenly"}}>
        {/* <Button onClick={()=>buttonClick(details.id)} size="medium" color="primary" variant="contained">
        {t('OBJECTCARD.5')}
        </Button> */}
        <Grid container spacing={2} xs={12} justifyContent='space-between'>
          <Grid item xs={12} md={hasManageOffice() ? 4 : 6}>
            <GenericButton onClick={()=>onBookingsClicked(details.id)} label={'OBJECTCARD.6'} width={'100%'}/>
          </Grid>
          <Grid item xs={12} md={hasManageOffice() ? 4 : 6}>
            <GenericButton onClick={()=>buttonClick(details.id)} label={'BOOKINGS.8'} width={'100%'}/>
          </Grid>


          {
            hasManageOffice() ?
                <Grid item xs={12} md={hasManageOffice() ? 4 : 6}>
                  <GenericButton onClick={()=>onEditClick(details.id)} label={'CANVAS.2'} width={'100%'}/>
                </Grid>

                :null
          }
        </Grid>

      </CardActions>
    </Card>
  );
}
