import React from "react";
var Spinner = require('react-spinkit');

const styles={
    'outerDiv':{
        'width': '100vw',
        'height': '100vh',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center'
    },
    'customSpinner':{
        'width': '15vw',
        'height': '15vw'
    }

}

interface Props {
   
}

export const CustomSpinner:React.FC <Props> = ()=> { 
    let color = process.env.REACT_APP_PRIMARY_COLOR;
    return (
        <div style={styles.outerDiv}>
          <Spinner noFadeIn name="cube-grid" color={color} style={styles.customSpinner}/>
      </div>
    );
}
