import React, { Component, useState, useEffect } from 'react';

import OfficeMeasure from './OfficeMeasure';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { navigate } from '../../history/navigate';
import { withTranslation, WithTranslation } from 'react-i18next';


interface State {
    floor: {
        description: string,
        floorplanPresent: boolean,
        floorplanScale: {
            length: number,
            p1: {
                x: number,
                y: number
            },
            p2: {
                x: number,
                y: number
            }
        },
        floorplanScalePixelPerMeter: number,
        id: string,
        name: string,
        officeId: string
    } | null,
    scaleExists: boolean,
    imageUrl: string | null
}

class OfficeEditorDataHandler extends React.Component<WithTranslation, State> {


    constructor(props) {
        super(props);

        this.state = {
            floor: null,
            scaleExists: false,
            imageUrl: null
        };

    }

    componentDidMount = () => {


        let hash = window.location.hash;
        var res = hash.split("/");
        let officeId = res[2];
        let floorId = res[3];


        HTTP_REQUESTS.OFFICE_API.GET_FLOOR(officeId, floorId, (res) => {
            if (res.floorplanScale != null) {
                if(this.props.t("CURRENT_LANGUAGE")=="DE"){
                    res.floorplanScale.length = String(res.floorplanScale.length).replace(".",",");
                }
                this.setState({
                    scaleExists: true
                });
            }
            this.setState({
                floor: res
            });

        }, (err) => {

        });


        HTTP_REQUESTS.OFFICE_API.GET_FLOORPLAN(officeId, floorId, (res) => {
        console.log(res,"floor response")
            
            let blob = new Blob(
                [res],
                { type: 'image/png' }
            );
            let image = URL.createObjectURL(blob);

            this.setState({
                imageUrl:image
            })
        }, (err) => {

        });
    }

    saveButtonClicked = (obj) => {

        if (this.state.floor) {
            let officeId = this.state.floor.officeId;
            let floorId = this.state.floor.id;
            HTTP_REQUESTS.OFFICE_API.UPDATE_FLOOR(officeId, floorId, obj, (res) => {
                //alert("Saved");
                navigate('/officeeditor/'+officeId+'/'+floorId);
            }, (err) => {
                //alert("Err");
            });
        }
    }

    render() {
        return (
            <div>
                {this.state.floor && this.state.imageUrl ? <OfficeMeasure floor={this.state.floor} saveButtonClicked={this.saveButtonClicked} scaleExists={this.state.scaleExists}  imageUrl={this.state.imageUrl}/> : null}
            </div>
        );
    }
}

export default withTranslation()(OfficeEditorDataHandler);