import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-konva';
import { navigate } from '../../history/navigate';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { PropertyCardList } from '../propertyCard/PropertyCardList';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import RichTextEditor from 'react-rte';
import ReactHtmlParser from 'react-html-parser'; 
import { Office } from '../../interfaces/Office';
import {WorkingHours} from "../offices/WorkingHours";


interface Props {
    office: Office | null;
}

export const DetailsForm: React.FC<Props> = (props) => {

  const { t } = useTranslation();
  const { office } = props;
  const [ value , setValue ] = React.useState(RichTextEditor.createValueFromString( office?.description, 'html'))
  document.title = t('TITLE.7');
  let length =0;
  if(office && office.images){
    length = office.images.length;
  }

  
  const settings = {
    className: "center",
    centerMode: true,
    centerPadding: "60px",
    dots: false,
    infinite: length > 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };

  let localizedDistance = String(office?.hygieneMeasuresDistance);
  
  if(localizedDistance != null && localizedDistance!= undefined && t('CURRENT_LANGUAGE') == "DE"){
    localizedDistance = localizedDistance.replace(".",",");
  }

  function backButtonClick(){
    navigate('/user/offices');
  }

  function showButtonClick(floorId){
    navigate('/officereservation/'+office?.id+'/'+floorId);
  }
  function generateOfficeInformation() {
    return <div style={{marginTop: '16px'}}>
      <Grid container spacing={3} xs={12}>
          <Grid item container>
            <Typography variant="h6" color="primary"><strong>{office?.name}</strong></Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item container xs={12}>
              <Typography>{office?.address.street}</Typography>
            </Grid>
            <Grid item container xs={12}>
              <Typography> {office?.address.zip} {office?.address.city} </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <WorkingHours data={office?.openingHours}/>
          </Grid>
      </Grid>
    </div>
  }
  return (
  <div className="details"  >
    <div className="details-container" style={{margin:36}} >
      <Grid xs={12} container justify="space-between" direction="row" style={{marginBottom:16}} >
        <Grid item xs={9}>
        <Button className="back-btn" variant="contained" onClick={backButtonClick} ><ArrowBackIosIcon fontSize="small" className="back-icon" /> {t('DETAILS.1')} </Button>
        </Grid>

      </Grid>
        <Grid xs={12} container direction="row" >
          <Grid item xs={12} md={9}>
             <div>
               {
                 office && office.floors ?
                     <PropertyCardList office={office? office : null} />
                     :
                     <div className="no-suitable-message" >
                       <Typography variant="body2">{t('FILTERBAR.9')}</Typography>
                     </div>

               }
             </div>
          </Grid>
          <Grid xs={1}></Grid>
          <Grid item container xs={12} md={2}>
            {generateOfficeInformation()}
          </Grid>

        </Grid>

      <Grid xs={12} container direction="row"  style={{}}  >
        <Grid xs={8} justify="center" alignItems="center" >
          <div className="images">
            <Slider {...settings}> 
              {office?.images.map((imge, index) =>                
                    <img src={imge.uri} alt={imge.description} />               
              )}
            </Slider>
          </div>
        </Grid>
      </Grid>
      <Grid xs={12} container  direction="row"spacing={5}  style={{marginTop:24}}>
        <Grid className="descriptions" item xs={9} style={{marginBottom:16}}>
        <Typography className="desc-header" color="primary" variant="h5">{t('PROPERTY.14')}</Typography>
        <div> { ReactHtmlParser (office?.description) } </div>
          <Typography className="desc-header" color="primary" variant="h5">{t('PROPERTY.27')}</Typography>
          <div className="hygiene-concept">
            <FormControlLabel
                control={<Checkbox checked={true} color="primary"/>} 
                label={t('PROPERTY.28')}
            />
            <Typography variant="body1">{t('PROPERTY.29')}: {localizedDistance} m</Typography>
          </div>
          <div> { ReactHtmlParser (office?.hygieneMeasuresDescription) } </div>
        </Grid>
        {/* <Grid item xs={3}>
        <div className="floors" id="floors">
          {office?.floors?
            office.floors.map(floor=>{
              return <div>
            <Typography variant="body2" component="p">
           
            <strong>{floor.name}</strong>
            <br/>
            {t('OBJECTCARD.2')}
            <br/>
            {t('OBJECTCARD.3')}: {floor.sharedSeatsAvailable}
            <br/>
            {t('OBJECTCARD.4')}: {floor.exclusiveSeatsAvailable}
            <br/>
            <br/>
          </Typography>
          <div>
          <Button variant="contained" className="reservation-btn" onClick={()=>{showButtonClick(floor.id)}} ><EventSeatIconOutlined fontSize="small" className="seat-icon" color="primary"  /> {t('DETAILS.10')} </Button>

            </div>
            <br/>
            <br/>
              </div>
            })
          :
          null}
        </div>
        </Grid> */}

      </Grid>
      
    </div>
  </div>
  )
}