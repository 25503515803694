import React, {FC, useState} from 'react';
import {availablePeriods} from '../../interfaces/AvailablePeriods';
import BookingDialog from "../bookings/bookingDialog/BookingDialog";
import {OpeningHours} from "../../interfaces/OpeningHours";
import RoomCard from "./RoomCard";


interface Props {
    data: Array<{ id: string, name: string, description: string, isNew: boolean, isExclusive: boolean, points: Array<number>, available: boolean, features: Array<string>, availablePeriods: Array<availablePeriods>, reservedForClients: Array<{ id: string, name: string }>, seats: Array<{ id: string, name: string, point: { x: number, y: number }, available: boolean, bookingInfo: string, roomId: string, features: Array<string>, availablePeriods: Array<availablePeriods> }> }>,
    workingHours: OpeningHours | null
}

export const RoomCardList: FC<Props> = (props) => {
    const {data, workingHours} = props;

    const [selectedRoom, setSelectedRoom] = React.useState({
        id: "",
        name: "",
        description: "",
        isNew: false,
        isExclusive: false,
        points: [],
        available: false,
        features: [],
        availablePeriods: [],
        reservedForClients: [],
        seats: []
    });
    const [selectedSeat, setSelectedSeat] = React.useState({
        id: "",
        name: "",
        point: {x:0,y:0},
        available: false,
        bookingInfo: "",
        roomId: "",
        features: [],
        availablePeriods: []
    });
    const [infoModalOpen, setInfoModalOpen] = useState(false);

    const handleInfoOpen = () => {
        setInfoModalOpen(true);
    };

    const handleInfoClose = () => {
        setInfoModalOpen(false);
    };


    return (
        <div className={"object-list"} style={{justifyContent: "center", display: "flex", flexWrap: "wrap"}}>
            {data.map((room) =>
                <RoomCard
                    data={data}
                    details={room}
                    handleInfoOpen={handleInfoOpen}
                    selectedSeat={selectedSeat}
                    setSelectedSeat={setSelectedSeat}
                    setSelectedRoom={setSelectedRoom}
                />
            )}
            {
                infoModalOpen ?
                    <BookingDialog
                        handleClose={handleInfoClose}
                        open={infoModalOpen}
                        workingHours={workingHours}
                        seatFeatures={selectedSeat.features}
                        selectedSeat={selectedSeat?.id}
                        dialogHeader={`${selectedRoom?.name}-${selectedSeat?.name}`}
                    />
                    :
                    null
            }
        </div>
    );
}