import React, { forwardRef } from 'react';
import { User } from '../../interfaces/User'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { withTranslation, WithTranslation } from 'react-i18next';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import MaterialTable from '@material-table/core';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import { PatchedPagination } from '../utils/PatchedPagination';
import { ExportCsv, ExportPdf } from '@material-table/exporters';

interface Props {
    users: Array<User>,
    deleteUserClick?: (id:string) => void,
    onClickEdit?: (id:string) => void
}

interface State {
    data: Array<User>,

}
class UsersTable extends React.Component<WithTranslation & Props, State> {
    
    constructor(props) {
        super(props);

        this.state = {
            data:[]
        };

    }

    render() {
        const { t }=this.props;
        const dummy = require("../../images.json");
        return (
            <div className="users-table-wrapper">
                <MaterialTable
                    title=""
                    icons={{ 
                        Delete: forwardRef((props, ref) => <DeleteOutline color="primary" {...props} ref={ref} />)
                    }}
                    columns={[
                        { title: 'Id', field: 'id',hidden:true,export:false},
                        { title: 'Avatar', field: 'avatar',export:false,
                        render: rowData => ( 
                           <Avatar color="primary" src={window.location.origin + "/api/useradmin/user/" +rowData.id + "/image"} />
                        )
                        },
                        { title: t('USERCRUD.1'), field: 'salutation', 
                        render: rowData => {
                           return t(`${rowData.salutation}`)
                        }
                        },
                        { title: t('USERCRUD.2'), field: 'firstname'},
                        { title: t('USERCRUD.3'), field: 'lastname'},
                        { title: 'E-Mail', field: 'email'},
                        { title: t('USERCRUD.6'), field: 'role',render: rowData => {
                            return t(`ROLE.${rowData.role}`)
                         }},
                        { title: t('USERSTABLE.15'), field:'client.name'}
                    ]}
                    data={this.props.users? this.props.users : []}
                    options={{
                        draggable:false,
                        search:true,
                        filtering: false,
                        headerStyle:{fontWeight:"bold",textAlign:"left"},
                        actionsColumnIndex:-1,
                        pageSize:10,
                        exportMenu: [
                            {
                            
                                label: t('EXPORT.CSV'),
                                exportFunc: (cols, data) => ExportCsv(cols, data, `${moment().format('DD-MM-YYYY HH:mm')}_bookings`)
                            },
                            {
                                label: t('EXPORT.PDF'),
                                exportFunc: (cols, data) => ExportPdf(cols, data, `${moment().format('DD-MM-YYYY HH:mm')}_bookings`)
                            }
                        ]
                    }}
                    localization={{
                        header:{
                            actions:t('USERCRUD.17')
                        },
                        body:{
                            deleteTooltip:t('USERSTABLE.1'),
                            editTooltip:t('USERSTABLE.2'),
                            emptyDataSourceMessage:t('USERSTABLE.3'),
                            editRow: {
                                deleteText: t('USERSTABLE.4'),
                                cancelTooltip: t('USERSTABLE.5'),
                                saveTooltip: t('USERSTABLE.6')
                            }
                        },
                        toolbar: {
                            exportTitle: 'Export',
                            exportAriaLabel: 'Export',
                            searchTooltip: t('USERSTABLE.7'),
                            searchPlaceholder: t('USERSTABLE.7')
                        },
                        pagination: {
                            labelDisplayedRows:  t('USERSTABLE.8'),
                            labelRowsSelect:  t('USERSTABLE.9'),
                            labelRowsPerPage:  t('USERSTABLE.10'),
                            firstAriaLabel:  t('USERSTABLE.11'),
                            firstTooltip:  t('USERSTABLE.11'),
                            previousAriaLabel:  t('USERSTABLE.12'),
                            previousTooltip:  t('USERSTABLE.12'),
                            nextAriaLabel:  t('USERSTABLE.13'),
                            nextTooltip:  t('USERSTABLE.13'),
                            lastAriaLabel:  t('USERSTABLE.14'),
                            lastTooltip:  t('USERSTABLE.14'),
                        },
                    }}
                    editable = {{onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                        {this.props.deleteUserClick ? this.props.deleteUserClick(oldData['id']):console.log("")}
                        setTimeout(() => {
                            resolve(true);
                            this.setState((prevState) => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return {...prevState, data};
                            });
                        }, 600);
                    }),
                    }}
                    actions={[
                        {
                          icon: ()=> {return <EditOutlinedIcon color="primary"/>},
                          tooltip: t('USERSTABLE.2'),
                          onClick: (event, rowData) => {if(this.props.onClickEdit ) this.props.onClickEdit(rowData['id']) }
                        }
                      ]}
                    components={{Pagination:PatchedPagination}}
                    
                    
                />
            </div>
        );
    }

    
}
export default withTranslation()(UsersTable);
