import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, InputAdornment, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment';
import "flatpickr/dist/themes/material_red.css";
import Flatpickr from "react-flatpickr";
import CheckIcon from '@material-ui/icons/Check';
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { english } from 'flatpickr/dist/l10n/default';
import { German } from 'flatpickr/dist/l10n/de';
import { AppContext } from "../../middlewares/appContext/AppContext";
import { notifyError } from '../notification/Notification';

interface Props {
  exclusiveChanged?: (value: boolean) => void,
  sharedChanged?: (value: boolean) => void,
  dateChanged?: (dateStart: Moment, dateEnd: Moment, searchType: boolean) => void,
  startDateTime: Moment,
  startMaxTime?:string,
  startMinTime?:string,
  endMaxTime?:string,
  endMinTime?:string,
  endDateTime: Moment,
  isSearchTypeEnabled: boolean,
  disabledDays?:Array<number>
  setOfficeMaxandMinDate?:(start:Moment,end:Moment) => void,
  searchType: string,
  onExactChanged?:(exact:string) => void
}

export const FilterBar: React.FC<Props> = (props: Props) => {
  const context = useContext(AppContext);

  const { t, i18n } = useTranslation();
  const [selectedDateEnd, setEndDate] = React.useState<Moment>(props.endDateTime.second(0).milliseconds(0));
  const [selectedDateStart, setStartDate] = React.useState<Moment>(props.startDateTime.second(0).milliseconds(0));
  const [exclusive, setExclusive] = React.useState<boolean>(true);
  const [shared, setShared] = React.useState<boolean>(true);
  const [startErrorMsg, setStartErrorMsg] = React.useState<string>('');
  const [endErrorMsg, setEndErrorMsg] = React.useState<string>('');
  const [startError, setStartError] = React.useState<boolean>(false);
  const [endError, setEndError] = React.useState<boolean>(false);
  const [isExactSearch, setIsExactSearch] = React.useState<string>(context?.searchType ? context.searchType : 'exact');

  const [searchTimeMsg, setSearchTimeMsg] = React.useState<string>(t('FILTERBAR.11'));
  const [endTimeMsg, setEndTimeMsg] = React.useState<string>('');
  const [hasSearchMsgDate, setHasSearchMsgDate] = React.useState<boolean>(false);
  const [hours, setHours] = React.useState<number>(0);


  const exclusiveChanged = e => {
    setExclusive(e.target.checked);
    if (props.exclusiveChanged) {
      props.exclusiveChanged(e.target.checked);
    }
  }

  const sharedChanged = e => {
    setShared(e.target.checked);
    if (props.sharedChanged) {
      props.sharedChanged(e.target.checked);
    }
  }

  const onExactChanged = (event: React.ChangeEvent<HTMLInputElement>) => {

    setIsExactSearch((event.target as HTMLInputElement).value);
  };

  const setSearchTypeMsg = () => {
    //Check the days are equal
    if(selectedDateStart.isSame(selectedDateEnd,'day')){
      var duration = moment.duration(selectedDateEnd.diff(selectedDateStart));
      var hours = duration.asHours();
      hours = Math.round(hours);
      if(hours < 0){
        hours=0;
      }
      setHours(hours);
      setHasSearchMsgDate(true)
    }else {
      let startTime = selectedDateStart.hour()
      let endTime = selectedDateEnd.hour()
      var hours = endTime - startTime
      hours = Math.round(hours);
      if(hours < 0){
        hours=0;
      }
      setHours(hours);
      setHasSearchMsgDate(false)
    }
  }

  const dateClose = () => {
    if (props.dateChanged) {
      localStorage.setItem('startDateTime', selectedDateStart.toISOString(true));
      localStorage.setItem('endDateTime', selectedDateEnd.toISOString(true));
      let searchType: boolean = isExactSearch === 'exact' ? true : false;
      setSearchTypeMsg();
      props.dateChanged(moment(localStorage.getItem('startDateTime')), moment(localStorage.getItem('endDateTime')), searchType);
    }
  }

  const en = {
    ...english,
    firstDayOfWeek: 1
  }

  useEffect(() => {
    /* let language = localStorage.getItem('language');
    if(language)
      setLang(language) */
    if (!localStorage.getItem('startDateTime'))
      localStorage.setItem('startDateTime', selectedDateStart.toISOString(true));
    if (!localStorage.getItem('endDateTime'))
      localStorage.setItem('endDateTime', selectedDateEnd.toISOString(true));
     //displayErrorMsg();
    if (props.searchType) {
      setIsExactSearch(props.searchType)
    }
    setSearchTypeMsg()

  }, []);



  useEffect(() => {
   setSearchTypeMsg();
   displayErrorMsg();
   if(props.setOfficeMaxandMinDate){
     props.setOfficeMaxandMinDate(selectedDateStart,selectedDateEnd)
   }
  }, [selectedDateEnd,selectedDateStart])

  useEffect(() => {
    if(context?.changeSearchType)
    {
      context.changeSearchType(isExactSearch)
    }
    if(props.onExactChanged){
      props.onExactChanged(isExactSearch)
    }
    localStorage.setItem("searchType",isExactSearch)
    displayErrorMsg()
    
  }, [isExactSearch])

  function displayErrorMsg() {
    if(moment(selectedDateStart.toString()).isAfter(selectedDateEnd.toString())) {
      setStartError(true)
      setStartErrorMsg(t('FILTERBAR.7'));
      setEndErrorMsg(t('FILTERBAR.8'));
      setEndError(true);
    }
    else if(selectedDateStart.isSame(selectedDateEnd,'day') && selectedDateStart.isSame(selectedDateEnd,'hour') && selectedDateStart.isSame(selectedDateEnd,'minute')){
      setStartError(true)
      setStartErrorMsg(t('FILTERBAR.7'));
      setEndError(true);
      setEndErrorMsg(t('FILTERBAR.8'));
    }
    else if(selectedDateStart.isSame(selectedDateEnd,'day')){
      if(
          (selectedDateStart.isSame(selectedDateEnd,'hour') && selectedDateEnd.get('minute')<selectedDateStart.get('minute') + 15)  ||
          (selectedDateEnd.get('hour')===selectedDateStart.get('hour')+1 && (selectedDateEnd.get('minute')<(selectedDateStart.get('minute') + 15-60)))) {
        setEndError(true);
        setStartError(true);
        setStartErrorMsg(t('FILTERBAR.13'));
        setEndErrorMsg(t('FILTERBAR.13'));
        notifyError(t('FILTERBAR.13'));
      }
      else {
        setStartError(false);
        setStartErrorMsg('');
        setEndError(false);
        setEndErrorMsg('');
        dateClose();
      }
    }
    // else if(selectedDateEnd.diff(selectedDateStart,'days') >= 1){
    //   if(selectedDateEnd.get('hour')<=selectedDateStart.get('hour')){
    //     setEndError(true);
    //     setStartError(true);
    //     setStartErrorMsg(t('FILTERBAR.13'));
    //     setEndErrorMsg(t('FILTERBAR.13'));
    //     notifyError(t('FILTERBAR.13'));
    //   }else {
    //     setStartError(false);
    //     setStartErrorMsg('');
    //     setEndError(false);
    //     setEndErrorMsg('');
    //     dateClose();
    //   }
    //
    // }
    else {
      setStartError(false);
      setStartErrorMsg('');
      setEndError(false); 
      setEndErrorMsg('');
      dateClose();
    }
  }


  return (
    <div className="filter-bar-main">
      <Grid container alignItems="center">
        <Grid item xs={false} md={false} lg={1} ></Grid>
        <Grid item xs={12} md={4} lg={4} className="search-type">
          <FormControl component="fieldset" disabled={!props.isSearchTypeEnabled}>
            <RadioGroup aria-label="searchParams" name="isExactSearch" value={isExactSearch} onChange={onExactChanged}>
              <FormControlLabel value={'exact'} control={<Radio color="primary" />} label={<Typography variant="body2">{t('FILTERBAR.10')}</Typography>} />
              <FormControlLabel value={'anytime'} control={<Radio color="primary" />} label={<Typography variant="body2">{hasSearchMsgDate ? t('FILTERBAR.12',{hours:hours,date:selectedDateEnd}):t('FILTERBAR.11',{hours:hours,date:selectedDateStart,date1:selectedDateEnd})}</Typography>} />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={8} lg={6} xl={6} >
          <Grid container className="form-container">
            <Grid item >
              <FormLabel className="form-label-header" component="legend">{t('FILTERBAR.5')}: </FormLabel>
            </Grid>
            <Grid item style={{marginRight:16}}>
              <Flatpickr
                options={{
                  dateFormat: "d-m-Y H:i",
                  defaultDate: selectedDateStart.format("DD-MM-YYYY HH:mm"),
                  enableTime: true,
                  minuteIncrement:15,
                  disable: [
                    function(date) {
                        // return true to disable //number array.includes(getday)
                        return (props.disabledDays ? props.disabledDays.includes(date.getDay()):0);
                    }
                  ],
                  minTime:props.startMinTime,
                  minDate:moment().format("DD-MM-YYYY"),
                  maxTime:props.startMaxTime,
                  time_24hr: true,
                  //minuteIncrement:30,
                  locale: context && context.lang === 'en' ? en : German,
                }}
                onChange={date => {
                  setStartDate(moment(date[0]));
                }}
                render={({ defaultValue }, ref) => {
                  return (
                    <div>
                      <TextField
                        style={{ marginRight: "16px" }}
                        color="secondary"
                        label={t('FILTERBAR.1')}
                        InputProps={{
                          endAdornment: <InputAdornment position="end"><CalendarTodayIcon fontSize="small" color="primary" /> </InputAdornment>,
                        }}
                        value={selectedDateStart.format("DD-MM-YYYY HH:mm")} ref={ref}
                      />

                    </div>
                  )
                }}
              />{startError ? <small style={{color:'red'}}> {startErrorMsg} </small>:null}
            </Grid>
            <Grid item>
              <Flatpickr
                options={{
                  dateFormat: "d-m-Y H:i",
                  defaultDate: selectedDateEnd.format("DD-MM-YYYY HH:mm"),
                  enableTime: true,
                  minuteIncrement:15,
                  disable: [
                    function(date) {
                        // return true to disable //number array.includes(getday)
                        return (props.disabledDays ? props.disabledDays.includes(date.getDay()):0);
                    }
                  ],
                  minTime:props.endMinTime,
                  maxTime:props.endMaxTime,
                  time_24hr: true,
                  locale: context && context.lang === 'en' ? en : German,
                  minDate: selectedDateStart.format("DD-MM-YYYY HH:mm")
                }}
                onChange={date => {
                  setEndDate(moment(date[0]));
                }}
                render={({ defaultValue }, ref) => {
                  return (
                    <div>
                      <TextField
                        label={t('FILTERBAR.2')}
                        color="secondary"
                        value={selectedDateEnd.format("DD-MM-YYYY HH:mm")}
                        ref={ref}
                        InputProps={{
                          endAdornment: <InputAdornment position="end"><CalendarTodayIcon fontSize="small" color="primary" /> </InputAdornment>,
                        }}
                      />

                    </div>
                  )
                }}
              />{endError ? <small style={{color:'red'}}> {endErrorMsg} </small>:null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
