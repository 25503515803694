import React from 'react';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { withTranslation, WithTranslation } from 'react-i18next';
import { navigate } from '../../history/navigate';
import { notifySuccess, notifyError } from '../notification/Notification';
import { AppContext } from "../../middlewares/appContext/AppContext";
import { Button, Grid,Typography } from '@material-ui/core';
import ClientsTable from './ClientsTable';

import { EditOutlined, PersonAddOutlined } from '@material-ui/icons';
import { Client } from '../../interfaces/Client';

interface State {
    
    clients:Array<Client>

}

interface Props {
    
}


class ClientManagement extends React.Component<WithTranslation & Props, State> {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        
        this.state = {
          clients:[]
        };

    }

    getAllClients = () => {

        HTTP_REQUESTS.USERADMIN_API.GET_ALL_CLIENTS((res)=>{
            this.setState({clients:res})
        },(err)=> {
            notifyError(this.props.t("CLIENT.13"))
        })
    }
    
    onClickEditClient = (id:string,editObj) => {

        HTTP_REQUESTS.USERADMIN_API.UPDATE_CLIENT(id,editObj, (success) => {
            const index = this.state.clients.findIndex(client => success.id === client.id),
            clients = [...this.state.clients] // important to create a copy, otherwise you'll modify state outside of setState call
            clients[index] = success;
            this.setState({clients});
            notifySuccess(this.props.t("CLIENT.10"));
            }, (error) => {
            notifyError(this.props.t("CLIENT.11"));
            })
    }

    removeUserFromState(e) {
        let filteredArray = this.state.clients.filter(item => item.id !== e)
        this.setState({clients: filteredArray});
    }

    deleteClientClick = (clientId) => {

        HTTP_REQUESTS.USERADMIN_API.DELETE_CLIENT(clientId,(res) => {
            notifySuccess(this.props.t("CLIENT.18"));
            this.removeUserFromState(clientId);
        }, (err) => {
            notifyError(err.message);
        })
    }

    onClickAddClient = () => {
        navigate("/clients/new");
    }
    
    componentDidMount() {
        const context: any = this.context;

        if(!(context?.user.permissions && context?.user.permissions.includes('MANAGE_CLIENTS'))){
            navigate('/dashboard');
        }else {
            this.getAllClients();
        }

    }

    render() {
        const {t} = this.props
        return (
            <div className="clients-form" >
                <Grid container justify="space-around" xs={12}>
                    <Grid item >
                    <Typography className="client-header" variant="h5" color ="secondary">{t("CLIENT.1")}</Typography>
                    </Grid>    
                    <Grid item >
                        <Button variant="contained" onClick={this.onClickAddClient} className="add-client-btn"> <PersonAddOutlined className="add-icon" color="primary"/> {t("CLIENT.2")} </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2} justify="center" xs={12}>
                    <Grid item xs={12} md={6} >
                    <ClientsTable onClickEdit={this.onClickEditClient} data={this.state.clients} deleteClientClick={this.deleteClientClick}/>
                    </Grid>
                </Grid>
            </div>
        );
    }

}
export default withTranslation()(ClientManagement);
