import React, { useEffect, useState } from 'react';
import { Button, Typography, Grid, FormControl, InputLabel, Select, MenuItem, TextField, FormControlLabel, FormLabel, Checkbox, FormHelperText } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { navigate } from '../../history/navigate';
import { useTranslation } from 'react-i18next';
import { Clear, EditOutlined, HelpOutline, HelpOutlined, SaveOutlined } from '@material-ui/icons';
import { Client } from '../../interfaces/Client';
import { AppContext } from "../../middlewares/appContext/AppContext";
import {RolesHelperModal} from "./RolesHelperModal";


interface userObj {
    email: string,
    enabled?: boolean,
    firstname: string,
    lastname: string,
    role: string,
    salutation: string,
    password: string,
    client:{
        id:string,
        name:string
    }
}

interface Props {
    onSubmit: (userObj) => void;
    user?: userObj,
    clients: Array<Client>
    isClientAdmin: boolean
    errorMessage: string
    emailError: boolean
    isLoading: boolean
}

export const UserCrudForm: React.FC<Props> = (props) => {
    const { t, i18n } = useTranslation();
    const { onSubmit, user, errorMessage, emailError, isLoading } = props

    const context = React.useContext(AppContext);


    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    document.title = t('TITLE.8');

    const checkObjNull = (obj) => {

        return Object.keys(obj).length === 0 && obj.constructor === Object
    }

    const checkPass = () => {
        if (!checkObjNull(user)) {
            return "********"
        }
        else {
            return ""
        }
    }

    const checkRole = () => {
        if(!checkObjNull(user)) {

            if(user?.client)
            {
                return user.client.id
            }
            else {
                return ""
            }
        } else {
            return ""
        }
    }

    useEffect (()=>{
        if(props.isClientAdmin)
        {
            if(context?.user?.client)
            {
                setSelectedClient(context?.user.client.id)
            }
            
            setRole("CLIENT_USER")
        }
    },[])

    const [email, setEmail] = useState(user?.email);
    const [password, setPassword] = useState(checkPass);
    const [salutation, setSalutation] = useState(user?.salutation);
    const [firstName, setFirstName] = useState(user?.firstname);
    const [lastName, setLastName] = useState(user?.lastname);
    const [role, setRole] = useState(user?.role || 'CLIENT_USER');
    const [enabled, setEnabled] = useState(user?.enabled || false);
    const [selectedClient, setSelectedClient] = useState(checkRole());
    const [savDisabled, setSaveDisabled] = useState(true);

    const [emailErr, setEmailErr] = useState(false);
    const [salutationErr, setSalutationErr] = useState(false);
    const [firstNameErr, setFirstNameErr] = useState(false);
    const [lastNameErr, setLastNameErr] = useState(false);
    const [enabledErr, setEnabledErr] = useState(false);
    const [openHelper, setOpenHelper] = useState(false);
    const [clientErr, setClientErr] = useState(false);

    const [emailErrText, setEmailErrText] = useState("");
    const [salutationErrText, setSalutationErrText] = useState("");
    const [firstNameErrText, setFirstNameErrText] = useState("");
    const [lastNameErrText, setLastNameErrText] = useState("");
    const [enabledErrText, setEnabledErrText] = useState("");
    const [clientErrText, setClientErrText] = useState("");

    useEffect(() => {
        if (email !== user?.email || password !== checkPass() || salutation !== user?.salutation ||
            firstName !== user?.firstname || lastName !== user?.lastname || role !== user?.role ||
            enabled !== user?.enabled || selectedClient !== checkRole()){
            setSaveDisabled(false)
        }else {
            setSaveDisabled(true)
        }
    }, [email,password,salutation,firstName,lastName,role,enabled,selectedClient]);

    const roleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRole((event.target as HTMLInputElement).value);

    };

    const enabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnabled(!enabled);
        setEnabledErr(false);
        setEnabledErrText("");
    }

    const emailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setEmailErr(false);
        setEmailErrText("");
    }

    const passwordChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    }

    const salutationChanged = (e: any) => { //TODO: Found suitable solution for onChange Event
        e.preventDefault();
        setSalutation(e.target.value);
        setSalutationErr(false);
        setSalutationErrText("");
    }

    const clientChanged = (e: any) => { //TODO: Found suitable solution for onChange Event
        e.preventDefault();
        setSelectedClient(e.target.value);
        setClientErr(false);
        setClientErrText("")
    }

    const firstNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
        setFirstNameErr(false);
        setFirstNameErrText("");
    }

    const lastNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value);
        setLastNameErr(false);
        setLastNameErrText("");
    }


    const backButtonClick = () => {
        navigate('/users');
    }

    const handleOpenHelper = () => {
        setOpenHelper(true)
    }

    const handleCloseHelper = () => {
        setOpenHelper(false)
    }



    const onSubmitClick = () => {

        let formValid = true;

        if (salutation === ' ' || salutation == null) {
            setSalutationErr(true)
            formValid = false;
            setSalutationErrText(t('INPUT_VALIDATIONS.1'))
        }
        if (email === '' || email == null) {
            setEmailErr(true)
            formValid = false;
            setEmailErrText(t('INPUT_VALIDATIONS.2'))
        }
        else if (email) {
            if (!re.test(email)) {
                setEmailErr(true)
                formValid = false;
                setEmailErrText(t('INPUT_VALIDATIONS.3'))
            }
        }
        if (firstName === '' || firstName == null) {
            setFirstNameErr(true)
            formValid = false;
            setFirstNameErrText(t('INPUT_VALIDATIONS.4'))
        }
        if (lastName === '' || lastName == null) {
            setLastNameErr(true)
            formValid = false;
            setLastNameErrText(t('INPUT_VALIDATIONS.5'))
        }
        if(role === "CLIENT_ADMIN" && selectedClient===""){
            formValid=false;
            setClientErr(true);
            setClientErrText(t('INPUT_VALIDATIONS.10'))
        }
        if (formValid) {
            let newUserObj = {
                email: email,
                enabled: enabled,
                firstname: firstName,
                lastname: lastName,
                role: role,
                salutation: salutation,
                password: password,
                clientId: selectedClient,
                newImageId:""
            }
            //console.log(newUserObj)
            onSubmit(newUserObj);
        }
    }

    return (
        <div className="user-crud-container">
            <Grid container spacing={2} direction="column" className="wrapper">
                <Grid container className="header">
                    <Typography variant="h5" className="user-header">{!checkObjNull(user) ? `${user?.firstname}  ${user?.lastname}` : t('USERCRUD.15')}</Typography>
                </Grid>
                <Grid container  spacing={2} >
                    <Grid container  spacing={2} xs={11}>

                    </Grid>
                    <Grid container  spacing={2} xs={1} alignItems="flex-start" direction="column" className="button-group">
                        <Grid item >
                            <Button className="edit-btn" variant="contained" onClick={onSubmitClick} disabled={isLoading || savDisabled} > <SaveOutlined fontSize="small" className="edit-icon" color="primary" /> {t('USERCRUD.10')}</Button>
                        </Grid>
                        <Grid item >
                            <Button className="cancel-btn" variant="outlined" onClick={backButtonClick} disabled={isLoading} > <Clear fontSize="small" className="cancel-icon" color="primary" /> {t('USERCRUD.19')}</Button>
                        </Grid> 
                    </Grid>
                </Grid>
                          
                <form className="user-info-form" noValidate>
                    <Grid container spacing={3} direction="row" className="first-row">

                        <Grid className="select-grid" item xs={12} >
                            <FormControl className="select"
                                color="secondary"
                                error={salutationErr}
                            >
                                <InputLabel>{t('USERCRUD.1')}</InputLabel>
                                <Select
                                    onChange={salutationChanged}
                                    defaultValue={salutation}
                                    inputProps={{ 'aria-label': '1' }}
                                >
                                    <MenuItem value={' '}><em>{t('USERCRUD.14')}</em></MenuItem>
                                    <MenuItem value={"Mr"}>{t('USERCRUD.11')}</MenuItem>
                                    <MenuItem value={"Mrs"}>{t('USERCRUD.12')}</MenuItem>
                                </Select>
                                <FormHelperText color="primary" >{salutationErr ? salutationErrText : null}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className="unset-left-padding">
                            <TextField
                                autoComplete="off"
                                name="firstName"
                                fullWidth
                                id="firstName"
                                label={t('USERCRUD.2')}
                                color="secondary"
                                value={firstName}
                                onChange={firstNameChanged}
                                error={firstNameErr}
                                helperText={firstNameErrText}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                fullWidth
                                id="lastName"
                                label={t('USERCRUD.3')}
                                name="lastName"
                                autoComplete="off"
                                color="secondary"
                                value={lastName}
                                onChange={lastNameChanged}
                                error={lastNameErr}
                                helperText={lastNameErrText}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="second-row">
                        {
                            props.isClientAdmin?<Grid item xs={4}></Grid>:
                            <Grid className="select-grid" item xs={4} >
                            <FormControl className="select"
                                color="secondary"
                            >
                                <InputLabel>{t("USERSTABLE.15")}</InputLabel>
                                <Select
                                    onChange={clientChanged}
                                    defaultValue={selectedClient}
                                    inputProps={{ 'aria-label': '1' }}
                                    error={clientErr}
                                >
                                    <MenuItem value={""}><em>{"Client"}</em></MenuItem>
                                    {   
                                        props.clients.map(client =>
                                            <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>
                                        )
                                    }

                                </Select>
                                <FormHelperText color="primary" >{clientErr ? clientErrText : null}</FormHelperText>
                            </FormControl>
                        </Grid>
                        }
                        

                        <Grid item xs={8} className="unset-left-padding">
                            <TextField
                                color="secondary"
                                fullWidth
                                id="email"
                                label={t('USERCRUD.4')}
                                name="email"
                                autoComplete="off"
                                value={email}
                                className="mail-input"
                                onChange={emailChanged}
                                error={emailErr || emailError}
                                helperText={emailErr? emailErrText : emailError? errorMessage : null}
                            />
                            <TextField
                                color="secondary"
                                fullWidth
                                id="password"
                                type="password"
                                label={t('USERCRUD.5')}
                                name="password"
                                autoComplete="off"
                                value={password}
                                className="password-input"
                                onChange={passwordChanged}
                            />
                        </Grid>

                    </Grid>
                    <Grid container direction="row" justify="flex-end" className="radio-group">
                        <Grid container xs={8} justify="space-between">
                            <Grid item xs={10}>
                                <FormControl component="fieldset" >
                                    <FormLabel component="legend">{t('USERCRUD.6')}</FormLabel>
                                    <RadioGroup row aria-label="Rolle" name="role" value={role} onChange={roleChanged} style={{ justifyContent: "space-evenly" }} >
                                        {
                                            props.isClientAdmin ? 
                                                <div>
                                                    <FormControlLabel value="CLIENT_ADMIN" control={<Radio color="primary" />} label={t('USERCRUD.22')} />
                                                    <FormControlLabel value="CLIENT_USER" control={<Radio color="primary" />} label={t('USERCRUD.23')} /> 
                                                </div> :
                                                <div>
                                                    <FormControlLabel value="USER" control={<Radio color="primary" />} label={t('USERCRUD.7')} />
                                                    <FormControlLabel value="ADMIN" control={<Radio color="primary" />} label={t('USERCRUD.8')} />
                                                    <FormControlLabel value="CLIENT_ADMIN" control={<Radio color="primary" />} label={t('USERCRUD.22')} />
                                                    <FormControlLabel value="CLIENT_USER" control={<Radio color="primary" />} label={t('USERCRUD.23')} />
                                                </div>
                                        }
                                        <div>
                                            <Button
                                            onClick={handleOpenHelper}
                                            color="primary"
                                            >
                                        <HelpOutlined />
                                        </Button>
                                        </div>


                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={2} className="user-status-grid">
                                <FormControlLabel control={<Checkbox checked={enabled} value={enabled} color="primary" onChange={enabledChange} />} label={t('USERCRUD.13')} />
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                <RolesHelperModal

                            handleOpen={handleOpenHelper}
                            handleClose={handleCloseHelper}
                            open={openHelper}
                            
                        />
            </Grid>
        </div >
    );

}
