import React from 'react'
import { TablePagination, TablePaginationProps } from '@material-ui/core';

export function PatchedPagination(props) {
  const {
    ActionsComponent,
    onPageChange,
    onRowsPerPageChange,
    ...tablePaginationProps
  } = props;

  return (
    <TablePagination
      {...tablePaginationProps}
      // @ts-expect-error onChangePage was renamed to onPageChange
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      ActionsComponent={(subprops) => {
        const { onPageChange, ...actionsComponentProps } = subprops;
        return (
          // @ts-expect-error ActionsComponent is provided by material-table
          <ActionsComponent
            {...actionsComponentProps}
            onPageChange={onPageChange}
          />
        );
      }}
    />
  );
}
