import React, { useState, useEffect, useContext } from 'react';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { navigate } from '../../history/navigate';
import { notifySuccess, notifyError } from '../notification/Notification';
import { Typography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BookingTable from './BookingTable';
import { Moment } from 'moment';
import { AppContext } from "../../middlewares/appContext/AppContext";
import {useLocation} from "react-router-dom";
import {removeNullandUndefined} from '../utils/Utils';

interface Bookings {
  id:string,
  officeName:string,
  floorName:string,
  seatName:string
  startDateTime:Moment,
  endDateTime:Moment,
  rrule:string,
  recurringBookingId:string
}

export interface BookingFilter {
  floorId?: string,
  officeId?: string,
  seatId?: string,
  size?: number
  page?: number
  from?: string,
  to?: string,
}

interface Props {
 
}
 
export const Bookings: React.FC<Props> = (props) => {
  const search = useLocation().search;
  const [isReady, setIsReady] = useState(false);
  const [filterCreated, setFilterCreated] = useState(false);
  const [filter, setFilter] = useState<BookingFilter>({
    floorId:'',
    officeId:'',
    seatId: '',
    page:0,
    size:5,
    from:'',
    to: ''
  });
  const { t, i18n } = useTranslation();
  const dateFormat:string = "DD-MM-YYYY HH:mm";
  const [bookings, setBookings] = useState(Array<Bookings>());
  const [totalBookings, setTotalBookings] = useState<number>(0);
  document.title = t('TITLE.10');

  const context = useContext(AppContext);

  const createFilter = (() => {
    const searchParams = new URLSearchParams(search);
    const _filter = {
      floorId: searchParams.get('floorId'),
      seatId: searchParams.get('seatId'),
      officeId: searchParams.get('officeId'),
      page: filter.page,
      size: filter.size,
    } as BookingFilter;
    setFilter(_filter);
    setFilterCreated(true);
  });

  useEffect(() => {
    createFilter();
  },[search]);

  useEffect(() => {
    if(filterCreated) {
      getBookings();
    }
  },[filter]);

  const getBookings = () => {
    let _filter : BookingFilter = removeNullandUndefined(filter);
    if(_filter.to){
      _filter.to = new Date(new Date(_filter.to).setUTCHours(23, 59, 59)).toISOString();
    }
    HTTP_REQUESTS.CLIENT_API.GET_BOOKINGS(_filter , (res)=>{
      setTotalBookings(res.pagination.totalElements)
      setBookings(res.content)
      setIsReady(true)
    },(err)=>{

    })
  }

  const onClickEdit=(id)=>{
    navigate("/bookings/"+id)
  }

  const onClickInfo = (officeId,floorId)=> {
   navigate("/officereservation/"+officeId+"/"+floorId);
  }

  const removeBookingFromState = (e) => {
    let filteredArray = bookings.filter(item => item.id !== e)
    setBookings(filteredArray);
  }

  const sortOrder = ["id", "user", "officeName", "floorName", "seatName", "seatFeatures", "roomFeatures", "startDate", "endDate"]

  const onClickDelete = (id)=> {
      HTTP_REQUESTS.CLIENT_API.CANCEL_BOOKING(id,(res) => {
        removeBookingFromState(id)
        getBookings();
        notifySuccess(t("BOOKINGSTABLE.BookingCancelled"))
      },(err)=>{
        notifyError(t("BOOKINGSTABLE.BookingCancelFailed"))
      })
  }

  const onClickRecurrentDelete = (id)=> {
    HTTP_REQUESTS.CLIENT_API.CANCEL_ALL_RECURRENT_BOOKINGS(id,(res) => {
      getBookings();
      removeBookingFromState(id)
      notifySuccess(t("BOOKINGSTABLE.BookingCancelled"))
    },(err)=>{
      notifyError(t("BOOKINGSTABLE.BookingCancelFailed"))
    })
  }

 const onChangePage = (page: number) => {
    const tempFilter = {...filter, page:page};
    if(JSON.stringify(tempFilter) != JSON.stringify(filter)) {
      setFilter(tempFilter);
    }
 }

 const onChangeSize = (size: number) => {
   const tempFilter = {...filter, size:size};
   if(JSON.stringify(tempFilter) != JSON.stringify(filter)) {
     setFilter(tempFilter);
   }
 }

 const onFilterChange = (filterData: any) => {
   setFilter({...filter,
     from: (filterData.from) ? new Date(filterData.from).toISOString() : '',
     to: (filterData.to) ? new Date(filterData.to).toISOString() : ''})
 }

 const onOrderChange = (orderBy: number, orderDirection: "desc" | "asc") => {
  console.log(orderBy, orderDirection)
    const tempFilter = {...filter, sort:`${sortOrder[orderBy]},${orderDirection}`};
    console.log(tempFilter)
    if(JSON.stringify(tempFilter) != JSON.stringify(filter)) {
      setFilter(tempFilter);
    } 
  }

 return (
  isReady ? <div className="booking-wrapper">
    <Grid container spacing={2} direction="column" className="booking-container">
        <Grid container >
          <Typography  variant="h5" className="booking-header">{context  && (context.user.role === "ADMIN" || context.user.role === "CLIENT_ADMIN") ? t('APPBAR.3') :t('BOOKINGS.10')}</Typography>
        </Grid>
        <Grid item className="booking-table">
          <BookingTable
            onChangeSize={onChangeSize}
            onChangePage={onChangePage}
            onOrderChange={onOrderChange}
            filter={filter}
            onFilterChange={onFilterChange}
            data={bookings} totalBookings={totalBookings} onClickDelete={onClickDelete} onClickRecurredDelete={onClickRecurrentDelete} onClickShow = {onClickInfo} />
        </Grid>
    </Grid>
  </div> : null
  );

}
