import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
    open:boolean,
    isRecurred:boolean,
    header: string,
    confirm: string,
    confirmAll?: string,
    cancel: string,
    text: string,
    textAll?: string,
    confirmCB: (id: string) => void,
    confirmCBAll?: (id: string) => void,
    cancelCB: () => void,
    confirmRecurring?: string,
    id?: string,
    toDeleteObject?: any
}

export const CustomConfirmDialog: React.FC<Props> = (props) => {

    const { open, header, confirm, cancel, text, confirmCB, cancelCB, confirmCBAll, confirmAll, textAll, confirmRecurring, isRecurred, toDeleteObject, id } = props;
    const generateInformation = () => {
        return (isRecurred) ? textAll : text;
    }
    const generateConfirm = () => {
        return (isRecurred) ? confirmRecurring : confirm;
    }
    const confirmOne = () => {
        if(toDeleteObject && confirmCB){
            confirmCB(toDeleteObject.id);
            cancelCB();
        }
        else if (id && confirmCB) {
            confirmCB(id);
            cancelCB();
        }
    }
    const confirmAllCB = () => {
        if(confirmCBAll && toDeleteObject)
        {
            confirmCBAll(toDeleteObject.recurringBookingId);
        }
        cancelCB();
    }
    
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="dialog-slide-title"
                aria-describedby="dialog-slide-description"
            >
                <DialogTitle id="dialog-slide-title">{header}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-slide-description">
                        {generateInformation()}
                    </DialogContentText>
                </DialogContent>


                <DialogActions>
                    <Button onClick={cancelCB} color="secondary" variant="text">
                        {cancel}
                    </Button>
                    <Button onClick={confirmOne}  color="primary" variant="contained">
                        {confirm}
                    </Button>
                    {isRecurred ?
                    <Button onClick={confirmAllCB}  color="primary" variant="contained">
                        {confirmAll}
                    </Button> : null}
                </DialogActions>

            </Dialog>
        </div>
    );
}
