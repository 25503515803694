import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { ReactComponent as FilterTempIcon } from './filter-solid.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

interface Props{
    color?:string
}

export default function FilterIcon(props: SvgIconProps & Props) {
  return (
    <SvgIcon component={FilterTempIcon} color={props.color} viewBox="0 0 600 476.6" ></SvgIcon>
  );

}

