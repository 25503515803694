import React, { useContext,useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { AppContext } from "../../middlewares/appContext/AppContext";
import {HTTP_REQUESTS} from '../../backendcomm/services/httpRequestService';
import { notifyError } from '../notification/Notification';
import { ObjectList } from '../objectList/ObjectList';
import { Office } from '../../interfaces/Office';
import moment, { Moment } from 'moment';
import { getShortOfficeArray } from '../utils/Utils';

interface Props {

}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
  }),
);

export const Properties: React.FC<{}> = () => {
    const { t, i18n } = useTranslation();
    const context = useContext(AppContext);
    const classes = useStyles();

    const [objArray,setObjArray] = useState(Array<Office>());
    const [startDateTime, setStartDateTime] = React.useState<Moment>(moment().add(10, "years"));
    const [endDateTime, setEndDateTime] = React.useState<Moment>(moment().add(10, "years"));

    document.title = t('TITLE.7');    

  useEffect(() => {
    let interval = {
        startDateTime: startDateTime.toISOString(),
        endDateTime: endDateTime.toISOString()
    }
   
    HTTP_REQUESTS.OFFICE_API.GET_DETAILS_ALL_OFFICES((res)=> {
      setObjArray(res);
      if(context && context.updateAllOffices)
      {
        let shortOffices = getShortOfficeArray(res)
        localStorage.setItem("ShortOffices",JSON.stringify(shortOffices))
        context.updateAllOffices(shortOffices)
      }
    },err=> {
      notifyError(t('PROPERTY.1'));
    })
  }, []);

    return (
        <div className={classes.root}>  
          <ObjectList data= {objArray} />
        </div>
    )
}