import React,{useEffect} from 'react'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';


interface Props {
    label?:string,
    onClick?: () => void,
    disabled?:boolean,
    width?:string
}

const theme = createMuiTheme({
    palette: {
      primary: {
        main: `${process.env.REACT_APP_BUTTON_COLOR}`,
      }
    },
  });

const GenericButton:React.FC<Props> = (props)=>{

    const {t} = useTranslation();
    const { label,onClick,disabled,width } = props;

    return (
        <ThemeProvider theme={theme}>
            <Button disabled={disabled} style={{textTransform:"none",paddingLeft:"6px",paddingRight:"6px", width: width ? width : "unset"}} onClick={onClick} size="medium" color="primary" variant="contained">{t(`${label}`)}</Button>
        </ThemeProvider>
    )
}


export default GenericButton

