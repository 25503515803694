import React, { Component, useState, useEffect } from 'react';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { navigate } from '../../history/navigate';
import { notifySuccess, notifyError } from '../notification/Notification';
import { Grid, Typography, TextField, Button, Paper, Link, FormControlLabel } from '@material-ui/core';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { useTranslation } from 'react-i18next';

interface Props {

}

export const TenantNotFound: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();
  document.title = t('TITLE.4');    

  const [domainInput, setDomainInput] = useState("");

  const domainInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomainInput(e.target.value);
  }

  const onClickForgot = () => {
    navigate('/forgotDomain')
  }

  const redirectUser = () => {
     let subdomain = ""

    if (window.location.hostname.endsWith(".localhost")) { //TODO: should be disabled before deployment
      subdomain = window.location.hostname.substring(0, window.location.hostname.length - 10);
    }
    else if(window.location.hostname.endsWith(".app.desk-hero.de"))
    {
      subdomain = window.location.hostname.substring(0, window.location.hostname.length - 22);
    }
    
     if (subdomain!.length >= 1) {
       let toRedirect = window.location.origin.replace(subdomain, domainInput)
       window.open(toRedirect, '_self');
     }
     else if (subdomain!.length < 1) {
       let toRedirect = window.location.protocol  + '//'+ domainInput + '.' + window.location.host;
       window.open(toRedirect, '_self');
     }
  }

  const onEnterPress = (e) => {
    if (e.key === 'Enter'){
      onClickSubmitDomain();
    }
  }

  const onClickSubmitDomain = () => {

    let domainRequestObj = {
      subdomain:domainInput
    }
    HTTP_REQUESTS.TENANT_API.GET_TENANT_URL(domainRequestObj,(res)=> {
      
    redirectUser();

    },(err) =>{
      console.log(err);
      notifyError(err.message);
    })
  }


  return (

    <Grid item onKeyPress={onEnterPress} xs={12} sm={8} md={5} component={Paper} elevation={6} >

      <div className="paper">
        <Grid container direction="column" justify="center" alignItems="center" className="tenant-not-found">
          <ReportProblemIcon className="not-activated-icon" color="primary" />
          <Typography variant="h5" >{t('TENANTNOTFOUND.1')}</Typography>

          <Grid className="form" >
            <Grid item xs={12}>
              <Typography align="center" variant="body1" >{t('TENANTNOTFOUND.2')}</Typography>
              <TextField
                color="secondary"
                required
                fullWidth
                name="domain"
                label="Domain"
                id="domain"
                autoComplete="domain"
                value={domainInput}
                onChange={domainInputChanged}
              />

            </Grid>
            <Grid item xs={12} className="button-grid">
              <Button type="button" color="primary" variant="contained" onClick={onClickSubmitDomain} className="submit" >{t('TENANTNOTFOUND.4')}</Button >
            </Grid>
            <Grid container justify="flex-end">
              <Link onClick={onClickForgot}/* style={{"cursor":"pointer"}}*/ >
                {t('TENANTNOTFOUND.3')}
              </Link>
            </Grid>
          </Grid>

        </Grid>

      </div>
    </Grid>
  )



}