import React from 'react';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { LoginForm } from './LoginForm';
import {  notifyError } from '../notification/Notification';

interface Props {

}

export const Login: React.FC<Props> = () => {

    const submit = (email, password) => {

        let loginData={
            email:email,
            password:password
        };

        HTTP_REQUESTS.TENANT_API.POST_LOGIN(loginData, (res) => {
                window.location.reload();
            }, (err) => {
                notifyError(err.message);
            });


    }

    return (<LoginForm onSubmitClick={submit} />)
   
}