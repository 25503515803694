import React, { Component, useState, useEffect } from 'react';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { RegistrationSuccessCompleted } from './RegistrationSuccessCompleted';
import { RegisterConfirmError } from './RegisterConfirmError';
import { navigate } from '../../history/navigate';
import { Buffer } from 'buffer';
import { Console } from 'console';
import { findAllInRenderedTree } from 'react-dom/test-utils';


interface Props {

}

export const RegisterConfirm: React.FC<any> = (props) => {
  const [verified, setVerified] = useState(Boolean);
  const [requestReturned, setrequestReturned] = useState(false);

  let code = "";

  try {
    let url = window.location.href;
    let searchParams = url.substring(url.indexOf("?") + 1);
    code = searchParams.replace('code=', '');
  } catch (error) {

  }
  //alert(code);
  useEffect(() => {
    console.log({ token: code });
    HTTP_REQUESTS.TENANT_API.GET_TENANT_REGISTIRATION_CODE_CONFIRM({ token: code }, (res) => {
      setVerified(true);
      setrequestReturned(true);
    }, (err) => {
      setVerified(false);
      setrequestReturned(true);

    });

  }, []);



  if (requestReturned) {
    if (verified) {
      return (<RegistrationSuccessCompleted />)
    } else {
      return (<RegisterConfirmError />)
    }
  } else {
    return null;
  }


}