import React, { useState, useEffect } from 'react';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { navigate } from '../../history/navigate';
import { UserCrudForm } from './UserCrudForm'; 
import { notifySuccess, notifyError } from '../notification/Notification';
import { Client } from '../../interfaces/Client';
import { AppContext } from "../../middlewares/appContext/AppContext";
import {useTranslation} from "react-i18next";

interface Props {
 
}
 
export const UserCrud: React.FC<Props> = () => {

  const { t } = useTranslation();
  const context = React.useContext(AppContext);

  const [user, setUser] = useState(Object);
  const [userId, setUserId] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [clients, setClients] = useState(Array<Client>());
  const [clientId, setClientId] = useState("");
  const [isClientAdmin, setIsClientAdmin] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [emailError, setEmailError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const submitClick =(userObj) => {

    if(userId)
    {
        setIsLoading(true);
        HTTP_REQUESTS.USERADMIN_API.EDIT_USER(userId, userObj, (res) => {
            console.log(res);
            notifySuccess(t('USERCRUD.25'));
            navigate('/users');
            setIsLoading(false)
        }, (err) => {
            if( err.relatedField){
                setErrorMessage(err.message);
                switch(err.relatedField){
                    case 'email':
                        setEmailError(true);
                        break;
                    default:
                        break;
                }
            }
            if( err.errors && err.errors.length > 0)
            {
                for(let i = 0; i < err.errors.length; i++){
                    switch (err.errors[i].field) {
                        case 'email':
                            setEmailError(true);
                            //setEmailErrorText(err.errors[i].defaultMessage)
                            setErrorMessage(err.errors[i].defaultMessage);
                            break;
                        default:
                            break;
                    }
                }
            }
            notifyError(err.message)
            setIsLoading(false)
        });
    }else {
        setIsLoading(true);
        HTTP_REQUESTS.USERADMIN_API.CREATE_USER(userObj,(res)=> {
            navigate('/users');
            setIsLoading(false);
        },(err)=> {
            if( err.relatedField){
                setErrorMessage(err.message);
                switch(err.relatedField){
                    case 'email':
                        setEmailError(true);
                        break;
                    default:
                        break;
                }
            }
            if( err.errors && err.errors.length > 0)
            {
                for(let i = 0; i < err.errors.length; i++){
                    switch (err.errors[i].field) {
                        case 'email':
                            setEmailError(true);
                            //setEmailErrorText(err.errors[i].defaultMessage)
                            setErrorMessage(err.errors[i].defaultMessage);
                            break;
                        default:
                            break;
                    }
                }
            }
            notifyError(err.message);
            setIsLoading(false);
        });
    }
  }

  const checkRole = () => {

    if(context?.user.role === "ADMIN")
    {
      HTTP_REQUESTS.USERADMIN_API.GET_ALL_CLIENTS((res)=>{
        
        setClients(res)
      },(err) => {
        navigate('/users')
      })

      
   
    }
    else if(context?.user.role === "CLIENT_ADMIN") {
      if(context?.user.client)
      {
        setClientId(context.user.client.id)
        
        setIsClientAdmin(true);
      }
     
    }
 

  }
 
  useEffect(() => {
    
   
      let url = window.location.href;
      let userId = url.substring(url.lastIndexOf('/') + 1);

      if(userId!='new')
      {
        setUserId(userId)

        HTTP_REQUESTS.USERADMIN_API.GET_ONE_USER(userId, (res) => {
          setUser(res);
          checkRole();
          setIsReady(true)
          
        }, (err) => {
          navigate('/users')
        });
       
      }

      else {
        checkRole();
        setIsReady(true);
      }
      

     
    
  

   
  },[]);


 
 

    return (
      isReady ? <UserCrudForm isLoading={isLoading} onSubmit={submitClick} isClientAdmin={isClientAdmin} clients={clients} user={user} errorMessage={errorMessage} emailError={emailError} /> :null
    );

  
 
}
