import React,{useState} from 'react';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import PersonAddDisabledTwoToneIcon from '@material-ui/icons/PersonAddDisabledTwoTone';
interface Props {
 
}
 
export const RegisterConfirmError:React.FC<Props> = ()=>{
    const { t, i18n } = useTranslation();
    document.title = t('TITLE.2');

  return (
   
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} >

        <div className="register-disabled">
            <PersonAddDisabledTwoToneIcon className="disabled-icon"/>
            {t('TENANTREGISTER.16')}
        </div>
        
       
        </Grid>
  );
}