import React, { useContext, useState, useEffect,Component } from 'react';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { DetailsForm } from './DetailsForm';
import {  notifyError, notifySuccess } from '../notification/Notification';
import { FilterBar } from '../filterBar/FilterBar';
import moment, { Moment } from 'moment';
import { WithTranslation, withTranslation,useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import { AppContext } from "../../middlewares/appContext/AppContext";
import { Office } from '../../interfaces/Office';
import { Search } from '../../interfaces/Search';
import { OpeningHours } from '../../interfaces/OpeningHours';
import { CustomSpinner } from '../spinner/CustomSpinner';


interface State {
    isReady:boolean,
    startDateTime:Moment,
    endDateTime:Moment,
    startMinTime:string,
    startMaxTime:string,
    endMinTime:string,
    endMaxTime:string,
    closedDays:Array<number>,
    officeId:string,
    office:Office | null,
    searchType:string,
}

type OfficeParams = {
    id: string
};

type OfficeDetailProps = RouteComponentProps<OfficeParams>;

class DetailsNew extends React.Component<WithTranslation & OfficeDetailProps, State> {

    static contextType = AppContext;

    constructor(props) {
        
        super(props)
    
        this.state = {
            isReady:false,
            startDateTime:moment().minutes(0).seconds(0),
            endDateTime:moment().add(2,'hours').minutes(0).seconds(0),
            startMinTime:'08:00',
            startMaxTime:'18:00',
            endMinTime:'08:00',
            endMaxTime:'18:00',
            closedDays:[],
            officeId:"",
            office:null,
            searchType:'exact'
        }
        this.getOffice=this.getOffice.bind(this)
        this.dateChanged = this.dateChanged.bind(this)
        this.onExactChanged = this.onExactChanged.bind(this)
    }

    componentDidMount(){
        // if(this.context?.searchType){
        //    this.setState({searchType:this.context.searchType})
        // }
        let officeId = this.props.match.params.id
        if(officeId){
            let initialStartTimeStr = localStorage.getItem('startDateTime');
            let initialEndTimeStr = localStorage.getItem('endDateTime');
            let momentStartDateTime = initialStartTimeStr ? moment(initialStartTimeStr) : moment().minutes(0).seconds(0);
            let momentEndDateTime = initialEndTimeStr ? moment(initialEndTimeStr) : moment().add(2,'hours').minutes(0).seconds(0);
            let searchType = localStorage.getItem('searchType');
            this.setState({officeId:officeId,startDateTime:momentStartDateTime,endDateTime:momentEndDateTime,searchType:searchType ? searchType : 'exact'},()=>{
                this.getOfficeInformation();
            })
        }
    }

    getOffice = (searchObject:Search) => {
      if((moment(searchObject.startDateTime).toString() != this.state.startDateTime.toString()) ||
        (moment(searchObject.endDateTime).toString() != this.state.endDateTime.toString()) ||
        (searchObject.exactPeriod != (this.state.searchType === 'exact' ? true : false)) ||
        (this.state.isReady === false)
      ) {
        HTTP_REQUESTS.CLIENT_API.GET_OFFICE_DETAILS(searchObject,this.state.officeId,(res)=>{
          this.setState({office:res,isReady:true},()=>{
            notifySuccess(this.props.t("CANVAS.30"),"no-duplicate");
          })
        },(error)=>{
          notifyError(error.message);
        })
      }
    }

    dateChanged(startDateTime:Moment,endDateTime:Moment,searchType:boolean){
      let searchObj:Search={
        startDateTime:startDateTime.toISOString(true),
        endDateTime:endDateTime.toISOString(true),
        exactPeriod:searchType
      }
      this.getOffice(searchObj)
      localStorage.setItem('startDateTime', startDateTime.toISOString(true));
      localStorage.setItem('endDateTime', endDateTime.toISOString(true));
    }

    onExactChanged(searchType: string) {
      this.setState({searchType: searchType}, () => {
        let searchObj:Search={
          startDateTime:this.state.startDateTime.toISOString(true),
          endDateTime:this.state.endDateTime.toISOString(true),
          exactPeriod:(searchType === 'exact') ? true : false
        }
        this.getOffice(searchObj)
      });
    }

    getOfficeInformation(){
     const context: any = this.context;
      let shortOfficeStr = localStorage.getItem("ShortOffices")
      let shortOffices = shortOfficeStr? JSON.parse(shortOfficeStr):null
      if(context?.allOffices){
        let office:Office = context.allOffices.filter(shortOffice => shortOffice.id === this.state.officeId)[0] 
        this.setState({office:office},()=>{
          this.getClosedDays()
          this.checkSearchAndOfficeTimes(this.state.startDateTime,this.state.endDateTime)
        })
      }else if(shortOffices) {
        let office:Office = shortOffices.filter(shortOffice => shortOffice.id === this.state.officeId)[0] 
        this.setState({office:office},()=>{
          this.getClosedDays()
          this.checkSearchAndOfficeTimes(this.state.startDateTime,this.state.endDateTime)
        })
      }
      else {
        this.getClosedDays()
        this.checkSearchAndOfficeTimes(this.state.startDateTime,this.state.endDateTime)      
      }
    }

    checkSearchAndOfficeTimes = (start:Moment,end:Moment):void => {

        let searchStartDateDay:string = start.format('dddd');
        let searchEndDateDay:string = end.format('dddd');
    
        let startMinStr = this.getOpeningTime(searchStartDateDay)
        let startMaxStr = this.getClosingTime(searchStartDateDay)      
        let endMinStr   = this.getOpeningTime(searchEndDateDay)  
        let endMaxStr   = this.getClosingTime(searchEndDateDay)

        let splittedStart = startMinStr.split(':')
        let startTimeHour = Number(splittedStart[0]);
        let startTimeMin = Number(splittedStart[1]);

        let splittedEnd = startMaxStr.split(':')
        let endTimeHour = Number(splittedEnd[0]);
        let endTimeMinute = Number(splittedEnd[1]);
        if(!this.state.office){
          this.setState({
            startMinTime:startMinStr,
            startMaxTime:startMaxStr,
            endMinTime:endMinStr,
            endMaxTime:endMaxStr,
            startDateTime:this.state.startDateTime.set('hour',7).set('minute',0),
            endDateTime:this.state.endDateTime.set('hour',18).set('minute',0)
        },()=>{
          let isExact:boolean = this.state.searchType === 'exact' ? true : false;
          this.dateChanged(this.state.startDateTime,this.state.endDateTime,isExact)
        });
        }else {
          let startDateTime = localStorage.getItem('startDateTime')?.toString()
          let endDateTime = localStorage.getItem('endDateTime')?.toString()
          this.setState({
              startMinTime:startMinStr,
              startMaxTime:startMaxStr,
              endMinTime:endMinStr,
              endMaxTime:endMaxStr,
              startDateTime:moment(start),
              endDateTime:moment(end)
          },()=>{
            let isExact:boolean = this.state.searchType === 'exact' ? true : false;
            this.dateChanged(start,end,isExact)
          });
        }
    }

    getOpeningTime = (day:string):string => {
        if(this.state.office?.openingHours) {
            let office:Office = this.state.office
          switch (day) {
            case 'Monday':
              return office.openingHours.moFrom
              break;
            case 'Tuesday':
              return office.openingHours.tuFrom
              break;
            case 'Wednesday':
              return office.openingHours.weFrom
              break;
            case 'Thursday':
              return office.openingHours.thFrom
              break;
            case 'Friday':
              return office.openingHours.frFrom
              break;
            case 'Saturday':
              return office.openingHours.saFrom
              break;
            case 'Sunday':
              return office.openingHours.suFrom
              break;  
            default:
              return "08:00"
              break;
          }
        }
        else {
          return "07:00"
        }
    }
    
    getClosingTime = (day:string):string => {
        if(this.state.office?.openingHours) {
            let office:Office = this.state.office
          switch (day) {
            case 'Monday':
              return office.openingHours.moTo
              break;
            case 'Tuesday':
              return office.openingHours.tuTo
              break;
            case 'Wednesday':
              return office.openingHours.weTo
              break;
            case 'Thursday':
              return office.openingHours.thTo
              break;
            case 'Friday':
              return office.openingHours.frTo
              break;
            case 'Saturday':
              return office.openingHours.saTo
              break;
            case 'Sunday':
              return office.openingHours.suTo
              break;  
            default:
              return "19:00"
              break;
          }
        }
        else {
          return "18:00"
        }
      }

    getClosedDays = () => {

        let closedDays:Array<number> = new Array();
    
        let dayObj = {
          moFrom:1,moTo:1,
          tuFrom:2,tuTo:2,
          weFrom:3,weTo:3,
          thFrom:4,thTo:4,
          frFrom:5,frTo:5,
          saFrom:6,saTo:6,
          suFrom:0,suTo:0
        }
        if(this.state.office?.openingHours){
          let openingHours:OpeningHours = this.state.office.openingHours
          for (const day in openingHours) {
            if(openingHours[day] === ""){
              if(!closedDays.includes(dayObj[day]))
                closedDays.push(dayObj[day])
            }
          }
          this.setState({closedDays:closedDays})
        }
    
    }
    
    render() {
        return (
            <div>
                <div> 
                { this.state.isReady ? 
                <FilterBar
                    dateChanged={this.dateChanged}
                    startMaxTime={this.state.startMaxTime}
                    startMinTime={this.state.startMinTime}
                    endMaxTime={this.state.endMaxTime}
                    endMinTime={this.state.endMinTime}
                    startDateTime={ this.state.startDateTime }
                    endDateTime={ this.state.endDateTime }
                    isSearchTypeEnabled={true}
                    disabledDays={this.state.closedDays}
                    setOfficeMaxandMinDate={this.checkSearchAndOfficeTimes}
                    searchType={this.state.searchType}  
                    onExactChanged={this.onExactChanged}
                /> : null }
                </div>
                <div>
                { this.state.isReady ? <DetailsForm  office = {this.state.office}/> : <CustomSpinner/> }
                </div>
            </div>
        )
    }
}
export default withTranslation()(withRouter(DetailsNew))
