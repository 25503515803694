import React from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { Checkbox, createStyles, FormControl, FormControlLabel, FormGroup, makeStyles, Theme, Typography,List, ListItem, ListItemText } from '@material-ui/core';

interface Props {
    seatFeatures: Array<string>,
    roomFeatures: Array<string>,
    selectedSeatFeatures?: Array<string>,
    selectedRoomFeatures?: Array<string>,
    onChangeSeatFeatures?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onChangeRoomFeatures?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    selection: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(2),
            width: '100%'
        },
        listItem: {
            paddingLeft: 0,
            paddingBottom: 0
        },
        container: {
           
        },
        item: {
        width: "auto"
        },
        noMarginLeft: {
            marginLeft:0
        }
    }),
);


export const BookingFeatures: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {seatFeatures, selectedSeatFeatures, onChangeSeatFeatures, selection, roomFeatures, onChangeRoomFeatures, selectedRoomFeatures} = props;

    const noFeatureInPlaceMsg = () => {
        return <Grid container> <Typography variant='body2'>{t("BOOKINGS.NO_FEATURE")}</Typography></Grid>
    }

    const featuresOfSeat = () => {
        return (
        <div className={classes.container}>
            <FormControl color="primary" component="fieldset" className={classes.formControl}>
                    <FormGroup>
                        <Grid container style={{overflow:"hidden"}}>
                            {seatFeatures.map((feature) =>
                           <Grid item xs={12} md={4} style={{overflow:"hidden"}}>
                               <FormControlLabel 
                                className={classes.noMarginLeft}
                                control={
                                    <Checkbox
                                        className={classes.item}
                                        checked={selectedSeatFeatures?.includes(feature)}
                                        onChange={onChangeSeatFeatures}
                                        name={feature}
                                        color='primary'
                                    />
                                }
                                label={feature} />
                            </Grid>
                           
                        )}
                       </Grid>
                    </FormGroup>
                </FormControl>
            </div>
        )
    }

    const featuresOfRoom = () => {
        return (
        <div className={classes.container}>
            <FormControl color={'primary'} component={"fieldset"} className={classes.formControl}>
                    <FormGroup>
                        <Grid container style={{overflow:"hidden"}}>
                            {roomFeatures.map((feature) =>
                                <Grid item xs={12} md={4}>
                                    <FormControlLabel
                                        className={classes.noMarginLeft}
                                        control={
                                            <Checkbox
                                                className={classes.item}
                                                checked={selectedRoomFeatures?.includes(feature)}
                                                onChange={onChangeRoomFeatures}
                                                name={feature}
                                                color='primary'
                                            />
                                        }
                                        label={feature} />
                                </Grid>
                            )}
                        </Grid>
                    </FormGroup>
                </FormControl>
            </div>
        )
    }

    const featuresOnlyText = () => {
        return (
            <div>
                <Typography color={'secondary'} variant={'subtitle2'}>{t('BOOKINGSTABLE.5')}</Typography>
            <List>
              {seatFeatures.map((value) =>
                <ListItem className={classes.listItem}>
                  <ListItemText
                      style={{wordBreak: 'break-all'}}
                    primary={value}
                  />
                </ListItem>,
              )}
            </List>
                <Typography color={'secondary'} variant={'subtitle2'}>{t('BOOKINGSTABLE.15')}</Typography>
            <List>
                {roomFeatures.map((value) =>
                    <ListItem className={classes.listItem}>
                        <ListItemText
                            style={{wordBreak: 'break-all'}}
                            primary={value}
                        />
                    </ListItem>,
                )}
            </List>
            </div>

        )
    }

    return (
        <div>
            <Grid container style={{marginTop:"16px"}}>
                <Typography variant='h6' color='secondary'>{t('BOOKINGS.3')}</Typography>
            </Grid>
            {
                !selection && featuresOnlyText()
            }
            {
               selection &&  <Typography variant={'subtitle1'} color={'secondary'}>{t('BOOKINGSTABLE.13')}</Typography>
            }
            {
                selection && ( seatFeatures && seatFeatures.length > 0 ? featuresOfSeat() : noFeatureInPlaceMsg())
            }
            { selection && <Typography variant={'subtitle1'} color={'secondary'}>{t('BOOKINGSTABLE.14')}</Typography>}
            {
                selection && ( roomFeatures && roomFeatures.length > 0 ? featuresOfRoom() : noFeatureInPlaceMsg())
            }
            {

            }
            
        </div>
    )
}
