import React from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { OpeningHours } from '../../interfaces/OpeningHours';
import { Typography } from '@material-ui/core';

interface Props {
    data: OpeningHours
}

export const WorkingHours: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const {data} = props;

    const closedText = () => {
        return t('DETAILS.11')
    }

    return (
        <div>
            <Grid container style={{margin:"16px 0"}}>
                <Typography variant='h6' color='secondary'>{t('PROPERTY.26')}</Typography>     
            </Grid>
            <Grid container spacing={2}>
                <Grid item container xs={12} id="mo">
                    <Grid item xs={12} lg={5} id="mo1">{t('DETAILS.2')}: </Grid>
                    <Grid item xs={12} lg={7} id="mo2">{`${data.moFrom ? (data.moFrom + ' - ' + data.moTo) : closedText()}`} </Grid>
                </Grid>
                <Grid item container xs={12} id="tu">
                    <Grid item xs={12} lg={5} id="tu1">{t('DETAILS.3')}: </Grid>
                    <Grid item xs={12} lg={7} id="tu2">{`${data.tuFrom ? (data.tuFrom + ' - ' + data.tuTo) : closedText()}`} </Grid>
                </Grid>
                <Grid item container xs={12} id="we">
                    <Grid item xs={12} lg={5} id="we1">{t('DETAILS.4')}: </Grid>
                    <Grid item xs={12} lg={7} id="we2">{`${data.weFrom ? (data.weFrom + ' - ' + data.weTo) : closedText()}`} </Grid>
                </Grid>
                <Grid item container xs={12} id="th">
                    <Grid item xs={12} lg={5} id="th1">{t('DETAILS.5')}: </Grid>
                    <Grid item xs={12} lg={7} id="th2">{`${data.thFrom ? (data.thFrom + ' - ' + data.thTo) : closedText()}`} </Grid>
                </Grid>
                <Grid item container xs={12} id="fr">
                    <Grid item xs={12} lg={5} id="fr1">{t('DETAILS.6')}: </Grid>
                    <Grid item xs={12} lg={7} id="fr2">{`${data.frFrom ? (data.frFrom + ' - ' + data.frTo) : closedText()}`} </Grid>
                </Grid>
                <Grid item container xs={12} id="sa">
                    <Grid item xs={12} lg={5} id="sa1">{t('DETAILS.7')}: </Grid>
                    <Grid item xs={12} lg={7} id="sa2">{`${data.saFrom ? (data.saFrom + ' - ' + data.saTo) : closedText()}`} </Grid>
                </Grid>
                <Grid item container xs={12} id="su">
                    <Grid item xs={12} lg={5} id="su1">{t('DETAILS.8')}: </Grid>
                    <Grid item xs={12} lg={7} id="su2">{`${data.suFrom ? (data.suFrom + ' - ' + data.suTo) : closedText()}`} </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
