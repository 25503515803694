import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';

import {ForgotPasswordHeader} from '../headers/ForgotPasswordHeader';
import { navigate } from '../../history/navigate';

interface Props {
  onSubmitClick: (email: string) => void;
}
 

export const ForgotPasswordForm:React.FC<Props> = (props)=>{
    const { t, i18n } = useTranslation();

  const { onSubmitClick} = props;
  document.title = t('TITLE.3');

  const [email, setEmail] = useState('');

  const onResetClick = () => {
    onSubmitClick(email)
  }

  const emailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }

  const onLoginClick = () => {
    navigate("/login");
  }

  const onEnterPress = (e) => {
    if (e.key === 'Enter'){
      onResetClick();
    }
  }

  return (
   
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} >

        <div className="paper-login">
          <form className="form" onKeyPress={onEnterPress} noValidate>
          <ForgotPasswordHeader/>
            <TextField
              
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('FORGOTPASSWORD.4')}
              name="email"
              autoComplete="email"
              autoFocus
              color="secondary"
              onChange={emailChanged}
              
            />
            <Grid container justify="center">
            <Button
              variant="contained"
              type="button"
              color="primary"
              className="submit"
              onClick={onResetClick}
            >
              {t('FORGOTPASSWORD.5')}
            </Button>
            </Grid>
            <Grid container justify="flex-end">
              <Link onClick={onLoginClick} >
              {t('FORGOTPASSWORD.3')}
              </Link>
            </Grid>
          </form>
        </div>
        </Grid>
        
  );
}