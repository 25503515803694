import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { navigate } from '../../history/navigate';
import {Office} from '../../interfaces/Office';
import { AppContext } from "../../middlewares/appContext/AppContext";
import { useTranslation } from 'react-i18next';
import GenericButton from '../button/GenericButton';
import NavigateService from '../../services/navigate.service';

import defaultRoomImage from '../images/room1.png';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 24
  },
  media: {
    height: 250,
    
  },
});

interface roomObj {


  "address": {
    "city": string,
    "street": string,
    "zip": string
  },
  "description": string,
  "hygieneMeasuresDescription": string,
  "hygieneMeasuresDistance": number,
  "id": string,
  "images": [
    {
      "description": string,
      "uri": string
    }
  ],
  "mapLink": string,
  "name": string,
  "openingHours": {
    "frFrom": string,
    "frTo": string,
    "moFrom": string,
    "moTo": string,
    "saFrom": string,
    "saTo": string,
    "suFrom": string,
    "suTo": string,
    "thFrom": string,
    "thTo": string,
    "tuFrom": string,
    "tuTo": string,
    "weFrom": string,
    "weTo": string
  }

}

interface Props {
  imageIndex: string,
  details: Office
}

export const ObjectCard: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { imageIndex,details } = props;
  const context = useContext(AppContext);
  const { t, i18n } = useTranslation();

  const hasManageOffice = () => {
    return (context && context.user.permissions.includes("MANAGE_OFFICES"))
  }

  const hasBookOffice = () => {
    return (context && context.user.permissions.includes("BOOK_OFFICES"))
  }


  const onDetailClicked = (id) => {
    if( hasBookOffice()){
      navigate("/user/offices/"+id);
    }
    else {
      navigate("/profile")
    }
    
  }

  const onBookingsClicked = (id) => {
    if( hasBookOffice()){
      const rootPath = NavigateService.rootPath(context?.user.role);
      navigate(`${rootPath}/bookings/?officeId=${id}`);
    }
    else {
      navigate("/profile")
    }
  }
  
  const onEditClicked = (id) => {
      navigate("/offices/" + id)
  }

  //alert(details);
  //  console.log(details);
  return (

    <Card className="card-root">
      <CardActionArea onClick={()=>{onDetailClicked(details.id)}}>
        <div className="media-container">
        <CardMedia
          className="card-media"
          component="img"
          alt={details.name}
          image={details&&details.images.length>0 ? process.env.PUBLIC_URL + details.images[0].uri:defaultRoomImage}
          title={details.name}
        />
        </div>
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2" color="primary">
            <strong>{details.name}</strong>
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {details.address.street}
            <br/>
            {details.address.zip} {details.address.city}
            <br/>
            <br/>
            {
              'seatsAvailable' in details ? `${t('OBJECTCARD.3')} :  ${details.seatsAvailable}` : null
            }
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions style={{justifyContent:"space-evenly",margin:8}}>
        {/* <Button onClick={()=>onDetailClicked(details.id)} size="medium" color="primary" variant="contained">
        {t('OBJECTCARD.5')}
        </Button> */}
        <GenericButton onClick={()=>onBookingsClicked(details.id)} label={'OBJECTCARD.6'}/>
        <GenericButton onClick={()=>onDetailClicked(details.id)} label={'OBJECTCARD.5'}/>
        {
          hasManageOffice() ? <GenericButton onClick={()=>onEditClicked(details.id)} label={'CANVAS.2'} />
          :null
        }
        
      </CardActions>
    </Card>
  );
}
