import React, { useContext,useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { AppContext } from "../../middlewares/appContext/AppContext";
import {Avatar, Box, Button, Divider, Hidden} from '@material-ui/core';
import { navigate } from '../../history/navigate';
import {UserInfo} from './UserInfo';
import {useTranslation} from 'react-i18next';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import logo from '../images/deskHeroLogo.png';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
     
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      height:"5vh"
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);

interface Props {
  isRelative:boolean
}

export default function MenuAppBar(props:Props) {
  const {t,i18n} = useTranslation();
  const { isRelative } = props;
  const classes = useStyles();
  const [auth, setAuth] = React.useState(true);
  const [homeClicked, setHomeClicked] = React.useState(false);
  const [objectClicked, setObjectClicked] = React.useState(false);
  const [bookingsClicked, setBookingsClicked] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [lang, setLang] = React.useState(localStorage.getItem('language')?localStorage.getItem('language'):'de');
  const [avatar, setAvatar] = React.useState("");
  const open = Boolean(anchorEl);
  const context = useContext(AppContext);

  const dummy = require("../images/dummyAvatar.png")
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    var flag = event.target.value as string;
    if ( flag === 'de'){
      i18n.changeLanguage('de');localStorage.setItem('language','de'); 
    }
    else if ( flag === 'en'){
      i18n.changeLanguage('en');localStorage.setItem('language','en');
    }
    setLang(flag);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if(context)
    {
      context.toggle()
    }
    let hash = window.location.hash;
    var res = hash.split("/");
    isImagePresent();
    if(hash){
      if(hash.includes("bookings"))
      {
        setBookingsClicked(true);
        setObjectClicked(false);
      }
      else if(hash.includes("offices")){
        setBookingsClicked(false);
        setObjectClicked(true);
      }
      else if(hash.includes("dashboard")){
        setBookingsClicked(false);
        setObjectClicked(false);
      }
    }
  }, [])

  useEffect(()=>{
    if(lang)
      context?.changeLang(lang)
  },[lang])

  useEffect(()=>{

  },[avatar])

  const checkPermissions = () => {
    if(context?.user.permissions && context?.user.permissions.includes('MANAGE_USERS'))
    {
      
      return false;
    }
    else {
      return true;
    }
  }

  //!(context?.user.permissions && context?.user.permissions.includes('MANAGE_USERS'))

  const logoutClick = () =>{

    HTTP_REQUESTS.TENANT_API.LOGOUT((res) => {
        localStorage.removeItem('startDateTime')
        localStorage.removeItem('endDateTime')
        window.location.reload();
    }, (err) => {
    });

}
  const usersBtnClick = () => {
    handleNavigate("/users");
    handleClose();
  };

  const clientsBtnClick = () => {
    handleNavigate("/clients");
    handleClose();
  };

  const objectBtnClick = () => {
    handleNavigate('/offices');
    setObjectClicked(true);
    setHomeClicked(false);
    setBookingsClicked(false);
  }

  const bookingsBtnClick = () => {
    handleNavigate('/bookings');
    setBookingsClicked(true);
    setObjectClicked(false);
    setHomeClicked(false);
  }
  const homeBtnClick = () => {
    setBookingsClicked(false);
    setObjectClicked(false);
    handleNavigate('/dashboard');
  }

  const profileBtnClick = () => {
    handleNavigate("/profile");
    handleClose();
  }

  const settingsBtnClick = () => {
    handleNavigate("/settings");
    handleClose();
  }

  

  const getAuthImage = () => {
    HTTP_REQUESTS.TENANT_API.GET_AUTH_USER_IMAGE((res) => {
      let blob = new Blob(
        [res], 
        { type:'image/png' }
      )
      let image = URL.createObjectURL(blob)

      //setAvatar(window.location.origin+"/api/auth/info/image")
    },(err)=> {
    })
  }

  const isImagePresent = () => {
    if(context?.user)
    {
      if(context.user.imagePresent)
      {
        //getAuthImage();
        //setAvatar(window.location.origin+"/api/auth/info/image")
        //setAvatar(context.imageUrl)

      }
      else {
        return "";
      }
    }
  }

  const handleNavigate = (url) => {
    if(context && context.user.role == "USER" || context?.user.role == "CLIENT_ADMIN" || context?.user.role == "CLIENT_USER" ){
      navigate("/user" + url) ;
    }else{
      navigate(url);
    }
  }

  const hasPermission = (key:string) => {
    console.log(context)
    if(context && context?.user.permissions.includes(key))
    {
      return true
    }
    else {
      return false
    }
  }

  return (
    <div className="app-bar-wrapper" >
      <AppBar position={isRelative?"relative":"fixed"} className="app-bar">
        <Toolbar className="toolbar" variant="dense">
        <div className="title">
        <img onClick={homeBtnClick} className="logo"  src={logo}></img>
        </div>
        
          {/* <Typography variant="h6" className="title">
            <strong className="company" onClick={homeBtnClick}>OpenOffice</strong>
          </Typography> */}
          <div className="navigation-bar">
        
            <FormControl>
              {/* <InputLabel ></InputLabel> */}
              <Select
                className="lang-select"
                value={lang}
                defaultValue={'en'}
                onChange={handleChange}
              >
                <MenuItem color='#FFFFFF' value={'de'}>Deutsch</MenuItem>
                <MenuItem value={'en'}>English</MenuItem>
              </Select>
            </FormControl>
          
          {/* <button onClick={() => { i18n.changeLanguage('de');localStorage.setItem('language','de') }}>Deutsch</button>
          <button onClick={() => { i18n.changeLanguage('en');localStorage.setItem('language','en') }}>English</button> */}

          {context && (
            <div className="navigation-bar">

              <Box
                sx={{display: {xs: 'none', sm: 'block'}}}
              >
              {
                hasPermission("BOOK_OFFICES") ?
                <Button variant='text' color="inherit" style={{ textTransform:"none",fontWeight:objectClicked?"bold":"initial"}} onClick={objectBtnClick}>{t('APPBAR.2')}</Button>
                : null
              }
              <Button variant='text' color="inherit" style={{ textTransform:"none",fontWeight:bookingsClicked?"bold":"initial"}} onClick={bookingsBtnClick}>{t('APPBAR.3')}</Button>
              </Box>
              <Avatar
                className="avatar"
                onClick={handleMenu}
                src={context.imageUrl}
                variant="circular"
              >{context.user.firstname.slice(0,1)}{context.user.lastname.slice(0,1)}
              </Avatar>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem>
                  <UserInfo/>
                </MenuItem>
                <Divider/>
                <Box
                  sx={{display: {sm: 'none', xs:'block'}}}
                >
                            {
                    hasPermission("BOOK_OFFICES") ?
                        <>
                          <MenuItem color="inherit" style={{ textTransform:"none",fontWeight:objectClicked?"bold":"initial"}} onClick={objectBtnClick}>{t('APPBAR.2')}</MenuItem>
                          <Divider/>
                        </>
                        : null
                  }
                  <MenuItem color="inherit" style={{ textTransform:"none",fontWeight:bookingsClicked?"bold":"initial"}} onClick={bookingsBtnClick}>{t('APPBAR.3')}
                  </MenuItem>
                </Box>
                <Divider/>
                <div hidden={!(context?.user.permissions && context?.user.permissions.includes('MANAGE_USERS'))}>
                <MenuItem  onClick={usersBtnClick}>{t('APPBAR.4')}</MenuItem>
                </div>
                <Divider/>
                <div hidden={!(context?.user.permissions && context?.user.permissions.includes('MANAGE_CLIENTS'))}>
                <MenuItem  onClick={clientsBtnClick}>{t("CLIENT.1")}</MenuItem>
                </div>
                <Divider/>
                <div hidden={!(context?.user.role && context?.user.role === "ADMIN")}>
                <MenuItem  onClick={settingsBtnClick}>{t("APPBAR.7")}</MenuItem>
                </div>
                <Divider/>
                <div></div>
                <div>
                <MenuItem  onClick={profileBtnClick}>{t('APPBAR.6')}</MenuItem>
                </div>
                <Divider/>
                <MenuItem onClick={logoutClick}><ExitToAppOutlinedIcon color="primary" /> {t('APPBAR.5')}</MenuItem>
              </Menu>
            </div>
          )}
          </div>
          
        </Toolbar>
      </AppBar>
    </div>
  );
}