import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import {ForgotDomainHeader} from '../headers/ForgotDomainHeader';
import { navigate } from '../../history/navigate';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';

interface Props {
 
}
 

export const ForgotDomainForm:React.FC<Props> = (props)=>{
    const { t, i18n } = useTranslation();

    document.title = t('TITLE.4');    

  const [email, setEmail] = useState('');

  const onClickSubmit = () => {
    let emailObj = {"email":email}
      
    HTTP_REQUESTS.TENANT_API.POST_FORGOT_DOMAIN(emailObj,(res)=>{
        navigate("/forgotDomainSuccess");
    },(err)=>{
      
    })
  }

  const onEnterPress = (e) => {
    if (e.key === 'Enter'){
      onClickSubmit();
    }
  }

  const emailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }

  const onLoginClick = () => {
    navigate("/login");
  }


  return (
   
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} >

        <div className="paper-login">
         
          <Grid onKeyPress={onEnterPress} className="form" /* noValidate */>
          <ForgotDomainHeader/>
            <TextField
              
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('FORGOTPASSWORD.4')}
              name="email"
              autoComplete="email"
              autoFocus
              color="secondary"
              onChange={emailChanged}
              value={email}
              
            />
            <Grid container justify="center">
            <Button
              variant="contained"
              type="button"
              color="primary"
              className="submit"
              onClick={onClickSubmit}
            >
              {t('TENANTNOTFOUND.4')}
            </Button>
            </Grid>
            <Grid container justify="flex-end">
              <Link onClick={onLoginClick} >
              {t('FORGOTPASSWORD.3')}
              </Link>
            </Grid>
          </Grid>
        </div>
        </Grid>
        
  );
}