import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import DoneAllTwoToneIcon from '@material-ui/icons/DoneAllTwoTone';
import { useTranslation } from 'react-i18next';
import { navigate } from '../../history/navigate';

interface Props {

}

export const ForgotDomainSuccess: React.FC<Props> = () => {
    const { t, i18n } = useTranslation();
    document.title = t('TITLE.4');    

    const onLoginClick =()=>{
        navigate('/login');
    }

    return (

        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} >
            <div className="register-success">
                <DoneAllTwoToneIcon color="primary" className="success-icon" />
                {t('TENANTNOTFOUND.5')}

                <Link onClick={onLoginClick} >
                {t('FORGOTPASSWORD.7')}
              </Link>
            </div>
        </Grid>
    );
}