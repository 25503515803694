import React, {FC, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {FormControl, FormGroup, FormHelperText, MenuItem, Popover, Select} from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import {makeStyles} from "@material-ui/core/styles";
import {availablePeriods} from '../../interfaces/AvailablePeriods';
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";


interface Seat {
    id: string,
    name: string,
    point: { x: number, y: number },
    available: boolean,
    bookingInfo: string,
    roomId: string,
    features: Array<string>,
    availablePeriods: Array<availablePeriods>
}

interface Room {
    id: string,
    name: string,
    description: string,
    isNew: boolean,
    isExclusive: boolean,
    points: Array<number>,
    available: boolean,
    features: Array<string>,
    availablePeriods: Array<availablePeriods>,
    reservedForClients: Array<{ id: string, name: string }>,
    seats: Array<Seat>
}

interface props {
    data: Array<Room>,
    details: Room,
    handleInfoOpen: () => void,
    selectedSeat: Seat,
    setSelectedSeat: React.Dispatch<React.SetStateAction<Seat>>,
    setSelectedRoom: React.Dispatch<React.SetStateAction<Room>>
};

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: "100%"
    },
    typography: {
        padding: theme.spacing(2),
    }
}));

const RoomCard: FC<props> = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {data, details, handleInfoOpen, selectedSeat, setSelectedSeat, setSelectedRoom} = props;
    const [seat, setSeat] = useState<Seat | null>(null);
    const [errorMS, setErrorMs] = useState(false);

    const handleChange = (event) => {
        setSeat(JSON.parse(event.target.value));
        if (Object.entries(JSON.parse(event.target.value)).length !== 0) {
            setErrorMs(false);
        }
    };

    const checkHandleInfoOpen = () => {
        if (seat === null) {
            setErrorMs(true);
        } else {
            setSelectedSeat(seat);
            setSelectedRoom(data.find((room) => room.seats.find((seat) => seat.id === selectedSeat.id)));
            handleInfoOpen();
        }
    }
    const [roomAnchorEl, setRoomAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [seatAnchorEl, setSeatAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClickForRoom = (event: React.MouseEvent<HTMLButtonElement>) => {
        setRoomAnchorEl(event.currentTarget);
    };

    const handleCloseForRoom = () => {
        setRoomAnchorEl(null);
    };
    const handleClickForSeat = (event: React.MouseEvent<HTMLButtonElement>) => {
        setSeatAnchorEl(event.currentTarget);
    };

    const handleCloseForSeat = () => {
        setSeatAnchorEl(null);
    };


    const roomFeatureOpen = Boolean(roomAnchorEl);
    const roomFeatureId = roomFeatureOpen ? 'simple-popover' : undefined;
    const seatFeaturesOpen = Boolean(seatAnchorEl);
    const seatFeaturesId = roomFeatureOpen ? 'simple-popover' : undefined;
    return (
        <Grid md={4} sm={6} xs={12}>
            <Card className="card-root">
                <CardContent>
                    <Typography style={{textAlign: "center"}} variant="h6" component="h2" color="primary">
                        <strong>{details.name}</strong>
                    </Typography>
                    <hr/>
                    <Grid>
                        <Typography variant="body1" color="textPrimary" component="h3">
                            {t("CANVAS.9")}
                        </Typography>
                        <Typography variant="body2" className={"block-with-text"} color="textSecondary" component="p">
                            {details.description}
                        </Typography>
                    </Grid>
                    <br/>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Button aria-describedby={roomFeatureId} variant="contained" color="secondary" style={{textTransform:"capitalize"}} onClick={handleClickForRoom}>
                                {t("BOOKINGSTABLE.18")}
                            </Button>
                            <Popover
                                id={roomFeatureId}
                                open={roomFeatureOpen}
                                anchorEl={roomAnchorEl}
                                onClose={handleCloseForRoom}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                {details.features && details.features.length > 0 ?
                                    data.find(x => x.id === details.id).features.map((feature, index) =>
                                        <Typography className={classes.typography}>{feature}</Typography>
                                    )
                                    :   <Typography className={classes.typography}>{t("BOOKINGS.NO_FEATURE")}</Typography>
                                }
                            </Popover>
                        </Grid>
                        <Grid item container >
                            <Grid item xs={6} style={{margin: "auto"}}>
                                <Typography color={"textPrimary"}>{t("BOOKINGSTABLE.16")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl className={classes.formControl} error={errorMS}>
                                    <Select
                                        onChange={handleChange}
                                        value={JSON.stringify(seat)}
                                        fullWidth
                                    >
                                        {details.seats.map((seat) => (
                                            <MenuItem value={JSON.stringify(seat)}>
                                                {seat.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errorMS && <FormHelperText>{t("BOOKINGSTABLE.17")}</FormHelperText>}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Button aria-describedby={seatFeaturesId} variant="contained" style={{textTransform:"capitalize"}} color="secondary" onClick={handleClickForSeat}>
                                {t("BOOKINGSTABLE.19")}
                            </Button>
                            <Popover
                                id={seatFeaturesId}
                                open={seatFeaturesOpen}
                                anchorEl={seatAnchorEl}
                                onClose={handleCloseForSeat}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                {seat && seat.features && seat.features.length > 0 ?
                                    seat.features.map((feature) =>
                                    <Grid item xs={6} md={4}>
                                        <Typography className={classes.typography}>{feature}</Typography>
                                    </Grid>
                                )
                                    :   <Typography className={classes.typography}>{t("BOOKINGS.NO_FEATURE")}</Typography>
                                }
                            </Popover>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button variant={"contained"} fullWidth color="primary"
                            onClick={checkHandleInfoOpen}>{t("BOOKINGS.8")}</Button>
                </CardActions>
            </Card>
        </Grid>
    );
};

export default RoomCard;