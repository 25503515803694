import React,{useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';

import Grid from '@material-ui/core/Grid';

import {MainHeader} from '../headers/MainHeader';
import { navigate } from '../../history/navigate';
import { useTranslation } from 'react-i18next';
import {  notifyError, notifySuccess } from '../notification/Notification';

interface Props {
  onSubmitClick: (email: string, password:string) => void;
}
 

export const LoginForm:React.FC<Props> = (props)=>{
  const { t, i18n } = useTranslation();

  const { onSubmitClick} = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailErr, setEmailErr] = useState<boolean>(false);
  const [pwdErr, setPwdErr] = useState<boolean>(false);
  const [textfieldErr, setTextfieldErr] = useState<String>(t('TENANTREGISTER.23'));
  const onRegisterClick = () => {
    navigate('/registertype')
  }

  document.title = t('TITLE.1');

  const onLoginClick = () => {
    if( checkValidations() )
      onSubmitClick(email, password);
    else
      notifyError(t('PROPERTY.8'));
  }

  const onEnterPress = (e) => {
    if (e.key === 'Enter'){
      onLoginClick();
    }
  }

  const onForgotPasswordClick = () => {
    navigate("/forgotPassword");
  }

  const emailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }

  const passwordChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }

  const checkValidations = () => {

    let formValid=true;

    if( !email || email === "" ){
      setEmailErr(true);
    }
    else{
      setPwdErr(false);
    } 
    if( !password || password === "" ){
      setPwdErr(true);
    }
    else{
      setPwdErr(false);
    }  
    
    return formValid
}

  return (
   
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} >

        <div className="paper-login">
         
          <form className="form" onKeyPress={onEnterPress} /* noValidate onSubmit={handleKeyPress}*/ >
          <MainHeader/>
            <TextField

              margin="normal"
              required
              fullWidth
              id="email"
              label={t('LOGIN.1')}
              name="email"
              autoComplete="email"
              autoFocus
              color="secondary"
              onChange={emailChanged}
              error={emailErr}
              helperText={ emailErr? textfieldErr : null}
            />
            <TextField
              
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('LOGIN.2')}
              type="password"
              id="password"
              autoComplete="current-password"
              color="secondary"
              onChange={passwordChanged}
              error={pwdErr}
              helperText={ pwdErr? textfieldErr : null}
              
            />
            <Grid container justify="center">
            <Button
              variant="contained"
              type="button"
              color="primary"
              className="submit"
              onClick={onLoginClick}
              
            >
             {t('LOGIN.5')}
            </Button>
            {/*<Button*/}
            {/*  variant="outlined"*/}
            {/*  type="button"*/}
            {/*  color="secondary"*/}
            {/*  onClick={onRegisterClick}*/}
            {/*  className="submit"*/}
            {/*>*/}
            {/* {t('LOGIN.3')}*/}
            {/*</Button>*/}
            </Grid>
            <Grid container justify="center">
              <Link onClick={onForgotPasswordClick}/* style={{"cursor":"pointer"}}*/ >
             {t('LOGIN.4')}
              </Link>
            </Grid>
          </form>
        </div>
        </Grid>
        
  );
}