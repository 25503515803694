import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { InputLabel } from '@material-ui/core';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { navigate } from '../../history/navigate';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import {configObj} from '../../interfaces/Config';
import RefreshIcon from '@material-ui/icons/Refresh';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import {  notifyError, notifySuccess } from '../notification/Notification';
import FormHelperText from '@material-ui/core/FormHelperText';

interface SubmitObj {
  captchaResponse? : string,
  customSubdomain? : string,
  email:string,
  firstname: string,
  lastname: string,
  password: string,
  salutation: string
}
 

 
interface Props {
  onSubmitClick: (submitData: SubmitObj) => void;
  getCaptcha: () => void;
  config: configObj | null;
  captcha: string;
  captchaError: boolean;
  subDomainError: boolean;
  emailError: boolean;
  pwdError: boolean;
  errorMessage: string;
}

export const RegisterForm: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();

  const { onSubmitClick,getCaptcha, config ,captcha, captchaError, subDomainError,emailError, errorMessage, pwdError} = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [salutation, setSalutation] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [captchaResult, setCaptchaResult] = useState('');
  const [customDomain, setCustomDomain] = useState('');

  const [emailErr, setEmailErr] = useState(false);
  const [emailErrText, setEmailErrText] = useState('');
  const [pwdErr, setPwdErr] = useState(pwdError);
  const [rePwdErr, setRePwdErr] = useState<boolean>(false);
  const [salutationErr, setSalutationErr] = useState<boolean>(false);
  const [firstNameErr, setFirstNameErr] = useState<boolean>(false);
  const [lastNameErr, setLastNameErr] = useState<boolean>(false);
  const [captchaErr, setCaptchaErr] = useState(captchaError);
  const [domainErr, setDomainErr] = useState(subDomainError);
  const [domainRegexErr, setDomainRegexErr] = useState(false);
  const [domainErrText, setDomainErrText] = useState("");
  const [textfieldErr, setTextfieldErr] = useState<String>(t('TENANTREGISTER.23'));

  let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  let subDomainRegex = /^[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]$/;;

  const emailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }

  const passwordChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }

  const rePasswordChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRePassword(e.target.value);
  }

  const salutationChanged = (e: any) => { //TODO: Found suitable solution for onChange Event
    e.preventDefault();
    setSalutation(e.target.value);
  }

  const firstNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  }

  const lastNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  }

  const customDomainChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomDomain(e.target.value);
    setDomainRegexErr(false);
  }

  const captchaResultChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCaptchaResult(e.target.value);
  }

  const onEnterPress = (e) => {
    if (e.key === 'Enter'){
      submit();
    }
  }


  let registration_params={ 
    "captchaResponse": captchaResult, 
    "customSubdomain": customDomain, 
    "email": email, 
    "firstname": firstName, 
    "lastname": lastName, 
    "password": password, 
    "salutation": salutation
  }
 
  const submit = () =>{
    if(checkValidations()){
      onSubmitClick(registration_params);
    }
    else{
      /* setEmailErr(false);üzgün biçimli bir e-posta adresi değil!
      setPwdErr(false);
      setRePwdErr(false);
      setSalutationErr(false);
      setFirstNameErr(false);
      setLastNameErr(false);
      setCaptchaErr(false);
      setDomainErr(false); */
      if(config && config.captchaRequired)
      {
        onCaptchaClick();
      }
      
    }
  }

  const checkValidations = () => {

    let formValid=true;

    if( !email || email === "" ){
      setEmailErr(true);
      formValid = false;
      setEmailErrText(t('INPUT_VALIDATIONS.2'))
    }
    else{
      if (!emailRegex.test(email)) {
        setEmailErr(true)
        formValid = false;
        setEmailErrText(t('INPUT_VALIDATIONS.3'))
    }
      setEmailErr(false);
    } 
    if( !password || password === "" ){
      setPwdErr(true);
      formValid = false;
    }
    else{
      setPwdErr(false);
    }
    if( password !== rePassword ){
      setRePwdErr(true);
      formValid = false;
    }
    else{
      setRePwdErr(false);
    }
    if( !salutation || salutation === "" ){
      setSalutationErr(true);
      formValid = false;
    }
    else{
      setSalutationErr(false);
    }
    if( !firstName || firstName === ""){
      setFirstNameErr(true);
      formValid = false;
    }
    else{
      setFirstNameErr(false);
    }
    if( !lastName || lastName === ""){
      setLastNameErr(true);
      formValid = false;
    }
    else{
      setLastNameErr(false);
    }
    if(config && config.captchaRequired)
    {
      if( !captcha || captcha === ""){
        setCaptchaErr(true);
        formValid = false;
      }
      else{
        setCaptchaErr(false);
      }
    }
    if(config && config.customSubdomainAllowed)
    {
      if( !customDomain || customDomain === ""){
        setDomainErr(true);
        formValid = false;
      }           
      else{
        if(!subDomainRegex.test(customDomain))
        {
          formValid=false;
          setDomainRegexErr(true);
        }
        setDomainErr(false);
      }
    } 
    if (salutation === ' ' || !salutation) {
      setSalutationErr(true)
      formValid = false;
  }
  else{
    setSalutationErr(false);
  }

    return formValid;
}

  const onClickBack = () => {
    navigate("/");
  }

  useEffect(() => {
    
    if(!config?.registrationEnabled)
    {
      navigate("/registerDisabled");
    }

  },[]);

  const onCaptchaClick = () => {
    setCaptchaResult("");
    getCaptcha();
  }


  return (

    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} className="paper-wrapper">


      <div className="paper">

        
        <form className="form" onKeyPress={onEnterPress} noValidate>
          <Grid container spacing={2}>
            <Grid container xs={12}>
              <Typography color="primary" className="header-text" component="h1" variant="h3" >
              {t('TENANTREGISTER.2')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="secondary"
                required
                fullWidth
                id="email"
                label={t('TENANTREGISTER.3')}
                name="email"
                autoComplete="email"
                value={email}
                error={emailErr || emailError }
                helperText={emailErr? emailErrText : emailError? errorMessage : null}
                onChange={emailChanged}               
              />
              
            </Grid>
            <Grid item xs={6}>
              <TextField
                color="secondary"
                required
                fullWidth
                name="password"
                label={t('TENANTREGISTER.4')}
                type="password"
                id="password"
                autoComplete="password"
                value={password}
                onChange={passwordChanged}
                error={pwdErr || pwdError}
                helperText={pwdErr? t('INPUT_VALIDATIONS.6') : pwdError? errorMessage : null}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                color="secondary"
                required
                fullWidth
                name="rePassword"
                label={t('TENANTREGISTER.25')}
                type="password"
                id="rePassword"
                //autoComplete="password"
                value={rePassword}
                onChange={rePasswordChanged}
                error={rePwdErr}
                helperText={ rePwdErr? password!==''? t('INPUT_VALIDATIONS.9') : t('INPUT_VALIDATIONS.6') : null}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl className="select"
                color="secondary"
                error={salutationErr}
              >

                <InputLabel>{t('TENANTREGISTER.24')}</InputLabel>
                <Select
                  onChange={salutationChanged}
                  defaultValue={salutation}
                  inputProps={{ 'aria-label': '1' }}
                >
                  <MenuItem value={' '}><em>{t('TENANTREGISTER.5')}</em></MenuItem>
                  <MenuItem value={"Mr"}>{t('TENANTREGISTER.21')}</MenuItem>
                  <MenuItem value={"Mrs"}>{t('TENANTREGISTER.22')}</MenuItem>
                </Select>
                <FormHelperText color="primary" >{salutationErr ? t('INPUT_VALIDATIONS.1') : null}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="fname"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label={t('TENANTREGISTER.6')}
                color="secondary"
                value={firstName}
                onChange={firstNameChanged}
                error={firstNameErr}
                helperText={firstNameErr? t('INPUT_VALIDATIONS.4') : null}
              />
            </Grid>
            <Grid item xs={12} >
              <TextField
                required
                fullWidth
                id="lastName"
                label={t('TENANTREGISTER.7')}
                name="lastName"
                autoComplete="lname"
                color="secondary"
                value={lastName}
                onChange={lastNameChanged}
                error={lastNameErr}
                helperText={lastNameErr? t('INPUT_VALIDATIONS.5') : null}
              />
            </Grid>
            <Grid item xs={12} hidden={!config?.customSubdomainAllowed}>
              <TextField
                required
                fullWidth
                id="custom-domain"
                label={t('TENANTREGISTER.8')}
                name="customDomain"
                autoComplete="customDomain"
                color="secondary"
                className="custom-subdomain"
                value={customDomain}
                onChange={customDomainChanged}
                error={subDomainError || domainErr || domainRegexErr }
                helperText={domainErr? t('INPUT_VALIDATIONS.8') :subDomainError? errorMessage : domainRegexErr? t("TENANTREGISTER.27") : t('TENANTREGISTER.26')}
              />
            </Grid>
            {
              config && !config.captchaRequired ? null
              
              :

            <Grid container xs={12} spacing={1} className="captcha-wrapper" >
              <Grid item xs={12} sm={6} md={6}>
                <img src={captcha} className="captcha" />
              </Grid>
              
              <Grid item xs={12} sm={12} md={6}>
              <FormControl className="captcha-input">
                <InputLabel className="label" htmlFor="captcha">{t('TENANTREGISTER.9')}</InputLabel>
                <Input
                  required
                  fullWidth
                  id="captcha"
                  type='text'
                  name="captcha"
                  autoComplete="captcha"
                  color="secondary"
                  value={captchaResult}
                  onChange={captchaResultChanged}
                  error={captchaError}
                  //helperText={captchaError}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={onCaptchaClick}>
                        <RefreshIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                </FormControl>
              </Grid>

             
            </Grid>
            }
            <Grid item xs={12} >
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label={t('TENANTREGISTER.10')}
              />
            </Grid>
            <Grid item xs={12} >
              <FormControlLabel

                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label={t('TENANTREGISTER.11')}
              />
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className="submit"
              onClick={submit}
            >
              {t('TENANTREGISTER.12')}
          </Button>
            <Button
              type="button"
              fullWidth
              variant="outlined"
              color="secondary"
              className="submit"
              onClick={onClickBack}
            >
              {t('TENANTREGISTER.13')}
          </Button>
          </Grid>
        </form>
      </div>
    </Grid>

  )
}
