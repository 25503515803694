import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {

}

export const PageNotFound: React.FC<Props> = () => {
    const { t, i18n } = useTranslation();
    return (
        <div>{t('NOTFOUND.1')}</div>
    )
}