import { HTTPClient } from '../httpClient/HttpClient';
import { REQUEST_ENDPOINTS } from '../paths/RequestURLs';

export const HTTP_REQUESTS =
{
    TENANT_API:
    {
        GET_CONFIG: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.TENANT_REGISTIRATION_CONFIG;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        POST_TENANT_REGISTER: (formData, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.TENANT_REGISTIRATION_NEW;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = formData;
            client.send();
        },
        POST_LOGIN: (formData, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.AUTH_API_LOGIN;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = formData;
            client.send();
        },
        GET_AUTH_INFO: (timezone,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.AUTH_API_INFO(timezone);
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        UPDATE_SELF: (updateParams,timezone, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.AUTH_API_INFO(timezone);
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = updateParams
            client.send();
        },
        GET_AUTH_USER_IMAGE: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.AUTH_API_INFO + "/image";
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        LOGOUT: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.AUTH_API_LOGOUT;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        GET_CAPTCHA: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.TENANT_REGISTIRATION_CAPTCHA;
            client.requestType = "GET";
            client.responseType = 'blob';
            client.acceptType = 'image/png';
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        CHECK_SUBDOMAIN: (subdomain, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.TENANT_REGISTIRATION_CHECK_SUBDOMAIN;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client._params = subdomain;
            client.send();
        },
        POST_FORGOT_PASSWORD: (formData, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.AUTH_API_FORGOT_PASSWORD_REQUEST;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = formData;
            client.send();
        },
        POST_FORGOT_PASSWORD_RESET: (formData, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.AUTH_API_FORGOT_PASSWORD_RESET;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = formData;
            client.send();
        },
        GET_TENANT_REGISTIRATION_CODE_CONFIRM: (formData, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.TENANT_REGISTIRATION_CONFIRM;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = formData;
            client.send();
        },
        POST_RESET_PASSWORD_VERIFY: (formData, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.AUTH_API_FORGOT_PASSWORD_VERIFY;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = formData;
            client.send();
        },
        GET_TENANT_URL: (subdomain, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.TENANT_URL;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client._params = subdomain;
            client.send();
        },
        POST_FORGOT_DOMAIN:(email, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.TENANT_FORGOT_DOMAIN;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = email;
            client.send();
        }

    },
    USERADMIN_API: {
        GET_USERS: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.USERADMIN_API_USERS;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        GET_ONE_USER: (userId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.USERADMIN_API_GET_ONE_USER + `/${userId}`;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        CREATE_USER: (formData, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.USERADMIN_API_CREATE_NEW;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = formData;
            client.send();
        },
        EDIT_USER: (userId, formData, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.USERADMIN_API_EDIT_USER + `/${userId}`;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = formData;
            client.send();
        },
        DELETE_USER: (userId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.USERADMIN_API_DELETE_USER + `/${userId}`;
            client.requestType = "DELETE";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        ADD_CLIENT: (newClient, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.USERADMIN_API_CLIENT;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = newClient;
            client.send();
        },
        GET_CLIENT: (clientId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.USERADMIN_API_CLIENT + `/${clientId}`;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        UPDATE_CLIENT: (clientId,clientObj,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.USERADMIN_API_CLIENT + `/${clientId}`;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = clientObj;
            client.send();
        },
        INVITE_CLIENT: (clientObj,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.USERADMIN_API_CLIENT_INVITE;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = clientObj;
            client.send();
        },
        DELETE_CLIENT: (clientId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.USERADMIN_API_CLIENT + `/${clientId}`;
            client.requestType = "DELETE";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        GET_ALL_CLIENTS: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.USERADMIN_API_GET_ALL_CLIENTS;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        GET_USER_IMAGE: (userId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.USERADMIN_API_GET_ONE_USER + `/${userId}/image`;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
    },
    OFFICE_API: {
        POST_OFFICE: (formData, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = formData;
            client.send();
        },
        GET_OFFICES: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        GET_OFFICE: (officeID, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeID}`;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        UPDATE_OFFICE: (officeID, formData, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeID}`;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = formData;
            client.send();
        },
        GET_ALL_FLOORS: (officeID, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeID}/floor`;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        CREATE_FLOOR: (officeID, formData, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeID}/floor`;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = formData;
            client.send();
        },
        GET_FLOOR: (officeID, floorID, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeID}/floor/${floorID}`;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        UPDATE_FLOOR: (officeID, floorID, formData, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeID}/floor/${floorID}`;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = formData;
            client.send();
        },
        DELETE_FLOOR: (officeID, floorID,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeID}/floor/${floorID}`;
            client.requestType = "DELETE";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        GET_FLOORPLAN: (officeID, floorID, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeID}/floor/${floorID}/plan`;
            client.requestType = "GET";
            client.responseType = 'blob';
            client.acceptType = 'image/png';
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        //TODO: Sor bunu! -fatih
        CREATE_FLOORPLAN: (officeID, floorID, plan,scale, successCallback, errorCallback) => {
            let client = new HTTPClient();   
            const json = JSON.stringify(scale);
            const blob = new Blob([json], {
              type: 'application/json'
            });
            const data = new FormData();
            data.append("file",plan)
            data.append("scale", blob);
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeID}/floor/${floorID}/plan`;
            client.requestType = "POST";
            client.contentType = client.CONTENT_TYPE.MULTIPART;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = data;
            client.send();
        },
        CREATE_NEW_ROOM: (officeId, floorId, room, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeId}` + "/floor/" + `${floorId}` + "/room";
            client.requestType = "POST";
            client.formData = room;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        UPDATE_ROOM: (officeID, floorID, roomID, formData, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeID}/floor/${floorID}/room/${roomID}`;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = formData;
            client.send();
        },
        GET_ROOMS: (officeId, floorId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeId}` + "/floor/" + `${floorId}` + "/rooms";
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        CREATE_NEW_SEAT: (officeId, floorId, seat, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeId}` + "/floor/" + `${floorId}` + "/seat";
            client.requestType = "POST";
            client.formData = seat;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        UPDATE_SEAT: (officeID, floorID, seatID, formData, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeID}/floor/${floorID}/seat/${seatID}`;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = formData;
            client.send();
        },
        GET_SEATS: (officeId, floorId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeId}` + "/floor/" + `${floorId}` + "/seats";
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        POST_IMAGE: (officeID, image, successCallback, errorCallback) => {
            let client = new HTTPClient();
            let imageData= new FormData();
            imageData.append('file',image);
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeID}/image`;
            client.requestType = "POST";
            client.contentType = client.CONTENT_TYPE.MULTIPART;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = imageData;
            client.send();
        },
        GET_IMAGE: (officeID, imageID, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeID}/image/${imageID}`;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        GET_FLOOR_IMAGE: (officeID,floorId, imageID, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeID}/floor/${floorId}/image/${imageID}`;
            client.requestType = "GET";
            client.responseType = 'blob';
            client.acceptType = 'image/png';
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        GET_FLOORS: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + '/floors';
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        GET_ALL_ROOMS: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + '/rooms';
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        GET_ALL_SEATS: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + '/seats';
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        GET_DETAILS_ALL_OFFICES: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + '/detail';
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        GET_FEATURES_ROOMS: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_FEATURES_ROOMS;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        CREATE_NEW_SEAT_FEATURE: (seatParams,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_FEATURES_SEATS;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = seatParams;
            client.send();
        },
        CREATE_NEW_ROOM_FEATURE: (roomParams,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_FEATURES_ROOMS;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = roomParams;
            client.send();
        },
        GET_FEATURES_SEATS: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_FEATURES_SEATS;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        DELETE_ONE_SEAT_FEATURE: (featureName,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_SEAT_FEATURES_DELETE(featureName);
            client.requestType = "DELETE";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        DELETE_SEAT: (officeID, floorID, seatID, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeID}/floor/${floorID}/seat/${seatID}`;
            client.requestType = "DELETE";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        DELETE_ROOM: (officeID, floorID, roomID, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_API + `/${officeID}/floor/${floorID}/room/${roomID}`;
            client.requestType = "DELETE";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        DELETE_ONE_ROOM_FEATURE: (featureName,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.OFFICE_ROOM_FEATURES_DELETE(featureName);
            client.requestType = "DELETE";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },

    },
    TEMPIMAGE_API: {
        POST_IMAGE: (image, successCallback, errorCallback) => {
            let client = new HTTPClient();
            let imageData= new FormData();
            imageData.append('file',image);
            client.requestPath = REQUEST_ENDPOINTS.TEMPIMAGE_API;
            client.requestType = "POST";
            client.contentType = client.CONTENT_TYPE.MULTIPART;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = imageData;
            client.send();
        },
        GET_IMAGE: (imageID, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.TEMPIMAGE_API + `/${imageID}`;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        }
    },
    CLIENT_API: {
        GET_BOOKINGS: (filter, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.CLIENT_API_BOOKING;
            client.requestType = "GET";
            client.addParameters(filter);
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        CREATE_BOOKING: (formData, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.CLIENT_API_BOOKING;
            client.requestType = "POST";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = formData;
            client.send();
        },
        GET_BOOKING_DETAILS: (bookingID, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.CLIENT_API_BOOKING + `/${bookingID}`;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        CANCEL_BOOKING: (bookingID, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.CLIENT_API_BOOKING + `/${bookingID}/cancel`;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        CANCEL_ALL_RECURRENT_BOOKINGS: (bookingID, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.CLIENT_API_BOOKING + `/${bookingID}/cancelRecurrent`;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        GET_OFFICE_DETAILS: (formData, officeID, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.CLIENT_API_SEARCH + `/office/${officeID}`;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = formData;
            client.send();
        },
        GET_OFFICES: (formData, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.CLIENT_API_SEARCH + `/offices`;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = formData;
            client.send();
        },
        GET_ROOMS_AND_SEATS: (formData, officeID, floorID, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.CLIENT_API_SEARCH + `/roomsAndSeats/${officeID}/${floorID}`;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.formData = formData;
            client.send();
        },
        GET_FLOOR_PLAN: ( officeID, floorID, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.CLIENT_API + `office/${officeID}/floor/${floorID}/plan` ;
            client.requestType = "GET";
            client.responseType = 'blob';
            client.acceptType = 'image/png';    
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        GET_FEATURES_ROOMS: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.CLIENT_FEATURES_ROOMS;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        GET_FEATURES_SEATS: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.CLIENT_FEATURES_SEATS;
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
        GET_SEATS_BOOKINGS: (seatId,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_ENDPOINTS.CLIENT_GET_SEAT_BOOKINGS(seatId);
            client.requestType = "GET";
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.send();
        },
    }


};
