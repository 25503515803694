import React from 'react';
import { Redirect } from "react-router-dom";
import { CustomSpinner } from '../../components/spinner/CustomSpinner';
import { LoginLayout } from '../../components/layouts/LoginLayout';

interface Props {
    isAuthenticated: boolean;
    isLoading: boolean;
    Component: React.ComponentType;
    path: string;
    tenantNotFound: boolean
}


export const PublicRoute: React.FC<Props> = (props) => {

    const { Component, isLoading, isAuthenticated, tenantNotFound } = props;

    if (tenantNotFound) {
        return <Redirect to="/tenantNotFound" />
    }
    if (isAuthenticated) {
        return <Redirect to="/dashboard" />
    }
    if (isLoading) {
        return <div><CustomSpinner /></div>
    }

    return <LoginLayout Component={Component} />
}
