import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';


interface Props {
    handleOpen: () => void,
    handleClose: () => void,
    open: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: "#ffffff",
            margin: theme.spacing(4),
            height: "30%",
            overflowY: "auto",
            width: "50%"

        },
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: "50%"
        },
        group: {
            margin: theme.spacing(3),
        },
        close: {
            justifyContent: 'flex-end',
            display: "flex"
        },
        header: {
            justifyContent: 'center',
            display: "flex",
            margin: theme.spacing(2),
        },
    }),
);

export const RolesHelperModal: React.FC<Props> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classes.paper}>

                        <div className={classes.close}>
                            <IconButton onClick={props.handleClose}>
                                <CloseIcon color="secondary" />
                            </IconButton>
                        </div>
                        <div className={classes.header}><Typography color="primary" variant="h5">{t("HELP.26")}</Typography></div>
                        <div className={classes.group}>

                            <Typography variant="body1" gutterBottom><strong> {t("HELP.25")} </strong></Typography>
                            
                        </div>

                    </div>

                </Fade>
            </Modal>
        </div>
    )
}
