import React,{useState} from 'react';
import {useTranslation} from 'react-i18next';
import AppBar from '../menu/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import * as data from '../../images.json';


interface Props {
    Component:React.ComponentType;
}

export const LoginLayout: React.FC<Props> = (props) => {
    const {t,i18n} = useTranslation();
    const [config, setConfig] = useState(null);
    const {Component} = props;

    return (
        <div>
            {/* <nav style={{ width: '100%', padding: '2rem 0', backgroundColor: 'gray' }}>
                <button onClick={() => { i18n.changeLanguage('de'); }}>Deutsch</button>
                <button onClick={() => { i18n.changeLanguage('en'); }}>English</button>
            </nav>
            <p> {t('TENANTREGISTER.1')}</p>
            {config ?  JSON.stringify(config): ""} */}
            {/* TODO => KAAN  
            Kendi componentini "JSON.stringify(config)" olan yerde çağır 
            
            Örn : <RegisterPage captchaRequired = {config.captchaRequired} ... > 

        */}
            <AppBar isRelative={true}/>
            <Grid container component="main" className="sign-up-wrapper">
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} style={{backgroundImage:`url(${data.BACKGROUNDS["register-bg"]})`}} className="image" />
            <Component/>
            </Grid>
            {/* <button onClick={()=>{navigate("/login")}}>go to home</button> */}
        </div>
    )
}