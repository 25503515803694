import React from 'react';
import './components/sass/styles.scss'
import { PageNotFound } from './components/notFound/PageNotFound';
import { Home } from './components/home/Home';
import { PrivateRoute } from "./middlewares/routes/PrivateRoute";
import { PrivateRouteUser } from "./middlewares/routes/PrivateRouteUser";
import { PublicRoute } from "./middlewares/routes/PublicRoute";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {Login} from './components/login/Login';
import {Register} from './components/registration/Register';
import { HTTP_REQUESTS } from './backendcomm/services/httpRequestService';
import { RegisterType } from './components/registration/RegisterType';
import { RegisterDisabled } from './components/registration/RegistrationDisabled';
import { RegisterSuccess } from './components/registration/RegistrationSuccess';
import { RegisterConfirm } from './components/registration/RegisterConfirm';
import {ForgotPassword} from './components/forgotPassword/ForgotPassword';
import { ForgotPasswordSuccess } from './components/forgotPassword/ForgotPasswordSuccess';
import { ForgotPasswordSuccessCompleted } from './components/forgotPassword/ForgotPasswordSuccessCompleted';
import {ChangePassword} from './components/forgotPassword/ChangePassword';
import {
  Switch,
  Route,
  HashRouter
} from "react-router-dom";
import UserManagement from './components/userManagement/UserManagement';
import { UserCrud } from './components/userManagement/UserCrud';
import { EditUser } from './components/userManagement/EditUser';
import { TenantNotActivated } from './components/tenantNotFound/TenantNotActivated';
import { TenantNotFound } from './components/tenantNotFound/TenantNotFound';
import { ForgotDomainForm } from './components/tenantNotFound/ForgotDomainForm';
import { ForgotDomainSuccess } from './components/tenantNotFound/ForgotDomainSuccess';
import { Properties } from './components/property/Properties';
import { Property} from './components/property/Property';
import  UploadPlan  from './components/upload/UploadPlan';
import { Objects } from './components/objectList/Objects';
import { Bookings } from './components/bookings/Bookings';
import OfficeEditorDataHandler from './components/canvas/OfficeEditorDataHandler';
import OfficeMeasureDataHandler from './components/canvas/OfficeMeasureDataHandler';
import OfficeReservationDataHandler from './components/canvas/OfficeReservationDataHandler';
import Clients from './components/clientManagement/Clients';
import ClientManagement from './components/clientManagement/ClientManagement';
import { LoginLayout } from './components/layouts/LoginLayout';
import Profile from './components/profile/Profile';
import DetailsNew from './components/details/DetailsNew';
import moment from 'moment-timezone';
import { ExtendBookingModal } from './components/bookings/extendBooking/ExtendBookingModal';
import { BookingDto } from './interfaces/BookingDto.class';
import Settings from './components/settings/Settings';

interface State {
  isAuthenticated: boolean;
  isLoading: boolean;
  authenticatedUserData: null;
  tenantNotFound: boolean;
  openExtendEventModal: boolean;
  extendBookingData: BookingDto | null
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: `${process.env.REACT_APP_PRIMARY_COLOR }`
    },
    secondary:{
      main: `${process.env.REACT_APP_SECONDARY_COLOR }`
    }
  }
});


class App extends React.Component<{}, State> {

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isLoading: true,
      authenticatedUserData: null,
      tenantNotFound:false,
      openExtendEventModal:false,
      extendBookingData: null
    };

  }

  handleCloseExtendModal = (): void => {
    this.setState({openExtendEventModal: false})
  }

  checkToOpenExtendBookingModal = (extendBooking: BookingDto): void => {
    console.log("1")
    const pref = localStorage.getItem('openExtendModal') || ""
    if(pref)
    {
      const userPrefForShowExtendModal = JSON.parse(localStorage.getItem('openExtendModal') || "");
      const boolValue = (/true/i).test(userPrefForShowExtendModal) //returns true
      if(extendBooking && boolValue){
        this.setState({openExtendEventModal: true, extendBookingData: extendBooking})
      }
    }
    else {
      localStorage.setItem("openExtendModal",JSON.stringify(true))
      const userPrefForShowExtendModal = JSON.parse(localStorage.getItem('openExtendModal') || "");
      const boolValue = (/true/i).test(userPrefForShowExtendModal) //returns true
      if(extendBooking && boolValue){
        this.setState({openExtendEventModal: true, extendBookingData: extendBooking})
      }
    }
    
  }

  

  componentDidMount() {
    if(window.location.hash != "#/register" && !window.location.hash.includes("#/register-confirm")){
      let loggedInUserInfo = null;
      const timezone = moment.tz.guess();
      HTTP_REQUESTS.TENANT_API.GET_AUTH_INFO(encodeURIComponent(timezone),(res) => {
        loggedInUserInfo = res;
       
        setTimeout(() => {
          this.setState({isAuthenticated:true, authenticatedUserData:loggedInUserInfo, isLoading:false},() => {
            if(res.extendBooking) {
              this.checkToOpenExtendBookingModal(res.extendBooking)
            }
          });
        }, 1000);
      }, (err) => {
        setTimeout(() => {
          this.setState({isLoading:false});
        }, 1000);
        //alert(JSON.stringify(err));
          if(err.error=="TenantNotFound"){
            this.setState({tenantNotFound:true});
            //navigate("/tenantNotFound");
          }
        
      });
    }else{
      setTimeout(() => {
        this.setState({isLoading:false});
      }, 1000);

    }
  }

  render() {
    return (
      <div className="App">
        <MuiThemeProvider theme={theme}>
          <HashRouter>
            <Switch>

              <PublicRoute path="/login"  isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={Login}/>
              <PublicRoute path="/register"  isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={Register}/>
              <PublicRoute path="/registerDisabled"  isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={RegisterDisabled}/>
              <PublicRoute path="/registertype"  isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={RegisterType}/>
              <PublicRoute path="/register-confirm"  isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={RegisterConfirm}/>
              <PublicRoute path="/registerSuccess"  isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={RegisterSuccess}/>

              <PublicRoute path="/forgotPassword"  isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={ForgotPassword}/>
              <PublicRoute path="/forgotPasswordSuccess"  isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={ForgotPasswordSuccess}/>
              <PublicRoute path="/forgotPasswordSuccessCompleted"  isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={ForgotPasswordSuccessCompleted}/>
              <PublicRoute path="/passwordReset"  isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={ChangePassword}/>

              <PublicRoute path="/tenantNotActivated"  isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={TenantNotActivated}/>
              
              <Route exact={true} path={"/tenantNotFound"}  > 
                {<LoginLayout Component={TenantNotFound} />}
              </Route>
              <PublicRoute path="/forgotDomain"  isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={ForgotDomainForm}/>
              <PublicRoute path="/forgotDomainSuccess"  isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={ForgotDomainSuccess}/>




              <PrivateRoute hasMenuBar={false} exact={true} path={"/dashboard"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={Home} />
              <PrivateRoute hasMenuBar={false} exact={true} path={"/users"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={UserManagement} />
              <PrivateRoute hasMenuBar={false} exact={true} path={"/offices"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={Properties} />
              <PrivateRoute hasMenuBar={false} exact={true} path={"/users/new"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={UserCrud} />
              <PrivateRoute hasMenuBar={false} exact={true} path={"/users/:id"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={UserCrud} />
              <Route exact={true} path={"/offices/:officeId"} >
                <PrivateRoute hasMenuBar={true}  exact={true} path={"/offices/:officeId"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={Property} />
              </Route>
              <Route exact={true} path={"/offices/:officeId/:floorId"} >
                <PrivateRoute hasMenuBar={true}  exact={true} path={"/offices/:officeId/:floorId"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={UploadPlan} />
              </Route>
              <PrivateRoute hasMenuBar={false} exact={true} path={"/offices"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={Objects} />
              <PrivateRoute hasMenuBar={false} exact={true} path={"/bookings"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={Bookings} />
              <PrivateRoute hasMenuBar={false} exact={true} path={"/clients"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={ClientManagement} />
              <PrivateRoute hasMenuBar={false} exact={true} path={"/profile"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={Profile} />
              <PrivateRoute hasMenuBar={false} exact={true} path={"/settings"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={Settings} />


              <Route exact={true} path={"/officeeditor/:officeId/:floorId"} >
                <PrivateRoute hasMenuBar={true} exact={true} path={"/officeeditor/:officeId/:floorId"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={OfficeEditorDataHandler} />
              </Route>

              <Route exact={true}  path={"/clients/:clientId"} >
                <PrivateRoute hasMenuBar={false} exact={true} path={"/clients/:clientId"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={Clients} />
              </Route>       
                
              <PrivateRoute hasMenuBar={true} exact={true} path={"/officemeasure/:officeId/:floorId"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={OfficeMeasureDataHandler} />
              <PrivateRouteUser hasMenuBar={false} exact={true} path={"/officereservation/:officeId/:floorId"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={OfficeReservationDataHandler} />
              {/* <Route exact={true}  path={"/officereservation/seat/:resourceId/schedule"} >
                <PrivateRouteUser hasMenuBar={false} exact={true} path={"/officereservation/seat/:resourceId/schedule"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={ResourceCalendarDataHandler} />
              </Route>   */}
              <PrivateRouteUser hasMenuBar={false} exact={true} path={"/user/dashboard"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={Home} />
              <PrivateRouteUser hasMenuBar={false} exact={true} path={"/user/offices"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={Objects} />
              <Route exact={true} path={"/user/offices/:id"} >
                <PrivateRouteUser hasMenuBar={false} exact={true} path={"/user/offices/:id"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={DetailsNew} />
              </Route>    
              <PrivateRouteUser hasMenuBar={false} exact={true} path={"/user/bookings"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={Bookings} />
              <PrivateRouteUser hasMenuBar={false} exact={true} path={"/user/profile"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading}  tenantNotFound={this.state.tenantNotFound} Component={Profile} />
              <PrivateRouteUser hasMenuBar={false} exact={true} path={"/user/users"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={UserManagement} />
              <PrivateRoute hasMenuBar={false} exact={true} path={"/"} authenticatedUserData={this.state.authenticatedUserData} isAuthenticated={this.state.isAuthenticated} isLoading={this.state.isLoading} tenantNotFound={this.state.tenantNotFound} Component={Home} />              
              <Route component={PageNotFound} />
            </Switch>
          </HashRouter>
          {
            this.state.openExtendEventModal && this.state.extendBookingData? 
              <ExtendBookingModal 
                open={this.state.openExtendEventModal} 
                handleClose={this.handleCloseExtendModal} 
                seatId={this.state.extendBookingData.seatId} 
                startDateTime={moment(this.state.extendBookingData.startDateTime)} 
                endDateTime={moment(this.state.extendBookingData.startDateTime).add(15,'minutes')}
              />
            :
              null
          }
        </MuiThemeProvider>
      </div>
    );
  }

}

export default App;
