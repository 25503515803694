import React from 'react';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import UsersTable from './UsersTable';
import { User } from '../../interfaces/User';
import { Button, Typography, Grid } from '@material-ui/core';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { withTranslation, WithTranslation } from 'react-i18next';
import { navigate } from '../../history/navigate';
import { notifySuccess, notifyError } from '../notification/Notification';
import { AppContext } from "../../middlewares/appContext/AppContext";

interface State {
    users: Array<User>,

}


class UserManagement extends React.Component<WithTranslation, State> {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        
        this.state = {
            users: [],

        };

    }
    
    newUserClick = ()=>{
        navigate('/users/new');
    }

    removeUserFromState(e) {
        let filteredArray = this.state.users.filter(item => item.id !== e)
        this.setState({users: filteredArray});
    }

    deleteUserClick = (userId) => {
        
        HTTP_REQUESTS.USERADMIN_API.DELETE_USER(userId,(res) => {
            notifySuccess(this.props.t("USERMANAGEMENT.9"));
            this.removeUserFromState(userId);
           
        }, (err) => {
            notifyError(err.message);
        })
    }

    editUserClick = (userId) => {
        navigate('/users/'+userId);
    }

    componentDidMount() {
        const context: any = this.context;

        document.title = this.props.t('TITLE.6');    

        if(!(context?.user.permissions && context?.user.permissions.includes('MANAGE_USERS'))){
            navigate('/dashboard');
        }else{
            HTTP_REQUESTS.USERADMIN_API.GET_USERS((res) => {

                this.setState({ users: res }, () => { console.log(this.state.users); })
            }, (err) => {
                console.log("Users get error")
            })
    
        }
    }

    render() {

        return (
            <div className="user-management-container">
                <Grid container spacing={2} direction="column" className="wrapper">
                    <Grid container className="header-items" direction="row">
                        <Typography variant="h5" className="user-header">{this.props.t('USERMANAGEMENT.1')}</Typography>
                        <Button className="new-user-btn" onClick={this.newUserClick} variant="contained"><PersonOutlineIcon color="primary" className="person-add-icon" />{this.props.t('USERMANAGEMENT.2')}</Button>
                    </Grid>
                    <Grid item className="users-table">
                        <UsersTable users={this.state.users} deleteUserClick={this.deleteUserClick} onClickEdit={this.editUserClick} />
                    </Grid>
                </Grid>
            </div>
        );
    }

}
export default withTranslation()(UserManagement);
