import { toast, Zoom, ToastPosition, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {generateIdByTime} from '../utils/Utils';

toast.configure();

let options = {
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    pauseOnFocusLoss: false,
    progress: undefined,
    toastId:"",
    transition: Zoom
}

export const notifySuccess = (message: string,id?:string) => {
    if(id){
        options.toastId = id
    }
    toast.success(message, options);
}

export const notifyInfo = (message: string) => {
    toast.info(message, options);
}

export const notifyWarn = (message: string) => {
    options.toastId = generateIdByTime();
    toast.warn(message, options);
}

export const notifyError = (message: string,id?:string) => {
    if(id){
        options.toastId = id
    }
    toast.error(message, options);
}
