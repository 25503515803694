import React, { Component, useState, useEffect } from 'react';

import CanvasKonvaUser from './CanvasKonvaUser';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { FilterBar } from '../filterBar/FilterBar';
import moment, { Moment } from 'moment';
import { notifySuccess, notifyError } from '../notification/Notification';
import { withTranslation, WithTranslation } from 'react-i18next';
import { availablePeriods } from '../../interfaces/AvailablePeriods';
import { AppContext } from "../../middlewares/appContext/AppContext";
import { OpeningHours } from '../../interfaces/OpeningHours';
import { Office } from '../../interfaces/Office';
import touchEventCheck from "../../services/isTouchEvent";
import {RoomCardList} from "../rooms/RoomCardList";

interface State {
    rooms: Array<{ id: string, name: string, description: string, isNew: boolean, isExclusive: boolean, points: Array<number>, available: boolean, features: Array<string>, availablePeriods: Array<availablePeriods>, reservedForClients:Array<{id:string,name:string}>,seats: Array<{ id: string, name: string, point: { x: number, y: number }, available: boolean,bookingInfo:string, roomId: string, features: Array<string>, availablePeriods: Array<availablePeriods> }> }>,
    officeId: string,
    floorId: string,
    imageUrl: string | null,
    requestReturned: boolean,
    roomFeatures: Array<string>,
    seatFeatures: Array<string>,
    startDateTime: string | null,
    endDateTime: string | null,
    startDateTimeMoment: Moment,
    endDateTimeMoment: Moment,
    searchType:string,
    isExact:boolean,
    flag: number,
    startMinTime:string,
    startMaxTime:string,
    endMinTime:string,
    endMaxTime:string,
    closedDays:Array<number>,
    openingHours:OpeningHours | null
}


class OfficeReservationDataHandler extends React.Component<WithTranslation, State> {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        const context: any = this.context;

        this.state = {
            rooms: [],
            officeId: "",
            floorId: "",
            imageUrl: null,
            requestReturned: false,
            roomFeatures: [],
            seatFeatures: [],
            startDateTime: localStorage.getItem('startDateTime') ?localStorage.getItem('startDateTime')  :moment().set('hours',9).set('minutes',0).toISOString(true),
            endDateTime: localStorage.getItem('endDateTime') ? localStorage.getItem('endDateTime'): moment().set('hours',17).set('minutes',0).toISOString(true),
            startDateTimeMoment: moment().set('hours',9).set('minutes',0),
            endDateTimeMoment: moment().set('hours',17).set('minutes',0),
            searchType: context?.searchType ? context.searchType : 'anytime',
            isExact:context?.searchType ? (context.searchType === 'anytime' ? false : true) : false,
            flag: 0,
            startMinTime:"08:00",
            startMaxTime:"18:00",
            endMinTime:"08:00",
            endMaxTime:"18:00",
            closedDays:[],
            openingHours: null
        };

    }

    componentDidMount = () => {
        const context: any = this.context;

        document.title = this.props.t('TITLE.13');

        let hash = window.location.hash;
        var res = hash.split("/");

        let officeId = res[2];
        let floorId = res[3];
        

        if (officeId && floorId) {

            let start = localStorage.getItem('startDateTime');
            let end = localStorage.getItem('endDateTime');
            if (typeof start !== 'undefined' && start !== null && typeof end !== 'undefined' && end !== null) {
                this.setState({
                    startDateTimeMoment: moment(start),
                    endDateTimeMoment: moment(end),
                    flag: 1
                });
            }
            else {
                localStorage.setItem('startDateTime', moment().set('hours',9).set('minutes',0).toISOString(true));
                localStorage.setItem('endDateTime', moment().set('hours',17).set('minutes',0).toISOString(true));
                this.setState({
                    startDateTimeMoment: moment().set('hours',9).set('minutes',0),
                    endDateTimeMoment: moment().set('hours',17).set('minutes',0),
                    flag: 1
                });

            }

            let search = {
                startDateTime: this.state.startDateTime,
                endDateTime: this.state.endDateTime,
                exactPeriod:this.state.isExact
            }
            

            let searchTypeStr = localStorage.getItem('searchType')
            if(searchTypeStr) {
                let isExact = searchTypeStr === 'anytime' ? false :true
                this.setState({searchType:searchTypeStr,isExact:isExact},()=>{search['exactPeriod']=this.state.isExact})
            }
            else if(this.context && context.searchType) {
                this.setState({searchType:context.searchType},()=>{
                    let isExact = this.state.searchType === 'anytime' ? false :true
                    this.setState({isExact:isExact},()=>{search['exactPeriod']=this.state.isExact})
                })
            }

            this.setState({
                officeId: officeId,
                floorId: floorId,
            },()=>{
                this.getOffice(this.state.officeId)
            });
            

            HTTP_REQUESTS.CLIENT_API.GET_FLOOR_PLAN(officeId, floorId, (res) => {

                let blob = new Blob(
                    [res],
                    { type: 'image/png' }
                );
                let image = URL.createObjectURL(blob);

                this.setState({
                    imageUrl: image
                });

                this.getRoomData(search);
            }, (err) => {

            })
        }
    }

    getOffice = (officeId:string) => {
        const context: any = this.context;
      let shortOfficeStr = localStorage.getItem("ShortOffices")
      let shortOffices = shortOfficeStr? JSON.parse(shortOfficeStr):null
      if(context?.allOffices){
        let office:Office = context.allOffices.filter(shortOffice => shortOffice.id === this.state.officeId)[0] 
        this.setState({openingHours:office.openingHours},()=>{
          this.getClosedDays()
          this.checkSearchAndOfficeTimes(this.state.startDateTimeMoment,this.state.endDateTimeMoment)
        })
      }else if(shortOffices) {
        let office:Office = shortOffices.filter(shortOffice => shortOffice.id === this.state.officeId)[0] 
        this.setState({openingHours:office.openingHours},()=>{
          this.getClosedDays()
          this.checkSearchAndOfficeTimes(this.state.startDateTimeMoment,this.state.endDateTimeMoment)
        })
      }
      else {
        this.getClosedDays()
        this.checkSearchAndOfficeTimes(this.state.startDateTimeMoment,this.state.endDateTimeMoment)      
      }
    }

    checkSearchAndOfficeTimes = (start:Moment,end:Moment):void => {
        let searchStartDateDay:string = start.format('dddd');
        let searchEndDateDay:string = end.format('dddd');
    
        let startMinStr = this.getOpeningTime(searchStartDateDay)
        
        let startMaxStr = this.getClosingTime(searchStartDateDay)
        
        let endMinStr = this.getOpeningTime(searchEndDateDay)
    
        let endMaxStr = this.getClosingTime(searchEndDateDay)

        this.setState({startMinTime:startMinStr,startMaxTime:startMaxStr,endMinTime:endMinStr,endMaxTime:endMaxStr})
        
    }
    
    getClosedDays = () => {
    
        let closedDays: Array<number> = new Array();

        let dayObj = {
            moFrom: 1, moTo: 1,
            tuFrom: 2, tuTo: 2,
            weFrom: 3, weTo: 3,
            thFrom: 4, thTo: 4,
            frFrom: 5, frTo: 5,
            saFrom: 6, saTo: 6,
            suFrom: 0, suTo: 0
        }
        if (this.state.openingHours) {

            let openingHours: any = this.state.openingHours

            for (const day in openingHours) {
                if (openingHours[day] === "") {
                    if (!closedDays.includes(dayObj[day]))
                        closedDays.push(dayObj[day])
                }
            }
            this.setState({ closedDays: closedDays })
        }
    }

    
    getOpeningTime = (day:string):string => {
        if(this.state.openingHours) {
          switch (day) {
            case 'Monday':
              return this.state.openingHours.moFrom
              break;
            case 'Tuesday':
              return this.state.openingHours.tuFrom
              break;
            case 'Wednesday':
              return this.state.openingHours.weFrom
              break;
            case 'Thursday':
              return this.state.openingHours.thFrom
              break;
            case 'Friday':
              return this.state.openingHours.frFrom
              break;
            case 'Saturday':
              return this.state.openingHours.saFrom
              break;
            case 'Sunday':
              return this.state.openingHours.suFrom
              break;  
            default:
              return "08:00"
              break;
          }
        }
        else {
          return "07:00"
        }
      }
    
    getClosingTime = (day:string):string => {
        if(this.state.openingHours) {
          switch (day) {
            case 'Monday':
              return this.state.openingHours.moTo
              break;
            case 'Tuesday':
              return this.state.openingHours.tuTo
              break;
            case 'Wednesday':
              return this.state.openingHours.weTo
              break;
            case 'Thursday':
              return this.state.openingHours.thTo
              break;
            case 'Friday':
              return this.state.openingHours.frTo
              break;
            case 'Saturday':
              return this.state.openingHours.saTo
              break;
            case 'Sunday':
              return this.state.openingHours.suTo
              break;  
            default:
              return "19:00"
              break;
          }
        }
        else {
          return "18:00"
        }
    }

    getRoomData = (search) => {
        HTTP_REQUESTS.CLIENT_API.GET_ROOMS_AND_SEATS(search, this.state.officeId, this.state.floorId, (res) => {
            let rooms = res;
            rooms.forEach(room => {
                room.isExclusive = true;
                if (room.seats == null) {
                    room.seats = [];
                }

                let oldPoints = room.points.slice();
                room.points = [];
                oldPoints.forEach(point => {
                    room.points.push(point.x);
                    room.points.push(point.y);
                });
            });

            this.setState({
                rooms: res,
                requestReturned: true,
            },()=>{
                let availableSeatCount = 0;
                
                this.state.rooms.forEach(room => {
                    
                    room.seats.forEach(seat=>{
                        
                        if(seat.available)
                        {
                            availableSeatCount = availableSeatCount + 1;
                        }
                    })
                });
                if(availableSeatCount == 0){
                    notifyError(this.props.t("FILTERBAR.9"),"no-duplicate");
                }
                else{
                    notifySuccess(this.props.t("CANVAS.30"));
                }
                
            });

            this.getFeatures();

        }, (err) => {

        });
    }

    getFeatures = () => {

        HTTP_REQUESTS.CLIENT_API.GET_FEATURES_ROOMS((roomFeatures) => {
            HTTP_REQUESTS.CLIENT_API.GET_FEATURES_SEATS((seatFeatures) => {
                this.setState({
                    roomFeatures: roomFeatures,
                    seatFeatures: seatFeatures
                })
            }, (err) => {

            });
        }, (err) => {

        });
    }

    exclusiveChanged = (value: boolean) => {
        //alert("Exclusive " + value);
    }

    sharedChanged = (value: boolean) => {
        //alert("Shared " +value);
    }

    dateChanged = (startDate: Moment, endDate: Moment, isSearchExact:boolean) => {
        localStorage.setItem('startDateTime', startDate.toISOString(true));
        localStorage.setItem('endDateTime', endDate.toISOString(true));
        this.setState({
            startDateTimeMoment: startDate,
            endDateTimeMoment: endDate
        },()=>{
            let search = {
                startDateTime: startDate.toISOString(true),
                endDateTime: endDate.toISOString(true),
                exactPeriod: isSearchExact
            }
    
            this.getRoomData(search);
        })

        
    }

    onExactChanged = (searchType: string) => {
        let exactToSet = searchType === 'anytime' ? false : true
        this.setState({isExact:exactToSet,searchType:searchType})
    }

    bookingSavedStatus = (saved: boolean, seatId: string) => {
        let rooms = this.state.rooms.slice();

        rooms.forEach(room => {
            room.seats.forEach(seat => {
                if (seat.id == seatId) {
                    if (saved) {
                        seat.available = false;
                        notifySuccess(this.props.t('CANVAS.23'));
                    } else {
                        notifyError(this.props.t('CANVAS.24'));                        
                    }
                }
            });
        });
        this.setState({
            rooms: rooms
        })
    }

    onSelectAvailable = (startDate: Moment, endDate: Moment) => {
        
        this.setState({
            startDateTimeMoment: startDate,
            endDateTimeMoment: endDate
        })

    }


    render() {
        return (
            <div>
                {this.state.flag !== 0 ? <FilterBar
                    exclusiveChanged={this.exclusiveChanged}
                    sharedChanged={this.sharedChanged}
                    dateChanged={this.dateChanged}
                    onExactChanged={this.onExactChanged}
                    startDateTime={this.state.startDateTimeMoment}
                    endDateTime={this.state.endDateTimeMoment}
                    isSearchTypeEnabled={true}
                    searchType={this.state.searchType}
                    startMinTime={this.state.startMinTime}
                    startMaxTime={this.state.startMaxTime}
                    endMinTime={this.state.endMinTime}
                    endMaxTime={this.state.endMaxTime}
                    setOfficeMaxandMinDate={this.checkSearchAndOfficeTimes}
                    disabledDays={this.state.closedDays}
                /> : null}
                {touchEventCheck() ? <RoomCardList data={this.state.rooms} workingHours={this.state.openingHours}/> :
                    <>
                        {this.state.requestReturned && this.state.imageUrl ?
                        <CanvasKonvaUser
                            rooms={this.state.rooms}
                            imageUrl={this.state.imageUrl}
                            roomFeatures={this.state.roomFeatures}
                            seatFeatures={this.state.seatFeatures}
                            isSearchTypeExact = {this.state.isExact}
                            startDateTime={this.state.startDateTimeMoment.utc(true)}
                            endDateTime={this.state.endDateTimeMoment.utc(true)}
                            bookingSavedStatus={this.bookingSavedStatus}
                            onSelectAvailable={this.onSelectAvailable}
                            workingHours={this.state.openingHours}
                        />
                        : null}
                </>
                    }
            </div>
        );
    }
}

export default withTranslation() (OfficeReservationDataHandler);