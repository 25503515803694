import React from 'react';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { withTranslation, WithTranslation } from 'react-i18next';
import { navigate } from '../../history/navigate';
import { notifySuccess, notifyError } from '../notification/Notification';
import { AppContext } from "../../middlewares/appContext/AppContext";
import { Client } from '../../interfaces/Client';
import { RouteComponentProps, withRouter } from 'react-router';
import ClientsForm from './ClientsForm';
import { InviteClient } from '../../interfaces/InviteClient';

interface State {
    clientId: string,
    client: Client,
    isNew: boolean,
    isReady:boolean,
    isLoading: boolean

}

type ClientParams = {
    clientId: string
};

type ClientDetailProps = RouteComponentProps<ClientParams>;


class Clients extends React.Component<WithTranslation & ClientDetailProps, State> {

    static contextType = AppContext;
   
    constructor(props) {
        super(props);
        
        this.state = {
            clientId: "",
            client: { id: "", name: "" },
            isNew: false,
            isReady: false,
            isLoading: false
        };

    }

    
    getClient = (id: string) => {
        HTTP_REQUESTS.USERADMIN_API.GET_CLIENT(id, (res) => {
            this.setState({ client: res,isReady:true })
        }, () => {
            notifyError(this.props.t("CLIENT.13"));
            navigate('/clients');
        })
    }

    editClient = (id:string,editObj) => {
        this.setState({isLoading:true})
        HTTP_REQUESTS.USERADMIN_API.UPDATE_CLIENT(id,editObj, () => {
            notifySuccess(this.props.t("CLIENT.10"));
            this.setState({isLoading:false})
            navigate("/clients");
        }, () => {
            notifyError(this.props.t("CLIENT.11"));
            this.setState({isLoading:false})
        })


    }

    createClient = (clientObj: InviteClient) => {
        this.setState({isLoading:true})
        HTTP_REQUESTS.USERADMIN_API.INVITE_CLIENT(clientObj, () => {
            notifySuccess(this.props.t("CLIENT.9"));
            this.setState({isLoading:false});
            navigate("/clients");
        }, () => {
            notifyError(this.props.t("CLIENT.12"));
            this.setState({isLoading:false})
        })

    }

    
    componentDidMount() {
        const context: any = this.context;

        if(!(context?.user.permissions && context?.user.permissions.includes('MANAGE_CLIENTS'))){
            navigate('/dashboard');
        }

        else if (this.props.match.params.clientId) {
            if (this.props.match.params.clientId === "new") {
                this.setState({ isNew: true ,isReady:true})
            }
            else {
                this.setState({ clientId: this.props.match.params.clientId }, () => {
                    this.getClient(this.state.clientId);
                })
            }
        }

    }
    
    componentDidUpdate(prevProps) {

        if (prevProps.match.params.clientId !== this.props.match.params.clientId) {
            if (this.props.match.params.clientId === "new") {
                this.setState({ isNew: true ,isReady:true})
            }
            else {
                this.setState({ clientId: this.props.match.params.clientId }, () => {
                    this.getClient(this.state.clientId);
                })
            }
            
        }
    }


    render() {

        return (
            <div className="clients-container" >
              {this.state.isReady ? <ClientsForm onUpdateClient={this.editClient} onCreateClient={this.createClient} isLoading={this.state.isLoading} isNew={this.state.isNew} client={this.state.client} /> : null}
            </div>
        );
    }

}
export default withTranslation()(withRouter(Clients))