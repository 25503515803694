import { Moment } from 'moment';
import React, { Component, useState, useEffect } from 'react';
import {Stage, Layer, Star, Text, Image, KonvaNodeComponent, Circle, Line, Rect} from 'react-konva';
import { availablePeriods } from '../../interfaces/AvailablePeriods';
import { BookingModal } from '../bookings/BookingModal';
import { InformationModal } from '../bookings/InformationModal';
import { AppContext } from "../../middlewares/appContext/AppContext";
import { navigate } from '../../history/navigate';
import BookingDialog from '../bookings/bookingDialog/BookingDialog';
import { OpeningHours } from '../../interfaces/OpeningHours';
import {WithTranslation, withTranslation} from "react-i18next";

interface Props {
    rooms: Array<{ id: string, name: string, description: string, isNew: boolean, isExclusive: boolean, features: Array<string> , availablePeriods: Array<availablePeriods>, points: Array<number>, reservedForClients: Array<{ id: string, name: string }>, available: boolean, seats: Array<{ id: string, name: string, point: { x: number, y: number }, bookingInfo: string, available: boolean, roomId: string, features: Array<string> , availablePeriods: Array<availablePeriods>  }> }>,
    imageUrl: string | null,
    roomFeatures: Array<string>,
    seatFeatures: Array<string>,
    startDateTime: Moment,
    endDateTime: Moment,
    bookingSavedStatus: (saved: boolean, seatId: string) => void,
    onSelectAvailable : (startDate: Moment, endDate: Moment) => void,
    isSearchTypeExact:boolean,
    workingHours?: OpeningHours | null
}

interface State {
    image: HTMLImageElement,
    canvasWidth: number,
    canvasHeight: number,
    pixelsPerMeter: number,
    safeDistanceInMeter: number,
    safeDistanceInPixels: number,
    modalOpen: boolean,
    infoModalOpen: boolean,
    selectedSeat: { id: string, name: string, point: { x: number, y: number }, available: boolean, roomId: string, features: Array<string>, availablePeriods: Array<availablePeriods> } | null,
    selectedRoom: { id: string, name: string, description: string, isNew: boolean, isExclusive: boolean, features: Array<string>, points: Array<number>, available: boolean, availablePeriods: Array<availablePeriods>, seats: Array<{ id: string, point: { x: number, y: number }, available: boolean, roomId: string, features: Array<string>, availablePeriods: Array<availablePeriods> }> } | null
    tooltip: { x: number, y: number, text: string, seatFeatures: object} | null,
    isExact:boolean,
    open: boolean,
    toolTipHeight:number,
    toolTipWidth:number,
    cursor:string
}

class CanvasKonvaUser extends React.Component<WithTranslation & Props, State> {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            image: new window.Image(),
            canvasWidth: 1000,
            canvasHeight: 1000,
            pixelsPerMeter: 0,
            safeDistanceInMeter: 1.5,
            safeDistanceInPixels: 0,
            modalOpen: false,
            infoModalOpen:false,
            selectedSeat: null,
            selectedRoom: null,
            tooltip: null,
            isExact:false,
            open: false,
            toolTipHeight: 80,
            toolTipWidth: 160,
            cursor: 'default'
        };

    }

    componentDidMount = () => {

        if (this.props.imageUrl) {
            const roomSizeInMeter = 60;
            const image = new window.Image();
            image.src = this.props.imageUrl;
            image.onload = () => {
                const pixelsPerMeter = image.naturalWidth / roomSizeInMeter;
                const safeDistanceInPixels = this.state.safeDistanceInMeter * pixelsPerMeter;

                this.setState({
                    image: image,
                    canvasWidth: image.naturalWidth,
                    canvasHeight: image.naturalHeight,
                    pixelsPerMeter: image.naturalWidth / roomSizeInMeter,
                    safeDistanceInPixels: safeDistanceInPixels,
                    isExact:this.props.isSearchTypeExact
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        
        if(prevProps.isSearchTypeExact != this.props.isSearchTypeExact ){
            this.setState({isExact:this.props.isSearchTypeExact})
        }

        
    }


    handleChairClick = e => {
        let id = e.target.attrs.id;
        let rooms = this.props.rooms;

        rooms.forEach(room => {

            room.seats.forEach(seat => {
                if (seat.id == id) {
                    console.log(room);
                    this.setState({
                        selectedSeat: seat,
                        selectedRoom: null
                    })
                    this.handleOpen();
                }
            });

        });
    };

    handleNotAvailableClick = e => {
        let id = e.target.attrs.id;
        let rooms = this.props.rooms;

        rooms.forEach(room => {

            room.seats.forEach(seat => {
                if (seat.id == id) {
                    console.log(room);
                    this.setState({
                        selectedSeat: seat,
                        selectedRoom: null
                    })
                    this.handleInfoOpen();
                }
            });

        });
    }

    onCanvasClick = e => {
        console.log(e.target.getStage().getPointerPosition());
    };

    onExclusiveRoomClicked = e => {
        let id = e.target.attrs.id;
        let rooms = this.props.rooms;

        rooms.forEach(room => {
            if (room.id == id) {
                console.log(room);
                //alert("Chair Clicked with id = " + seat.id + " is Free : " + seat.free);
                this.setState({
                    selectedSeat: null,
                    selectedRoom: room
                })
                this.handleOpen();
            }

        });

        this.handleOpen();
    };



    handleOpen = () => {
        this.setState({
            modalOpen: true
        })
    };

    handleClose = () => {
        this.setState({
            modalOpen: false
        })
    };

    handleInfoOpen = () => {
        this.setState({
            infoModalOpen: true
        })
        console.log("LOCATION",window.location)
        // navigate(window.location.pathname +'/schedule')
    };

    handleInfoClose = () => {
        this.setState({
            infoModalOpen: false
        })
    };

    handleNavSchedule = (seatId:string):void => {
        navigate(`/officereservation/seat/${seatId}/schedule`)
    }


    onMouseEnter = e => {
        let available = e.target.attrs.available;
        let farFromCircle = this.state.safeDistanceInPixels / 2 + 5;
        //console.log("mouseenter");
        let tooltip:any = { x: e.target.attrs.x + farFromCircle, y: e.target.attrs.y - farFromCircle, text: e.target.attrs.bookingInfo,seatFeatures:null};

        if (available) {
            tooltip = { x: e.target.attrs.x + farFromCircle, y: e.target.attrs.y - farFromCircle, text: "",seatFeatures:null};
        }

        this.props.rooms.map( room => {
            room.seats.filter(seat => {
                if(seat.id===e.target.attrs.id){
                    tooltip["seatFeatures"] =  seat.features;
                }
            })
        });

        this.setState({
            tooltip: tooltip,
            open: true,
            cursor : 'pointer'
        });
        let length = 0;
        // @ts-ignore
        this.state.tooltip.seatFeatures.map((feature,index) => {
            if (index<=9)
                length = feature.length > length ? feature.length : length;
        })
        if (length >= 10 && length < 15) {
            this.setState({
                toolTipWidth: (length- 10) * 10 + 160
            })
        } else if (length >= 15 && length < 20) {
            this.setState({
                toolTipWidth: (length- 10) * 20 + 160
            })
        }else if (length >= 20){
            this.setState({
                toolTipWidth: (length- 12) * 23 + 160
            })
        }else if (length < 10){
            this.setState({
                toolTipWidth: 160
            })
        }
        if (Object.keys(this.state.tooltip.seatFeatures).length > 1 && Object.keys(this.state.tooltip.seatFeatures).length <= 10) {
            this.setState({
                toolTipHeight: 80 +(Object.keys(this.state.tooltip.seatFeatures).length-1) * 30
            })
        }else if(Object.keys(this.state.tooltip.seatFeatures).length > 10){
            this.setState({
                toolTipHeight: 80 + 9 * 30
            })
        }else {
            this.setState({
                toolTipHeight: 80
            })
        }
    }

    // Check the coordinate height of the tooltip to be smaller than the coordinate height of the image
    handleHeightTooltip=()=>{
        if(this.state.toolTipHeight+this.state.tooltip.y > this.state.canvasHeight){
            return this.state.toolTipHeight+this.state.tooltip.y -this.state.canvasHeight
        }else {
            return 0
        }
    }
    // Check the coordinate width of the tooltip to be smaller than the coordinate width of the image
    handleWidthTooltip=()=>{
        if(this.state.toolTipWidth+this.state.tooltip.x > this.state.canvasWidth){
            return this.state.toolTipWidth+this.state.tooltip.x -this.state.canvasWidth
        }else {
            return 0
        }
    }


    onMouseOut = e => {
        let available = e.target.attrs.available;
        //console.log("mouseout");

        this.setState({
            tooltip: null,
            open: false,
            cursor : 'default',
        });

    }

    render() {
        let heightRatio = 25;
        return (
            <div className="reservation-canvas" style={{cursor: this.state.cursor}} >

                <Stage width={this.state.canvasWidth} height={this.state.canvasHeight} onClick={this.onCanvasClick}>
                    <Layer>
                        <Image image={this.state.image} />
                    </Layer>



                    <Layer>
                        {this.props.rooms.map((room, i) => (

                            <Line
                                key={i}
                                id={room.id}
                                points={room.points}
                                fill={room.reservedForClients.length > 0 ? "#f38e45ab" : "#3468fa59"}
                                strokeWidth={2}
                                stroke={"black"}
                                closed
                            />
                        ))}
                    </Layer>

                    <Layer>
                        {this.props.rooms.map((room, i) => (

                            room.seats.map((seat, i) => (
                                <Circle
                                    id={seat.id}
                                    key={seat.id}
                                    x={seat.point.x}

                                    y={seat.point.y}
                                    numPoints={5}
                                    {...(seat.available ? { fill: '#89b717' } : { fill: 'red' })}
                                    opacity={0.9}
                                    shadowColor="black"
                                    shadowBlur={10}
                                    radius={this.state.safeDistanceInPixels / 2}
                                    stroke='#ffffffb0'
                                    strokeWidth={this.state.safeDistanceInPixels / 4}
                                    // {...(seat.available && this.state.isExact ? { onclick: this.handleChairClick } : (seat.available && this.state.isExact === false ? {onClick:this.handleNotAvailableClick}:null) )}
                                    onclick ={this.handleNotAvailableClick}
                                    onMouseOver={this.onMouseEnter}
                                    onMouseLeave={this.onMouseOut}
                                    attrs={{ "available": seat.available, "bookingInfo": seat.bookingInfo }}
                                />

                            ))

                        ))}
                    </Layer>

                    <Layer  >
                        {this.state.open && this.state.tooltip?.text == "" ?
                            <>
                                <Rect
                                    x={this.state.tooltip?.x - this.handleWidthTooltip()}
                                    y={this.state.tooltip?.y - this.handleHeightTooltip()}
                                    width={this.state.toolTipWidth}
                                    height={this.state.toolTipHeight}
                                    fill={'#eef2f3'}
                                    cornerRadius={20}
                                    opacity={0.9}
                                >
                                </Rect>
                                <Text
                                    text={this.props.t("BOOKINGSTABLE.13")}
                                    x={this.state.tooltip?.x - this.handleWidthTooltip() +15}
                                    y={this.state.tooltip?.y - this.handleHeightTooltip() + 15}
                                    fontSize={19}
                                    listening={true}
                                    fill={'black'}
                                    fontStyle={'bold'}
                                    fontFamily={"sans-serif"}
                                >
                                </Text>
                                <Line
                                    x={this.state.tooltip?.x - this.handleWidthTooltip() +10}
                                    y={this.state.tooltip?.y - this.handleHeightTooltip() + 40}
                                    points={[this.state.toolTipWidth - 20, 0, 0, 0]}
                                    tension={0.5}
                                    closed
                                    stroke="#2c3e50"
                                />
                                {Object.keys(this.state.tooltip.seatFeatures).length === 0 &&
                                    <Text
                                        text={this.props.t("BOOKINGS.NO_FEATURE")}
                                        x={this.state.tooltip?.x - this.handleWidthTooltip() + 30}
                                        y={this.state.tooltip?.y - this.handleHeightTooltip() + 55}
                                        fontSize={17}
                                        listening={true}
                                        fill={'#2c3e50'}
                                        fontStyle={'normal'}
                                        align={'left'}
                                        fontFamily={"sans-serif"}
                                    >
                                    </Text>}
                                {
                                    this.state.tooltip.seatFeatures instanceof Array &&
                                    this.state.tooltip.seatFeatures.map((feature, count) => {
                                        return (count < 10 ?
                                            <>
                                                <Circle
                                                    x={this.state.tooltip?.x - this.handleWidthTooltip() + 20}
                                                    y={this.state.tooltip?.y - this.handleHeightTooltip() + 63 + (count*heightRatio)}
                                                    fill= {'black'}
                                                    opacity={0.9}
                                                    radius={4}
                                                />
                                                <Text
                                                    text={feature}
                                                    x={this.state.tooltip?.x - this.handleWidthTooltip() + 30}
                                                    y={this.state.tooltip?.y - this.handleHeightTooltip() + 55 + (count*heightRatio)}
                                                    fontSize={17}
                                                    fill={'#2c3e50'}
                                                    fontStyle={'normal'}
                                                    align={'left'}
                                                    fontFamily={"sans-serif"}
                                                >
                                                </Text>
                                            </> :
                                                <Text
                                                    text={`\n.... +${Object.keys(this.state.tooltip.seatFeatures).length - 10} ${this.props.t("BOOKINGS.More")}`}
                                                    x={this.state.tooltip?.x - this.handleWidthTooltip() + 20}
                                                    y={this.state.tooltip?.y - this.handleHeightTooltip() + 55 + (10*25)}
                                                    fontSize={18}
                                                    fill={'black'}
                                                    fontStyle={'bold'}
                                                    align={'left'}
                                                    fontFamily={"sans-serif"}
                                                >
                                                </Text>
                                        )
                                        }
                                    )}
                        </>
                            : null
                        }
                    </Layer>

                    <Layer>
                        {this.state.tooltip ?

                            <Text
                                text={this.state.tooltip.text}
                                x={this.state.tooltip.x}
                                y={this.state.tooltip.y}
                                fontSize={20}
                                listening={false}
                            >
                            </Text>

                            : null}
                    </Layer>



                </Stage>

                <BookingModal
                    handleOpen={this.handleOpen}
                    handleClose={this.handleClose}
                    open={this.state.modalOpen}
                    selectedSeat={this.state.selectedSeat}
                    selectedRoom={this.state.selectedRoom}
                    roomFeatures={this.props.roomFeatures}
                    seatFeatures={this.props.seatFeatures}
                    startDateTime={this.props.startDateTime}
                    endDateTime={this.props.endDateTime}
                    bookingSavedStatus={this.props.bookingSavedStatus}
                    handleNavSchedule={this.handleNavSchedule}
                />
                {/* {
                    this.state.infoModalOpen ?
                    <InformationModal
                        handleOpen={this.handleInfoOpen}
                        handleClose={this.handleInfoClose}
                        selectedSeat={this.state.selectedSeat}
                        open={this.state.infoModalOpen}
                        selectedRoom={this.state.selectedRoom}
                        startDateTime={this.props.startDateTime}
                        endDateTime={this.props.endDateTime}
                        onSelectAvailable={this.props.onSelectAvailable}
                        bookingSavedStatus={this.props.bookingSavedStatus}
                        handleNavSchedule={this.handleNavSchedule}
                        /> 
                        :
                    null
                } */}

{
                    this.state.infoModalOpen ? 
                    <BookingDialog
                        handleClose={this.handleInfoClose}
                        open={this.state.infoModalOpen}
                        workingHours = {this.props.workingHours}
                        seatFeatures = {this.props.seatFeatures}
                        selectedSeat = {this.state.selectedSeat?.id}
                        dialogHeader = {`${this.state.selectedRoom?.name}-${this.state.selectedSeat?.name}`}
                    /> 
                    :
                    null
                }
                
            </div>
        );
    }
}

export default withTranslation() (CanvasKonvaUser);