import React, { Component, useState, useEffect, useContext } from 'react';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { notifyError, notifySuccess } from '../notification/Notification';
import { useTranslation } from 'react-i18next';
import { ObjectList } from './ObjectList';
import { FilterBar } from '../filterBar/FilterBar';
import { Office } from '../../interfaces/Office';
import moment, { Moment } from 'moment';
import { AppContext } from "../../middlewares/appContext/AppContext";
import { getShortOfficeArray } from '../utils/Utils';

interface Props {

}

export const Objects: React.FC<Props> = () => {

  const { t, i18n } = useTranslation();

  const [config, setConfig] = useState();
  const [objArray,setObjArray] = useState(Array<Office>());
  const [startDateTime, setStartDateTime] = React.useState<Moment>(()=>{
    let storedStartDateTime: any = localStorage.getItem('startDateTime');
    return storedStartDateTime ? moment(storedStartDateTime) : moment().set('hours',9).set('minutes',0)
  });
  const [endDateTime, setEndDateTime] = React.useState<Moment>(() => {
    let storedEndDateTime: any = localStorage.getItem('endDateTime');
    return storedEndDateTime ? moment(storedEndDateTime) : moment().set('hours',17).set('minutes',0)
  });
  const context = useContext(AppContext);
  const [searchType, setSearchType] = React.useState<string>('exact');

  document.title = t('TITLE.7');

  useEffect(() => {
    //alert('looolo');
    //TODO: GET All OBJECT REQUESTS (Kaan)
    // HTTP_REQUESTS.OFFICE_API.GET_DETAILS_ALL_OFFICES((res)=> {
    //   setObjArray(res);
    // },err=> {
    //   notifyError("Error when getting offices")
    // })
    // let search = { startDateTime: startDateTime, endDateTime: endDateTime};
    if(context?.searchType){
      setSearchType(context.searchType)
    }
    let isExact = searchType === 'anytime' ? false:true;
    dateChanged(startDateTime, endDateTime,isExact);
    
  }, []);

  function getOffices(interval){
    HTTP_REQUESTS.CLIENT_API.GET_OFFICES(interval, res => {
      setObjArray(res);
      if(context && context.updateAllOffices)
      {
        let shortOffices = getShortOfficeArray(res)
        localStorage.setItem("ShortOffices",JSON.stringify(shortOffices))
        context.updateAllOffices(shortOffices)
      }
      if(res.length === 0){
        notifyError(t('FILTERBAR.9'))
      }
      else {  
        notifySuccess(t('CANVAS.30'),"no-duplicate")
      }
    },err=> {
      notifyError(t('CANVAS.32'))
    })
  }

  function exclusiveChanged(value: boolean){
    //alert("Exclusive " + value);
  }

  function sharedChanged(value: boolean){
    //alert("Shared " +value);
  }

  function dateChanged(startDate: Moment, endDate: Moment, isSearchExact:boolean){

    setStartDateTime(startDate);
    setEndDateTime(endDate);

    localStorage.setItem('startDateTime', startDate.toISOString(true));
    localStorage.setItem('endDateTime', endDate.toISOString(true));
    let interval = {
        startDateTime: startDate.toISOString(true),
        endDateTime: endDate.toISOString(true),
        exactPeriod: isSearchExact
    }

    getOffices(interval);
    
  }


  return (
    
    <div style={{width:"100%"}}>
      <FilterBar 
        exclusiveChanged={exclusiveChanged}
        sharedChanged={sharedChanged}
        dateChanged={dateChanged}
        startDateTime={startDateTime}
        endDateTime={endDateTime}
        searchType={searchType}
        isSearchTypeEnabled={true}
      />
      <ObjectList data= {objArray} />
    </div>
    
  )


}