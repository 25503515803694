const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

export const REQUEST_ENDPOINTS =  {
    TENANT_REGISTIRATION_CAPTCHA :BACKEND_API_URL + '/tenant/registration/captcha',
    TENANT_REGISTIRATION_CHECK_SUBDOMAIN :BACKEND_API_URL + '/tenant/registration/checkSubdomain',
    TENANT_REGISTIRATION_CONFIG :BACKEND_API_URL + '/tenant/registration/config',
    TENANT_REGISTIRATION_NEW :BACKEND_API_URL + '/tenant/registration/new',
    TENANT_REGISTIRATION_CONFIRM: BACKEND_API_URL + '/tenant/registration/confirm',
    TENANT_URL:BACKEND_API_URL + '/tenant/url',
    TENANT_FORGOT_DOMAIN : BACKEND_API_URL + '/tenant/forgotDomain',
    AUTH_API_LOGIN :BACKEND_API_URL + '/auth/login',
    AUTH_API_INFO : (timezone) => BACKEND_API_URL + `/auth/info?timezone=${timezone}`,
    AUTH_API_LOGOUT :BACKEND_API_URL + '/auth/logout',
    AUTH_API_FORGOT_PASSWORD_REQUEST :BACKEND_API_URL + '/auth/resetPassword/request',
    AUTH_API_FORGOT_PASSWORD_RESET   :BACKEND_API_URL + '/auth/resetPassword/reset',
    AUTH_API_FORGOT_PASSWORD_VERIFY   :BACKEND_API_URL + '/auth/resetPassword/verify',
    USERADMIN_API_USERS: BACKEND_API_URL + '/useradmin/users',
    USERADMIN_API_GET_ONE_USER: BACKEND_API_URL + '/useradmin/user',
    USERADMIN_API_CREATE_NEW: BACKEND_API_URL + '/useradmin/user',
    USERADMIN_API_EDIT_USER: BACKEND_API_URL + '/useradmin/user',
    USERADMIN_API_DELETE_USER: BACKEND_API_URL + '/useradmin/user',
    USERADMIN_API_CLIENT: BACKEND_API_URL + '/useradmin/client',
    USERADMIN_API_GET_ALL_CLIENTS: BACKEND_API_URL + '/useradmin/clients',
    USERADMIN_API_CLIENT_INVITE: BACKEND_API_URL + '/useradmin/client/invite',
    OFFICE_API: BACKEND_API_URL + '/office',
    OFFICE_FEATURES_ROOMS: BACKEND_API_URL + '/office/features/rooms',
    OFFICE_FEATURES_SEATS: BACKEND_API_URL + '/office/features/seats',    
    OFFICE_SEAT_FEATURES_DELETE: (featureName) => BACKEND_API_URL + `/office/features/seats/${featureName}`,    
    OFFICE_ROOM_FEATURES_DELETE: (featureName) => BACKEND_API_URL + `/office/features/rooms/${featureName}`,
    TEMPIMAGE_API:BACKEND_API_URL + '/tempimage',
    CLIENT_API_BOOKING: BACKEND_API_URL + '/client/booking',
    CLIENT_API_SEARCH: BACKEND_API_URL + '/client/search',
    CLIENT_API: BACKEND_API_URL + '/client/',
    CLIENT_FEATURES_ROOMS: BACKEND_API_URL + '/client/office/features/rooms',
    CLIENT_FEATURES_SEATS: BACKEND_API_URL + '/client/office/features/seats',
    CLIENT_GET_SEAT_BOOKINGS: (seatId) => BACKEND_API_URL + `/client/seats/${seatId}/booking`
}
