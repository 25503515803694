import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Button, Checkbox, Fade, FormControlLabel, Grid, InputAdornment, TextField} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import RRuleGeneratorTS, { translations } from 'react-rrule-generator';
import 'react-rrule-generator-ts/dist/index.css';
import '../../App.css';
import "flatpickr/dist/themes/material_red.css";
import Flatpickr from "react-flatpickr";
import moment, { Moment } from 'moment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { english } from 'flatpickr/dist/l10n/default';
import { German } from 'flatpickr/dist/l10n/de';
import { useTranslation } from 'react-i18next';
import {RRule} from 'rrule'
import { BookingFeatures } from '../bookings/bookingFeatures/BookingFeatures';


interface Props {
  openBookingModal: boolean,
  handleCloseBookingModal: () => void,
  anchorEl: HTMLButtonElement | null,
  startTime: Moment,
  endTime: Moment,
  onCreateBooking: (booking: any) => void,
  resourceId: string,
  seatFeatures: Array<string>,
  roomFeatures: Array<string>,
  selectedSeatFeatures: Array<string>,
  selectedRoomFeatures: Array<string>,
  onChangeSeatFeatures: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onChangeRoomFeatures: (event: React.ChangeEvent<HTMLInputElement>) => void,
  disableDatePickers?: boolean;
  isLoading: boolean;

}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      overflowY: 'scroll',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      display: 'flex'
},
    paper: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
      maxWidth: '50%',
      margin: 'auto',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
    },
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  })
);


export const BookingPopover: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [rrule, setRrule] = useState<string>("");
  const [startError, setStartError] = React.useState<boolean>(false);
  const [endError, setEndError] = React.useState<boolean>(false);
  const [startErrorMsg, setStartErrorMsg] = React.useState<string>('');
  const [endErrorMsg, setEndErrorMsg] = React.useState<string>('');
  const [selectedDateEnd, setSelectedDateEnd] = React.useState<Moment>(props.endTime);
  const [selectedDateStart, setSelectedDateStart] = React.useState<Moment>(props.startTime);
  const [isRecurrent, setIsRecurrent] = React.useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [isExpandedFeature, setIsExpendedFeature] = React.useState<string | false>(false);
  const {isLoading} = props

  const en = {
    ...english,
    firstDayOfWeek: 1
  }

  const handleIsRecurrent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRecurrent(event.target.checked)
  }

  const handleRruleChanged = (rRule) => {
    syncRruleDateAndDateInputs(rRule)
    setRrule(rRule);
  }
  /**
   *  Sync selectedDateStart and selectedDateEnd with rRule's start date.
   * @param rRule
   */
  const syncRruleDateAndDateInputs = (rRule) => {
    const DTSTART = moment(rRule.split('DTSTART:').pop().split('RRULE')[0].replace(/\s+/g, '')).format('YYYY-MM-DD');
    const currentStartTime = moment(selectedDateStart).format('HH:mm');
    const currentEndTime = moment(selectedDateEnd).format('HH:mm');
    setSelectedDateStart(moment(DTSTART + ' ' + currentStartTime));
    setSelectedDateEnd(moment(DTSTART + ' ' + currentEndTime));
  }

  React.useEffect(() => {
    // Create a rule:
    const rule = new RRule({
      freq: RRule.WEEKLY,
      interval: 1,
      until: selectedDateEnd.toDate(),
      dtstart: selectedDateStart.toDate(),
    })
    setRrule(rule.toString())

  }, [])
  const onCancelClick = () => {
    if(props.handleCloseBookingModal){
      props.handleCloseBookingModal();
    }
  }

  const onBookClick = () => {
    if(props.onCreateBooking){
      const bookingObject = {
        startDateTime: moment(selectedDateStart).utc(true),
        endDateTime: moment(selectedDateEnd).utc(true),
        rrule: isRecurrent ? rrule : "",
        seatId: props.resourceId,
        seatFeatures: props.selectedSeatFeatures,
        roomFeatures: props.selectedRoomFeatures,
        allDay: props.disableDatePickers
      }
      props.onCreateBooking(bookingObject)
    }
  }

  const formValidate = () => {
    let formValid = true;
    const requiredFields = [
      selectedDateStart,
      selectedDateEnd,
      props.disableDatePickers
    ]
    requiredFields.forEach((item, index) => {
      if(item === null || item === undefined) {
        console.log('form no valid', index);
        formValid = false;
        return false;
      }
    });
    return formValid;
  }

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const calculateDateFormatRender = (isRecurrent: boolean) => {
    return (isRecurrent) ? 'HH:mm' : 'DD-MM-YYYY HH:mm';
  }

  const generateOptions = (isRecurrent, props) => {
    return {
      dateFormat: 'd-m-Y H:i',
      defaultDate: props.startTime.format('DD-MM-YYYY HH:mm'),
      enableTime: true,
      noCalendar: !!(isRecurrent),
      // minTime: props.startMinTime,
      minDate: (isRecurrent) ? false : props.startTime.format("DD-MM-YYYY"),
      // maxTime: props.startMaxTime,
      time_24hr: true,
      minuteIncrement:30,
      locale: localStorage.getItem('language') === 'en' ? en : German,
      clickOpens: (!props.disableDatePickers),
      allowInput: (!props.disableDatePickers),
      disable: [
        function(date) {
          return (props.disabledDays ? props.disabledDays.includes(date.getDay()):0);
        }
      ],
    }
  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openBookingModal}
        onClose={props.handleCloseBookingModal}
        closeAfterTransition
      >
        <Fade in={props.openBookingModal}>
          <div className={classes.paper}>
            <Grid container alignItems='center' justifyContent='center' style={{ marginTop: 10 }}>
              <Grid item xs={12} md={4} alignItems="center">
                <Flatpickr
                  key={(isRecurrent) ? 'isRecurrentStart' : 'noRecurrentStart'}
                  options={generateOptions(isRecurrent, props)}
                  onChange={date => {
                    setSelectedDateStart(moment(date[0]));
                  }}
                  render={({ defaultValue }, ref) => {
                    return (
                      <div>
                        <TextField
                          style={{ marginRight: "16px" }}
                          color="secondary"
                          label={t('FILTERBAR.1')}
                          InputProps={{
                            endAdornment: <InputAdornment position="end"><CalendarTodayIcon fontSize="small" color="primary" /> </InputAdornment>,
                          }}
                          value={selectedDateStart.format(calculateDateFormatRender(isRecurrent))} ref={ref}
                        />

                      </div>
                    )
                  }}
                />{startError ? <small style={{ color: 'red' }}> {startErrorMsg} </small> : null}
              </Grid>
              <Grid item xs={12} md={4} alignItems="center" justifyContent="center">
                <Flatpickr
                  key={(isRecurrent) ? 'isRecurrentEnd' : 'noRecurrentEnd'}
                  options={generateOptions(isRecurrent, props)}
                  onChange={date => {
                    setSelectedDateEnd(moment(date[0]));
                  }}
                  render={({ defaultValue }, ref) => {
                    return (
                      <div>
                        <TextField
                          style={{ marginRight: "16px" }}
                          color="secondary"
                          label={t('FILTERBAR.2')}
                          InputProps={{
                            endAdornment: <InputAdornment position="end"><CalendarTodayIcon fontSize="small" color="primary" /> </InputAdornment>,
                          }}
                          value={selectedDateEnd.format(calculateDateFormatRender(isRecurrent))} ref={ref}
                        />
                      </div>
                    )
                  }}
                />{endError ? <small style={{ color: 'red' }}> {endErrorMsg} </small> : null}
              </Grid>
              <Grid item xs={12} md={4} alignItems="center" justifyContent="center" style={{marginTop:20}}>
                   <FormControlLabel
                      control={
                        <Checkbox
                          checked={isRecurrent}
                          onChange={handleIsRecurrent}
                          name='isRecurrent'
                          color='primary'
                        />
                      }
                    label={t('BOOKINGS.Recurrent Event')}
                  />
              </Grid>
              <Grid item xs={12} >
                {(props.seatFeatures || props.roomFeatures )?
                    <BookingFeatures
                        roomFeatures={props.roomFeatures}
                        seatFeatures={props.seatFeatures}
                        selection
                        onChangeSeatFeatures={props.onChangeSeatFeatures}
                        onChangeRoomFeatures={props.onChangeRoomFeatures}
                        selectedSeatFeatures={props.selectedSeatFeatures}
                        selectedRoomFeatures={props.selectedRoomFeatures}
                    /> : null}
              </Grid>
            </Grid>
            <Grid item xs={12} alignItems="center" justify="center" style={{ marginTop: 10 }}>
              <Accordion expanded={isRecurrent} onChange={handleChange('panel1')} className="recurrent-accordion-wrapper">
                <AccordionSummary
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>{t('BOOKINGS.Recurrent Event Settings')}</Typography>
                 
                </AccordionSummary>
                <AccordionDetails>
                  <RRuleGeneratorTS
                    onChange={handleRruleChanged}
                    value={rrule}
                    config={{
                      repeat: ["Monthly", "Weekly"],
                      yearly: "on",
                      end: ["On date"],
                      weekStartsOnSunday: false,
                      hideError: true,
                      hideStart:false
                    }}
                    translations={localStorage.getItem('language') === 'en' ? translations.english : translations.german}
                  />
                </AccordionDetails>
              </Accordion>
              
            </Grid>
            <Grid container alignItems='center' justifyContent='space-between' style={{ marginTop: 10 }}>

                <Button variant="outlined" onClick={onCancelClick} > {t('BOOKINGS.9')} </Button>

                <Button color="primary" variant="contained"  disabled={!formValidate() || isLoading} onClick={onBookClick}> {t('BOOKINGS.8')} </Button>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
