import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import DoneAllTwoToneIcon from '@material-ui/icons/DoneAllTwoTone';
import { useTranslation } from 'react-i18next';
import { navigate } from '../../history/navigate';

interface Props {

}

export const RegisterSuccess: React.FC<Props> = () => {
    const { t, i18n } = useTranslation();

    const onLoginClick =()=>{
        navigate('/login');
    }
  document.title = t('TITLE.2');
    
    return (

        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} >
            <div className="register-success">
                <DoneAllTwoToneIcon className="success-icon" />
                {t('TENANTREGISTER.15')}
                <hr/>
                {t('TENANTREGISTER.18')}
                
                <Link onClick={onLoginClick} >
                {t('FORGOTPASSWORD.7')}
              </Link>
            </div>
           
        </Grid>
    );
}